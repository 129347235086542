import { useEffect } from 'react';

let suffix: string;

export function setSuffix(value: string, update?: boolean) {
  if (update) {
    document.title = `${document.title.replace(suffix, '')}${value}`;
  }
  suffix = value;
}

export function useDocumentTitle(title: string) {
  return useEffect(() => {
    document.title = `${title}${suffix ? ` - ${suffix}` : ''}`;
  }, [title]);
}
