import { YogaExercise } from '@cocast/types';

export const YogaExercises = [
  {
    category: 'yoga',
    recordType: 'stretching',
    id: '0eaba2d6ad704ae13846466f4ab72089',
    imageId: 'dea9c4f6-16ed-459a-8b63-8df1a1509600',
    name: 'child pose',
  },
  {
    category: 'yoga',
    recordType: 'stretching',
    id: '72d850dbee5d9f6f4f1d61e8ef269b35',
    imageId: 'd3dc6dfa-fb77-46b4-97cf-197a19509900',
    name: 'fish pose (matsyasana)',
  },
  {
    category: 'yoga',
    recordType: 'stretching',
    id: '559575848b35d627b07c3a1a44654e66',
    imageId: 'ffc2fb71-a9fc-45e9-65d9-0f63bc5d1c00',
    name: 'half moon pose (ardha chandrasana)',
  },
  {
    category: 'yoga',
    recordType: 'stretching',
    id: '9845fae435fa3acf08feb22536c1f0ea',
    imageId: 'fabf0c8b-570d-4393-6922-939ebd545600',
    name: 'lotus poisition (padmasana)',
  },
  {
    category: 'yoga',
    recordType: 'stretching',
    id: '54ed2d21a157aee9a77d248b7a4ad411',
    imageId: '8928dbe0-94d6-499b-3296-88f3389da500',
    name: 'seated forward bend (upavista konasana)',
  },
  {
    category: 'yoga',
    recordType: 'stretching',
    id: 'a11a69701712e3df08c885334b5d68a0',
    imageId: '357f6f02-e563-4738-7c36-01f5a9a36200',
    name: 'tree pose (vrksasana)',
  },
  {
    category: 'yoga',
    recordType: 'stretching',
    id: '15071b0941ba3a106c8a9446494a808f',
    imageId: '390056ea-b928-4fb5-1fef-cbddb0db4b00',
    name: 'triangle pose (trikonasana)',
  },
  {
    category: 'yoga',
    recordType: 'stretching',
    id: 'cb6c4463635b598dc9abcc3f845ee4b1',
    imageId: '9755151b-4b5c-4446-d6c1-09e4401d7600',
    name: 'wide legged forward bend (prasarita padottanasana)',
  },
] as YogaExercise[];
