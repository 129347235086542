import { Icons } from '@cocast/icons';
import { getImageDeliverUrl } from '@cocast/utils';
import { formatProgramWeek } from '@cocast/utils-business/format';
import { Tag } from 'components';
import { Avatar } from 'legacy/Avatar';
import { memo } from 'react';
import { businessStore, clientStore } from 'state';
import { Sheet, SheetProps, useSheet } from 'widgets';
import { TrainingProgramDetailProgress } from './TrainingProgramDetailProgress';
import { TrainingProgramDetailWorkouts } from './TrainingProgramDetailWorkouts';

interface Props extends Pick<SheetProps, 'open' | 'setOpen'> {}

function TrainingProgramDetailComponent({ open, setOpen }: Props) {
  const training = clientStore.info.data.training;
  const { programCurrentDay, programStartAt, programEndAt } = training;
  const { weeks, name, createdBy, cover, tags } = training.program;
  const staff = businessStore.getStaff(createdBy);

  const info = (
    <>
      <h3 className="capitalize line-clamp-2">{name}</h3>
      {tags?.length ? (
        <div className="space-x-xs">
          {tags.map((tag) => (
            <Tag key={tag.id} tag={tag} className="sm" />
          ))}
        </div>
      ) : null}
      {staff ? (
        <div className="flex-start-center space-x-xs mt-xs">
          <Avatar size={22} name={staff.name} src={staff.avatar} id={createdBy} crossOrigin />
          <p className="text-caption text-base-400">Planed by {staff.name}</p>
        </div>
      ) : null}
    </>
  );

  return (
    <Sheet open={open} setOpen={setOpen} onConfirm>
      {cover ? (
        <div
          className="h-[180px] xxs:h-[210px] xs:h-[240px] sm:h-[280px] bg-cover bg-center rounded-lg my-lg pt-md flex items-end overflow-hidden"
          style={{ backgroundImage: `url("${getImageDeliverUrl(cover, { w: 600 })}")` }}
        >
          <div
            className="w-full pl-md pb-sm pt-xl"
            style={
              cover
                ? { background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0.54%, rgba(0, 0, 0, 0.7) 99.45%)' }
                : undefined
            }
          >
            {info}
          </div>
        </div>
      ) : null}

      <div className="flex-start-center space-x-sm">
        <Icons.Calendar size={5.5} className="text-primary" />
        <p className="text-b2 font-semibold text-base-400">
          {weeks?.length} Weeks plan • {formatProgramWeek(programCurrentDay)}
        </p>
      </div>
      <div className="flex-start-center space-x-sm pt-sm">
        <Icons.Program size={5.5} className="text-mint" />
        <p className="text-b2 font-semibold text-base-400">
          From {clientStore.formatDate(programStartAt)} to {clientStore.formatDate(programEndAt)}
        </p>
      </div>

      <TrainingProgramDetailProgress training={training} />

      <TrainingProgramDetailWorkouts training={training} />
    </Sheet>
  );
}

const TrainingProgramDetail = memo(TrainingProgramDetailComponent);

export function useTrainingProgramDetail() {
  return useSheet(TrainingProgramDetail);
}

export type TrainingProgramDetailSheet = ReturnType<typeof useTrainingProgramDetail>;
