import { BusinessWorkoutVo } from '@cocast/api-client';
import { Icons } from '@cocast/icons';
import { countWorkoutSets } from '@cocast/utils-business/workout';
import { default as classNames } from 'classnames';
import { memo, ReactNode, useCallback } from 'react';
import { Clickable } from '../../widgets';
import { ExerciseDetailSheet } from '../Exercise/ExerciseDetail';
import { WorkoutDetailSheet } from './WorkoutDetail';

interface ItemProps {
  workout: BusinessWorkoutVo;
  workoutDetail: WorkoutDetailSheet;
  exerciseDetail: ExerciseDetailSheet;
  className?: string;
  titleClassName?: string;
  children?: ReactNode;
}

const blockClassName = 'rounded-md bg-opacity-10 py-xs px-sm text-caption flex-center space-x-3xs truncate';

function WorkoutItemComponent({
  workout: { sections, name, description },
  workout,
  workoutDetail,
  exerciseDetail,
  className,
  titleClassName,
  children,
}: ItemProps) {
  const { supersetCount, setCount, circuitsCount } = countWorkoutSets(sections);

  const onClick = useCallback(() => {
    workoutDetail.open({ workout, exerciseDetail });
  }, []);

  return (
    <Clickable className={classNames(className, 'bg-black px-lg py-md rounded-md')} onClick={onClick}>
      <p className={classNames('text-b1 capitalize', titleClassName)}>{name}</p>
      {description ? <p className="text-b2 mt-3xs text-content-100">{description}</p> : null}
      <div className="flex mt-sm gap-3xs flex-wrap">
        <div className={classNames(blockClassName, 'bg-cobalt')}>
          <Icons.Section size={4} className="text-cobalt" />
          <span>
            {sections.length} Section{sections.length > 1 ? 's' : ''}
          </span>
        </div>
        <div className={classNames(blockClassName, 'bg-maroon')}>
          <Icons.DumbBell size={4} className="text-maroon" />
          <span>
            {setCount} Exercise{setCount > 1 ? 's' : null}
          </span>
        </div>
        {supersetCount ? (
          <div className={classNames(blockClassName, 'bg-purple')}>
            <Icons.Superset size={4} className="text-purple" />
            <span>
              {supersetCount} Superset{supersetCount > 1 ? 's' : null}
            </span>
          </div>
        ) : null}
        {circuitsCount ? (
          <div className={classNames(blockClassName, 'bg-mint')}>
            <Icons.Circle size={4} className="text-mint" />
            <span>
              {circuitsCount} Circuit{circuitsCount > 1 ? 's' : null}
            </span>
          </div>
        ) : null}
      </div>
      {children}
    </Clickable>
  );
}

export const WorkoutItem = memo(WorkoutItemComponent);
