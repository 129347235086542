import { default as classNames } from 'classnames';
import {
  ButtonHTMLAttributes,
  ComponentType,
  createElement,
  ForwardedRef,
  forwardRef,
  memo,
  MouseEvent,
  ReactHTML,
  useMemo,
  useState,
} from 'react';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (e: MouseEvent<HTMLElement>) => unknown | Promise<unknown>;
  hideContentWhileLoading?: boolean;
  as?: keyof ReactHTML | ComponentType<any>;
  loading?: boolean;
}

function ButtonComponent(
  {
    className: classNameProp,
    onClick: onClickProp,
    children,
    hideContentWhileLoading,
    loading: loadingProp,
    as = 'button',
    ...props
  }: ButtonProps,
  ref?: ForwardedRef<HTMLElement>,
) {
  const [isLoading, setLoading] = useState(false);

  const onClick = useMemo(
    () =>
      onClickProp
        ? async (e: MouseEvent<HTMLElement>) => {
            try {
              const result = onClickProp(e);
              if (result instanceof Promise) {
                setLoading(true);
                await result;
              }
            } catch (e) {
              console.error(e);
            } finally {
              setLoading(false);
            }
          }
        : undefined,
    [onClickProp],
  );

  const loading = typeof loadingProp === 'boolean' ? loadingProp : isLoading;
  return createElement(
    as,
    {
      ...props,
      onClick,
      className: classNames('btn', classNameProp, { loading, disabled: props.disabled }),
      disabled: loading || props.disabled,
      ref,
    },
    hideContentWhileLoading && loading ? null : children,
  );
}

export const Button = memo(forwardRef(ButtonComponent));
