import { isEmptyObject } from './is';

export function filterMap<T extends {} = object>(
  map: T,
  deep?: boolean,
  assertValue?: (v: unknown) => boolean,
): Partial<T> | null {
  const assert = assertValue || isValid;
  if (Array.isArray(map)) {
    const result = map
      .map((v) => {
        let valid;
        let value = v;
        if (v && typeof v === 'object') {
          value = filterMap(v, deep);
          valid = value ? isEmptyObject(value as object) : false;
        } else {
          valid = assert(v);
        }
        return valid ? v : null;
      })
      .filter((i) => !!i);
    return result.length ? (result as any as T) : null;
  }

  const result: Record<string, unknown> = {};
  Object.entries(map).forEach(([k, v]) => {
    let valid;
    let value = v;
    if (v && typeof v === 'object') {
      if (deep) {
        value = filterMap(v);
      }
      valid = value ? (assertValue ? value : !isEmptyObject(value as object)) : false;
    } else {
      valid = assert(v);
    }
    if (valid) {
      result[k] = v;
    }
  });
  return isEmptyObject(result) ? null : (result as Partial<T>);
}

function isValid(v: unknown) {
  if (v === null) {
    return false;
  }
  switch (typeof v) {
    case 'string':
      return !!v.trim();
    case 'number':
      return !!v;
    case 'undefined':
      return false;
  }
  return true;
}

export function mapMap<T extends {}>(map: T, fn: <K extends keyof T>(k: K, v: T[K]) => T[K]): T {
  if (!map || typeof map !== 'object') {
    return map;
  }
  return Object.entries(map).reduce<T>((result, [k, v]) => {
    result[k as keyof T] = fn(k as keyof T, v as T[keyof T]);
    return result;
  }, {} as T);
}

export function pickMap<T extends {}>(map: T, keys: (keyof T | string)[] | Set<keyof T | string>): Partial<T> {
  const has = Array.isArray(keys) ? (k: string) => keys.includes(k) : (k: string) => keys.has(k);
  return Object.entries(map).reduce<Partial<T>>((result, [k, v]) => {
    if (has(k)) {
      result[k as keyof T] = v as T[keyof T];
    }
    return result;
  }, {});
}

export function omitMap<T extends {}, K extends keyof T>(map: T, keys: K[] | Set<K>): Omit<T, K> {
  const has = Array.isArray(keys) ? (k: K) => keys.includes(k) : (k: K) => keys.has(k);
  return Object.entries(map).reduce(
    (result, [k, v]) => {
      if (!has(k as K)) {
        (result as any)[k] = v;
      }
      return result;
    },
    {} as Omit<T, K>,
  );
}

export function omitRemoveMap<T extends {}, K extends keyof T>(map: T, keys: K[] | Set<K>) {
  (Array.isArray(keys) ? keys : Array.from(keys.values())).forEach((k) => delete map[k]);
}

export function deepCopy<T>(value: T): T {
  if (typeof value === 'function') {
    return value;
  }

  if (Array.isArray(value)) {
    return (value as any).map((i: any) => deepCopy(i));
  }

  if (typeof value === 'object') {
    return JSON.parse(JSON.stringify(value));
  }

  return value;
}

export function equalsMap<T extends {}>(a: Partial<T>, b: Partial<T>): boolean {
  if (a === b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);
  if (aKeys.length !== bKeys.length) {
    return false;
  }
  return aKeys.every((k) => (a as any)[k] === (b as any)[k]);
}
