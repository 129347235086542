import { StoreUtils } from '@cocast/mobx';
import { computed, makeObservable, observable } from 'mobx';
import { clientStore } from '../client/store';

export class AccountStore extends StoreUtils {
  constructor() {
    super();
    makeObservable(this);
    window.__account = this;
  }

  @observable
  public authenticationToken: string;

  @computed({ keepAlive: true })
  public get didLogin() {
    return !!this.authenticationToken;
  }

  @computed({ keepAlive: true })
  public get info() {
    return clientStore.info.data?.account;
  }
}

export const accountStore = new AccountStore();
