import { Icons } from '@cocast/icons';
import { ScheduleStatus as Status } from '@cocast/types';
import { default as classNames } from 'classnames';
import { memo } from 'react';

interface ScheduleStatusProps {
  status: Status;
  className?: string;
}

const statusProps = {
  [Status.Created]: {
    className: 'orange',
    icon: Icons.Star,
  },
  [Status.Confirmed]: {
    className: 'lemon',
    icon: Icons.Check,
  },
  [Status.Finished]: {
    className: 'green',
    icon: Icons.Check,
  },
  [Status.Canceled]: {
    className: 'red',
    icon: Icons.Close,
  },
};

function ScheduleStatusComponent({ status, className }: ScheduleStatusProps) {
  if (!status) {
    return null;
  }

  const { className: statusClassName, icon: Icon } = statusProps[status];
  return (
    <span className={classNames('tag xs !bg-opacity-0 capitalize', className, statusClassName)}>
      <Icon size={4} className="mr-3xs" />
      {status === Status.Created ? 'new' : status}
    </span>
  );
}

export const ScheduleStatus = memo(ScheduleStatusComponent);
