import { ReactElement, ReactNode, useState } from 'react';
import { useTimeout } from './useTimer';

export function useDelayRender(delay: number, render?: boolean) {
  const [show, setShow] = useState(false);

  useTimeout(() => {
    setShow(true);
  }, delay);

  const showing = typeof render === 'boolean' ? show && render : show;
  return (
    children: ReactNode | ReactNode[] | (() => ReactNode | ReactNode[]),
    placeholder: ReactNode | ReactNode[] = null,
  ): ReactElement => (showing ? (typeof children === 'function' ? children() : children) : (placeholder as any));
}
