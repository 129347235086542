import { memo, MutableRefObject, useEffect, useState } from 'react';

interface Props {
  maxLength: number;
  inputRef: MutableRefObject<HTMLInputElement>;
}

function InputCounterComponent({ maxLength, inputRef }: Props) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(inputRef.current.value.length);
    inputRef.current.addEventListener('input', () => {
      setCount(inputRef.current.value.length);
    });
  }, []);

  return (
    <span className="input-counter">
      {count} / {maxLength}
    </span>
  );
}

export const InputCounter = memo(InputCounterComponent);
