import { useUpdatedState } from '@cocast/hooks/useUpdatedState';
import { Icons } from '@cocast/icons';
import { WithToString } from '@cocast/types';
import { findAncestorNode } from '@cocast/utils-web/node';
import { default as classNames } from 'classnames';
import {
  ComponentType,
  createElement,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  memo,
  ReactNode,
  useCallback,
} from 'react';
import { RadioProps } from './Radio';
import { RadioGroupOptions, RadioGroupOptionsProps } from './RadioGroupOptions';

export interface RadioOption<T> {
  label: ReactNode;
  value: T;
  key?: WithToString;
  labelClassName?: string;
}

export interface RadioGroupProps<T = string> extends Omit<HTMLAttributes<HTMLDivElement>, 'value' | 'defaultValue'> {
  options: (string | number | RadioOption<T>)[];
  defaultValue?: T;
  value?: T;
  onValueChange?: (value: T) => unknown;
  label?: ReactNode;
  labelClassName?: string;
  description?: ReactNode;
  descriptionClassName?: string;
  optionClassName?: string;
  error?: string;
  errorClassName?: string;
  required?: boolean;
  disabled?: boolean;
  optionsComponent?: ComponentType<RadioGroupOptionsProps<T>>;
  optionsClassName?: string;
}

function RadioGroupComponent<T = WithToString>(
  {
    className,
    options,
    defaultValue,
    value: valueProp,
    onValueChange,
    label,
    labelClassName,
    description,
    descriptionClassName,
    optionClassName,
    error,
    errorClassName,
    required,
    disabled,
    optionsComponent = RadioGroupOptions,
    optionsClassName,
    ...props
  }: RadioGroupProps<T>,
  ref?: ForwardedRef<HTMLDivElement>,
) {
  const [value, setValue] = useUpdatedState<T>(valueProp ?? defaultValue);

  const onCheck: RadioProps['onCheck'] = useCallback(
    (checked, e) => {
      if (!checked || disabled) {
        return;
      }
      const getIndex = (e: HTMLElement) => e.dataset.radioIndex;
      const root = findAncestorNode(e.target as HTMLElement, (node) => !!getIndex(node));
      const index = getIndex(root);
      const item = options[+index];
      const newValue = typeof item === 'string' || typeof item === 'number' ? (item as T) : item.value;
      setValue(newValue);
      onValueChange?.(newValue);
    },
    [options, disabled],
  );

  return (
    <div className={classNames('radio-group input-root space-x-[20px] child:mb-[4px]', className)} ref={ref} {...props}>
      {label ? <label className={classNames(labelClassName, 'label', { required })}>{label}</label> : null}
      {createElement(optionsComponent, {
        className: optionsClassName,
        options,
        value,
        optionClassName,
        onCheck,
        disabled,
      })}
      {!error && description ? (
        <label className={classNames('label desc', descriptionClassName)}>{description}</label>
      ) : null}
      {error ? (
        <label className={classNames('label error', errorClassName)}>
          <Icons.Alert />
          <span>{error}</span>
        </label>
      ) : null}
    </div>
  );
}

export const RadioGroup = memo(forwardRef(RadioGroupComponent));
