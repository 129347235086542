import { useLoading } from '@cocast/hooks/useLoading';
import { IconComponent, Icons } from '@cocast/icons';
import { IonButton } from '@ionic/react';
import { default as classNames } from 'classnames';
import { ComponentProps, createElement, memo } from 'react';

interface Props extends Omit<ComponentProps<typeof IonButton>, 'children'> {
  onClick: () => Promise<unknown>;
  text: string;
  textClassName?: string;
  icon?: IconComponent;
  iconSize?: number;
  iconClassName?: string;
  hideText?: boolean;
}

function SpinnerButtonComponent({
  onClick,
  text,
  textClassName,
  icon,
  iconSize = 22,
  iconClassName,
  className,
  hideText,
  ...props
}: Props) {
  const { handler, loading } = useLoading(onClick, [onClick]);

  return (
    <IonButton onClick={loading ? undefined : handler} className={loading ? 'pointer-events-none' : null} {...props}>
      {icon || loading
        ? createElement(loading ? Icons.Spinner : icon, {
            size: iconSize / 4,
            className: classNames(iconClassName, loading ? 'animate-spin' : null, 'mr-3xs'),
          })
        : null}
      {loading && hideText ? null : <span className={classNames(text, loading ? 'opacity-50' : null)}>Save</span>}
    </IonButton>
  );
}

export const SpinnerButton = memo(SpinnerButtonComponent);
