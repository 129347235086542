import { api, ClientAccountUpdateDto } from '@cocast/api-client';
import { toast } from '@cocast/ui/toast';
import { H } from 'highlight.run';
import { removeAuthenticationToken } from 'libs';
import { action, autorun, makeObservable, runInAction } from 'mobx';
import { accountStartRoute } from 'routes';
import { isOnline } from 'utils';
import { businessStore } from '../business/store';
import { clientStore } from '../client/store';
import { uiStore } from '../ui/store';
import { accountStore } from './store';

export class AccountAction {
  constructor() {
    makeObservable(this);
    this.watch();
  }

  private watch = () => {
    if (isOnline()) {
      autorun(() => {
        const { info } = accountStore;
        const { info: business } = businessStore;
        if (info && business) {
          H.identify(`${info.name}@${business.name}`, {
            id: info.id,
            email: info.email,
            name: info.name,
            avatar: info.avatar,
            businessName: business.name,
            businessId: business.id,
          });
        }
      });
    }
  };

  @action
  public readonly setAuthenticationToken = (token: string) => {
    accountStore.authenticationToken = token;
  };

  @action
  public readonly logout = async () => {
    accountStartRoute.replace();
    await removeAuthenticationToken();
    this.setAuthenticationToken(null);
  };

  @action
  public readonly update = async (data: ClientAccountUpdateDto, callback?: () => unknown) => {
    if (!uiStore.liveDemo) {
      const [error] = await api.ClientAccount.update(data);
      if (error) {
        return;
      }
    }
    runInAction(() => {
      const account = {
        ...accountStore.info,
        ...data,
      };
      account.name = `${account.firstname} ${account.lastname}`;
      clientStore.info.data.account = account;
    });
    toast.success('Updated');
    callback?.();
  };
}

export const accountAction = new AccountAction();
