import { TagVo } from '@cocast/api-client';
import { default as classNames } from 'classnames';
import { memo, MouseEvent, ReactNode } from 'react';

type TagAttributes = Partial<Pick<TagVo, 'name' | 'primary' | 'secondary' | 'text'>>;

export interface TagProps extends TagAttributes {
  tag?: TagAttributes;
  children?: ReactNode | ReactNode[];
  className?: string;
  onClick?: (e: MouseEvent) => void;
}

function TagComponent({ tag, children, className, onClick, ...props }: TagProps) {
  const name = tag?.name || props?.name;
  const primary = tag?.primary || props?.primary;
  const secondary = tag?.secondary || props?.secondary;
  const text = tag?.text || props?.text;

  return (
    <span
      className={classNames('tag', className, onClick ? 'cursor-pointer' : null)}
      style={{
        backgroundColor: primary,
        color: text,
        borderColor: secondary,
      }}
      onClick={onClick}
    >
      {children || name}
    </span>
  );
}

export const Tag = memo(TagComponent);
