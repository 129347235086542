import { useDidMounted } from '@cocast/hooks/useDidMounted';
import { createElement, memo } from 'react';
import { RouteErrorBoundary, useRouteContext } from './context';
import { Route } from './route';
import { RouterComponentProps, RouterConnectProps, RouterProps } from './types';

function RouterComponent<R extends Route<any, any>>({ route, component, children }: RouterProps<R, unknown>) {
  const show = route.useMatch();
  return show ? <RouteErrorBoundary>{createElement(component, {}, children)}</RouteErrorBoundary> : null;
}

function RouterConnectComponent<R extends Route<any, any>>({ route, component, children }: RouterConnectProps<R>) {
  const { routes } = useRouteContext();
  const mounted = useDidMounted();
  if (!mounted.current) {
    routes.add(route);
  }

  const r = route.useRoute();
  return r.match ? (
    <RouteErrorBoundary>
      {createElement(component, { route: r } as RouterComponentProps<R>, children)}
    </RouteErrorBoundary>
  ) : null;
}

const RouterWithoutConnect = memo(RouterComponent);

const RouterConnect = memo(RouterConnectComponent);

export const Router = RouterWithoutConnect as typeof RouterWithoutConnect & { Connect: typeof RouterConnect };

Router.Connect = RouterConnect;
