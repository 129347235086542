import { App } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { BundleInfo, CapacitorUpdater } from '@capgo/capacitor-updater';
import { EnvType } from '@cocast/types';
import { getEnv, isApp } from '../utils';

export interface UpdateInfo {
  version: string;
  url: string;
}

export async function checkUpdate(): Promise<UpdateInfo> {
  if (!isApp() || getEnv() === EnvType.DEV) {
    return null;
  }

  const channel = getEnv() === EnvType.ONLINE ? 'production' : 'test';
  const response = await fetch(
    `https://client-portal-updater.cocast.fit/?channel=${channel}&current=${BUNDLE_VERSION}`,
  );
  const { update, error, version, url } = await response.json();
  if (error) {
    throw new Error(error);
  }
  if (!update) {
    return null;
  }
  return {
    version,
    url,
  };
}

export async function downloadUpdate(info: UpdateInfo, callback: (bundle: BundleInfo) => unknown) {
  const download = async () => {
    console.info('[client-portal] Download update bundle: ', info);
    const bundle = await CapacitorUpdater.download(info);
    callback(bundle);
  };

  const state = await App.getState();
  if (state.isActive) {
    await download();
  } else {
    App.addListener('appStateChange', async (state) => {
      if (state.isActive) {
        await download();
      }
    });
  }
}

export async function autoUpdate() {
  const info = await checkUpdate();
  if (!info) {
    return;
  }

  console.info('[client-portal] New version: ', info);
  return downloadUpdate(info, async (bundle) => {
    console.info('[client-portal] Bundle download success: ', bundle);
    const install = async () => {
      SplashScreen.show();
      try {
        console.info('[client-portal] Install update bundle: ', bundle);
        await CapacitorUpdater.set(bundle);
      } catch (e) {
        SplashScreen.hide();
      }
    };

    const state = await App.getState();
    if (!state.isActive) {
      await install();
    } else {
      App.addListener('appStateChange', async (state) => {
        if (!state.isActive) {
          await install();
        }
      });
    }
  });
}

export function reset() {
  return CapacitorUpdater.reset();
}

window.__updater = {
  bundle: BUNDLE_VERSION,
  reset,
  autoUpdate,
};
