import { ChangeEvent, ComponentProps, ForwardedRef, forwardRef, memo, useCallback, useRef } from 'react';
import { Input, InputProps } from './Input';

export interface NumberInputProps
  extends Omit<InputProps, 'onValueChange'>,
    Omit<ComponentProps<'input'>, keyof InputProps<'input'> | 'ref'> {
  integer?: boolean;
  onValueChange?: (value: number) => void;
}

function NumberInputComponent(
  { integer, onValueChange, ...inputProps }: NumberInputProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const last = useRef<string>(inputProps.value?.toString() || inputProps.defaultValue?.toString());
  const onInput = useCallback(({ target, target: { value: input } }: ChangeEvent<HTMLInputElement>) => {
    const v = input && integer ? parseInt(input) : Number(input);
    if (isNaN(v)) {
      target.value = last.current || '';
      onValueChange?.(last.current ? (integer ? parseInt(last.current) : Number(last.current)) : 0);
    } else {
      const value = v.toString();
      target.value = value;
      last.current = value;
      onValueChange?.(v);
    }
  }, []);

  return <Input ref={ref} type="number" onInput={onInput} {...inputProps} />;
}

export const NumberInput = memo(forwardRef(NumberInputComponent));
