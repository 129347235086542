import { ClientPortalHosts, EnvType, WebHosts } from '@cocast/types';

export function getEnv(origin?: string): EnvType {
  if (!origin) {
    origin = window.location.origin;
  }
  if (
    origin === WebHosts.get(EnvType.DEV) ||
    origin === ClientPortalHosts.get(EnvType.DEV) ||
    origin.startsWith('http://192.168') ||
    origin.startsWith('http://localhost')
  ) {
    return EnvType.DEV;
  }
  if (origin === WebHosts.get(EnvType.TEST) || origin === ClientPortalHosts.get(EnvType.TEST)) {
    return EnvType.TEST;
  }
  return EnvType.ONLINE;
}

export function isDev() {
  return getEnv() === EnvType.DEV;
}

export function isOnline() {
  return getEnv() === EnvType.ONLINE;
}

export function isSSR() {
  return import.meta.env?.SSR;
}

export function isIOS() {
  return /iphone|ipad|itouch/i.test(navigator.userAgent);
}

export function isAndroid() {
  return /android/i.test(navigator.userAgent);
}

export function isMobile() {
  return isIOS() || isAndroid();
}
