import { cn } from '@cocast/utils-web/misc';
import { memo } from 'react';
import { richtextStyle } from './Richtext.style';

interface Props {
  children: string;
  className?: string;
}

const style = `
.richtext-render {
  font-family: 'Inter', sans-serif;
  background: #262529;
  color: #fff;
  padding: 0;
}

.richtext-render p:empty {
  height: 0.8em;
}

.richtext-render h1 {
  margin: 32px 0;
}

.richtext-render h2 {
  margin: 28px 0;
}

.richtext-render h3 {
  margin: 20px 0;
}

.richtext-render h4 {
  margin: 16px 0;
}

.richtext-render p {
  margin: 1em 0;
}

.richtext-render ul {
  list-style: disc;
  margin: 1em 0 1.5em 1.5em;
}

.richtext-render ol {
  list-style: decimal;
  margin: 1em 0 1.5em 1.5em;
}

.richtext-render :not(pre) > code {
  background: #3C3C3E;
}

.richtext-render hr {
  opacity: 0.5;
}
`;

function RichTextRenderComponent({ children, className }: Props) {
  return (
    <>
      <style>{richtextStyle('.richtext-render', style)}</style>
      <section className={cn('richtext-render', className)} dangerouslySetInnerHTML={{ __html: children }} />
    </>
  );
}

export const RichTextRender = memo(RichTextRenderComponent);
