import { APIHosts, EnvType } from '@cocast/types';

export function getEnv(): EnvType {
  const env = process?.env?.COCAST_ENV;
  if (env) {
    return env as EnvType;
  }
  const nodeEnv = process?.env?.NODE_ENV;
  if (nodeEnv) {
    if (nodeEnv === 'development') {
      return EnvType.DEV;
    }
    if (nodeEnv === 'production') {
      return EnvType.ONLINE;
    }
  }
}

export function isDev() {
  return getEnv() === EnvType.DEV;
}

export function isServer() {
  return typeof window === 'undefined';
}

let isSafariBrowser: boolean;

export function isSafari() {
  if (typeof isSafariBrowser !== 'boolean') {
    isSafariBrowser = !isServer() && /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
  }
  return isSafariBrowser;
}

export function isAndroid() {
  return !isServer() && /android/i.test(window.navigator.userAgent);
}

export function isIOS() {
  return !isServer() && /iPad|iPhone|iPod/.test(window.navigator.userAgent);
}

export function getAPIHost(env?: EnvType) {
  return isDev() && isServer() ? 'http://127.0.0.1:4001' : APIHosts.get(env || getEnv());
}
