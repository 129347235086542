import { BusinessExerciseVo, BusinessWorkoutVo } from '@cocast/api-client';
import { WorkoutPreviewSection } from './WorkoutPreviewSection';
import { useBoolean } from '@cocast/hooks/useBoolean';
import { Icons } from '@cocast/icons';
import { Exercise } from '@cocast/types';
import { countWorkoutSets, equipmentIcons, getWorkoutEquipments } from '@cocast/utils-business/workout';
import { Tag } from 'components';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { businessStore } from 'state';
import { ExerciseDetailSheet } from '../../Exercise/ExerciseDetail';

interface Props {
  workout: BusinessWorkoutVo;
  exerciseDetail: ExerciseDetailSheet;
}

function WorkoutDetailPreviewComponent({
  workout,
  workout: { name, description, tags, sections },
  exerciseDetail,
}: Props) {
  const [showDetail, setShowDetail] = useBoolean(true);
  const { sectionCount, setCount, supersetCount, circuitsCount, equipments } = useMemo(
    () => ({
      ...countWorkoutSets(sections, (i) => businessStore.exerciseMap.has(i)),
      sectionCount: sections.length,
      equipments: getWorkoutEquipments(sections, businessStore.exerciseMap),
    }),
    [workout],
  );

  const onPreviewExercise = useCallback(
    (exercise: Exercise | BusinessExerciseVo) => exerciseDetail.open({ exercise }),
    [],
  );

  return (
    <>
      <h2 className="pt-md">{name}</h2>
      {tags?.length ? (
        <div className="space-x-xs mt-xs mb-sm">
          {tags.map((tag) => (
            <Tag key={tag.id} tag={tag} className="sm" />
          ))}
        </div>
      ) : null}
      {description ? <p className="text-b2 mt-3xs text-content-100">{description}</p> : null}

      <div className="mt-xl flex-start-center space-x-md child:w-[80px] text-caption">
        <div className="text-cobalt">
          <div className="flex-start-center">
            <h3 className="mr-xs">{sectionCount}</h3>
            <Icons.Section size={6} />
          </div>
          <p className="text-base-300">Sections</p>
        </div>
        <div className="text-orange">
          <div className="flex-start-center">
            <h3 className="mr-xs">{setCount}</h3>
            <Icons.DumbBell size={6} />
          </div>
          <p className="text-base-300">Exercises</p>
        </div>
        <div className={supersetCount ? 'text-purple' : 'text-base-400'}>
          <div className="flex-start-center">
            <h3 className="mr-xs">{supersetCount}</h3>
            <Icons.Superset size={6} />
          </div>
          <p className={supersetCount ? 'text-base-300' : 'text-base-400'}>Supersets</p>
        </div>
        <div className={circuitsCount ? 'text-mint' : 'text-base-400'}>
          <div className="flex-start-center">
            <h3 className="mr-xs">{circuitsCount}</h3>
            <Icons.Circle size={6} />
          </div>
          <p className={circuitsCount ? 'text-base-300' : 'text-base-400'}>Circuits</p>
        </div>
      </div>

      {equipments.length ? (
        <div className="mt-lg flex-start-center space-x-md child:w-[80px] text-caption text-steel">
          {equipments.map((equipment) => {
            const Icon = equipmentIcons.get(equipment);
            return Icon ? (
              <div key={equipment}>
                <Icon size={8} />
                <p className="capitalize mt-[2px] truncate">{equipment}</p>
              </div>
            ) : null;
          })}
        </div>
      ) : null}

      <div className="!mt-xl space-y-md">
        {sections.map((section, index) => (
          <WorkoutPreviewSection
            key={index}
            section={section}
            index={index}
            showDetail={showDetail}
            getExercise={businessStore.getExercise}
            onPreviewExercise={onPreviewExercise}
          />
        ))}
      </div>
    </>
  );
}

export const WorkoutDetailPreview = observer(WorkoutDetailPreviewComponent);
