import { WithToString } from '@cocast/types';
import { default as classNames } from 'classnames';
import { createElement, ForwardedRef, forwardRef, memo, useMemo, useState } from 'react';
import { RadioGroup, RadioGroupProps } from './RadioGroup';
import { RadioGroupOptionsProps } from './RadioGroupOptions';

function SwitcherComponent<T = WithToString>(
  props: Omit<RadioGroupProps<T>, 'optionsComponent'>,
  ref?: ForwardedRef<HTMLDivElement>,
) {
  return createElement(RadioGroup, {
    ...props,
    optionsComponent: SwitcherOptions,
    ref,
  });
}

export const Switcher = memo(forwardRef(SwitcherComponent));

const itemClassName = 'px-sm py-xxs cursor-pointer';

function SwitcherOptionsComponent<T = string>({
  options,
  value,
  optionClassName,
  onCheck,
  disabled,
  optionsClassName,
}: RadioGroupOptionsProps<T>) {
  const activeIndex = options.findIndex((i) => (typeof i === 'object' ? i.value : i) === value);

  const [ref, setRef] = useState<HTMLDivElement>();
  const indicatorStyle = useMemo(() => {
    if (!ref || activeIndex < 0) {
      return undefined;
    }

    const item = ref.children[activeIndex] as HTMLDivElement;
    return {
      left: item.offsetLeft,
      width: item.offsetWidth,
    };
  }, [activeIndex, ref]);

  return (
    <div
      className={classNames(
        optionsClassName,
        'flex-center relative border-thin border-light bg-base rounded-md text-center space-x-xxs p-[3px]',
        disabled ? 'cursor-now-allowed opacity-60' : null,
      )}
      ref={setRef}
    >
      {options.map((i, index) => {
        const isString = typeof i !== 'object';
        const v = typeof i === 'string' || typeof i === 'number' ? i : i.value;
        const checked = v === value;

        return (
          <div
            className={classNames(
              optionClassName,
              checked ? 'text-base' : null,
              itemClassName,
              'flex-grow-1 flex-shrink-1 z-10',
            )}
            onClick={(e) => onCheck(!checked, e)}
            key={isString ? i : index}
            data-radio-index={index}
          >
            {isString ? i : i.label}
          </div>
        );
      })}
      <div
        className={classNames(
          optionClassName,
          itemClassName,
          'h-[calc(100%-6px)] top-[3px] absolute bg-black top-0 left-0 rounded-[12px] transition-all pointer-events-none !ml-0',
        )}
        style={indicatorStyle}
      />
    </div>
  );
}

const SwitcherOptions = memo(SwitcherOptionsComponent);
