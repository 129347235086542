import { ValueOfPromise } from '@cocast/types';
import { PromiseResult, promiseWrapper } from '@cocast/utils';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

export interface UseLoadingOptions<P extends any[]> {
  immediate?: boolean;
  params?: P;
  keepLast?: boolean;
}

export function useLoading(initState: boolean): {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  withLoading: <T>(p: Promise<T> | (() => Promise<T>)) => PromiseResult<T>;
};
export function useLoading<P extends any[], R>(
  promiseCreator: (...p: P) => R,
  deps: any[],
  options?: UseLoadingOptions<P>,
): {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  error: Error | undefined;
  result: (R extends Promise<infer U> ? U : R) | undefined;
  setResult: Dispatch<SetStateAction<R extends Promise<infer U> ? U : R>>;
  handler: (...p: P) => PromiseResult<R extends Promise<infer U> ? U : R>;
};
export function useLoading<P extends any[], R>(
  promiseCreatorOrInitState: ((...p: P) => R) | boolean,
  deps: any[] = [],
  options?: UseLoadingOptions<P>,
) {
  const valueMode = useMemo(() => typeof promiseCreatorOrInitState === 'boolean', []);
  const [loading, setLoading] = useState(valueMode ? (promiseCreatorOrInitState as boolean) : !!options?.immediate);
  if (valueMode) {
    const withLoading = useCallback(async (p: Promise<any> | (() => Promise<any>)) => {
      setLoading(true);
      const result = await promiseWrapper(typeof p === 'function' ? p() : p);
      setLoading(false);
      return result;
    }, []);

    return {
      loading,
      setLoading,
      withLoading,
    };
  }

  const [data, setData] = useState<ValueOfPromise<PromiseResult<R>> | null>(null);

  const handler = useCallback(async (...params: P) => {
    let p;
    try {
      p = (promiseCreatorOrInitState as Function)(...params);
    } catch (e) {
      return [e, null] as ValueOfPromise<PromiseResult<R>>;
    }
    if (!(p instanceof Promise)) {
      return [null, p] as ValueOfPromise<PromiseResult<R>>;
    }
    setLoading(true);
    const result = await promiseWrapper(p);
    setLoading(false);
    setData(result as ValueOfPromise<PromiseResult<R>>);
    if (result[0]) {
      console.error('useLoading throw error: ', result[0]);
    }
    return result;
  }, deps);

  const setResult = useCallback((setter: SetStateAction<R>) => {
    setData(([error, result]) => {
      const newResult = typeof setter === 'function' ? (setter as Function)(result) : setter;
      return [error, newResult] as ValueOfPromise<PromiseResult<R>>;
    });
  }, []);

  useEffect(() => {
    setLoading(false);
    if (!options?.keepLast) {
      setData(null);
    }
  }, deps);

  useEffect(() => {
    if (options?.immediate) {
      handler(...(options?.params || ([] as P)));
    }
  }, [handler]);

  return {
    loading,
    setLoading,
    error: data?.[0],
    result: data?.[1],
    setResult,
    handler,
  };
}
