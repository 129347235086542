import { BusinessExerciseVo } from '@cocast/api-client';
import { Exercise } from '@cocast/types';
import { AerobicExercises } from './aerobic';
import { StrengthExercises } from './strength';
import { StretchingExercises } from './stretching';
import { YogaExercises } from './yoga';

export function mergeBusinessExercise(exercises: BusinessExerciseVo[]): (Exercise | BusinessExerciseVo)[] {
  if (!exercises) {
    return;
  }
  const ids = new Set(exercises.map((e) => e.relatedExercise).filter((i) => !!i));
  const defaultExercises = [...StrengthExercises, ...StretchingExercises, ...YogaExercises, ...AerobicExercises].filter(
    (exercise) => !ids.has(exercise.id),
  );
  return [...exercises, ...defaultExercises];
}

export type ExerciseMap = Map<string, Exercise | BusinessExerciseVo>;

export function buildExerciseMap(
  exercises: (Exercise | BusinessExerciseVo)[],
  businessExercises: BusinessExerciseVo[],
): ExerciseMap {
  const exerciseMap = new Map(exercises?.map((e) => [e.id, e]));
  businessExercises?.map((exercise) => {
    if (exercise.relatedExercise) {
      exerciseMap.set(exercise.relatedExercise, exercise);
    }
  });
  return exerciseMap;
}
