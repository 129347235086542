import { Currency } from './currency';
import { Timezone } from './time';

export enum Country {
  AU = 'AU',
  AT = 'AT',
  BE = 'BE',
  BR = 'BR',
  CA = 'CA',
  HR = 'HR',
  CY = 'CY',
  CZ = 'CZ',
  DK = 'DK',
  EE = 'EE',
  FI = 'FI',
  FR = 'FR',
  DE = 'DE',
  GR = 'GR',
  HU = 'HU',
  IN = 'IN',
  IE = 'IE',
  IT = 'IT',
  JP = 'JP',
  LV = 'LV',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  MY = 'MY',
  MT = 'MT',
  MX = 'MX',
  NL = 'NL',
  NZ = 'NZ',
  NO = 'NO',
  PL = 'PL',
  PT = 'PT',
  RO = 'RO',
  SG = 'SG',
  SK = 'SK',
  ES = 'ES',
  SE = 'SE',
  CH = 'CH',
  TH = 'TH',
  AE = 'AE',
  GB = 'GB',
  US = 'US',
  HK = 'HK',
  RW = 'RW',
  ZA = 'ZA',
  // Added at 2024-03-30
  AR = 'AR',
  BS = 'BS',
  BB = 'BB',
  BO = 'BO',
  BG = 'BG',
  CL = 'CL',
  CO = 'CO',
  CR = 'CR',
  EG = 'EG',
  SV = 'SV',
  GE = 'GE',
  IS = 'IS',
  IR = 'IR',
  IL = 'IL',
  JO = 'JO',
  KW = 'KW',
  MC = 'MC',
  OM = 'OM',
  PK = 'PK',
  PA = 'PA',
  PH = 'PH',
  QA = 'QA',
  WS = 'WS',
  SA = 'SA',
  RS = 'RS',
  KR = 'KR',
  TR = 'TR',
  TV = 'TV',
  UA = 'UA',
  VN = 'VN',
  YE = 'YE',
}

export interface CountryInfo {
  key: Country;
  name: string;
  countryCode: number;
  currency: Currency;
  timezone?: Timezone;
}

export const countryInfoMap = new Map<Country | string, CountryInfo>([
  [
    Country.US,
    {
      key: Country.US,
      name: 'United States',
      countryCode: 1,
      currency: Currency.USD,
      timezone: Timezone['America/New_York'],
    },
  ],
  [
    Country.GB,
    {
      key: Country.GB,
      name: 'United Kingdom',
      countryCode: 44,
      currency: Currency.GBP,
      timezone: Timezone['Europe/London'],
    },
  ],
  [
    Country.AU,
    {
      key: Country.AU,
      name: 'Australia',
      countryCode: 61,
      currency: Currency.AUD,
      timezone: Timezone['Australia/Sydney'],
    },
  ],
  [
    Country.CA,
    { key: Country.CA, name: 'Canada', countryCode: 1, currency: Currency.CAD, timezone: Timezone['America/Toronto'] },
  ],
  [
    Country.SG,
    {
      key: Country.SG,
      name: 'Singapore',
      countryCode: 65,
      currency: Currency.SGD,
      timezone: Timezone['Asia/Singapore'],
    },
  ],
  [
    Country.HK,
    {
      key: Country.HK,
      name: 'Hong Kong SAR China',
      countryCode: 852,
      currency: Currency.HKD,
      timezone: Timezone['Asia/Hong_Kong'],
    },
  ],
  [
    Country.AT,
    { key: Country.AT, name: 'Austria', countryCode: 43, currency: Currency.EUR, timezone: Timezone['Europe/Vienna'] },
  ],
  [
    Country.BE,
    {
      key: Country.BE,
      name: 'Belgium',
      countryCode: 32,
      currency: Currency.EUR,
      timezone: Timezone['Europe/Brussels'],
    },
  ],
  [
    Country.BR,
    {
      key: Country.BR,
      name: 'Brazil',
      countryCode: 55,
      currency: Currency.BRL,
      timezone: Timezone['America/Sao_Paulo'],
    },
  ],
  [
    Country.HR,
    { key: Country.HR, name: 'Croatia', countryCode: 385, currency: Currency.HRK, timezone: Timezone['Europe/Zagreb'] },
  ],

  [
    Country.CY,
    { key: Country.CY, name: 'Cyprus', countryCode: 357, currency: Currency.EUR, timezone: Timezone['Asia/Nicosia'] },
  ],
  [
    Country.CZ,
    {
      key: Country.CZ,
      name: 'Czech Republic',
      countryCode: 420,
      currency: Currency.CZK,
      timezone: Timezone['Europe/Prague'],
    },
  ],
  [
    Country.DK,
    {
      key: Country.DK,
      name: 'Denmark',
      countryCode: 45,
      currency: Currency.DKK,
      timezone: Timezone['Europe/Copenhagen'],
    },
  ],
  [
    Country.EE,
    {
      key: Country.EE,
      name: 'Estonia',
      countryCode: 372,
      currency: Currency.EUR,
      timezone: Timezone['Europe/Tallinn'],
    },
  ],
  [
    Country.FI,
    {
      key: Country.FI,
      name: 'Finland',
      countryCode: 358,
      currency: Currency.EUR,
      timezone: Timezone['Europe/Helsinki'],
    },
  ],
  [
    Country.FR,
    { key: Country.FR, name: 'France', countryCode: 33, currency: Currency.EUR, timezone: Timezone['Europe/Paris'] },
  ],
  [
    Country.DE,
    { key: Country.DE, name: 'Germany', countryCode: 49, currency: Currency.EUR, timezone: Timezone['Europe/Berlin'] },
  ],
  [
    Country.GR,
    { key: Country.GR, name: 'Greece', countryCode: 30, currency: Currency.EUR, timezone: Timezone['Europe/Athens'] },
  ],
  [
    Country.HU,
    {
      key: Country.HU,
      name: 'Hungary',
      countryCode: 36,
      currency: Currency.HUF,
      timezone: Timezone['Europe/Budapest'],
    },
  ],
  [
    Country.IN,
    { key: Country.IN, name: 'India', countryCode: 91, currency: Currency.INR, timezone: Timezone['Asia/Kolkata'] },
  ],
  [
    Country.IE,
    { key: Country.IE, name: 'Ireland', countryCode: 353, currency: Currency.EUR, timezone: Timezone['Europe/Dublin'] },
  ],
  [
    Country.IT,
    { key: Country.IT, name: 'Italy', countryCode: 39, currency: Currency.EUR, timezone: Timezone['Europe/Rome'] },
  ],
  [
    Country.JP,
    { key: Country.JP, name: 'Japan', countryCode: 81, currency: Currency.JPY, timezone: Timezone['Asia/Tokyo'] },
  ],
  [
    Country.LV,
    { key: Country.LV, name: 'Latvia', countryCode: 371, currency: Currency.EUR, timezone: Timezone['Europe/Riga'] },
  ],
  [
    Country.LI,
    {
      key: Country.LI,
      name: 'Liechtenstein',
      countryCode: 423,
      currency: Currency.CHF,
      timezone: Timezone['Europe/Vaduz'],
    },
  ],
  [
    Country.LT,
    {
      key: Country.LT,
      name: 'Lithuania',
      countryCode: 370,
      currency: Currency.EUR,
      timezone: Timezone['Europe/Vilnius'],
    },
  ],
  [
    Country.LU,
    {
      key: Country.LU,
      name: 'Luxembourg',
      countryCode: 352,
      currency: Currency.EUR,
      timezone: Timezone['Europe/Luxembourg'],
    },
  ],
  [
    Country.MY,
    {
      key: Country.MY,
      name: 'Malaysia',
      countryCode: 60,
      currency: Currency.MYR,
      timezone: Timezone['Asia/Kuala_Lumpur'],
    },
  ],
  [
    Country.MT,
    { key: Country.MT, name: 'Malta', countryCode: 356, currency: Currency.EUR, timezone: Timezone['Europe/Malta'] },
  ],
  [
    Country.MX,
    {
      key: Country.MX,
      name: 'Mexico',
      countryCode: 52,
      currency: Currency.MXN,
      timezone: Timezone['America/Mexico_City'],
    },
  ],
  [
    Country.NL,
    {
      key: Country.NL,
      name: 'Netherlands',
      countryCode: 31,
      currency: Currency.EUR,
      timezone: Timezone['Europe/Amsterdam'],
    },
  ],
  [
    Country.NZ,
    {
      key: Country.NZ,
      name: 'New Zealand',
      countryCode: 64,
      currency: Currency.NZD,
      timezone: Timezone['Pacific/Auckland'],
    },
  ],
  [
    Country.NO,
    { key: Country.NO, name: 'Norway', countryCode: 47, currency: Currency.NOK, timezone: Timezone['Europe/Oslo'] },
  ],
  [
    Country.PL,
    { key: Country.PL, name: 'Poland', countryCode: 48, currency: Currency.PLN, timezone: Timezone['Europe/Warsaw'] },
  ],
  [
    Country.PT,
    {
      key: Country.PT,
      name: 'Portugal',
      countryCode: 351,
      currency: Currency.EUR,
      timezone: Timezone['Europe/Lisbon'],
    },
  ],
  [
    Country.RO,
    {
      key: Country.RO,
      name: 'Romania',
      countryCode: 40,
      currency: Currency.RON,
      timezone: Timezone['Europe/Bucharest'],
    },
  ],
  [
    Country.SK,
    {
      key: Country.SK,
      name: 'Slovakia',
      countryCode: 421,
      currency: Currency.EUR,
      timezone: Timezone['Europe/Bratislava'],
    },
  ],
  [
    Country.ES,
    { key: Country.ES, name: 'Spain', countryCode: 34, currency: Currency.EUR, timezone: Timezone['Europe/Madrid'] },
  ],
  [
    Country.SE,
    {
      key: Country.SE,
      name: 'Sweden',
      countryCode: 46,
      currency: Currency.SEK,
      timezone: Timezone['Europe/Stockholm'],
    },
  ],
  [
    Country.CH,
    {
      key: Country.CH,
      name: 'Switzerland',
      countryCode: 41,
      currency: Currency.CHF,
      timezone: Timezone['Europe/Zurich'],
    },
  ],
  [
    Country.TH,
    { key: Country.TH, name: 'Thailand', countryCode: 66, currency: Currency.THB, timezone: Timezone['Asia/Bangkok'] },
  ],
  [
    Country.AE,
    {
      key: Country.AE,
      name: 'United Arab Emirates',
      countryCode: 971,
      currency: Currency.AED,
      timezone: Timezone['Asia/Dubai'],
    },
  ],
  [
    Country.RW,
    { key: Country.RW, name: 'Rwanda', countryCode: 250, currency: Currency.RWF, timezone: Timezone['Africa/Kigali'] },
  ],
  [
    Country.ZA,
    {
      key: Country.ZA,
      name: 'South Africa',
      countryCode: 27,
      currency: Currency.ZAR,
      timezone: Timezone['Africa/Johannesburg'],
    },
  ],
  // Added at 2024-03-30
  [
    Country.AR,
    {
      key: Country.AR,
      name: 'Argentina',
      countryCode: 54,
      currency: Currency.ARS,
      timezone: Timezone['America/Argentina/Buenos_Aires'],
    },
  ],
  [
    Country.BS,
    {
      key: Country.BS,
      name: 'Bahamas',
      countryCode: 1,
      currency: Currency.BSD,
      timezone: Timezone['America/Nassau'],
    },
  ],
  [
    Country.BB,
    {
      key: Country.BB,
      name: 'Barbados',
      countryCode: 1,
      currency: Currency.BBD,
      timezone: Timezone['America/Barbados'],
    },
  ],
  [
    Country.BO,
    {
      key: Country.BO,
      name: 'Bolivia',
      countryCode: 591,
      currency: Currency.BOB,
      timezone: Timezone['America/La_Paz'],
    },
  ],
  [
    Country.BG,
    {
      key: Country.BG,
      name: 'Bulgaria',
      countryCode: 359,
      currency: Currency.BGN,
      timezone: Timezone['Europe/Sofia'],
    },
  ],
  [
    Country.CL,
    {
      key: Country.CL,
      name: 'Chile',
      countryCode: 56,
      currency: Currency.CLP,
      timezone: Timezone['America/Santiago'],
    },
  ],
  [
    Country.CO,
    {
      key: Country.CO,
      name: 'Colombia',
      countryCode: 57,
      currency: Currency.COP,
      timezone: Timezone['America/Bogota'],
    },
  ],
  [
    Country.CR,
    {
      key: Country.CR,
      name: 'Costa Rica',
      countryCode: 506,
      currency: Currency.CRC,
      timezone: Timezone['America/Costa_Rica'],
    },
  ],
  [
    Country.EG,
    {
      key: Country.EG,
      name: 'Egypt',
      countryCode: 20,
      currency: Currency.EGP,
      timezone: Timezone['Africa/Cairo'],
    },
  ],
  [
    Country.SV,
    {
      key: Country.SV,
      name: 'El Salvador',
      countryCode: 503,
      currency: Currency.SVC,
      timezone: Timezone['America/El_Salvador'],
    },
  ],
  [
    Country.GE,
    {
      key: Country.GE,
      name: 'Georgia',
      countryCode: 995,
      currency: Currency.GEL,
      timezone: Timezone['Asia/Tbilisi'],
    },
  ],
  [
    Country.IS,
    {
      key: Country.IS,
      name: 'Iceland',
      countryCode: 354,
      currency: Currency.ISK,
      timezone: Timezone['Atlantic/Reykjavik'],
    },
  ],
  [
    Country.IR,
    {
      key: Country.IR,
      name: 'Iran',
      countryCode: 98,
      currency: Currency.IRR,
      timezone: Timezone['Asia/Tehran'],
    },
  ],
  [
    Country.IL,
    {
      key: Country.IL,
      name: 'Israel',
      countryCode: 972,
      currency: Currency.ILS,
      timezone: Timezone['Asia/Jerusalem'],
    },
  ],
  [
    Country.JO,
    {
      key: Country.JO,
      name: 'Jordan',
      countryCode: 962,
      currency: Currency.JOD,
      timezone: Timezone['Asia/Amman'],
    },
  ],
  [
    Country.KW,
    {
      key: Country.KW,
      name: 'Kuwait',
      countryCode: 965,
      currency: Currency.KWD,
      timezone: Timezone['Asia/Kuwait'],
    },
  ],
  [
    Country.MC,
    {
      key: Country.MC,
      name: 'Monaco',
      countryCode: 377,
      currency: Currency.EUR,
      timezone: Timezone['Europe/Monaco'],
    },
  ],
  [
    Country.OM,
    {
      key: Country.OM,
      name: 'Oman',
      countryCode: 968,
      currency: Currency.OMR,
      timezone: Timezone['Asia/Muscat'],
    },
  ],
  [
    Country.PK,
    {
      key: Country.PK,
      name: 'Pakistan',
      countryCode: 92,
      currency: Currency.PKR,
      timezone: Timezone['Asia/Karachi'],
    },
  ],
  [
    Country.PA,
    {
      key: Country.PA,
      name: 'Panama',
      countryCode: 507,
      currency: Currency.PAB,
      timezone: Timezone['America/Panama'],
    },
  ],
  [
    Country.PH,
    {
      key: Country.PH,
      name: 'Philippines',
      countryCode: 63,
      currency: Currency.PHP,
      timezone: Timezone['Asia/Manila'],
    },
  ],
  [
    Country.QA,
    {
      key: Country.QA,
      name: 'Qatar',
      countryCode: 974,
      currency: Currency.QAR,
      timezone: Timezone['Asia/Qatar'],
    },
  ],
  [
    Country.WS,
    {
      key: Country.WS,
      name: 'Samoa',
      countryCode: 685,
      currency: Currency.WST,
      timezone: Timezone['Pacific/Apia'],
    },
  ],
  [
    Country.SA,
    {
      key: Country.SA,
      name: 'Saudi Arabia',
      countryCode: 966,
      currency: Currency.SAR,
      timezone: Timezone['Asia/Riyadh'],
    },
  ],
  [
    Country.RS,
    {
      key: Country.RS,
      name: 'Serbia',
      countryCode: 381,
      currency: Currency.RSD,
      timezone: Timezone['Europe/Belgrade'],
    },
  ],
  [
    Country.KR,
    {
      key: Country.KR,
      name: 'South Korea',
      countryCode: 82,
      currency: Currency.KRW,
      timezone: Timezone['Asia/Seoul'],
    },
  ],
  [
    Country.TR,
    {
      key: Country.TR,
      name: 'Turkey',
      countryCode: 90,
      currency: Currency.TRY,
      timezone: Timezone['Europe/Istanbul'],
    },
  ],
  [
    Country.TV,
    {
      key: Country.TV,
      name: 'Tuvalu',
      countryCode: 688,
      currency: Currency.TVD,
      timezone: Timezone['Pacific/Funafuti'],
    },
  ],
  [
    Country.UA,
    {
      key: Country.UA,
      name: 'Ukraine',
      countryCode: 380,
      currency: Currency.UAH,
      timezone: Timezone['Europe/Kiev'],
    },
  ],
  [
    Country.VN,
    {
      key: Country.VN,
      name: 'Vietnam',
      countryCode: 84,
      currency: Currency.VND,
      timezone: Timezone['Asia/Ho_Chi_Minh'],
    },
  ],
  [
    Country.YE,
    {
      key: Country.YE,
      name: 'Yemen',
      countryCode: 967,
      currency: Currency.YER,
      timezone: Timezone['Asia/Aden'],
    },
  ],
]);
