import { ClientAccountAuthenticateVo, setConfig } from '@cocast/api-client';
import { toast } from '@cocast/ui/toast';
import { navigation, persistAuthenticationToken } from 'libs';
import { homeRoute } from 'routes';
import { accountAction } from 'state';
import { Switch } from './Switch';
import { accountState } from './state';

export async function afterLogin({ authenticationToken: token, businesses }: ClientAccountAuthenticateVo) {
  setConfig({ auth: token });
  if (businesses?.length) {
    businesses.sort((a, b) => a.name.localeCompare(b.name));
    accountState.setState({ businesses });
    navigation().push(() => <Switch />);
    return;
  }

  await persistAuthenticationToken(token);
  accountAction.setAuthenticationToken(token);
  homeRoute.replace();
  navigation().popToRoot();
  setTimeout(() => {
    toast.success('Login successful');
  }, 10);
}
