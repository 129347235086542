import { api } from '@cocast/api-client';
import { useStateRef } from '@cocast/hooks/useStateRef';
import { toast } from '@cocast/ui/toast';
import { Password } from 'legacy/Input/Password';
import { navigation } from 'libs';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { userRoute } from 'routes';
import { uiStore } from 'state';
import { Page, ToolbarButton } from 'widgets';

function UserPasswordComponent() {
  const [current, setCurrent, currentRef] = useStateRef('');
  const [pwd, setPwd, pwdRef] = useStateRef('');
  const [repeat, setRepeat] = useState('');

  const onSave = useCallback(async () => {
    if (!uiStore.liveDemo) {
      const [error] = await api.ClientAccount.updatePassword({
        current: currentRef.current,
        password: pwdRef.current,
      });
      if (error) {
        return;
      }
    }
    toast.success('Password updated');
    navigation().pop();
  }, []);

  const disabled = current.length < 4 || pwd.length < 4 || pwd !== repeat;

  return (
    <Page
      title="Change Password"
      onBack="back"
      backDefaultHref={userRoute.path}
      footer={<ToolbarButton text="Save" disabled={disabled} handler={onSave} />}
    >
      <Password label="Current password" className="dark mt-xxl" onValueChange={setCurrent} />
      <Password label="New password" className="dark mt-xl" onValueChange={setPwd} />
      <Password label="Repeat new password" className="dark mt-xl" onValueChange={setRepeat} />
    </Page>
  );
}

export const UserPassword = observer(UserPasswordComponent);
