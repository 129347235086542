import { memo } from 'react';

function NotFoundComponent() {
  return (
    <section className="flex-center flex-col">
      <h1 className="text-hero-2 mt-xl mb-sm">Oops!</h1>
      <p className="text-t3">You are lost</p>
    </section>
  );
}

export const NotFound = memo(NotFoundComponent);
