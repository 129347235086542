export function getSearchParam(key: string): string | null {
  const search = window.location.search;
  const query = new URLSearchParams(search);
  return query.get(key);
}

export function getSearchParams() {
  return Array.from(new URLSearchParams(window.location.search).entries()).reduce<Record<string, string>>(
    (accu, [k, v]) => {
      accu[k] = v;
      return accu;
    },
    {},
  );
}
