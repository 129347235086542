import { useAsyncEffect } from '@cocast/hooks/useAsyncEffect';
import { useDelayState } from '@cocast/hooks/useDelayState';
import { ComponentType, createElement, Fragment, memo, PropsWithChildren, useRef, useState } from 'react';
import { isValidElementType } from 'react-is';
import { Transition as Trans } from './HeadlessTransition';

export interface TransitionProps<T extends {} = {}> {
  name: unknown;
  show?: boolean;
  disableAppear?: boolean;
  enter?: string;
  enterFrom?: string;
  enterTo?: string;
  leave?: string;
  leaveFrom?: string;
  leaveTo?: string;
  transitionDelay?: number;
  asFragment?: boolean;
  transition?: Partial<
    Omit<TransitionProps, 'transition' | 'name' | 'show' | 'disableAppear' | 'transitionDelay' | 'asFragment'>
  >;
  beforeEnter?: () => void | Promise<unknown>;
  afterEnter?: () => void | Promise<unknown>;
  beforeLeave?: () => void | Promise<unknown>;
  afterLeave?: () => void | Promise<unknown>;
  className?: string;
  component?: ComponentType<T>;
  props?: T;
}

function TransitionComponent<T extends {} = {}>({
  children,
  show: showing,
  disableAppear,
  transitionDelay = 300,
  enter,
  enterFrom,
  enterTo,
  leave,
  leaveFrom,
  leaveTo,
  asFragment,
  transition,
  beforeEnter,
  afterEnter,
  beforeLeave,
  afterLeave,
  className,
  component,
  props: componentProps,
}: PropsWithChildren<TransitionProps<T>>) {
  const [show, setShow] = useState(showing);
  const [willChange, setWillChange] = asFragment ? [] : useDelayState(false);

  const timer = useRef<number | null>();
  useAsyncEffect(async () => {
    if (showing === show) {
      return;
    }
    if (showing) {
      if (timer.current) {
        return;
      }
      setWillChange?.(true, 0);
      timer.current = window.setTimeout(() => {
        setShow(true);
        timer.current = null;
      }, transitionDelay);
      setWillChange?.(false, transitionDelay + 300);
    } else {
      setWillChange?.(true, 0);
      setShow(false);
      setWillChange?.(false, 300);
    }
  }, [showing, show]);

  const props = {
    show,
    beforeEnter,
    afterEnter,
    beforeLeave,
    afterLeave,
    className: asFragment ? undefined : className,
    style: willChange ? { willChange: 'transform, opacity' } : undefined,
  } as {};

  return (
    <Trans
      as={asFragment ? Fragment : undefined}
      appear={!disableAppear}
      enter={enter || transition?.enter || 'transition-all duration-300 ease-in-out'}
      enterFrom={enterFrom || transition?.enterFrom || 'opacity-0 translate-y-[5vh]'}
      enterTo={enterTo || transition?.enterTo || 'opacity-100 translate-y-0'}
      leave={leave || transition?.leave || 'transition-all duration-300 ease-in-out'}
      leaveFrom={leaveFrom || transition?.leaveFrom || 'opacity-100 translate-y-0'}
      leaveTo={leaveTo || transition?.leaveTo || 'opacity-0 translate-y-[5vh]'}
      {...props}
    >
      {isValidElementType(component) ? createElement(component, componentProps) : children}
    </Trans>
  );
}

export const Transition = memo(TransitionComponent) as typeof TransitionComponent;
