import { WithToString } from '@cocast/types';
import { validate as validateMail } from 'email-validator';
import { default as PasswordValidator } from 'password-validator';

export type Validator<T = number | string> = (v: T) => ValidateResult;

export type AsyncValidator<T = number | string> = (v: T) => Promise<ValidateResult>;

export type ValidateResult = string | null | undefined;

let passwordSchema: PasswordValidator;

function getPasswordSchema() {
  if (!passwordSchema) {
    passwordSchema = new PasswordValidator();
    passwordSchema
      .is()
      .min(8, 'Must have at least 8 characters')
      .is()
      .max(20, 'Must have a maximum length of 20 characters')
      .has()
      .letters(1, 'Must have at least 1 letter')
      .has()
      .digits(1, 'Must have at least 1 digits')
      .has()
      .not()
      .spaces();
  }
  return passwordSchema;
}

export function validatePassword(value: WithToString): ValidateResult {
  const details = getPasswordSchema().validate(value.toString(), { details: true }) as { message: string }[];
  return details.length ? details[0].message : null;
}

export function validateEmail(value: WithToString): ValidateResult {
  const valid = validateMail(value.toString());
  return valid ? null : 'Must use a valid email address';
}

const PHONE_REGEX = /^[0-9]{4}[0-9]+$/im;

export function validatePhone(value: WithToString): ValidateResult {
  const valid = PHONE_REGEX.test(value.toString().replaceAll(' ', ''));
  return valid ? null : 'Must be a valid phone number';
}

const URL_REGEX = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/im;

export function validateUrl(value: WithToString): ValidateResult {
  const valid = URL_REGEX.test(value.toString());
  return valid ? null : 'Must be a valid URL';
}

export type DefaultValidator = 'notnull' | 'url' | 'email' | 'password' | 'phone' | 'allowEmpty(phone)' | number;

export const DefaultValidators = new Map<DefaultValidator, Validator>([
  ['notnull', (v) => (v === null || v === undefined || !v.toString().trim() ? 'This field is required' : null)],
  ['url', validateUrl],
  ['email', validateEmail],
  ['password', validatePassword],
  ['phone', validatePhone],
  ['allowEmpty(phone)', allowEmpty(validatePhone)],
]);

export function getValidator<T extends number | string>(
  v: DefaultValidator | Validator | AsyncValidator | null,
): Validator | undefined {
  if (typeof v === 'function') {
    return v as Validator;
  }
  if (typeof v === 'number') {
    return (value: WithToString) => (value?.toString()?.length < v ? `Must have at least ${v} characters` : null);
  }
  if (!v) {
    return;
  }
  return DefaultValidators.get(v as DefaultValidator);
}

export function allowEmpty(validator: DefaultValidator | Validator): Validator {
  const validate = getValidator(validator);
  return function allowEmptyValidator(value: number | string) {
    if (value.toString().trim()) {
      return validate!(value);
    }
  };
}
