import { AerobicExercise } from '@cocast/types';

export const AerobicExercises = [
  {
    category: 'aerobic',
    recordType: 'tabata',
    type: 'tabata',
    id: '69207a1bce1f5d30969ef68d068e2cd6',
    imageId: '5e4cf7a1-6549-4618-948e-54d9143e8f00',
    name: 'back and forth step',
  },
  {
    category: 'aerobic',
    recordType: 'tabata',
    type: 'tabata',
    id: '526f32abe3cd5f1f108cafe5f7c3ca91',
    imageId: '31932fc3-08a7-4fab-94ba-d1db67187d00',
    name: 'battling rope',
  },
  {
    category: 'aerobic',
    recordType: 'aerobic',
    type: 'conditioning',
    id: 'f7a278a240236348c5e01da9ab54bf1e',
    imageId: '77a4fd52-60dd-419b-e196-aeaf71ae5d00',
    name: 'bike',
  },
  {
    category: 'aerobic',
    recordType: 'tabata',
    type: 'tabata',
    id: '3c319af65bc1c371f7ce04f2ad753671',
    imageId: '1d6fcfe9-9172-402e-ef48-3110fde12000',
    name: 'boxing',
  },
  {
    category: 'aerobic',
    recordType: 'tabata',
    type: 'tabata',
    id: 'b82c58280d159def1e2eecc58c92d8b3',
    imageId: 'bc92984d-0e73-445e-b0a8-77ac67578300',
    name: 'burpee',
  },
  {
    category: 'aerobic',
    recordType: 'tabata',
    type: 'tabata',
    id: 'dc663911511624fd5f6efb1a0f9e728d',
    imageId: '52655d14-c58a-4de9-f935-8e795a153d00',
    name: 'dumbell swing',
  },
  {
    category: 'aerobic',
    recordType: 'aerobic',
    type: 'conditioning',
    id: '14b75342a38a7494c18346877a148ab4',
    imageId: 'abff3aba-3d67-4c73-eb36-73eb5ba47d00',
    name: 'elliptical',
  },
  {
    category: 'aerobic',
    recordType: 'tabata',
    type: 'tabata',
    id: '688579eda60e9a08ccbd60cdd9f5b5b1',
    imageId: '2db1dcb5-497c-485b-3de1-f817b111cb00',
    name: 'high knee skip',
  },
  {
    category: 'aerobic',
    recordType: 'tabata',
    type: 'tabata',
    id: '6657d3fecd689c341c3633160da29c50',
    imageId: '37bde6aa-a4e8-4402-9be5-635eccb66400',
    name: 'jump on fit box',
  },
  {
    category: 'aerobic',
    recordType: 'tabata',
    type: 'tabata',
    id: '34f492c6e3a844a3aa61e05e4e03e6b1',
    imageId: '020b91b6-8143-46c1-0787-c5040300c800',
    name: 'jump split',
  },
  {
    category: 'aerobic',
    recordType: 'tabata',
    type: 'tabata',
    id: '4e83cc52c3c3373b157bbf1bf0296ca7',
    imageId: '82343f2e-ce92-4bf2-f273-a3ef19d4bd00',
    name: 'jump squat',
  },
  {
    category: 'aerobic',
    recordType: 'tabata',
    type: 'tabata',
    id: 'e3a88ca8cd3d556d3901c8c25d2cb953',
    imageId: '8140cec2-711f-4fe2-7a59-d1dab8a65400',
    name: 'kettlebell swing',
  },
  {
    category: 'aerobic',
    recordType: 'time',
    type: 'timing',
    id: '80248b5946c7bd6aaed4039d07fdf52f',
    imageId: '47c6e9b0-520d-46de-3112-1a57c1b74800',
    name: 'plank',
  },
  {
    category: 'aerobic',
    recordType: 'tabata',
    type: 'tabata',
    id: '73be2a31027dc119d23e79eb2bf241d9',
    imageId: '99ed0679-b9f2-4494-a0bd-1ac9489fb700',
    name: 'push up (knee to chest)',
  },
  {
    category: 'aerobic',
    recordType: 'time',
    type: 'timing',
    id: 'f8acc704ee0674f834735c76860c00cc',
    imageId: '15b3444f-8e7d-427e-2360-c6ed98006900',
    name: 'jump rope',
  },
  {
    category: 'aerobic',
    recordType: 'aerobic',
    type: 'conditioning',
    id: '89de3bba56349eda0de40fa350fb571a',
    imageId: '8a4bc10e-b9c9-48de-c125-026399559800',
    name: 'rowing',
  },
  {
    category: 'aerobic',
    recordType: 'aerobic',
    type: 'conditioning',
    id: 'c7e1c57ea9ef0acc37ff9096c9f501c9',
    imageId: 'e6fda7bd-7785-4e8b-d4b9-48892823e700',
    name: 'running',
  },
  {
    category: 'aerobic',
    recordType: 'tabata',
    type: 'tabata',
    id: '3253064cd8f08f28ccd929cef64c58e2',
    imageId: 'a3222485-0f16-48dd-fc0a-e99f65c40100',
    name: 'shuffle',
  },
  {
    category: 'aerobic',
    recordType: 'tabata',
    type: 'tabata',
    id: '540ff0b5a3a73552ccb03c2949929e84',
    imageId: '6b9cea9d-f93c-4b6d-eadd-2c852cedb300',
    name: 'skater hops',
  },
  {
    category: 'aerobic',
    recordType: 'aerobic',
    type: 'conditioning',
    id: '06eb887b8773a904489dc877443f7096',
    imageId: '78429732-c250-4c7a-6ebb-0e13b6ee7200',
    name: 'stepmill',
  },
] as AerobicExercise[];

export const AerobicTypes = ['conditioning', 'tabata', 'timing'] as string[];
