import { filterMap, isNil } from '@cocast/utils';
import { action, makeObservable, observable } from 'mobx';

interface Options<T> {
  scope?: string;
  module?: string;
  defaultValue?: T;
}

export class StorageValue<T> {
  constructor(key: string, { module, scope, defaultValue }: Options<T> = {}) {
    this.key = (scope ? `[${scope}]` : '') + (module ? `${module}.${key}` : key);
    const v = window.localStorage.getItem(this.key);
    try {
      this.value = v ? JSON.parse(v) : defaultValue;
      if (isNil(this.value) || typeof this.value !== typeof defaultValue) {
        this.value = defaultValue;
      }
    } catch (e) {
      this.value = defaultValue;
    }
    makeObservable(this);
  }

  private readonly key: string;

  private writeTimer: number = null;

  @observable
  public value: T;

  @action
  public readonly set = (value: T | ((current: T) => T)) => {
    const v = typeof value === 'function' ? (value as Function)(this.value) : value;
    if (v === this.value) {
      return;
    }

    const newValue = typeof v === 'object' ? filterMap(v) : v;
    this.value = newValue;

    if (this.writeTimer) {
      window.clearTimeout(this.writeTimer);
      this.writeTimer = null;
    }
    this.writeTimer = window.setTimeout(() => {
      window.localStorage.setItem(this.key, JSON.stringify(newValue));
      this.writeTimer = null;
    }, 0);
  };

  @action
  public readonly setField = <K extends keyof T>(field: K, value: T[K] | ((current: T[K]) => T[K])) => {
    this.set((current) => ({
      ...current,
      [field]: typeof value === 'function' ? (value as Function)(current[field]) : value,
    }));
  };

  static from = <T>(key: string, options?: Options<T>) => {
    return new StorageValue<T>(key, options);
  };
}
