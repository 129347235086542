import { memo, useEffect } from 'react';
import { homeRoute } from 'routes';

export function RootPageComponent() {
  useEffect(() => {
    homeRoute.replace();
  }, []);

  return <></>;
}

export const RootPage = memo(RootPageComponent);
