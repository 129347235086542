import { WithToString } from '@cocast/types';
import { filterMap, isEmptyObject, isNil } from '@cocast/utils';
import { isDev, isSSR } from '@cocast/utils-web/env';
import { withPreventEvent } from '@cocast/utils-web/eventHelper';
import { getSearchParams } from '@cocast/utils-web/query';
import { BaseSyntheticEvent, useMemo } from 'react';
import { useLocation, useRoute } from 'wouter';
import { MatcherFn, default as makeMatcher } from 'wouter/matcher';

export type SetState<P extends ReadonlyArray<string>, Q extends ReadonlyArray<string>> = ((
  ...params: Parameters<Route<P, Q>['url']>
) => {
  then: (callback: () => unknown) => void;
}) & {
  withPreventEvent: (e: BaseSyntheticEvent | Event) => void;
  withUnary: () => void;
  withAllQuery: () => void;
};

interface History {
  push: (v: string) => void;
  replace: (v: string) => void;
}

const defaultHistory: History = {
  push: (v: string) => {
    return window.history.pushState(null, '', v);
  },
  replace: (v: string) => {
    return window.history.replaceState(null, '', v);
  },
};

export class Route<P extends ReadonlyArray<string>, Q extends ReadonlyArray<string>> {
  constructor(path: string, query?: Q, params?: P) {
    this.params = params;
    this.query = query;
    const basedPath = `${Route.basedPath}${path}`.replaceAll('//', '/');
    this.originalPath = basedPath;
    this.path = params?.length ? `${basedPath}/:${params.join('/:')}` : basedPath;

    this.navigate.withPreventEvent = withPreventEvent(() => this.navigate(undefined, undefined, { keepQueries: [] }));
    this.navigate.withUnary = () => this.navigate(undefined, undefined, { keepQueries: [] });
    this.navigate.withAllQuery = () => this.navigate(undefined, undefined, { keepQueries: 'all' });
    this.replace.withPreventEvent = withPreventEvent(() => this.replace(undefined, undefined, { keepQueries: [] }));
    this.replace.withUnary = () => this.replace(undefined, undefined, { keepQueries: [] });
    this.replace.withAllQuery = () => this.replace(undefined, undefined, { keepQueries: 'all' });
  }

  private readonly matcher: MatcherFn = makeMatcher();

  private readonly originalPath: string;

  private readonly params?: P;

  private readonly query?: Q;

  public readonly path: string;

  public readonly url = (
    query?: {
      [K in Q[number]]?: WithToString;
    },
    params?: {
      [K in P[number]]: WithToString;
    },
    options?: {
      discardQueries?: Array<Q[number]> | ReadonlyArray<Q[number]> | boolean;
      keepQueries?: Array<Q[number]> | ReadonlyArray<Q[number]> | 'all' | '*';
    },
  ) => {
    let url = this.originalPath;
    if (this.params?.length) {
      if (!params) {
        throw new Error(`params is required: ${url}`);
      }
      url = `${this.originalPath}/${this.params.map((name) => params[name as P[number]]).join('/')}`;
    }

    if (!options?.discardQueries && !options?.keepQueries && (!this.query?.length || !query || isEmptyObject(query))) {
      return `${url}${window.location.search}`;
    }

    let queries: { [K in Q[number]]?: string } =
      isNil(options?.discardQueries) && isNil(options?.keepQueries)
        ? ({ ...query } as { [K in Q[number]]?: string })
        : options?.discardQueries === true
          ? {}
          : { ...getSearchParams(), ...query };
    if (Array.isArray(options?.discardQueries)) {
      options.discardQueries.forEach((key) => {
        delete queries[key!];
      });
    } else if (options?.keepQueries) {
      const keys =
        options.keepQueries === '*'
          ? Object.keys(queries)
          : options.keepQueries === 'all'
            ? this.query
            : options.keepQueries;
      queries = (keys as Q[number][]).reduce<{ [K in Q[number]]?: string }>((acc, key) => {
        if (!isNil(queries[key])) {
          acc[key] = queries[key];
        }
        return acc;
      }, {});
    }
    queries = filterMap(queries);
    if (!queries) {
      return url;
    }
    const search = new URLSearchParams(queries).toString();
    return `${url}?${search}`;
  };

  public readonly useMatch = (): boolean => {
    return useRoute(this.path)[0];
  };

  public readonly useRoute = (): {
    match: boolean;
    params: {
      [K in P[number]]: string;
    };
    query: {
      [K in Q[number]]?: string;
    };
    route: Route<P, Q>;
  } => {
    const [match, params] = useRoute<{
      [K in P[number]]: string;
    }>(this.path);
    const query = this.useQuery();
    return {
      match,
      params: params as {
        [K in P[number]]: string;
      },
      query,
      route: this,
    };
  };

  public readonly useQuery = (
    match?: boolean,
  ): {
    [K in Q[number]]?: string;
  } => {
    if (!this.query?.length) {
      return {};
    }
    const [location] = useLocation();
    const notMatch = match && !this.match(location);
    const search = notMatch ? null : isSSR() ? location.split('?')[1] || '' : window.location.search;
    return useMemo(() => {
      if (!search) {
        return {};
      }
      const p = new URLSearchParams(search);
      const query = Array.from(p.entries()).reduce<Record<string, string>>((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
      return query as {
        [K in Q[number]]: string;
      };
    }, [search]);
  };

  public readonly useParams = (): { [K in P[number]]?: string } => {
    if (!this.params?.length) {
      return {};
    }
    const [match, params] = useRoute<{ [K in P[number]]: string }>(this.path);
    return match ? params : {};
  };

  public readonly match = (location?: string): boolean => {
    return this.matcher(this.path, location || window.location.pathname)[0];
  };

  public readonly equals = (path: string) => path === this.path;

  public readonly navigate = ((...params: Parameters<Route<P, Q>['url']>) => {
    const url = this.url(...params);
    if (url === window.location.pathname + (window.location.search || '')) {
      return;
    }
    if (isDev()) {
      console.info('Navigate route to: ', url);
    }
    const s: { callback?: Function } = {};
    setTimeout(() => {
      Route.history.push(url);
      s.callback?.();
    }, 0);
    return {
      then: (callback: Function) => {
        s.callback = callback;
      },
    };
  }) as SetState<P, Q>;

  public readonly replace = ((...params: Parameters<Route<P, Q>['url']>) => {
    const url = this.url(...params);
    if (isDev()) {
      console.info('Replace route to: ', url);
    }

    const s: { callback?: Function } = {};
    setTimeout(() => {
      Route.history.replace(url);
      s.callback?.();
    }, 0);
    return {
      then: (callback: Function) => {
        s.callback = callback;
      },
    };
  }) as SetState<P, Q>;

  public readonly open = (...params: Parameters<Route<P, Q>['url']>) => {
    const url = this.url(...params);
    if (isDev()) {
      console.info('Open route to: ', url);
    }
    window.open(url, '_self');
  };

  public readonly discardQueries = (...discardKeys: Q[number][]) => {
    let url = this.originalPath;
    const newQuery = getSearchParams();
    if (discardKeys.length) {
      discardKeys.forEach((key) => {
        delete newQuery[key!];
      });
    }
    const search = new URLSearchParams(newQuery).toString();
    const newUrl = url + (search ? `?${search}` : '');
    const s: { callback?: Function } = {};
    setTimeout(() => {
      Route.history.replace(newUrl);
      s.callback?.();
    }, 0);
    return {
      then: (callback: Function) => {
        s.callback = callback;
      },
    };
  };

  public readonly isCurrent = () => {
    return this.equals(window.location.pathname);
  };

  static readonly from = <P extends ReadonlyArray<string>, Q extends ReadonlyArray<string>>(
    ...params: ConstructorParameters<typeof Route<P, Q>>
  ) => {
    return new Route(...params);
  };

  static readonly useHistory = (history: History) => {
    Route.history = history;
  };

  static basedPath: string = '/';

  static history: History = defaultHistory;
}
