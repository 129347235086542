import { EnvType } from '@cocast/types';
import type { toast } from '@cocast/ui/toast';
import { getEnv as getNextEnv } from '@cocast/utils-next/env';
import { getEnv as getWebEnv } from '@cocast/utils-web/env';

export interface APIClientConfig {
  auth?: string;
  onAuthenticationFailed?: (error: any) => unknown;
  env?: EnvType;
  toast?: Pick<typeof toast, 'loading' | 'error' | 'dismiss'>;
}

const c: APIClientConfig = {};

export function getConfig() {
  return c;
}

export function setConfig(config: Partial<APIClientConfig>) {
  Object.assign(c, config);
}

export function setEnv(env: EnvType) {
  c.env = env;
}

export function getEnv(): EnvType {
  return c.env || (typeof window === 'undefined' ? getNextEnv() : getWebEnv());
}
