import { ComponentType, createElement, MutableRefObject, PropsWithChildren, PureComponent } from 'react';

export interface ErrorBoundaryFallbackProps {
  error: Error;
  errorBoundary: ErrorBoundaryRef;
}

export interface ErrorBoundaryRef {
  reset: () => unknown;
}

export interface ErrorBoundaryProps {
  fallback: ComponentType<ErrorBoundaryFallbackProps>;
  error?: Error;
  errorBoundary?: MutableRefObject<ErrorBoundaryRef>;
}

interface State {
  error?: Error;
}

export class ErrorBoundary extends PureComponent<PropsWithChildren<ErrorBoundaryProps>> {
  state: State = {};

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  static getDerivedStateFromProps(props: ErrorBoundaryProps, state: State) {
    return { error: props.error || state.error };
  }

  private readonly ref: ErrorBoundaryRef = {
    reset: () => this.setState({ error: null }),
  };

  render() {
    if (this.props.errorBoundary) {
      this.props.errorBoundary.current = this.ref;
    }

    const { error } = this.state;
    if (error) {
      return createElement(this.props.fallback, { error, errorBoundary: this.ref });
    }
    return this.props.children;
  }
}
