import { useDelayState } from '@cocast/hooks/useDelayState';
import { useForwardRef } from '@cocast/hooks/useForwardRef';
import { Icons } from '@cocast/icons';
import { default as classNames } from 'classnames';
import {
  ChangeEvent,
  ComponentProps,
  createElement,
  CSSProperties,
  ElementType,
  FocusEvent,
  ForwardedRef,
  forwardRef,
  KeyboardEvent,
  memo,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { InputCounter } from './Counter';

export interface InputProps<T extends ElementType = 'input'> {
  title?: string;
  as?: T;
  className?: string;
  focusClassName?: string;
  inputClassName?: string;
  label?: ReactNode;
  labelClassName?: string;
  description?: ReactNode;
  descriptionClassName?: string;
  error?: string;
  errorClassName?: string;
  noErrorMessage?: boolean;
  disabled?: boolean;
  prefix?: ReactNode;
  prefixClassName?: string;
  suffix?: ReactNode;
  suffixClassName?: string;
  style?: CSSProperties;
  inputStyle?: CSSProperties;
  renderContent?: (val: any) => string;
  inputRef?: ForwardedRef<HTMLInputElement>;
  prefixRef?: ForwardedRef<HTMLSpanElement>;
  suffixRef?: ForwardedRef<HTMLSpanElement>;
  titleOffset?: number;
  onEnter?: (e: KeyboardEvent<HTMLInputElement>) => unknown;
  onValueChange?: (value: string) => unknown;
  children?: ReactNode | ReactNode[];
  selectOnFocus?: boolean;
  required?: boolean;
  counter?: boolean;
}

function InputComponent<T extends ElementType = 'input'>(
  {
    as,
    title,
    placeholder,
    className,
    focusClassName,
    inputClassName,
    label,
    labelClassName,
    description,
    descriptionClassName,
    required,
    error,
    errorClassName,
    noErrorMessage,
    prefix,
    prefixClassName,
    suffix,
    suffixClassName,
    style,
    inputStyle,
    renderContent,
    titleOffset,
    inputRef: inputRefProp,
    prefixRef: prefixRefProp,
    suffixRef: suffixRefProp,
    onFocus: onFocusProp,
    onBlur: onBlurProp,
    onValueChange,
    readOnly,
    disabled,
    onEnter,
    selectOnFocus,
    children,
    maxLength,
    counter,
    ...props
  }: InputProps<T> & Omit<ComponentProps<T>, keyof InputProps<T>>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const [inputElement, setInputElement] = useState<HTMLInputElement>();
  const inputRef = useForwardRef(inputRefProp);
  const setInputRef = useCallback((i: HTMLInputElement) => {
    inputRef.current = i;
    setInputElement(i);
  }, []);

  const [prefixElement, setPrefixElement] = useDelayState<HTMLSpanElement>(null, 50);
  useForwardRef(prefixRefProp).current = prefixElement;
  const [suffixElement, setSuffixElement] = useDelayState<HTMLSpanElement>(null, 50);
  useForwardRef(suffixRefProp).current = suffixElement;

  const [focus, setFocus] = useState(false);
  const onFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      setFocus(true);
      onFocusProp?.(e);
      if (selectOnFocus) {
        inputRef.current.select();
      }
    },
    [onFocusProp, selectOnFocus],
  );
  const onBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      setFocus(false);
      onBlurProp?.(e);
    },
    [onBlurProp],
  );
  const onKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (onEnter && e.key === 'Enter') {
        onEnter(e);
      }
      props?.onKeyPress?.(e);
    },
    [onEnter],
  );
  const needCount = maxLength && counter;
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onValueChange) {
        onValueChange(e.target.value);
      }
      props?.onChange?.(e);
    },
    [onValueChange, needCount, props.onChange],
  );

  const dl = prefixElement ? prefixElement.offsetWidth + 20 : undefined;
  const dr = suffixElement ? suffixElement.offsetWidth + 20 : undefined;
  const inputProps: ComponentProps<'input'> = {
    ref: setInputRef,
    className: inputClassName,
    onFocus,
    onBlur,
    onChange,
    onKeyPress,
    placeholder: title && !focus ? undefined : placeholder,
    readOnly,
    disabled,
    maxLength,
    style: {
      paddingLeft: dl,
      paddingRight: dr,
      ...inputStyle,
    },
    ...props,
  };
  if (props.type === 'number' && maxLength) {
    delete inputProps.type;
    inputProps.pattern = '[0-9]+';
  }
  const inputContent = createElement(as || 'input', inputProps);

  const hasValue = !!(props?.value || inputElement?.value);
  return (
    <div
      className={classNames(
        'input input-root',
        { focus, error, required, 'has-value': hasValue, readonly: readOnly, disabled },
        focus ? focusClassName : null,
        className,
      )}
      style={style}
      ref={ref}
    >
      {label ? <label className={classNames(labelClassName, 'label')}>{label}</label> : null}
      {prefix || suffix || title ? (
        <div className="input-wrapper">
          {prefix ? (
            <span
              style={{ height: inputElement?.offsetHeight }}
              ref={setPrefixElement}
              className={classNames('input-prefix', prefixClassName)}
            >
              {prefix}
            </span>
          ) : null}
          {inputContent}
          {suffix ? (
            <span
              style={{ height: inputElement?.offsetHeight }}
              ref={setSuffixElement}
              className={classNames('input-suffix', suffixClassName)}
            >
              {suffix}
            </span>
          ) : null}
          {title ? (
            <span
              className="input-title"
              style={{
                marginLeft: dl || '12px',
                top: focus || hasValue ? '-22px' : undefined,
                transform:
                  focus || hasValue ? `translateX(${-1 * (dl || 12) + (titleOffset ?? 0)}px) scale(0.85)` : undefined,
              }}
            >
              {title}
            </span>
          ) : null}
        </div>
      ) : (
        inputContent
      )}

      {!error && description ? (
        <label className={classNames('label desc', descriptionClassName)}>{description}</label>
      ) : null}

      {error && !noErrorMessage ? (
        <label className={classNames('label error', errorClassName)}>
          <Icons.Alert />
          <span>{error}</span>
        </label>
      ) : null}

      {counter && maxLength ? <InputCounter maxLength={maxLength} inputRef={inputRef} /> : null}

      {children}
    </div>
  );
}

export const Input = memo(forwardRef(InputComponent));
