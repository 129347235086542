import { ErrorBoundary, ErrorBoundaryFallbackProps } from '@cocast/components/ErrorBoundary';
import { useDelayRender } from '@cocast/hooks/useDelayRender';
import { ComponentType, ReactNode, createContext, memo, useContext, useMemo } from 'react';
import { Fallback } from './Fallback';
import { Route } from './route';

interface Props {
  children: ReactNode | ReactNode[];
  fallback?: ComponentType<{}>;
  errorFallback?: ComponentType<ErrorBoundaryFallbackProps>;
}

interface ContextValue {
  routes?: Set<Route<any, any>>;
  errorFallback?: ComponentType<ErrorBoundaryFallbackProps>;
}

const RouterContext = createContext<ContextValue>({});

export function useRouteContext() {
  return useContext(RouterContext);
}

function RouterProviderComponent({ children, fallback, errorFallback }: Props) {
  const context = useMemo<ContextValue>(() => ({ routes: new Set<Route<any, any>>(), errorFallback }), []);
  const render = useDelayRender(500);

  return (
    <RouterContext.Provider value={context}>
      {children}
      {render(() => (fallback && context.routes.size > 0 ? <Fallback component={fallback} /> : null))}
    </RouterContext.Provider>
  );
}

export const RouterProvider = memo(RouterProviderComponent);

export const RouteErrorBoundary = memo(({ children }: { children: ReactNode | ReactNode[] }) => {
  const { errorFallback } = useRouteContext();
  if (!errorFallback) {
    return children;
  }
  return <ErrorBoundary fallback={errorFallback}>{children}</ErrorBoundary>;
});
