import { BusinessClientMemoGoalVo } from '@cocast/api-client';
import { default as classNames } from 'classnames';
import { Radio } from 'legacy/Radio';
import { observer } from 'mobx-react-lite';
import { ReactNode, useCallback, useMemo } from 'react';
import { clientAction, clientStore } from 'state';
import { sheet } from 'widgets';

interface Props {
  goal: Partial<BusinessClientMemoGoalVo>;
  clamp?: boolean;
  className?: string;
  children?: ReactNode;
  onFinished?: (id: string) => unknown;
}

function GoalItemComponent({
  goal: { id, title, description, createdAt, finishedAt },
  clamp,
  className,
  children,
  onFinished,
}: Props) {
  const date = useMemo(() => clientStore.fromNow(finishedAt || createdAt), [createdAt, finishedAt]);

  const onFinish = useCallback(() => {
    sheet.confirm({
      title: 'Finish Goal ?',
      description: `Are you sure you want to finish goal "${title}" ?`,
      confirmText: 'Finish',
      noResize: true,
      onConfirm: (sheet) =>
        clientAction.finishGoal(id, () => {
          sheet.setOpen.disable();
          onFinished?.(id);
        }),
    });
  }, [onFinished, id]);

  return (
    <div className={classNames('flex-start-center space-x-lg', className)} data-goal-id={id}>
      <Radio
        className="lemon lg"
        checked={!!finishedAt}
        onCheck={finishedAt ? undefined : onFinish}
        preventSyntheticEvent
      />
      <div>
        {title ? (
          <p
            className={classNames(
              'text-base text-b1',
              finishedAt ? 'line-through opacity-50' : null,
              clamp ? 'line-clamp-1' : null,
            )}
          >
            {title}
          </p>
        ) : null}
        {description ? (
          <p
            className={classNames(
              'text-content-100 text-caption-light',
              title ? 'mt-3xs' : null,
              finishedAt ? 'line-through opacity-60' : null,
              clamp ? 'line-clamp-1' : null,
            )}
          >
            {description}
          </p>
        ) : null}
        <p className="text-content-100 text-caption mt-3xs">
          {finishedAt ? 'Finished' : 'Created'} at {date}
        </p>
      </div>
      {children}
    </div>
  );
}

export const GoalItem = observer(GoalItemComponent);
