import { useEffect, useRef, useState } from 'react';

export function useUpdatedState<T>(initialState: T | (() => T)): ReturnType<typeof useState<T>> {
  const [state, setState] = useState<T>(initialState);

  useEffect(() => {
    setState(typeof initialState === 'function' ? (initialState as Function)() : initialState);
  }, [initialState]);

  return [state, setState];
}

export function useUpdatedStateRef<T>(initialState: T | (() => T)) {
  const [state, setState] = useState<T>(initialState);

  useEffect(() => {
    setState(typeof initialState === 'function' ? (initialState as Function)() : initialState);
  }, [initialState]);

  const ref = useRef<T>();
  ref.current = state;

  return [state, setState, ref] as const
}
