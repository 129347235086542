import { api, BusinessClientMemoGoalVo, ClientGoalCreateDto } from '@cocast/api-client';
import { toast } from '@cocast/ui/toast';
import { action, makeObservable, runInAction } from 'mobx';
import { nanoid } from 'nanoid';
import { businessStore } from '../business/store';
import { uiStore } from '../ui/store';
import { clientStore } from './store';

export class ClientAction {
  constructor() {
    makeObservable(this);
  }

  @action
  public readonly finishGoal = async (id: string, callback?: () => unknown) => {
    if (!uiStore.liveDemo) {
      const [error] = await api.Client.finishGoal({ id });
      if (error) {
        return;
      }
    }
    runInAction(() => {
      const { goals } = clientStore;
      const index = goals?.results?.findIndex((goal) => goal.id === id);
      if (index !== undefined && index !== -1) {
        goals.results[index].finishedAt = new Date().toISOString();
      }
      goals.finished += 1;
      goals.inProgress -= 1;
    });
    callback?.();
    toast.success('Goal finished');
  };

  @action
  public readonly createGoal = async (
    data: ClientGoalCreateDto,
    callback?: (goal: BusinessClientMemoGoalVo) => unknown,
  ) => {
    let result: BusinessClientMemoGoalVo;
    if (!uiStore.liveDemo) {
      const [error, result] = await api.Client.createGoal(data);
      if (error) {
        return;
      }
    } else {
      result = {
        ...data,
        createdAt: new Date().toISOString(),
        forBusinessClientId: '',
        forBusinessClientMemoId: '',
        forBusinessId: businessStore.businessId,
        id: nanoid(),
      };
    }
    runInAction(() => {
      const { goals } = clientStore;
      goals.results.unshift(result);
      goals.inProgress += 1;
    });
    callback?.(result);
    toast.success('Goal created');
  };
}

export const clientAction = new ClientAction();
