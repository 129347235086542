import { Children, cloneElement, isValidElement, memo, PropsWithChildren, ReactElement } from 'react';
import { TransitionProps } from './Transition';

export interface TransitionGroupProps {
  current: unknown;
  transition?: TransitionProps['transition'];
}

function TransitionGroupComponent({ current, children, transition }: PropsWithChildren<TransitionGroupProps>) {
  return (
    <>
      {Children.map(children, (child, index) => {
        if (!isValidElement(child)) {
          console.warn(`[TransitionGroup] Child ${index} is not a valid element`);
          return child;
        }
        const element = child as ReactElement;
        return cloneElement(element, {
          show: current === element.props.name,
          transition: element.props.transition || transition,
        });
      })}
    </>
  );
}

export const TransitionGroup = memo(TransitionGroupComponent);
