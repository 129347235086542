/** This file is generated automatically. Do not edit it manually. **/

export enum ColoredIconType {
  google = 'google',
  youtube = 'youtube',
}

export enum CountryIconType {
  ae = 'ae',
  at = 'at',
  au = 'au',
  be = 'be',
  br = 'br',
  ca = 'ca',
  ch = 'ch',
  cy = 'cy',
  cz = 'cz',
  de = 'de',
  dk = 'dk',
  ee = 'ee',
  es = 'es',
  fi = 'fi',
  fr = 'fr',
  gb = 'gb',
  gr = 'gr',
  hk = 'hk',
  hr = 'hr',
  hu = 'hu',
  ie = 'ie',
  in = 'in',
  it = 'it',
  jp = 'jp',
  li = 'li',
  lt = 'lt',
  lu = 'lu',
  lv = 'lv',
  mt = 'mt',
  mx = 'mx',
  my = 'my',
  nl = 'nl',
  no = 'no',
  nz = 'nz',
  pl = 'pl',
  pt = 'pt',
  ro = 'ro',
  rw = 'rw',
  se = 'se',
  sg = 'sg',
  sk = 'sk',
  th = 'th',
  us = 'us',
  za = 'za',
}

export enum IllustrationIconType {
  accessKeys = 'access-keys',
  address = 'address',
  bug = 'bug',
  cards = 'cards',
  chat = 'chat',
  docs = 'docs',
  done = 'done',
  empty = 'empty',
  info = 'info',
  lockKey = 'lock-key',
  metric = 'metric',
  notFound = 'not-found',
  nothingFound = 'nothing-found',
  phone = 'phone',
  photos = 'photos',
  search = 'search',
  tags = 'tags',
  target = 'target',
  viewPhone = 'view-phone',
}

export enum IconType {
  agreement = 'agreement',
  alert = 'alert',
  alignCenter = 'align-center',
  alignLeft = 'align-left',
  alignRight = 'align-right',
  alternate = 'alternate',
  apple = 'apple',
  apps = 'apps',
  arrowLeft = 'arrow-left',
  arrowRight = 'arrow-right',
  assign = 'assign',
  avatar = 'avatar',
  band = 'band',
  barChart = 'bar-chart',
  barbell = 'barbell',
  bell = 'bell',
  bin = 'bin',
  birthday = 'birthday',
  bodyFat = 'body-fat',
  body = 'body',
  bodyweight = 'bodyweight',
  bold = 'bold',
  boxing = 'boxing',
  briefcase = 'briefcase',
  cable = 'cable',
  calendarAlt = 'calendar-alt',
  calendar = 'calendar',
  camera = 'camera',
  car = 'car',
  card = 'card',
  change = 'change',
  charge = 'charge',
  chartPie = 'chart-pie',
  chart = 'chart',
  checkIn = 'check-in',
  check = 'check',
  circle = 'circle',
  clock = 'clock',
  close = 'close',
  code = 'code',
  coins = 'coins',
  contacts = 'contacts',
  copy = 'copy',
  crown = 'crown',
  cube = 'cube',
  dollorCircle = 'dollor-circle',
  download = 'download',
  dragHorizontal = 'drag-horizontal',
  dumbBell = 'dumb-bell',
  edit = 'edit',
  emergency = 'emergency',
  equipment = 'equipment',
  externalLink = 'external-link',
  eyeSlash = 'eye-slash',
  eye = 'eye',
  facebook = 'facebook',
  fileText = 'file-text',
  filter = 'filter',
  flag = 'flag',
  flash = 'flash',
  focus = 'focus',
  food = 'food',
  form = 'form',
  formatClear = 'format-clear',
  fullscreenExit = 'fullscreen-exit',
  fullscreen = 'fullscreen',
  genderFemale = 'gender-female',
  genderMale = 'gender-male',
  gender = 'gender',
  gift = 'gift',
  globe = 'globe',
  goal = 'goal',
  google = 'google',
  groupClass = 'group-class',
  hammerMachine = 'hammer-machine',
  header1 = 'header1',
  header2 = 'header2',
  header3 = 'header3',
  header4 = 'header4',
  header5 = 'header5',
  header6 = 'header6',
  headphonesMicro = 'headphones-micro',
  heart = 'heart',
  height = 'height',
  houseAlt = 'house-alt',
  hover = 'hover',
  hr = 'hr',
  hybridTrain = 'hybrid-train',
  image = 'image',
  indentDecrease = 'indent-decrease',
  indentIncrease = 'indent-increase',
  injury = 'injury',
  inpersonTrain = 'inperson-train',
  intakeForm = 'intake-form',
  invoice = 'invoice',
  italic = 'italic',
  journal = 'journal',
  kettlebell = 'kettlebell',
  key = 'key',
  layer = 'layer',
  leverageMachine = 'leverage-machine',
  lineBreak = 'line-break',
  lineChart = 'line-chart',
  linkChain = 'link-chain',
  link = 'link',
  listBulleted = 'list-bulleted',
  listNumbers = 'list-numbers',
  list = 'list',
  mail = 'mail',
  mapPinAlt = 'map-pin-alt',
  mapPin = 'map-pin',
  mark = 'mark',
  maximize = 'maximize',
  medical = 'medical',
  memo = 'memo',
  message = 'message',
  microsoft = 'microsoft',
  minimize = 'minimize',
  minus = 'minus',
  moreHorizontal = 'more-horizontal',
  obstacles = 'obstacles',
  onlineTrain = 'online-train',
  overview = 'overview',
  pause = 'pause',
  personalClass = 'personal-class',
  phone = 'phone',
  playSolid = 'play-solid',
  play = 'play',
  plus = 'plus',
  program = 'program',
  questionCircle = 'question-circle',
  question = 'question',
  quote = 'quote',
  redo = 'redo',
  refresh = 'refresh',
  reset = 'reset',
  rowing = 'rowing',
  rows = 'rows',
  ruler = 'ruler',
  run = 'run',
  save = 'save',
  scale = 'scale',
  search = 'search',
  section = 'section',
  services = 'services',
  settingsAlt = 'settings-alt',
  settings = 'settings',
  signOut = 'sign-out',
  smallDown = 'small-down',
  smallLeft = 'small-left',
  smallRight = 'small-right',
  smallUp = 'small-up',
  smithMachine = 'smith-machine',
  sort = 'sort',
  spinner = 'spinner',
  star = 'star',
  strength = 'strength',
  strikethrough = 'strikethrough',
  superset = 'superset',
  swimming = 'swimming',
  tag = 'tag',
  template = 'template',
  thinArrowDown = 'thin-arrow-down',
  thinArrowUp = 'thin-arrow-up',
  timer = 'timer',
  trainer = 'trainer',
  twitter = 'twitter',
  underline = 'underline',
  undo = 'undo',
  ungroup = 'ungroup',
  update = 'update',
  uploadAlt = 'upload-alt',
  user = 'user',
  users = 'users',
  variant = 'variant',
  video = 'video',
  view = 'view',
  vital = 'vital',
  warn = 'warn',
  water = 'water',
  yoga = 'yoga',
}
