import { api } from '@cocast/api-client';
import { useDocumentTitle } from '@cocast/hooks/useDocumentTitle';
import { useLoading } from '@cocast/hooks/useLoading';
import { useExerciseDetail, useScheduleDetail, useWorkoutDetail } from 'components';
import { SkeletonGroup } from 'legacy/Skeleton';
import { memo, useState } from 'react';
import { homeRoute } from 'routes';
import { Page, Segment } from 'widgets';
import { ScheduleListItem } from './ScheduleListItem';

type Filter = 'upcoming' | 'pending' | 'past';

const options: Filter[] = ['upcoming', 'pending', 'past'];

function ScheduleListComponent() {
  const [filter, setFilter] = useState<Filter>('upcoming');

  const { result: schedules, loading } = useLoading(
    async () => {
      const [, result] = await api.Client.schedules({ filter, take: 999 });
      return result;
    },
    [filter],
    { immediate: true },
  );

  const scheduleDetail = useScheduleDetail();
  const workoutDetail = useWorkoutDetail();
  const exerciseDetail = useExerciseDetail();

  useDocumentTitle('Schedules');

  return (
    <Page
      title="Schedules"
      segment={
        <Segment<Filter>
          options={options}
          defaultValue={filter}
          onChange={setFilter}
          labelClassName="capitalize font-bold"
        />
      }
      onBack="back"
      backDefaultHref={homeRoute.path}
      headerToolbarClassName="pb-xs !pt-[max(var(--ion-safe-area-top,0),18px)]"
    >
      {loading ? (
        <SkeletonGroup rows={3} itemClassName="!bg-dark" height="138px" rounded="20px" space="16px" />
      ) : schedules?.total ? (
        schedules.results?.map((schedule) => (
          <ScheduleListItem
            key={schedule.id}
            schedule={schedule}
            scheduleDetail={scheduleDetail}
            workoutDetail={workoutDetail}
            exerciseDetail={exerciseDetail}
          />
        ))
      ) : (
        <div>No {filter} schedules</div>
      )}
    </Page>
  );
}

export const ScheduleList = memo(ScheduleListComponent);
