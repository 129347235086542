import { Route } from '@cocast/router';

export const rootRoute = Route.from('/');

export const homeRoute = Route.from('/home');

export const userRoute = Route.from('/user');

// export const scheduleRoute = Route.from('/schedule');

export const accountRoute = Route.from('/account/:module');
export const accountStartRoute = Route.from('/account/start', ['preview'] as const);

export const notFoundRoute = Route.from('/404');
