import { useBoolean } from '@cocast/hooks/useBoolean';
import { Icons } from '@cocast/icons';
import { toast } from '@cocast/ui/toast';
import { promiseWrapper } from '@cocast/utils';
import { cn } from '@cocast/utils-web/misc';
import { resizeImage, selectImage } from '@cocast/utils-web/upload';
import { default as classNames } from 'classnames';
import { createElement, CSSProperties, memo, useCallback } from 'react';

export interface AvatarProps {
  src?: string;
  name?: string;
  id?: string;
  size?: number;
  className?: string;
  textClassName?: string;
  onUpload?: (file: File) => unknown;
  style?: CSSProperties;
  crossOrigin?: boolean;
}

function AvatarComponent({ className, src, name, id, size, textClassName, onUpload, style, crossOrigin }: AvatarProps) {
  const [uploading, setUploading] = useBoolean(false);
  const onClickUpload = useCallback(async () => {
    const [error, file] = await promiseWrapper(selectImage());
    if (error) {
      toast.error(error.message ?? error.toString());
      return;
    }
    if (!file) {
      return null;
    }
    const resizedFile = await resizeImage(file, { maxWidth: 200, maxHeight: 200 });
    return setUploading.withEnable(() => onUpload(resizedFile));
  }, [onUpload]);

  const showUpload = onUpload && !src && !name && !id;
  return (
    <div
      className={cn(className, 'avatar', { uploading, upload: onUpload, 'show-upload': showUpload })}
      style={{
        backgroundImage: src
          ? crossOrigin
            ? undefined
            : `url(${src})`
          : showUpload
            ? 'linear-gradient(160.08deg, #EEEEEE 8.26%, #BEBEBE 83.95%)'
            : 'linear-gradient(180deg, #7A7A7A 0%, #252525 100%)',
        width: size,
        height: size,
        ...style,
      }}
      onClick={onUpload ? onClickUpload : undefined}
    >
      {src && crossOrigin ? (
        <img src={src} alt={name} className="w-full h-full object-cover" referrerPolicy="no-referrer" />
      ) : null}
      {src || !name ? null : (
        <span className={classNames('avatar-name', textClassName)} style={{ fontSize: size ? size / 2 : undefined }}>
          {name?.[0]?.toUpperCase()}
        </span>
      )}
      {onUpload ? (
        <div className="avatar-upload">
          {createElement(uploading ? Icons.Spinner : Icons.UploadAlt, {
            size: 5,
            className: uploading ? 'animate-spin' : null,
          })}
        </div>
      ) : null}
    </div>
  );
}

export const Avatar = memo(AvatarComponent);
