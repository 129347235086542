export enum APIErrorCode {
  // Account
  'AUTHENTICATION_TOKEN_INVALID' = 10000,
  'AUTHENTICATION_TOKEN_NOT_EXISTS' = 10001,
  'EMAIL_ALREADY_EXISTS' = 10002,
  'INVITE_TOKEN_NOT_EXISTS' = 10003,
  'INVALID_PASSWORD' = 10004,
  'ACCOUNT_ALREADY_LINKED' = 10005,
  // Permission
  'NO_PERMISSIONS' = 20000,
  'ROLE_IS_IN_USE' = 20001,
  'STAFF_REACH_LIMIT' = 20002,
  // Client
  'CLIENT_NOT_LINKED' = 30000,
}
