import { SkeletonGroup } from 'legacy/Skeleton';
import { memo } from 'react';
import { Page } from 'widgets';

const itemSkeleton = (
  <SkeletonGroup
    rows={[
      ['120px', '40px', '12px'],
      ['100%', '200px', '20px'],
    ]}
    space="16px"
    itemClassName="!bg-dark"
    className="mb-xl"
  />
);

function DashboardSkeletonComponent() {
  return (
    <Page
      title="Dashboard"
      contentTitle={
        <div className="px-md flex-between-center">
          <div className="flex-start-center space-x-sm">
            <div className="loading-skeleton w-[42px] h-[42px] rounded-full !bg-dark" />
            <SkeletonGroup
              itemClassName="!bg-dark"
              rows={[
                ['120px', '24px'],
                ['180px', '14px'],
              ]}
              space="8px"
            />
          </div>
        </div>
      }
      contentClassName="pt-xl"
    >
      {itemSkeleton}
      {itemSkeleton}
    </Page>
  );
}

export const DashboardSkeleton = memo(DashboardSkeletonComponent);
