import { BusinessScheduleVo } from '@cocast/api-client';
import { Icons } from '@cocast/icons';
import { DurationType, ScheduleStatus as Status } from '@cocast/types';
import { formatDuration } from '@cocast/utils-business/format';
import { default as classNames } from 'classnames';
import { ScheduleStatus } from 'components';
import { Avatar } from 'legacy/Avatar';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { businessStore, clientStore } from 'state';
import { countWorkoutsSets } from 'utils';

const blockClassName = 'rounded-md bg-opacity-10 py-xs px-sm text-caption flex-center space-x-3xs truncate';

interface Props {
  schedule: BusinessScheduleVo;
}

function ScheduleItemComponent({ schedule }: Props) {
  const staff = schedule?.assignedStaff;
  const workouts = schedule?.assignedWorkouts;

  const { sectionCount, setCount, supersetCount, circuitsCount } = useMemo(
    () => countWorkoutsSets(workouts?.map(({ id }) => businessStore.getWorkout(id)) || []),
    [workouts],
  );

  return (
    <>
      <div className="flex space-x-md">
        <Avatar size={42} name={staff.name} src={staff.avatar} id={staff.id} crossOrigin />
        <div className="retractable overflow-hidden">
          <div className="flex-between-center space-x-sm">
            <p className="text-b1 truncate capitalize">
              {schedule.name}
              {schedule.assignedClassId ? ` (Class)` : ''}
            </p>
            <ScheduleStatus status={schedule?.status as Status} />
          </div>
          <p className="text-caption-light mt-3xs">
            {clientStore.formatDate(schedule.startAt, true)} · with <span className="capitalize">{staff?.name}</span>
          </p>
        </div>
      </div>

      <div className="pl-[58px]">
        <div className="flex mt-md gap-3xs flex-wrap">
          <div className={classNames(blockClassName, 'bg-primary')}>
            <Icons.Timer size={6} className="text-primary" />
            <span>{formatDuration(schedule.duration, DurationType.Minute)}</span>
          </div>
          {schedule.group ? (
            <div className={classNames(blockClassName, 'bg-fuchsia')}>
              <Icons.GroupClass size={6} className="text-fuchsia no-retractable" />
              <span className="truncate">Group</span>
            </div>
          ) : null}
          {workouts?.length ? (
            <div className={classNames(blockClassName, 'bg-orange')}>
              <Icons.Kettlebell size={6} className="text-orange" />
              <span>{workouts?.length || 0} Workouts</span>
            </div>
          ) : null}
          {sectionCount ? (
            <div className={classNames(blockClassName, 'bg-cobalt')}>
              <Icons.Section size={6} className="text-cobalt" />
              <span>{sectionCount} Sections</span>
            </div>
          ) : null}
          {setCount ? (
            <div className={classNames(blockClassName, 'bg-maroon')}>
              <Icons.DumbBell size={6} className="text-maroon" />
              <span>{setCount} Exercises</span>
            </div>
          ) : null}
          {supersetCount ? (
            <div className={classNames(blockClassName, 'bg-purple')}>
              <Icons.Superset size={6} className="text-purple" />
              <span>{supersetCount} Supersets</span>
            </div>
          ) : null}
          {circuitsCount ? (
            <div className={classNames(blockClassName, 'bg-mint')}>
              <Icons.Circle size={6} className="text-mint" />
              <span>{circuitsCount} Circuits</span>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export const ScheduleItem = observer(ScheduleItemComponent);
