import { api } from '@cocast/api-client';
import { useStateRef } from '@cocast/hooks/useStateRef';
import { Icons } from '@cocast/icons';
import { Button } from 'legacy/Button';
import { Input } from 'legacy/Input';
import { Password } from 'legacy/Input/Password';
import { memo, useCallback, useState } from 'react';
import { accountStartRoute } from 'routes';
import { Page } from 'widgets';
import { accountState } from './state';
import { afterLogin } from './utils';

function SignupComponent() {
  const { email } = accountState;
  const [password, setPassword, passwordRef] = useStateRef('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const onContinue = useCallback(async () => {
    const [error, info] = await api.ClientAccount.signup({
      email: accountState.email,
      password: passwordRef.current,
    });
    if (error) {
      return;
    }
    await afterLogin(info);
  }, []);

  const valid = password === repeatPassword && password.length >= 4;
  return (
    <Page
      title="Sign up"
      contentTitle="Create your account"
      contentClassName="space-y-lg pt-lg"
      onBack="back"
      backDefaultHref={accountStartRoute.path}
    >
      <Input
        className="dark"
        prefix={<Icons.Mail size={4} />}
        placeholder="Your email address"
        type="email"
        value={email}
        readOnly
      />
      <Password className="dark" placeholder="Password" onValueChange={setPassword} />
      <Password className="dark" placeholder="Repeat password" onValueChange={setRepeatPassword} />
      <Button className="btn w-full lg lemon solid !font-black !mt-xxl" onClick={onContinue} disabled={!valid}>
        Sign Up
      </Button>
    </Page>
  );
}

export const Signup = memo(SignupComponent);
