import type { WorkoutFields, WorkoutSectionStruct, WorkoutSetStruct, WorkoutSupersetStruct } from '@cocast/api-client';
import { ExerciseMap } from '@cocast/exercises';
import { IconComponent, Icons } from '@cocast/icons';

export function countWorkoutSets(sections: WorkoutSectionStruct[], filter?: (exercise: string) => boolean) {
  let setCount = 0;
  let supersetCount = 0;
  let circuitsCount = 0;
  sections?.forEach(({ sets }) =>
    sets.forEach((set) => {
      if (set.superset) {
        const exercises = (set as WorkoutSupersetStruct).exercises;
        const len = filter ? exercises?.filter(({ exercise }) => filter(exercise)).length : exercises.length;
        if (!len) {
          return;
        }
        if (len === 1) {
          setCount++;
        }
        if (len === 2) {
          supersetCount++;
        } else {
          circuitsCount++;
        }
      } else {
        setCount++;
      }
    }),
  );
  return {
    setCount,
    supersetCount,
    circuitsCount,
  };
}

export function getWorkoutEquipments(sections: WorkoutSectionStruct[], exerciseMap: ExerciseMap) {
  const exercises = new Set<string>();
  sections.forEach(({ sets }) =>
    sets.forEach((set) => {
      if (set.superset) {
        (set as WorkoutSupersetStruct).exercises.forEach((exercise) => exercises.add(exercise.exercise));
      } else {
        exercises.add((set as WorkoutSetStruct).exercise);
      }
    }),
  );
  const equipments = new Set<string>();
  exercises.forEach((exercise) => {
    const equipment = exerciseMap.get(exercise)?.equipment?.toLowerCase();
    if (equipment) {
      equipments.add(equipment);
    }
  });
  return Array.from(equipments);
}

export const equipmentIcons = new Map<string, IconComponent>([
  ['barbell', Icons.Barbell],
  ['bodyweight', Icons.Bodyweight],
  ['cable', Icons.Cable],
  ['dumbbell', Icons.DumbBell],
  ['hummer machine', Icons.HammerMachine],
  ['smith machine', Icons.SmithMachine],
  ['leverage machine', Icons.LeverageMachine],
  ['trx & band', Icons.Band],
]);

export type WorkoutFieldType = keyof Omit<WorkoutFields, '__typename'>;

export const workoutFieldLabels: Map<WorkoutFieldType, string> = new Map([
  ['shortDistance', 'distance (short)'],
  ['heartRate', 'heart rate'],
  ['percentageHeartRate', '%HR'],
  ['timePerRound', 'time/round'],
  ['restPerRound', 'rest/round'],
  ['rir', 'RIR (reps in reserve)'],
  ['rpm', 'RPM (rotations per minute)'],
  ['rpe', 'RPE (rate of perceived exertion)'],
]);

export const workoutFieldAlias: Map<WorkoutFieldType, string> = new Map([
  ['shortDistance', 'distance'],
  ['heartRate', 'heart rate'],
  ['rir', 'RIR'],
  ['rpm', 'RPM'],
  ['rpe', 'RPE'],
  ['weight', 'W'],
]);
