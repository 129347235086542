/** This file is generated automatically. Do not edit it manually. **/
import { createIcon } from './factory';

export const CountryAE = createIcon('country', 'ae', 'CountryAE');
export const CountryAR = createIcon('country', 'ar', 'CountryAR');
export const CountryAT = createIcon('country', 'at', 'CountryAT');
export const CountryAU = createIcon('country', 'au', 'CountryAU');
export const CountryBB = createIcon('country', 'bb', 'CountryBB');
export const CountryBE = createIcon('country', 'be', 'CountryBE');
export const CountryBG = createIcon('country', 'bg', 'CountryBG');
export const CountryBO = createIcon('country', 'bo', 'CountryBO');
export const CountryBR = createIcon('country', 'br', 'CountryBR');
export const CountryBS = createIcon('country', 'bs', 'CountryBS');
export const CountryCA = createIcon('country', 'ca', 'CountryCA');
export const CountryCH = createIcon('country', 'ch', 'CountryCH');
export const CountryCL = createIcon('country', 'cl', 'CountryCL');
export const CountryCO = createIcon('country', 'co', 'CountryCO');
export const CountryCR = createIcon('country', 'cr', 'CountryCR');
export const CountryCY = createIcon('country', 'cy', 'CountryCY');
export const CountryCZ = createIcon('country', 'cz', 'CountryCZ');
export const CountryDE = createIcon('country', 'de', 'CountryDE');
export const CountryDK = createIcon('country', 'dk', 'CountryDK');
export const CountryEE = createIcon('country', 'ee', 'CountryEE');
export const CountryEG = createIcon('country', 'eg', 'CountryEG');
export const CountryES = createIcon('country', 'es', 'CountryES');
export const CountryFI = createIcon('country', 'fi', 'CountryFI');
export const CountryFR = createIcon('country', 'fr', 'CountryFR');
export const CountryGB = createIcon('country', 'gb', 'CountryGB');
export const CountryGE = createIcon('country', 'ge', 'CountryGE');
export const CountryGR = createIcon('country', 'gr', 'CountryGR');
export const CountryHK = createIcon('country', 'hk', 'CountryHK');
export const CountryHR = createIcon('country', 'hr', 'CountryHR');
export const CountryHU = createIcon('country', 'hu', 'CountryHU');
export const CountryIE = createIcon('country', 'ie', 'CountryIE');
export const CountryIL = createIcon('country', 'il', 'CountryIL');
export const CountryIN = createIcon('country', 'in', 'CountryIN');
export const CountryIR = createIcon('country', 'ir', 'CountryIR');
export const CountryIS = createIcon('country', 'is', 'CountryIS');
export const CountryIT = createIcon('country', 'it', 'CountryIT');
export const CountryJO = createIcon('country', 'jo', 'CountryJO');
export const CountryJP = createIcon('country', 'jp', 'CountryJP');
export const CountryKR = createIcon('country', 'kr', 'CountryKR');
export const CountryKW = createIcon('country', 'kw', 'CountryKW');
export const CountryLI = createIcon('country', 'li', 'CountryLI');
export const CountryLT = createIcon('country', 'lt', 'CountryLT');
export const CountryLU = createIcon('country', 'lu', 'CountryLU');
export const CountryLV = createIcon('country', 'lv', 'CountryLV');
export const CountryMC = createIcon('country', 'mc', 'CountryMC');
export const CountryMT = createIcon('country', 'mt', 'CountryMT');
export const CountryMX = createIcon('country', 'mx', 'CountryMX');
export const CountryMY = createIcon('country', 'my', 'CountryMY');
export const CountryNL = createIcon('country', 'nl', 'CountryNL');
export const CountryNO = createIcon('country', 'no', 'CountryNO');
export const CountryNZ = createIcon('country', 'nz', 'CountryNZ');
export const CountryOM = createIcon('country', 'om', 'CountryOM');
export const CountryPA = createIcon('country', 'pa', 'CountryPA');
export const CountryPH = createIcon('country', 'ph', 'CountryPH');
export const CountryPK = createIcon('country', 'pk', 'CountryPK');
export const CountryPL = createIcon('country', 'pl', 'CountryPL');
export const CountryPT = createIcon('country', 'pt', 'CountryPT');
export const CountryQA = createIcon('country', 'qa', 'CountryQA');
export const CountryRO = createIcon('country', 'ro', 'CountryRO');
export const CountryRS = createIcon('country', 'rs', 'CountryRS');
export const CountryRW = createIcon('country', 'rw', 'CountryRW');
export const CountrySA = createIcon('country', 'sa', 'CountrySA');
export const CountrySE = createIcon('country', 'se', 'CountrySE');
export const CountrySG = createIcon('country', 'sg', 'CountrySG');
export const CountrySK = createIcon('country', 'sk', 'CountrySK');
export const CountrySV = createIcon('country', 'sv', 'CountrySV');
export const CountryTH = createIcon('country', 'th', 'CountryTH');
export const CountryTR = createIcon('country', 'tr', 'CountryTR');
export const CountryTV = createIcon('country', 'tv', 'CountryTV');
export const CountryUA = createIcon('country', 'ua', 'CountryUA');
export const CountryUS = createIcon('country', 'us', 'CountryUS');
export const CountryVN = createIcon('country', 'vn', 'CountryVN');
export const CountryWS = createIcon('country', 'ws', 'CountryWS');
export const CountryYE = createIcon('country', 'ye', 'CountryYE');
export const CountryZA = createIcon('country', 'za', 'CountryZA');

export const CountryIcons = {
  AE: CountryAE,
  AR: CountryAR,
  AT: CountryAT,
  AU: CountryAU,
  BB: CountryBB,
  BE: CountryBE,
  BG: CountryBG,
  BO: CountryBO,
  BR: CountryBR,
  BS: CountryBS,
  CA: CountryCA,
  CH: CountryCH,
  CL: CountryCL,
  CO: CountryCO,
  CR: CountryCR,
  CY: CountryCY,
  CZ: CountryCZ,
  DE: CountryDE,
  DK: CountryDK,
  EE: CountryEE,
  EG: CountryEG,
  ES: CountryES,
  FI: CountryFI,
  FR: CountryFR,
  GB: CountryGB,
  GE: CountryGE,
  GR: CountryGR,
  HK: CountryHK,
  HR: CountryHR,
  HU: CountryHU,
  IE: CountryIE,
  IL: CountryIL,
  IN: CountryIN,
  IR: CountryIR,
  IS: CountryIS,
  IT: CountryIT,
  JO: CountryJO,
  JP: CountryJP,
  KR: CountryKR,
  KW: CountryKW,
  LI: CountryLI,
  LT: CountryLT,
  LU: CountryLU,
  LV: CountryLV,
  MC: CountryMC,
  MT: CountryMT,
  MX: CountryMX,
  MY: CountryMY,
  NL: CountryNL,
  NO: CountryNO,
  NZ: CountryNZ,
  OM: CountryOM,
  PA: CountryPA,
  PH: CountryPH,
  PK: CountryPK,
  PL: CountryPL,
  PT: CountryPT,
  QA: CountryQA,
  RO: CountryRO,
  RS: CountryRS,
  RW: CountryRW,
  SA: CountrySA,
  SE: CountrySE,
  SG: CountrySG,
  SK: CountrySK,
  SV: CountrySV,
  TH: CountryTH,
  TR: CountryTR,
  TV: CountryTV,
  UA: CountryUA,
  US: CountryUS,
  VN: CountryVN,
  WS: CountryWS,
  YE: CountryYE,
  ZA: CountryZA,
};
