import { BusinessScheduleVo } from '@cocast/api-client';
import { memo, useMemo } from 'react';
import { businessStore } from 'state';
import { ExerciseDetailSheet } from '../../Exercise/ExerciseDetail';
import { WorkoutDetailSheet } from '../../Workout/WorkoutDetail';
import { WorkoutItem } from '../../Workout/WorkoutItem';

interface Props {
  schedule: BusinessScheduleVo;
  workoutDetail: WorkoutDetailSheet;
  exerciseDetail: ExerciseDetailSheet;
}

function ScheduleDetailWorkoutComponent({ schedule: { assignedWorkouts }, workoutDetail, exerciseDetail }: Props) {
  const workouts = useMemo(() => assignedWorkouts?.map(({ id }) => businessStore.getWorkout(id)), [assignedWorkouts]);

  if (!workouts?.length) {
    return <div>No workout assigned</div>;
  }

  return (
    <>
      {workouts.map((workout, index) => (
        <WorkoutItem
          key={workout.id}
          workout={workout}
          workoutDetail={workoutDetail}
          exerciseDetail={exerciseDetail}
          className="mb-md"
        />
      ))}
    </>
  );
}

export const ScheduleDetailWorkout = memo(ScheduleDetailWorkoutComponent);
