import { useDocumentTitle } from '@cocast/hooks/useDocumentTitle';
import { RouterProvider, TransitionRouter } from '@cocast/router';
import { memo } from 'react';
import { accountStartRoute } from 'routes';
import { AccountDirect } from '../NotFound';
import { Start } from './Start';

function AccountComponent() {
  useDocumentTitle('Welcome');

  return (
    <RouterProvider fallback={AccountDirect}>
      <TransitionRouter.Connect route={accountStartRoute} component={Start} />
    </RouterProvider>
  );
}

export const Account = memo(AccountComponent);
