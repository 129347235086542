import { IconComponent, Icons } from '@cocast/icons';
import { Route } from '@cocast/router';
import { IonFooter, IonToolbar } from '@ionic/react';
import { default as classNames } from 'classnames';
import { memo } from 'react';
import { homeRoute, userRoute } from 'routes';

const config: { route: Route<any, any>; icon: IconComponent; match?: Route<any, any>[] }[] = [
  { route: homeRoute, icon: Icons.HouseAlt },
  { route: userRoute, icon: Icons.User },
];

function TabsComponent() {
  return (
    <IonFooter className="transition-all-200 delay-[400ms]">
      <IonToolbar className="!bg-primary flex-center">
        <div className="flex-evenly-center mx-auto max-w-[var(--max-content-width)] space-x-sm">
          {config.map(({ route, icon: Icon, match }) => {
            const matched = route.useMatch() || match?.some((route) => route.isCurrent());
            return (
              <div
                key={route.path}
                className="flex-center retractable clickable"
                onClick={route.navigate.withPreventEvent}
              >
                <div className={classNames(matched ? 'bg-lemon text-black' : null, 'px-sm py-3xs rounded-lg')}>
                  <Icon size={6} />
                </div>
              </div>
            );
          })}
        </div>
      </IonToolbar>
    </IonFooter>
  );
}

export const Tabs = memo(TabsComponent);
