import { preloadImages } from '@cocast/utils-web/image';
import { useEffect, useState } from 'react';

export function useImagePreload(imgs: string[]) {
  const [state, setState] = useState<{
    loading: boolean;
    loaded?: boolean;
    error?: Error;
  }>({ loading: true });

  useEffect(() => {
    if (imgs?.length) {
      preloadImages(
        imgs,
        () => setState({ loading: false, loaded: true }),
        (error) => setState({ loading: false, loaded: false, error }),
      );
    }
  }, [imgs]);

  return state;
}
