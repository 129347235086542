import { useEffect, useRef } from 'react';

export function useDidMounted() {
  const didMounted = useRef(false);

  useEffect(() => {
    didMounted.current = true;
  }, []);

  return didMounted;
}
