import { action, makeObservable } from 'mobx';
import { uiStore } from './store';

export class UIAction {
  constructor() {
    makeObservable(this);
  }

  @action
  public readonly onGlobalError = (error?: any) => {};

  @action
  public setLiveDemo = (value: boolean) => {
    uiStore.liveDemo = value;
  };
}

export const uiAction = new UIAction();
