export type PromiseResult<T> = Promise<[null, T] | [Error, T | null]>;

export type ValueOfPromiseResult<T> = T extends PromiseResult<infer U> ? U : never;

interface PromiseWrapperAll {
  <T, U>(promises: [PromiseResult<T>, PromiseResult<U>]): Promise<[null, [T, U]] | [Error[], [T | null, U | null]]>;

  <T, U, I>(promises: [PromiseResult<T>, PromiseResult<U>, PromiseResult<I>]): Promise<
    [null, [T, U, I]] | [Error[], [T | null, U | null, I | null]]
  >;

  <T, U, I, J>(promises: [PromiseResult<T>, PromiseResult<U>, PromiseResult<I>, PromiseResult<J>]): Promise<
    [null, [T, U, I, J]] | [Error[], [T | null, U | null, I | null, J | null]]
  >;

  <T, U, I, J, K>(
    promises: [PromiseResult<T>, PromiseResult<U>, PromiseResult<I>, PromiseResult<J>, PromiseResult<K>],
  ): Promise<[null, [T, U, I, J, K]] | [Error[], [T | null, U | null, I | null, J | null, K | null]]>;

  <T, U, I, J, K, L>(
    promises: [
      PromiseResult<T>,
      PromiseResult<U>,
      PromiseResult<I>,
      PromiseResult<J>,
      PromiseResult<K>,
      PromiseResult<L>,
    ],
  ): Promise<[null, [T, U, I, J, K, L]] | [Error[], [T | null, U | null, I | null, J | null, K | null, L | null]]>;
}

interface PromiseWrapper {
  <T>(p: Promise<T>): PromiseResult<T>;
  all: PromiseWrapperAll;
}

export const promiseWrapper: PromiseWrapper = <T>(p: Promise<T>): PromiseResult<T> => {
  return new Promise((resolve) => {
    try {
      p.then((i) => resolve([null, i as T])).catch((e) => resolve([e, null]));
    } catch (e) {
      resolve([e as Error, null]);
    }
  });
};

promiseWrapper.all = (async (promises: PromiseResult<unknown>[]) => {
  const all = await Promise.all(promises);
  const results: unknown[] = [];
  const errors: (Error | null)[] = [];
  let hasError = false;
  all.forEach(([err, res]) => {
    if (err) {
      hasError = true;
      errors.push(err);
      results.push(null);
    } else {
      errors.push(null);
      results.push(res);
    }
  });
  return hasError ? [errors, results] : [null, results];
}) as unknown as PromiseWrapperAll;

export function promiseUnwrapper<T>(promise: PromiseResult<T>): Promise<T> {
  return new Promise((resolve, reject) => {
    promise.then(([err, res]) => {
      if (err) {
        reject(err);
      } else {
        resolve(res as T);
      }
    });
  });
}

export interface WaitUntilOptions<T> {
  timeout?: number;
  predicate?: (value: T) => boolean;
  maxTimes?: number;
  rejectOnFailed?: boolean;
}

export function waitUntil<T>(factor: (times: number) => Promise<T> | T, options: WaitUntilOptions<T>): Promise<T>;
export function waitUntil<T>(factor: (times: number) => Promise<T> | T, timeout: number, maxTimes?: number): Promise<T>;
export function waitUntil<T>(
  factor: (times: number) => Promise<T> | T,
  predicate: (value: T) => boolean,
  timeout: number,
  maxTimes?: number,
): Promise<T>;
export function waitUntil<T>(
  factor: (times: number) => Promise<T> | T,
  predicateOrTimeoutOrOptions: ((value: T) => boolean) | number | WaitUntilOptions<T>,
  timeoutOrMaxTimes?: number,
  times?: number,
): Promise<T> {
  const [predicate = (v: any) => v, timeout, maxTimes = Infinity, rejectOnFailed = true] =
    typeof predicateOrTimeoutOrOptions === 'object'
      ? [
          predicateOrTimeoutOrOptions.predicate,
          predicateOrTimeoutOrOptions.timeout,
          predicateOrTimeoutOrOptions.maxTimes,
          predicateOrTimeoutOrOptions.rejectOnFailed ?? false,
        ]
      : typeof predicateOrTimeoutOrOptions === 'function'
      ? [predicateOrTimeoutOrOptions, timeoutOrMaxTimes, times]
      : [undefined, predicateOrTimeoutOrOptions, timeoutOrMaxTimes];

  return new Promise(async (resolve, reject) => {
    let times = 0;

    const exec = async () => {
      if (times > maxTimes) {
        if (rejectOnFailed) {
          return reject('Max times');
        }
        console.log('Max times');
        return resolve(null);
      }

      let result: T;
      try {
        result = await factor(times);
      } catch (error) {
        return rejectOnFailed ? reject(error) : resolve(null);
      }

      times++;

      if (!predicate(result)) {
        setTimeout(exec, timeout);
      } else {
        resolve(result);
      }
    };

    return exec();
  });
}

export async function waitPromise<T>(promise: Promise<T> | T, minTimes: number): Promise<T> {
  const t = Date.now();
  const result = await promise;
  const now = Date.now();

  const dt = minTimes - (now - t);
  if (dt > 0) {
    await wait(dt);
  }

  return result;
}

export function wait(time: number): Promise<void> {
  return new Promise<void>((resolve) => setTimeout(resolve, time));
}
