import { useUpdatedStateRef } from '@cocast/hooks/useUpdatedState';
import { Input, InputProps } from 'legacy/Input';
import { default as dayjs } from 'dayjs';
import { Dispatch, memo, MutableRefObject, SetStateAction, useCallback } from 'react';
import { useSheet } from '../../Sheet';
import { TimeSelector } from './TimeSelector';

export interface TimePickerInputRef {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}

interface Props extends InputProps {
  value?: string;
  defaultValue?: string;
  pickerDefaultValue?: string;
  onChange?: (value: string) => void;
  format?: string;
  picker?: MutableRefObject<TimePickerInputRef>;
}

function TimeInputComponent({
  value,
  defaultValue,
  pickerDefaultValue,
  onChange,
  format = 'YYYY-MM-DD',
  picker,
  ...props
}: Props) {
  const [v, set, ref] = useUpdatedStateRef(value || defaultValue);

  const sheet = useSheet(TimeSelector);
  const onClick = useCallback(
    () =>
      sheet.open({
        value: ref.current || pickerDefaultValue,
        onChange: (v: string) => {
          set(v);
          onChange?.(v);
        },
      }),
    [onChange],
  );

  if (picker) {
    picker.current = {
      value: v,
      setValue: set,
    };
  }

  return <Input {...props} onClick={onClick} value={v ? dayjs(v).format(format) : ''} readOnly />;
}

export const TimeInput = memo(TimeInputComponent);
