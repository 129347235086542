import { useDocumentTitle } from '@cocast/hooks/useDocumentTitle';
import { IconComponent, Icons } from '@cocast/icons';
import { useLoadable } from '@cocast/mobx';
import { Avatar } from 'legacy/Avatar';
import { navigation, onAndroidBack } from 'libs';
import { observer } from 'mobx-react-lite';
import { ComponentType, createElement, useCallback, useEffect, useMemo } from 'react';
import { accountStore, clientStore, uiStore } from 'state';
import { Clickable, LivePreviewTag, Page } from 'widgets';
import { UserSkeleton } from './Skeleton';
import { SystemPreference } from './SystemPreference';
import { UserEmail } from './UserEmail';
import { UserPassword } from './UserPassword';
import { UserPhone } from './UserPhone';
import { UserProfile } from './UserProfile';

function UserComponent() {
  const { loading } = useLoadable(clientStore.info);
  const { info } = accountStore;
  const { liveDemo } = uiStore;

  const menus = useMemo<
    {
      label: string;
      menu: {
        name: string;
        icon: IconComponent;
        component: ComponentType;
      }[];
    }[]
  >(
    () => [
      {
        label: 'Personal Info',
        menu: [
          // {
          //   name: 'Address',
          //   icon: Icons.MapPin,
          //   component: () => <></>,
          // },
          {
            name: 'Phone number',
            icon: Icons.Phone,
            component: UserPhone,
          },
          // {
          //   name: 'Emergency Contact',
          //   icon: Icons.Emergency,
          //   component: () => <></>,
          // },
          // {
          //   name: 'Training Preference',
          //   icon: Icons.Strength,
          //   component: () => <></>,
          // },
        ],
      },
      {
        label: 'Account',
        menu: [
          {
            name: 'Email',
            icon: Icons.Mail,
            component: UserEmail,
          },
          {
            name: 'Password',
            icon: Icons.Key,
            component: UserPassword,
          },
        ],
      },
      {
        label: 'System',
        menu: [
          {
            name: 'Preferences',
            icon: Icons.SettingsAlt,
            component: SystemPreference,
          },
        ],
      },
    ],
    [],
  );

  const onEditProfile = useCallback(() => {
    navigation().push(() => <UserProfile />);
  }, []);

  useEffect(
    () =>
      onAndroidBack(() => {
        history.back();
      }),
    [],
  );

  useDocumentTitle(info?.name || 'My');

  if (loading) {
    return <UserSkeleton />;
  }

  const { id, name, avatar, email } = info;
  return (
    <Page
      title={name}
      contentTitle={
        <section className="flex-between-center px-sm mb-xxl">
          <div className="flex-start-center space-x-sm">
            <Avatar id={id} size={48} name={name} src={avatar} crossOrigin />
            <div>
              <h3>{name}</h3>
              <p className="text-b2 font-bold text-content-100">{email}</p>
            </div>
          </div>
          <button className="btn transparent !px-0" onClick={onEditProfile}>
            <Icons.Edit size={6} />
          </button>
        </section>
      }
      contentClassName="px-0"
    >
      {menus.map(({ label, menu }) => (
        <div key={label} className="mb-lg">
          <label className="text-b2 text-base-400 block mb-xs">{label}</label>
          {menu.map(({ name, icon: Icon, component }) => (
            <Clickable
              key={name}
              className="flex-start-center space-x-md text-b1 py-sm rounded-md text-base-200 hover:opacity-80"
              onClick={() => navigation().push(() => createElement(component))}
            >
              {Icon ? <Icon size={6} /> : <div className="w-[24px] h-[1px]" />}
              <span>{name}</span>
              <Icons.SmallRight size={6} className="absolute right-0 top-sm" />
            </Clickable>
          ))}
        </div>
      ))}
      {liveDemo ? <LivePreviewTag /> : null}
    </Page>
  );
}

export const User = observer(UserComponent);
