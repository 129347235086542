import { Browser } from '@capacitor/browser';
import { Icons } from '@cocast/icons';
import { IonItem, IonList } from '@ionic/react';
import { default as classNames } from 'classnames';
import { navigation } from 'libs';
import { observer } from 'mobx-react-lite';
import { ReactNode, memo, useCallback } from 'react';
import { userRoute } from 'routes';
import { accountAction, uiStore } from 'state';
import { Page, sheet } from 'widgets';

function SystemPreferenceComponent() {
  const info = uiStore.info.data;

  const onLogout = useCallback(() => {
    sheet.confirm({
      title: 'Log out ?',
      description: 'Are you sure you want to log out ?',
      confirmText: 'Continue',
      onConfirm: (sheet) => {
        sheet.setOpen.disable();
        accountAction.logout();
        navigation().popToRoot();
      },
      confirmDanger: true,
      onCancel: true,
      noResize: true,
    });
  }, []);

  return (
    <Page title="Preference" onBack="back" backDefaultHref={userRoute.path}>
      <h3 className="mt-lg">Version</h3>
      <IonList inset className="!mx-0" style={{ '--ion-item-background': '#262529' }}>
        <ListItem>
          <span className="font-bold">Native</span>
          <span>{info?.version ?? 'Not available'}</span>
        </ListItem>
        <ListItem>
          <span className="font-bold">Bundle</span>
          <span>{BUNDLE_VERSION}</span>
        </ListItem>
      </IonList>

      <h3 className="mt-xl">About</h3>
      <IonList inset className="!mx-0" style={{ '--ion-item-background': '#262529' }}>
        <ListItem url="https://www.cocast.fit/terms_of_use.html">
          <span className="font-bold">Term of use</span>
          <Icons.ExternalLink size={5} />
        </ListItem>
        <ListItem url="https://www.cocast.fit/privacy_policy.html">
          <span className="font-bold">Privacy Policy</span>
          <Icons.ExternalLink size={5} />
        </ListItem>
        <ListItem url="https://www.cocast.fit" external>
          <span className="font-bold">Website</span>
          <Icons.ExternalLink size={5} />
        </ListItem>
      </IonList>

      <button className="btn error solid w-full mt-xl" onClick={onLogout}>
        Log out
      </button>
    </Page>
  );
}

export const SystemPreference = observer(SystemPreferenceComponent);

interface ItemProps {
  children: ReactNode | ReactNode[];
  className?: string;
  url?: string;
  external?: boolean;
}

function ListItemComponent({ children, className, url, external }: ItemProps) {
  return (
    <IonItem
      onClick={
        url
          ? external
            ? () => window.open(url)
            : () => Browser.open({ url, presentationStyle: 'popover' })
          : undefined
      }
    >
      <div className={classNames(className, 'w-full flex-between-center text-b2')}>{children}</div>
    </IonItem>
  );
}

const ListItem = memo(ListItemComponent);
