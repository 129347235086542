import { isNil, PromiseResult, waitUntil } from '@cocast/utils';
import { makeObservable, observable, runInAction } from 'mobx';
import { useEffect, useRef, useState } from 'react';

const fetchKey = Symbol.for('Loadable fetch method');

export class Loadable<T> {
  constructor({ loading = true, data, error }: Partial<Loadable<T>>, fetch?: () => PromiseResult<T>) {
    this.loading = loading;
    this.data = data;
    this.error = error;
    if (fetch) {
      this[fetchKey] = fetch;
    }
    makeObservable(this);
  }

  @observable
  public loading: boolean;

  @observable
  public data?: T;

  @observable
  public error?: Error;

  public fetching: boolean = false;

  private readonly [fetchKey]: () => PromiseResult<T>;

  public readonly fetch = (callback?: (result?: PromiseResult<T>) => unknown) => {
    const fetch = this[fetchKey];
    if (!fetch) {
      callback?.();
      return;
    }
    Loadable.fromPromiseWrapper(fetch(), this, callback);
  };

  public get load() {
    return this.fetch;
  }

  public readonly reload = () => {
    return new Promise((resolve) => {
      if (this.fetching) {
        resolve(null);
        return;
      }
      this.fetch(resolve);
    });
  };

  public readonly setLoading = (loading: boolean) => {
    runInAction(() => {
      this.loading = loading;
    });
  };

  static from = <T>(
    params?: T | (() => PromiseResult<T>),
    init?: Pick<Loadable<T>, 'loading' | 'data'>,
  ): Loadable<T> => {
    if (typeof params === 'function') {
      return new Loadable({ loading: true, ...init }, params as () => PromiseResult<T>);
    }
    return new Loadable({ loading: isNil(params), data: params, ...init });
  };

  static fromPromiseWrapper = <T>(
    promiseResult: (() => PromiseResult<T>) | PromiseResult<T> | void,
    from?: Loadable<T>,
    callback?: (result: PromiseResult<T>) => void,
  ): Loadable<T> => {
    const i = from || new Loadable(Loadable.from<T>());
    if (promiseResult) {
      if (!callback) {
        runInAction(() => {
          i.loading = true;
        });
      }
      i.fetching = true;
      (async () => {
        const [error, result] = await (typeof promiseResult === 'function' ? promiseResult() : promiseResult);
        setTimeout(async () => {
          i.fetching = false;
          runInAction(() => {
            i.data = result;
            i.loading = false;
            if (error) {
              i.error = error;
            }
          });
          callback?.([error, result] as unknown as PromiseResult<T>);
        }, 0);
      })();
    }
    return i;
  };

  static success = <T>(data: T): Loadable<T> => {
    return new Loadable({ loading: false, data });
  };

  static error = <T>(error: Error, data?: T): Loadable<T> => {
    return new Loadable({ loading: false, error, data });
  };
}

export function useLoadable<T extends Loadable<unknown>[]>(...loadables: T) {
  useEffect(() => {
    loadables.forEach((loadable) => {
      if (loadable && !loadable.data && !loadable.fetching && !loadable.error) {
        loadable.fetch();
      }
    });
  }, loadables);

  return useLoadableState(...loadables);
}

export function useLoadableState<T extends Loadable<unknown>[]>(...loadables: T) {
  const state = loadables.some((loadable) => loadable?.loading);
  const [loading, setLoading] = useState(state);

  const timer = useRef<number>();
  useEffect(() => {
    if (timer.current) {
      window.clearTimeout(timer.current);
    }
    if (state) {
      setLoading(true);
    } else {
      timer.current = window.setTimeout(() => {
        setLoading(false);
        timer.current = null;
      }, 100);
    }
  }, [state]);

  return { loadables, loading };
}

export function waitLoadables<T extends Loadable<unknown>[]>(...loadables: T) {
  return waitUntil(() => loadables.every((loadable) => !loadable.loading), 10, Infinity);
}
