import { useRefState } from '@cocast/hooks/useRefState';
import { useStateRef } from '@cocast/hooks/useStateRef';
import { Icons } from '@cocast/icons';
import { Gender } from '@cocast/types';
import { pickMap } from '@cocast/utils';
import { Avatar } from 'legacy/Avatar';
import { Input } from 'legacy/Input';
import { RadioGroup } from 'legacy/Radio/RadioGroup';
import { navigation } from 'libs';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { userRoute } from 'routes';
import { accountAction, accountStore, businessAction } from 'state';
import { Page, TimeInput, ToolbarButton } from 'widgets';

const genderOptions: Gender[] = [Gender.Female, Gender.Male, Gender.Others];

function UserProfileComponent() {
  const info = accountStore.info;
  const [avatar, setAvatar, avatarRef] = useStateRef(info.avatar);
  const [data, setData] = useRefState(pickMap(info, ['firstname', 'lastname', 'birthday', 'gender']));

  const onUploadAvatar = useCallback(async (file: File) => {
    const url = await businessAction.uploadFile(file);
    setAvatar(url);
  }, []);

  const set = useCallback((v: Partial<typeof data.current>) => setData((d) => ({ ...d, ...v })), []);
  const setFirstName = useCallback((firstname: string) => set({ firstname }), []);
  const setLastName = useCallback((lastname: string) => set({ lastname }), []);
  const setGender = useCallback((gender: Gender) => set({ gender }), []);
  const onBirthdayChange = useCallback((birthday: string) => set({ birthday }), []);

  const onSave = useCallback(
    () =>
      accountAction.update(
        {
          avatar: avatarRef.current,
          ...data.current,
        },
        () => navigation().pop(),
      ),
    [],
  );

  return (
    <Page
      title="Profile"
      onBack="back"
      contentTitle={
        <section className="flex-center">
          <div className="relative">
            <Avatar id={info.id} size={100} name={info.name} src={avatar} onUpload={onUploadAvatar} crossOrigin />
            <Icons.Edit
              size={6}
              className="bg-lemon rounded-full p-xxs text-black absolute right-0 bottom-0 pointer-events-none"
            />
          </div>
        </section>
      }
      footer={<ToolbarButton text="Save" handler={onSave} />}
      backDefaultHref={userRoute.path}
      contentClassName="space-y-xl"
    >
      <Input
        className="dark !mt-xxl"
        label="First name"
        maxLength={64}
        defaultValue={data.current.firstname}
        onValueChange={setFirstName}
        noErrorMessage
        required
      />
      <Input
        className="dark"
        label="Last name"
        maxLength={64}
        defaultValue={data.current.lastname}
        onValueChange={setLastName}
        noErrorMessage
        required
      />
      <TimeInput
        className="dark"
        label="Birthday"
        pickerDefaultValue="2000-01-01T12:00:00"
        defaultValue={data.current.birthday}
        onChange={onBirthdayChange}
      />
      <RadioGroup
        label="Gender"
        className="dark lemon capitalize"
        optionClassName="lg"
        options={genderOptions}
        defaultValue={data.current.gender}
        onValueChange={setGender}
      />
    </Page>
  );
}

export const UserProfile = observer(UserProfileComponent);
