import { SetStateAction, useCallback, useRef } from 'react';

export function useRefState<T>(initial?: T) {
  const ref = useRef<T>(initial);

  const set = useCallback((newState: SetStateAction<T>) => {
    ref.current = typeof newState === 'function' ? (newState as Function)(ref.current) : newState;
  }, []);

  return [ref, set] as const;
}
