import { useEffect } from 'react';
import { accountStartRoute, homeRoute, notFoundRoute } from 'routes';
import { NotFound as NotFound404 } from 'widgets';

export function NotFound() {
  return (
    <div className="flex-center flex-col h-screen">
      <NotFound404 />
      <button className="btn lemon mt-lg" onClick={homeRoute.replace.withPreventEvent}>
        Back to home
      </button>
    </div>
  );
}

export function Redirect() {
  useEffect(() => {
    console.info('Redirect to not found page', window.location.href);
    notFoundRoute.replace();
  }, []);
  return <></>;
}

export function AccountDirect() {
  console.info('Redirect to account start page', window.location.href);
  useEffect(() => {
    accountStartRoute.replace();
  }, []);
  return <></>;
}
