import { default as classNames } from 'classnames';
import { ComponentType, createElement, CSSProperties, Fragment, memo, ReactHTML } from 'react';

const DEFAULT_HEIGHT = '32px';
const DEFAULT_SPACE = '8px';
const DEFAULT_ROUNDED = '6px';

export interface SkeletonProps {
  width?: string;
  height?: string;
  color?: string;
  className?: string;
  rounded?: string;
}

export const Skeleton = memo(
  ({
    className,
    width = '100%',
    height = DEFAULT_HEIGHT,
    color: backgroundColor,
    rounded: borderRadius = DEFAULT_ROUNDED,
  }: SkeletonProps) => {
    return (
      <div
        className={classNames('loading-skeleton', className)}
        style={{
          width,
          height,
          backgroundColor,
          borderRadius,
        }}
      />
    );
  },
);

export interface SkeletonGroupProps {
  width?: string;
  height?: string;
  space?: string;
  color?: string;
  rows?: number | (string | [string, string] | [string, string, string])[];
  className?: string;
  itemClassName?: string;
  rounded?: string;
  style?: CSSProperties;
  as?: keyof ReactHTML | ComponentType<any>;
}

export const SkeletonGroup = memo(
  ({
    rows,
    width = '100%',
    height = DEFAULT_HEIGHT,
    space = DEFAULT_SPACE,
    rounded: borderRadius = DEFAULT_ROUNDED,
    color,
    className,
    itemClassName,
    style,
    as = 'div',
  }: SkeletonGroupProps) => {
    if (Array.isArray(rows)) {
      return (
        <div className={className}>
          {rows.map((item, index) => {
            const [w, h, rounded] = Array.isArray(item) ? item : [item, height];
            return (
              <div
                key={index}
                className={classNames('loading-skeleton', itemClassName)}
                style={{
                  width: w,
                  height: h,
                  marginBottom: index === rows.length - 1 ? 0 : space,
                  backgroundColor: color,
                  borderRadius: rounded ?? borderRadius,
                }}
              />
            );
          })}
        </div>
      );
    }

    const props = as === Fragment ? {} : { className, style };
    return createElement(
      as,
      props,
      [...Array(rows)].map((_, index) => (
        <div
          key={index}
          className={classNames('loading-skeleton', `delay-${index % 5}`, itemClassName)}
          style={{
            width,
            height,
            marginBottom: index === rows! - 1 ? 0 : space,
            backgroundColor: color,
            borderRadius,
          }}
        />
      )),
    );
  },
);
