export interface SelectFileOptions {
  accept?: string;
  multiple?: boolean;
  max?: number;
  maxSize?: number;
}

export function selectFiles({ accept, multiple, max, maxSize }: SelectFileOptions = {}): Promise<File[]> {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('style', 'display: none;');
    if (accept) {
      input.setAttribute('accept', accept);
    }
    if (multiple) {
      input.setAttribute('multiple', '');
    }

    document.body.appendChild(input);
    const destroy = () => {
      try {
        document.body.removeChild(input);
      } catch (_) {}
    };

    input.addEventListener('change', () => {
      destroy();
      const files = Array.from(input.files);
      const result = max ? files.slice(0, max) : files;
      if (maxSize) {
        for (const file of result) {
          const size = file.size / 1000 / 1000;
          if (size > maxSize) {
            return reject(
              `File "${shortFilename(file.name)}" is too large (${size.toFixed(0)}mb), max size is ${maxSize}mb`,
            );
          }
        }
      }
      resolve(result);
    });
    input.addEventListener('error', (e) => {
      destroy();
      reject(e.error);
    });
    input.addEventListener('cancel', () => {
      destroy();
      resolve([] as File[]);
    });

    input.click();
  });
}

export async function selectImage(options: SelectFileOptions = {}) {
  const [file] = await selectFiles({
    accept: 'image/png, image/apng, image/jpeg, image/jpg, image/gif, image/webp',
    maxSize: 3,
    ...options,
  });
  return file;
}

export function shortFilename(filename: string, maxLength = 20): string {
  if (filename.length <= maxLength) {
    return filename;
  }
  const ext = filename.slice(filename.lastIndexOf('.'));
  const name = filename.slice(0, maxLength - ext.length);
  return `${name}..${ext}`;
}

export interface ResizeOptions {
  maxWidth?: number;
  maxHeight?: number;
}

export async function resizeImage(file: File, { maxWidth, maxHeight }: ResizeOptions): Promise<File> {
  const img = document.createElement('img');
  img.setAttribute('src', URL.createObjectURL(file));
  return new Promise((resolve) => {
    img.addEventListener('load', async () => {
      let { width, height } = img;
      if (width <= maxWidth && height <= maxHeight) {
        return resolve(file);
      }

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      const { canvas, context, destroy, offscreen } = createCanvas(width, height);
      context.drawImage(img, 0, 0, width, height);
      if (offscreen) {
        const blob = await (canvas as OffscreenCanvas).convertToBlob();
        const result = new File([blob], file.name);
        destroy();
        return resolve(result);
      } else {
        return new Promise<File>((resolve) => {
          (canvas as HTMLCanvasElement).toBlob((blob) => {
            const result = new File([blob], file.name);
            destroy();
            resolve(result);
          });
        });
      }
    });
  });
}

function createCanvas(
  width: number,
  height: number,
): {
  canvas: HTMLCanvasElement | OffscreenCanvas;
  context: OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D;
  destroy: () => void;
  offscreen: boolean;
} {
  if (window.OffscreenCanvas) {
    const canvas = new OffscreenCanvas(width, height);
    const context = canvas.getContext('2d');

    return {
      canvas,
      context,
      destroy: () => {},
      offscreen: true,
    };
  } else {
    const canvas = document.createElement('canvas');
    canvas.setAttribute('width', `${width}px`);
    canvas.setAttribute('height', `${height}px`);
    canvas.setAttribute('style', 'display: none');
    document.body.appendChild(canvas);
    const context = canvas.getContext('2d');
    return {
      canvas,
      context,
      offscreen: false,
      destroy: () => {
        document.body.removeChild(canvas);
      },
    };
  }
}
