import { BusinessExerciseVo } from '@cocast/api-client';
import { ExerciseCategory } from '@cocast/types';
import { capitalize, getImageDeliverUrl, getStreamDeliverCover } from '@cocast/utils';

const variantsMap = {
  sm: 'w=180',
  md: 'w=360',
  lg: 'w=720',
  xl: 'w=1080',
};

export type ExerciseImageSize = keyof typeof variantsMap;

export function getExercisePreview(
  { images, imageId, video, videoType }: Pick<BusinessExerciseVo, 'images' | 'imageId' | 'video' | 'videoType'>,
  size: ExerciseImageSize = 'sm',
) {
  if (video && !videoType) {
    return getStreamDeliverCover(video);
  }
  const src = images?.[0] || imageId;
  if (src) {
    return getImageDeliverUrl(src, variantsMap[size]);
  }
  if (video && videoType === 'youtube') {
    return getImageDeliverUrl('a9f5f07b-e8c9-40c0-6877-2469d6af6000', variantsMap[size]);
  }
  return null;
}

type ExercisePrimaryFocusParams = Pick<BusinessExerciseVo, 'category' | 'type' | 'subType'>;

export function getExercisePrimaryFocus(exercise: ExercisePrimaryFocusParams, raw: true): [string, string];
export function getExercisePrimaryFocus(exercise: ExercisePrimaryFocusParams): string;
export function getExercisePrimaryFocus(
  exercise: ExercisePrimaryFocusParams,
  raw?: unknown,
): string | [string, string] {
  let primary: string;
  let secondary: string;
  switch (exercise.category) {
    case ExerciseCategory.Strength:
      primary = exercise.type;
      secondary = exercise.subType;
      break;
    case ExerciseCategory.Stretching:
      primary = 'Stretching';
      secondary = exercise.type;
      break;
    case ExerciseCategory.Aerobic:
      primary = 'Aerobic';
      secondary = exercise.type;
      break;
    case ExerciseCategory.Yoga:
      primary = 'Yoga';
      break;
    case ExerciseCategory.Other:
      primary = 'Other';
      break;
  }

  if (raw) {
    return [primary, secondary];
  }

  return secondary ? `${capitalize(primary)} (${capitalize(secondary)})` : capitalize(primary);
}

export function getExercisePrimaryFocusId(i: ExercisePrimaryFocusParams) {
  return i.subType || i.type || i.category;
}
