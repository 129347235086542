export enum UnitType {
  BeatsPerMin = 'beats per min',
  Calories = 'calories',
  Centimeters = 'centimeters',
  Degrees = 'degrees',
  Feet = 'feet',
  Grams = 'grams',
  Inches = 'inches',
  Kilograms = 'kilograms',
  Kilometers = 'kilometers',
  KilometersPerHour = 'kilometers per hour',
  Liters = 'liters',
  Meters = 'meters',
  MetersPerSecond = 'meters per second',
  Miles = 'miles',
  MilesPerHour = 'milesPerHour',
  Millimeters = 'millimeters',
  Milliliters = 'milliliters',
  MinutesPerKilometer = 'minutes per kilometer',
  MlPerKgPerMin = 'ml/kg/min',
  MmHg = 'mmHg',
  Ounces = 'ounces',
  Percent = 'percent',
  Pints = 'pints',
  Points = 'points',
  Portions = 'portions',
  Pounds = 'pounds',
  Reps = 'reps',
  Rounds = 'rounds',
  RPM = 'RPM',
  Score = 'score',
  Steps = 'steps',
  Stone = 'stone',
  Time = 'time',
  Watts = 'watts',
  Number = 'number',
}

export const unitSymbolMap = new Map<UnitType, string>([
  [UnitType.BeatsPerMin, 'bpm'],
  [UnitType.Calories, 'cal'],
  [UnitType.Centimeters, 'cm'],
  [UnitType.Degrees, '°'],
  [UnitType.Feet, 'ft'],
  [UnitType.Grams, 'g'],
  [UnitType.Inches, 'in'],
  [UnitType.Kilograms, 'kg'],
  [UnitType.Kilometers, 'km'],
  [UnitType.KilometersPerHour, 'km/h'],
  [UnitType.Liters, 'L'],
  [UnitType.Meters, 'm'],
  [UnitType.MetersPerSecond, 'm/s'],
  [UnitType.Miles, 'mi'],
  [UnitType.MilesPerHour, 'mi/h'],
  [UnitType.Millimeters, 'mm'],
  [UnitType.Milliliters, 'mL'],
  [UnitType.MinutesPerKilometer, 'min/km'],
  [UnitType.Ounces, 'oz'],
  [UnitType.Percent, '%'],
  [UnitType.Pints, 'pt'],
  [UnitType.Pounds, 'lb'],
  [UnitType.Time, 's'],
  [UnitType.Rounds, ''],
]);
