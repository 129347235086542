import { useCallback } from 'react';
import { Route } from '../route';

export interface OnTransitionRouteLeaveOptions {
  once?: boolean;
}

// TODO: Symbols as WeakMap keys is not supported for now.
const ANY_ROUTE = Symbol;

export type CallbacksMap = WeakMap<
  Route<any, any> | typeof ANY_ROUTE,
  [() => void | Promise<void>, OnTransitionRouteLeaveOptions | undefined][]
>;

export const AfterTransitionLeaveCallbacks: CallbacksMap = new Map();

export function afterTransitionRouteLeave(
  route: Route<any, any> | '*',
  callback: () => void,
  options?: OnTransitionRouteLeaveOptions,
) {
  const r = route === '*' ? ANY_ROUTE : route;
  let callbacks = AfterTransitionLeaveCallbacks.get(r);
  if (!callbacks) {
    callbacks = [];
    AfterTransitionLeaveCallbacks.set(r, callbacks);
  }
  callbacks.push([callback, options]);
  return () => {
    const index = callbacks.findIndex(([fn]) => fn === callback);
    callbacks.splice(index, 1);
  };
}

export const BeforeTransitionLeaveCallbacks: CallbacksMap = new WeakMap();

export function beforeTransitionRouteLeave(
  route: Route<any, any> | '*',
  callback: () => void,
  options?: OnTransitionRouteLeaveOptions,
) {
  const r = route === '*' ? ANY_ROUTE : route;
  let callbacks = BeforeTransitionLeaveCallbacks.get(r);
  if (!callbacks) {
    callbacks = [];
    BeforeTransitionLeaveCallbacks.set(r, callbacks);
  }
  callbacks.push([callback, options]);
  return () => {
    const index = callbacks.findIndex(([fn]) => fn === callback);
    callbacks.splice(index, 1);
  };
}

export function useTransitionRouteCallbacks(route: Route<any, any>, callbacksMap: CallbacksMap) {
  return useCallback(() => {
    const routeCallbacks = callbacksMap.get(route);
    const anyRouteCallbacks = callbacksMap.get(ANY_ROUTE);
    const callbacks = [...(routeCallbacks ?? []), ...(anyRouteCallbacks ?? [])];
    if (!callbacks?.length) {
      return;
    }
    return Promise.all(
      callbacks.map(async ([callback, options]) => {
        if (options?.once) {
          const index = callbacks.findIndex(([fn]) => fn === callback);
          callbacks.splice(index, 1);
        }
        try {
          await callback();
        } catch (e) {
          console.error('Error in transition route callback', e);
        }
      }),
    );
  }, [route]);
}
