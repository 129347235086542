import { IonDatetime } from '@ionic/react';
import { memo, useCallback, useRef } from 'react';
import { Sheet, SheetProps } from '../../Sheet';

interface Props extends SheetProps {
  value?: string;
  onChange?: (v: string) => unknown;
}

function TimeSelectorComponent({ value, onChange: onChangeProp, setOpen, ...props }: Props) {
  const input = useRef<HTMLIonDatetimeElement>();
  const onConfirm = useCallback(() => {
    onChangeProp?.(input.current.value as string);
    setOpen.disable();
  }, [onChangeProp]);

  return (
    <Sheet
      {...props}
      setOpen={setOpen}
      style={{ '--rsbs-bg': '#222428' }}
      onConfirm={onConfirm}
      noResize
      noScrollLocking
      noExpandOnDrag
    >
      <IonDatetime ref={input} presentation="date" className="mx-auto" lang="en-US" value={value} />
    </Sheet>
  );
}

export const TimeSelector = memo(TimeSelectorComponent);
