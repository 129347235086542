import { default as classNames } from 'classnames';
import { memo, ReactNode } from 'react';
import { Avatar, AvatarProps } from './Avatar';

export interface AvatarsProps {
  avatars: Omit<AvatarProps, 'size' | 'onUpload'>[];
  size?: number;
  className?: string;
  maxItems?: number;
  stack?: boolean;
  children?: ReactNode | ReactNode[];
}

function AvatarsComponent({ avatars, size, maxItems, className, stack, children }: AvatarsProps) {
  const items = maxItems ? avatars.slice(0, maxItems) : avatars;
  const count = items.length;
  return (
    <div className={classNames('avatars', { stack }, className)}>
      {items.map((props, index) => (
        <Avatar
          key={props.id || index}
          {...props}
          size={size}
          style={
            stack
              ? {
                  zIndex: count - index,
                  left: `${-1 * index * count * 2}%`,
                }
              : undefined
          }
          className="border-thin"
        />
      ))}
      {children ? (
        <div
          className="relative"
          style={
            stack
              ? {
                  zIndex: 99,
                  left: `${-1 * (count - 1) * count * 2}%`,
                }
              : undefined
          }
        >
          {children}
        </div>
      ) : null}
    </div>
  );
}

export const Avatars = memo(AvatarsComponent);
