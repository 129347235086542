export enum ExerciseCategory {
  Strength = 'strength',
  Stretching = 'stretching',
  Aerobic = 'aerobic',
  Yoga = 'yoga',
  Other = 'other',
}

export enum ExerciseRecordType {
  Strength = 'strength',
  Aerobic = 'aerobic',
  Reps = 'reps',
  Time = 'time',
  Bodyweight = 'bodyweight',
  AssistedBodyweight = 'assistedBodyweight',
  Stretching = 'stretching',
  Tabata = 'tabata',
}

export enum StrengthExerciseType {
  Back = 'back',
  Bicep = 'bicep',
  Calf = 'calf',
  Chest = 'chest',
  Forearm = 'forearm',
  Hips = 'hips',
  Shoulder = 'shoulder',
  Thigh = 'thigh',
  Trapezius = 'trapezius',
  Tricep = 'tricep',
  Waist = 'waist',
}

interface BaseExercise {
  id: string;
  imageId?: string;
  category: ExerciseCategory;
  recordType: ExerciseRecordType;
  type?: string;
  subType?: string;
  equipment?: string;
  name: string;
  description?: string;
  video?: string;
  videoType?: 'youtube';
  images?: string[];
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  relatedExercise?: string;
  tags?: { id: string; name: string; primary?: string; secondary?: string; text?: string }[];
}

export interface StrengthExercise extends BaseExercise {
  category: ExerciseCategory.Strength;
  type: StrengthExerciseType;
  subType?: string;
  equipment: string;
  name: string;
  id: string;
}

export enum StretchingExerciseType {
  Dynamic = 'dynamic',
  Static = 'static',
}

export interface StretchingExercise extends BaseExercise {
  category: ExerciseCategory.Stretching;
  type: StretchingExerciseType;
  name: string;
  id: string;
}

export enum AerobicExerciseType {
  Conditioning = 'conditioning',
  Tabata = 'tabata',
  Timing = 'timing',
}

export interface AerobicExercise extends BaseExercise {
  category: ExerciseCategory.Aerobic;
  type: AerobicExerciseType;
  name: string;
  id: string;
}

export interface YogaExercise extends BaseExercise {
  category: ExerciseCategory.Yoga;
  name: string;
  id: string;
}

export type Exercise = StrengthExercise | StretchingExercise | AerobicExercise | YogaExercise;
