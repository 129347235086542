import { useStateRef } from '@cocast/hooks/useStateRef';
import { ComponentType, createElement, memo, useCallback } from 'react';
import { Sheet, SheetProps, useSheet } from '../../Sheet';

export interface SelectorOption<T> {
  label?: string;
  value: T;
}

export interface SelectorItemProps<T> extends SelectorOption<T> {
  index: number;
  selected?: boolean;
  onSelect: (value: T, index: number) => unknown;
}

export interface SelectorProps<T> extends SheetProps {
  options: T[] | SelectorOption<T>[];
  defaultValue?: T;
  value?: T;
  onChange?: (v: T) => unknown;
  item?: ComponentType<SelectorItemProps<T>>;
  closeAfterSelect?: boolean;
  className?: string;
}

function SelectorComponent<T>({
  options,
  defaultValue,
  value,
  onChange,
  closeAfterSelect,
  item = Item,
  setOpen,
  className,
  ...props
}: SelectorProps<T>) {
  const [v, set, ref] = useStateRef<T>(value ?? defaultValue);

  const onSelect: SelectorItemProps<T>['onSelect'] = useCallback(
    (v, index) => {
      set(v);
      if (closeAfterSelect) {
        onChange(v);
        setOpen.disable();
      }
    },
    [closeAfterSelect],
  );

  const onConfirm = useCallback(() => {
    onChange(ref.current);
    setOpen.disable();
  }, [onSelect]);

  return (
    <Sheet {...props} setOpen={setOpen} onConfirm={onConfirm}>
      <section className={className}>
        {options.map((option: T | SelectorOption<T>, index: number) => {
          const value = (option as SelectorOption<T>)?.value || (option as T);
          return createElement(item, {
            key: index,
            label: (option as SelectorOption<T>)?.label || option.toString(),
            value,
            index,
            selected: v === value,
            onSelect,
          });
        })}
      </section>
    </Sheet>
  );
}

export const Selector = memo(SelectorComponent);

export function useSelector() {
  return useSheet(Selector);
}

function ItemComponent<T>({ value, label, index, selected, onSelect }: SelectorItemProps<T>) {
  const onClick = useCallback(() => {
    onSelect(value, index);
  }, [value, index]);

  return <div onClick={onClick}>{label || value.toString()}</div>;
}

const Item = memo(ItemComponent);
