import { cn } from '@cocast/utils-web/misc';
import * as React from 'react';

export interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number;
  color?: string;
}

export type IconComponent = React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;

export function createIcon(scope: string, name: string, displayName?: string): IconComponent {
  const component = React.forwardRef<SVGSVGElement, IconProps>(({ size, color, style, className, ...props }, ref) => {
    const i = `icon-${scope}-${name}`;
    return (
      <svg
        ref={ref}
        aria-hidden="true"
        style={size ? { width: `${size / 4}rem`, height: `${size / 4}rem`, ...style } : style}
        className={cn(className, 'fill-current')}
        {...props}
      >
        <use href={`#${i}`} fill={color} stroke={color} />
      </svg>
    );
  });

  if (displayName) {
    component.displayName = displayName;
  }

  return component;
}
