import {
  BusinessExerciseVo,
  WorkoutFields,
  WorkoutSectionStruct,
  WorkoutSetStruct,
  WorkoutSupersetStruct,
} from '@cocast/api-client';
import { ExerciseThumbnail } from '@cocast/components/Exercise/ExerciseThumbnail';
import { Exercise } from '@cocast/types';
import { Badge } from '@cocast/ui/badge';
import { memo } from 'react';
import { WorkoutPreviewRecord } from '../WorkoutPreviewRecord';

interface Props {
  section: WorkoutSectionStruct;
  index: number;
  showDetail: boolean;
  getExercise: (id: string) => Exercise | BusinessExerciseVo | undefined;
  onPreviewExercise?: (exercise: Exercise | BusinessExerciseVo) => unknown;
}

export const WorkoutPreviewSection = memo(
  ({ section: { name, description, sets }, index, showDetail, onPreviewExercise, getExercise }: Props) => {
    return (
      <div>
        <h4 className="text-sm font-medium">{name || `Section ${index + 1}`}</h4>
        {description && showDetail ? <p className="text-2xs mt-xs text-muted-foreground">{description}</p> : null}

        <div className="mt-md pt-xs border-l-thin border-grey-300 ml-sm space-y-lg">
          {sets.map((set) => {
            if (!set.records?.length) {
              return null;
            }

            let name: string;
            let exercises: (Exercise | BusinessExerciseVo)[];
            let fields: WorkoutFields[];
            if (set.superset) {
              const { exercises: items, name: setName } = set as WorkoutSupersetStruct;
              exercises = items?.map(({ exercise }) => getExercise(exercise)).filter((i) => !!i);
              if (!exercises?.length) {
                return null;
              }
              fields = items.map(({ fields }) => fields);
              const len = exercises.length;
              name = setName || `${len === 2 ? 'Superset' : 'Circuit'} of ${len} exercises`;
            } else {
              const { exercise: id, fields: setFields } = set as WorkoutSetStruct;
              const exercise = getExercise(id);
              if (!exercise) {
                return null;
              }
              name = exercise.name;
              exercises = [exercise];
              fields = [setFields];
            }

            return (
              <div key={set.id} className="pl-md pb-lg flex space-x-md">
                {showDetail ? (
                  <div className="flex-start-center flex-col gap-sm flex-grow-0 flex-shrink-0">
                    {exercises.map((exercise, index) => (
                      <ExerciseThumbnail
                        exercise={exercise}
                        key={index}
                        size={10}
                        className="border grow-0 shrink-0"
                        onPreview={onPreviewExercise ? () => onPreviewExercise(exercise) : undefined}
                      />
                    ))}
                  </div>
                ) : null}
                <div className="grow">
                  <div className="flex-start-center gap-md">
                    <p className="text-xs font-medium line-clamp-1 max-md:grow">{name}</p>
                    {set.tempo ? (
                      <Badge className="border grow-0 shrink-0 tag" variant="slate" size="xs">
                        {set.tempo}
                      </Badge>
                    ) : null}
                  </div>
                  {set.description && showDetail ? (
                    <p className="text-2xs mt-0.5 text-muted-foreground">{set.description}</p>
                  ) : null}
                  {showDetail ? (
                    <div className="mt-md">
                      {set.records.map((record, index) => (
                        <WorkoutPreviewRecord
                          index={index}
                          record={record}
                          key={index}
                          superset={set.superset}
                          exercises={exercises}
                          fields={fields}
                        />
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  },
);
