import { api } from '@cocast/api-client';
import { useStateRef } from '@cocast/hooks/useStateRef';
import { Icons } from '@cocast/icons';
import { default as classNames } from 'classnames';
import { Avatar } from 'legacy/Avatar';
import { navigation } from 'libs';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { accountStartRoute } from 'routes';
import { Page, ToolbarButton } from 'widgets';
import { accountState } from './state';
import { afterLogin } from './utils';

function SwitchComponent() {
  const { businesses } = accountState;
  if (!businesses.length) {
    navigation().popToRoot();
  }

  const [current, setCurrent, idRef] = useStateRef(businesses[0].id);

  const onContinue = useCallback(async () => {
    const [error, authenticationToken] = await api.ClientAccount.switch({ business: idRef.current });
    if (error) {
      return;
    }
    return afterLogin({ authenticationToken });
  }, []);

  return (
    <Page
      title="Welcome back 👋"
      contentTitle={<h4 className="px-md">Choose the business you want to continue with.</h4>}
      contentClassName="space-y-md pt-lg"
      onBack="back"
      backDefaultHref={accountStartRoute.path}
      footer={<ToolbarButton text="Continue" handler={onContinue} />}
    >
      {businesses.map(({ id, name, avatar }) => (
        <div
          key={id}
          className={classNames(
            'capitalize flex-start-center text-b1 border-thin rounded-lg px-md py-sm w-full cursor-pointer transition-all-200 bg-dark relative',
            current === id ? 'border-lemon text-lemon' : 'border-dark-200',
          )}
          onClick={() => setCurrent(id)}
        >
          <Avatar className="mr-xs w-[44px] h-[44px]" id={id} name={name} src={avatar} crossOrigin />
          <span className="pr-3xs">{name}</span>
          {current === id ? (
            <Icons.Check className="rounded-full bg-lemon text-dark p-3xs absolute right-lg" size={6} />
          ) : null}
        </div>
      ))}
    </Page>
  );
}

export const Switch = observer(SwitchComponent);
