import { Icons } from '@cocast/icons';
import { IonRippleEffect } from '@ionic/react';
import { default as classNames } from 'classnames';
import { GoalItem, useGoalForm } from 'components';
import { navigation } from 'libs';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { clientStore } from 'state';
import { Clickable } from '../../widgets';
import { GoalList } from '../GoalList';
import { Item } from './Item';

const blockClassName = 'flex-start-center retractable relative bg-opacity-10 rounded-md pl-sm py-sm space-x-xs w-[50%]';
const blurClassName = 'w-[26px] h-[26px] absolute left-lg bottom-xs blur-[10px] rounded-full';

function GoalComponent() {
  const goals = clientStore.info.data?.goals;

  const onViewInProgress = useCallback(() => navigation().push(() => <GoalList filter="inProgress" />), []);
  const onViewFinished = useCallback(() => navigation().push(() => <GoalList filter="finished" />), []);

  const goalForm = useGoalForm();
  const onAdd = useCallback(() => goalForm.open(), []);

  return (
    <Item title="Goals" className="" handlerText="View All" linkComponent={GoalList}>
      <div className="w-full flex-center space-x-md">
        <Clickable className={classNames(blockClassName, 'bg-purple')} onClick={onViewInProgress}>
          <div className={classNames(blurClassName, 'bg-purple opacity-60')} />
          <Icons.Goal size={6} className="text-purple" />
          <p>
            <span className="text-b1">{goals?.inProgress || 0}</span>
            <span className="text-caption ml-3xs">In progress</span>
          </p>
          <IonRippleEffect className="!ml-0" />
        </Clickable>

        <Clickable className={classNames(blockClassName, 'bg-mint')} onClick={onViewFinished}>
          <div className={classNames(blurClassName, 'bg-mint opacity-40')} />
          <Icons.Clock size={6} className="text-mint" />
          <p>
            <span className="text-b1">{goals?.finished || 0}</span>
            <span className="text-caption ml-3xs">Finished</span>
          </p>
          <IonRippleEffect className="!ml-0" />
        </Clickable>
      </div>

      <hr className="my-lg border-b-thin border-content-200 border-opacity-30" />

      <div className="space-y-md">
        {goals?.results?.map((goal) => <GoalItem key={goal.id} goal={goal} clamp />)}
        <div className="flex-start-center space-x-lg pt-[2px] clickable origin-left" onClick={onAdd}>
          <Icons.Plus size={6} className="px-[1px] text-lemon" />
          <span className="text-b1 font-normal link text-lemon px-[1px]">Add a new goal</span>
        </div>
      </div>
    </Item>
  );
}

export const Goal = observer(GoalComponent);
