import { SkeletonGroup } from 'legacy/Skeleton';
import { memo } from 'react';
import { Page } from 'widgets';

const itemSkeleton = (
  <SkeletonGroup
    rows={[
      ['120px', '18px'],
      ['100%', '36px'],
      ['100%', '36px'],
    ]}
    space="16px"
    itemClassName="!bg-dark"
    className="mb-xxl"
  />
);

function UserSkeletonComponent() {
  return (
    <Page
      title="My"
      contentTitle={
        <section className="flex-between-center px-sm mb-xxl">
          <div className="flex-start-center space-x-sm">
            <div className="loading-skeleton w-[48px] h-[48px] rounded-full !bg-dark" />
            <SkeletonGroup
              rows={[
                ['120px', '22px'],
                ['220px', '16px'],
              ]}
              space="8px"
              itemClassName="!bg-dark"
            />
          </div>
        </section>
      }
      contentClassName="pt-xl"
    >
      {itemSkeleton}
      {itemSkeleton}
    </Page>
  );
}

export const UserSkeleton = memo(UserSkeletonComponent);
