import { BusinessClientOverviewTrainingVo } from '@cocast/api-client';
import { Icons } from '@cocast/icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { businessStore, clientStore } from 'state';
import { Segment, SegmentOption } from 'widgets';
import { useExerciseDetail } from '../../Exercise/ExerciseDetail';
import { useWorkoutDetail } from '../../Workout/WorkoutDetail';
import { WorkoutItem } from '../../Workout/WorkoutItem';

interface Props {
  training: BusinessClientOverviewTrainingVo;
}

function TrainingProgramDetailWorkoutsComponent({
  training: { programCurrentDay, programWeeks, programWeeksDetail, programStartAt, programComplianceDetail },
}: Props) {
  const [week, setWeek] = useState(Math.floor(programCurrentDay / 7).toString());

  const weeksOptions = useMemo(() => {
    const options: SegmentOption[] = [];
    for (let i = 0; i < programWeeks; i++) {
      options.push({
        label: `Week ${i + 1}`,
        value: i.toString(),
      });
    }
    return options;
  }, [programWeeks]);

  const workoutDetail = useWorkoutDetail();
  const exerciseDetail = useExerciseDetail();

  const currentWeek = programWeeksDetail?.[+week];
  return (
    <>
      <Segment size="lg" options={weeksOptions} value={week} onChange={setWeek} className="mb-lg" paddingX={0} />
      {Array.from({ length: 7 }, (_, i) => {
        const day = +week * 7 + i;
        const date = dayjs(programStartAt).add(day, 'day');
        const isToday = dayjs().isSame(date, 'day');
        const workouts = currentWeek?.[day]?.workouts?.map((id) => businessStore.getWorkout(id)).filter((i) => !!i);
        const finished = !!programComplianceDetail?.find((item) => item.day === i && item.week === +week);

        return (
          <div key={i} className="mb-lg rounded-md overflow-hidden">
            <div className="bg-black flex-between-center px-sm py-xs text-b1 font-normal text-base-400">
              <div className="flex-start-center space-x-xxs">
                <Icons.Calendar size={5} />
                <p>
                  Day {i + 1} {isToday ? '(Today)' : null}
                </p>
              </div>
              <p>{clientStore.formatDate(date)}</p>
            </div>
            {workouts?.length ? (
              workouts.map((workout, index) => (
                <WorkoutItem
                  key={workout.id}
                  workout={workout}
                  workoutDetail={workoutDetail}
                  exerciseDetail={exerciseDetail}
                  className={classNames('my-[2px]', index === workouts.length - 1 ? '!rounded-t-0' : '!rounded-0')}
                  titleClassName={finished ? 'pr-3xl' : undefined}
                >
                  {finished ? (
                    <span className="tag green solid sm absolute top-md right-md !text-black text-b2 font-bold">
                      Finished
                    </span>
                  ) : null}
                </WorkoutItem>
              ))
            ) : (
              <div className="py-md text-b2 text-center opacity-60 bg-dark-200">No workouts this day</div>
            )}
          </div>
        );
      })}
    </>
  );
}

export const TrainingProgramDetailWorkouts = observer(TrainingProgramDetailWorkoutsComponent);
