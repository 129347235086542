import { useBoolean } from '@cocast/hooks/useBoolean';
import { getImageDeliverUrl } from '@cocast/utils';
import { default as classNames } from 'classnames';
import { ComponentProps, memo } from 'react';

interface Props extends ComponentProps<'img'> {
  spinner?: boolean;
  id?: string;
  variant?: string | { w?: number; h?: number };
  imgClassName?: string;
}

function ImageComponent({ spinner, src, alt, id, variant, className, imgClassName, ...props }: Props) {
  if (!spinner) {
    return (
      <img
        src={src || getImageDeliverUrl(id, variant)}
        className={classNames(className, imgClassName)}
        alt={alt || 'image'}
        {...props}
      />
    );
  }

  const [loading, setLoading] = useBoolean(true);
  return (
    <div className={classNames(className, loading ? 'loading-skeleton' : null)}>
      <img
        src={src || getImageDeliverUrl(id, variant)}
        alt={alt || 'image'}
        onLoad={setLoading.disable}
        className={classNames(imgClassName, loading ? 'opacity-0' : 'opacity-100')}
        {...props}
      />
    </div>
  );
}

export const Image = memo(ImageComponent);
