import { useMemo } from 'react';
import { useLocation, useRouter } from 'wouter';
import { useRouteContext } from './context';

export function useMatchedRoute() {
  const { matcher } = useRouter();
  const { routes } = useRouteContext();
  const [location] = useLocation();

  return useMemo(() => {
    for (const route of routes.values()) {
      const [match] = matcher(route.path, location);
      if (match) {
        return route;
      }
    }
    return null;
  }, [location]);
}
