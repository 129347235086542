import { useUpdatedState } from '@cocast/hooks/useUpdatedState';
import { IconComponent } from '@cocast/icons';
import type { IonSegmentCustomEvent, SegmentChangeEventDetail } from '@ionic/core/dist/types/components';
import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import { default as classNames } from 'classnames';
import { memo, useCallback } from 'react';

export interface SegmentOption<T extends string = string> {
  icon?: IconComponent;
  label: string;
  value: T;
}

export interface SegmentProps<T extends string> {
  options: (SegmentOption<T> | string)[];
  defaultValue?: T;
  value?: T;
  onChange?: (value: T) => unknown;
  className?: string;
  labelClassName?: string;
  size?: 'lg';
  paddingX?: number;
}

function SegmentComponent<T extends string>({
  options,
  defaultValue,
  value,
  onChange,
  className,
  labelClassName,
  size,
  paddingX,
}: SegmentProps<T>) {
  const [v, set] = useUpdatedState<T | string>(value ?? defaultValue ?? getValue(options[0]));

  const onIonChange = useCallback((e: IonSegmentCustomEvent<SegmentChangeEventDetail>) => {
    const v = e.detail.value.toString();
    set(v);
    onChange(v as T);
  }, []);

  return (
    <IonSegment value={v} onIonChange={onIonChange} className={className} swipeGesture>
      {options.map((item) => {
        const { icon: Icon, label, value } = typeof item === 'string' ? { label: item, value: item, icon: null } : item;
        return (
          <IonSegmentButton
            value={value}
            key={value}
            className={classNames('min-w-0', { 'h-[36px]': size === 'lg' })}
            style={
              paddingX
                ? {
                    '--padding-start': paddingX,
                    '--padding-end': paddingX,
                  }
                : undefined
            }
          >
            {Icon ? <Icon size={4} /> : null}
            <IonLabel className={labelClassName}>{label}</IonLabel>
          </IonSegmentButton>
        );
      })}
    </IonSegment>
  );
}

function getValue<T extends string = string>(i: SegmentOption<T> | string) {
  return typeof i === 'string' ? i : i.value;
}

export const Segment = memo(SegmentComponent) as typeof SegmentComponent;
