import { Resource } from '@cocast/api-client/resource';
import { toast } from '@cocast/ui/toast';
import { selectImage } from '@cocast/utils-web/upload';
import { businessStore } from './store';

class BusinessAction {
  public uploadFiles = async (files: File[]): Promise<string[]> => {
    const [success, urls, response] = await Resource.upload({ files, businessId: businessStore.businessId });
    if (!success) {
      console.error(response);
      toast.error('Upload failed');
      return;
    }
    return urls;
  };

  public uploadFile = async (file: File): Promise<string> => {
    const [url] = await this.uploadFiles([file]);
    return url;
  };

  public uploadImageFile = async (): Promise<string> => {
    const file = await selectImage();
    return this.uploadFile(file);
  };

  public uploadImages = async (files: File[]): Promise<string[]> => {
    const [success, ids, response] = await Resource.uploadImage({ files, businessId: businessStore.businessId });
    if (!success) {
      console.error(response);
      toast.error('Upload failed');
      return;
    }
    return ids;
  };

  public uploadImage = async (file: File): Promise<string> => {
    const [id] = await this.uploadImages([file]);
    return id;
  };
}

export const businessAction = new BusinessAction();
