import { withPreventEvent } from '@cocast/utils-web/eventHelper';
import { BaseSyntheticEvent, useEffect, useMemo, useState } from 'react';

type Setter = () => void;
export type ToggleBoolean = ((newValue?: any) => void) & {
  current: boolean;
  enable: Setter & { withPreventEvent: (e: BaseSyntheticEvent | Event) => void };
  disable: Setter & { withPreventEvent: (e: BaseSyntheticEvent | Event) => void };
  withEnable: (fn: () => unknown) => Promise<void>;
} & { withPreventEvent: (e: BaseSyntheticEvent | Event) => void };

export interface UseBoolanOptions {
  onEnable?: () => void;
  onDisable?: () => void;
}

export function useBoolean(v: boolean, { onEnable, onDisable }: UseBoolanOptions = {}): [boolean, ToggleBoolean] {
  const [value, setValue] = useState(v);

  const toggle = useMemo(() => {
    const toggle: ToggleBoolean = (newValue?: boolean) => {
      setValue((currentValue) => {
        return typeof newValue === 'boolean' ? newValue : !currentValue;
      });
    };

    toggle.current = value;

    toggle.withPreventEvent = withPreventEvent(toggle);

    const enable: ToggleBoolean['enable'] = () => setValue(true);
    enable.withPreventEvent = withPreventEvent(() => setValue(true));
    toggle.enable = enable;

    const disable: ToggleBoolean['enable'] = () => setValue(false);
    disable.withPreventEvent = withPreventEvent(() => setValue(false));
    toggle.disable = disable;

    toggle.withEnable = async (fn) => {
      enable();
      try {
        await fn();
      } finally {
        disable();
      }
    };

    return toggle;
  }, []);

  useEffect(() => {
    toggle.current = value;
    if (value) {
      onEnable?.();
    } else {
      onDisable?.();
    }
  }, [value, onEnable, onDisable]);

  return [value, toggle];
}
