import { cn } from '@cocast/utils-web/misc';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { createElement } from 'react';

const badgeVariants = cva(
  'inline-flex items-center rounded-md border-current transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/80',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80 border-border',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/80',
        success: 'bg-green-100 text-green-500',
        purple: 'bg-purple-100 text-purple-500',
        slate: 'bg-slate-100 text-slate-400',
        gray: 'bg-gray-100 text-gray-500',
        blue: 'bg-blue-50 text-blue-500',
        fuchsia: 'bg-fuchsia-50 text-fuchsia-500',
        pink: 'bg-pink-50 text-pink-500',
        yellow: 'bg-yellow-50 text-yellow-500',
        orange: 'bg-orange-50 text-orange-500',
        brand: 'bg-brand-50 text-brand-500',
        white: 'bg-white text-foreground border-border',
        outline: 'text-foreground',
      },
      size: {
        xs: 'px-1.5 py-1 text-2xs leading-none',
        sm: 'px-2 py-1 text-xs leading-none',
        default: 'px-2.5 py-1.5 text-xs leading-none',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {
  as?: React.ElementType;
}

function Badge({ className, variant, size, as = 'div', ...props }: BadgeProps) {
  return createElement(as, { className: cn(badgeVariants({ variant, size }), className), ...props });
}

export { Badge, badgeVariants };
