import { IonRippleEffect } from '@ionic/react';
import { default as classNames } from 'classnames';
import { ComponentProps, memo, ReactNode } from 'react';

interface Props extends ComponentProps<'section'> {
  children?: ReactNode | ReactNode[];
  disabled?: boolean;
}

function ClickableComponent({ className, children, disabled, ...props }: Props) {
  return (
    <section
      {...props}
      className={classNames(
        disabled ? null : 'ion-activatable ripple-parent relative overflow-hidden cursor-pointer',
        className,
      )}
    >
      {children}
      {disabled ? null : <IonRippleEffect className="!m-0" />}
    </section>
  );
}

export const Clickable = memo(ClickableComponent);
