import { ForwardedRef, forwardRef, memo, SVGProps } from 'react';
import { twMerge } from 'tailwind-merge';
import { ColoredIconType, CountryIconType, IconType, IllustrationIconType } from './iconTypes';

export interface UIIconProps extends SVGProps<SVGSVGElement> {
  name: IconType;
  color?: string;
  scope?: 'ui';
  size?: number | string;
}

export interface ColoredIconProps extends SVGProps<SVGSVGElement> {
  name: ColoredIconType;
  scope: 'colored';
  size?: number | string;
}

export interface CountryIconProps extends SVGProps<SVGSVGElement> {
  name: CountryIconType;
  scope: 'country';
  size?: number | string;
}

export interface IllustrationIconProps extends SVGProps<SVGSVGElement> {
  name: IllustrationIconType;
  scope: 'illustration';
  size?: number | string;
}

export type IconProps = UIIconProps | ColoredIconProps | CountryIconProps | IllustrationIconProps;

function IconComponent(
  { name, className, color, scope = 'ui', size, style, ...props }: IconProps,
  ref: ForwardedRef<SVGSVGElement>,
) {
  const i = `icon-${scope}-${name}`;
  const s = size ? (typeof size === 'string' ? size : `${size}px`) : undefined;
  return (
    <svg
      ref={ref}
      aria-hidden="true"
      className={twMerge('icon', i, className)}
      style={s ? { width: s, height: s, ...style } : style}
      {...props}
    >
      <use href={`#${i}`} fill={color} stroke={color} />
    </svg>
  );
}

export const Icon = memo(forwardRef(IconComponent));
