export enum EnvType {
  DEV = 'development',
  TEST = 'test',
  ONLINE = 'online',
}

export const APIHosts = new Map<EnvType, string>([
  [EnvType.DEV, 'https://api-dev.cocast.fit'],
  [EnvType.TEST, 'https://api-test.cocast.fit'],
  [EnvType.ONLINE, 'https://api.cocast.fit'],
]);

export const WebHosts = new Map<EnvType, string>([
  [EnvType.DEV, 'https://dev.cocast.fit'],
  [EnvType.TEST, 'https://test.cocast.fit'],
  [EnvType.ONLINE, 'https://go.cocast.fit'],
]);

export const ClientPortalHosts = new Map<EnvType, string>([
  [EnvType.DEV, 'https://my.dev.cocast.fit'],
  [EnvType.TEST, 'https://client-test.cocast.fit'],
  [EnvType.ONLINE, 'https://my.cocast.fit'],
]);

export const FormHosts = new Map<EnvType, string>([
  [EnvType.ONLINE, 'https://form.cocast.fit'],
  [EnvType.TEST, 'https://form-test.cocast.fit'],
  [EnvType.DEV, 'https://form.dev.cocast.fit'],
]);

export const BuilderHosts = new Map<EnvType, string>([
  [EnvType.ONLINE, 'https://builder.cocast.fit'],
  [EnvType.TEST, 'https://builder-test.cocast.fit'],
  [EnvType.DEV, 'https://builder.dev.cocast.fit'],
]);

export const DashboardHosts = new Map<EnvType, string>([
  [EnvType.ONLINE, 'https://go.cocast.fit'],
  [EnvType.TEST, 'https://test.cocast.fit'],
  [EnvType.DEV, 'https://dev.cocast.fit'],
]);
