import { APIHosts, Maybe } from '@cocast/types';
import { getEnv } from './config';

export function prefix(path: string, params?: { [key: string]: string | number | boolean | undefined | null }) {
  const env = getEnv();
  if (!getEnv()) {
    throw new Error('[@cocast/api-client] Env is not set');
  }

  const p = params
    ? '?' +
      Object.entries(params)
        .map(([k, v]) => (v ? `${k}=${v}` : ''))
        .filter((i) => !!i)
        .join('&')
    : '';
  return `${APIHosts.get(env)}/api/${path}${p}`;
}

export interface APIResponse<T = unknown> {
  t: number;
  requestId?: string;
  status?: 'success' | 'failed';
  statusCode: number;
  message?: string;
  data?: T;
  stacks?: string[];
}

export type ParsedAPIResponse<T = unknown> = [boolean, Maybe<T>, APIResponse<T>];

function parseResponse<T>(response: APIResponse<T>): ParsedAPIResponse<T> {
  return [response.status === 'success', response.data, response];
}

function createErrorResponse(error: Error, statusCode = -1): APIResponse<void> {
  return {
    t: Date.now(),
    status: 'failed',
    statusCode,
    message: error.message || error.toString(),
    stacks: error.stack.split('\n'),
  };
}

export async function getApiJson<T = unknown>(
  input: RequestInfo | URL,
  init?: RequestInit,
): Promise<ParsedAPIResponse<T | void>> {
  try {
    const response = await fetch(input, init);
    const result = await response.json();
    return parseResponse<T>(result);
  } catch (e) {
    return [false, null, createErrorResponse(e)];
  }
}

export async function postApiJson<T = unknown>(
  url: string,
  body: object,
  init?: RequestInit,
): Promise<ParsedAPIResponse<T | void>> {
  try {
    const response = await fetch(url, {
      ...init,
      method: 'POST',
      headers: {
        ...(init?.headers || {}),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const result = await response.json();
    return parseResponse<T>(result);
  } catch (e) {
    return [false, null, createErrorResponse(e)];
  }
}

export async function putApiJson<T = unknown>(url: string, params: object): Promise<ParsedAPIResponse<T | void>> {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const result = await response.json();
    return parseResponse<T>(result);
  } catch (e) {
    return [false, null, createErrorResponse(e)];
  }
}

export async function deleteApiJson<T = unknown>(
  url: string,
  params: object = {},
): Promise<ParsedAPIResponse<T | void>> {
  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const result = await response.json();
    return parseResponse<T>(result);
  } catch (e) {
    return [false, null, createErrorResponse(e)];
  }
}

export async function postApiForm<T = unknown>(url: string, form: FormData): Promise<ParsedAPIResponse<T>> {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: form,
    });
    const result = await response.json();
    return parseResponse<T>(result);
  } catch (e) {
    return [false, null, createErrorResponse(e) as unknown as APIResponse<T>];
  }
}
