export enum TimeFormat {
  '12h' = '12h',
  '24h' = '24h',
}

export enum DateFormat {
  'DD/MM/YYYY' = 'DD/MM/YYYY',
  'MM/DD/YYYY' = 'MM/DD/YYYY',
  'YYYY/MM/DD' = 'YYYY/MM/DD',
  'DD-MM-YYYY' = 'DD-MM-YYYY',
  'MM-DD-YYYY' = 'MM-DD-YYYY',
  'YYYY-MM-DD' = 'YYYY-MM-DD',
  'DD.MM.YYYY' = 'DD.MM.YYYY',
  'MM.DD.YYYY' = 'MM.DD.YYYY',
  'YYYY.MM.DD' = 'YYYY.MM.DD',
}

export enum DurationType {
  Second = 'second',
  Minute = 'minute',
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export enum Timezone {
  'Australia/Perth' = 'Australia/Perth',
  'Australia/West' = 'Australia/West',
  'Australia/Eucla' = 'Australia/Eucla',
  'Australia/Adelaide' = 'Australia/Adelaide',
  'Australia/Broken_Hill' = 'Australia/Broken_Hill',
  'Australia/Darwin' = 'Australia/Darwin',
  'Australia/North' = 'Australia/North',
  'Australia/South' = 'Australia/South',
  'Australia/Yancowinna' = 'Australia/Yancowinna',
  'Australia/ACT' = 'Australia/ACT',
  'Australia/Brisbane' = 'Australia/Brisbane',
  'Australia/Canberra' = 'Australia/Canberra',
  'Australia/Currie' = 'Australia/Currie',
  'Australia/Hobart' = 'Australia/Hobart',
  'Australia/Lindeman' = 'Australia/Lindeman',
  'Australia/Melbourne' = 'Australia/Melbourne',
  'Australia/NSW' = 'Australia/NSW',
  'Australia/Queensland' = 'Australia/Queensland',
  'Australia/Sydney' = 'Australia/Sydney',
  'Australia/Tasmania' = 'Australia/Tasmania',
  'Australia/Victoria' = 'Australia/Victoria',
  'Australia/LHI' = 'Australia/LHI',
  'Australia/Lord_Howe' = 'Australia/Lord_Howe',
  'America/Adak' = 'America/Adak',
  'America/Atka' = 'America/Atka',
  'America/Anchorage' = 'America/Anchorage',
  'America/Juneau' = 'America/Juneau',
  'America/Nome' = 'America/Nome',
  'America/Sitka' = 'America/Sitka',
  'America/Yakutat' = 'America/Yakutat',
  'America/Dawson' = 'America/Dawson',
  'America/Ensenada' = 'America/Ensenada',
  'America/Los_Angeles' = 'America/Los_Angeles',
  'America/Metlakatla' = 'America/Metlakatla',
  'America/Santa_Isabel' = 'America/Santa_Isabel',
  'America/Tijuana' = 'America/Tijuana',
  'America/Vancouver' = 'America/Vancouver',
  'America/Whitehorse' = 'America/Whitehorse',
  'America/Boise' = 'America/Boise',
  'America/Cambridge_Bay' = 'America/Cambridge_Bay',
  'America/Chihuahua' = 'America/Chihuahua',
  'America/Dawson_Creek' = 'America/Dawson_Creek',
  'America/Denver' = 'America/Denver',
  'America/Edmonton' = 'America/Edmonton',
  'America/Hermosillo' = 'America/Hermosillo',
  'America/Inuvik' = 'America/Inuvik',
  'America/Mazatlan' = 'America/Mazatlan',
  'America/Ojinaga' = 'America/Ojinaga',
  'America/Phoenix' = 'America/Phoenix',
  'America/Shiprock' = 'America/Shiprock',
  'America/Yellowknife' = 'America/Yellowknife',
  'America/Bahia_Banderas' = 'America/Bahia_Banderas',
  'America/Belize' = 'America/Belize',
  'America/Cancun' = 'America/Cancun',
  'America/Chicago' = 'America/Chicago',
  'America/Costa_Rica' = 'America/Costa_Rica',
  'America/El_Salvador' = 'America/El_Salvador',
  'America/Guatemala' = 'America/Guatemala',
  'America/Indiana/Knox' = 'America/Indiana/Knox',
  'America/Indiana/Tell_City' = 'America/Indiana/Tell_City',
  'America/Knox_IN' = 'America/Knox_IN',
  'America/Managua' = 'America/Managua',
  'America/Matamoros' = 'America/Matamoros',
  'America/Menominee' = 'America/Menominee',
  'America/Merida' = 'America/Merida',
  'America/Mexico_City' = 'America/Mexico_City',
  'America/Monterrey' = 'America/Monterrey',
  'America/North_Dakota/Beulah' = 'America/North_Dakota/Beulah',
  'America/North_Dakota/Center' = 'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem' = 'America/North_Dakota/New_Salem',
  'America/Rainy_River' = 'America/Rainy_River',
  'America/Rankin_Inlet' = 'America/Rankin_Inlet',
  'America/Regina' = 'America/Regina',
  'America/Resolute' = 'America/Resolute',
  'America/Swift_Current' = 'America/Swift_Current',
  'America/Tegucigalpa' = 'America/Tegucigalpa',
  'America/Winnipeg' = 'America/Winnipeg',
  'America/Atikokan' = 'America/Atikokan',
  'America/Bogota' = 'America/Bogota',
  'America/Cayman' = 'America/Cayman',
  'America/Coral_Harbour' = 'America/Coral_Harbour',
  'America/Detroit' = 'America/Detroit',
  'America/Fort_Wayne' = 'America/Fort_Wayne',
  'America/Grand_Turk' = 'America/Grand_Turk',
  'America/Guayaquil' = 'America/Guayaquil',
  'America/Havana' = 'America/Havana',
  'America/Indiana/Indianapolis' = 'America/Indiana/Indianapolis',
  'America/Indiana/Marengo' = 'America/Indiana/Marengo',
  'America/Indiana/Petersburg' = 'America/Indiana/Petersburg',
  'America/Indiana/Vevay' = 'America/Indiana/Vevay',
  'America/Indiana/Vincennes' = 'America/Indiana/Vincennes',
  'America/Indiana/Winamac' = 'America/Indiana/Winamac',
  'America/Indianapolis' = 'America/Indianapolis',
  'America/Iqaluit' = 'America/Iqaluit',
  'America/Jamaica' = 'America/Jamaica',
  'America/Kentucky/Louisville' = 'America/Kentucky/Louisville',
  'America/Kentucky/Monticello' = 'America/Kentucky/Monticello',
  'America/Lima' = 'America/Lima',
  'America/Louisville' = 'America/Louisville',
  'America/Montreal' = 'America/Montreal',
  'America/Nassau' = 'America/Nassau',
  'America/New_York' = 'America/New_York',
  'America/Nipigon' = 'America/Nipigon',
  'America/Panama' = 'America/Panama',
  'America/Pangnirtung' = 'America/Pangnirtung',
  'America/Port-au-Prince' = 'America/Port-au-Prince',
  'America/Thunder_Bay' = 'America/Thunder_Bay',
  'America/Toronto' = 'America/Toronto',
  'America/Caracas' = 'America/Caracas',
  'America/Anguilla' = 'America/Anguilla',
  'America/Antigua' = 'America/Antigua',
  'America/Argentina/San_Luis' = 'America/Argentina/San_Luis',
  'America/Aruba' = 'America/Aruba',
  'America/Asuncion' = 'America/Asuncion',
  'America/Barbados' = 'America/Barbados',
  'America/Blanc-Sablon' = 'America/Blanc-Sablon',
  'America/Boa_Vista' = 'America/Boa_Vista',
  'America/Campo_Grande' = 'America/Campo_Grande',
  'America/Cuiaba' = 'America/Cuiaba',
  'America/Curacao' = 'America/Curacao',
  'America/Dominica' = 'America/Dominica',
  'America/Eirunepe' = 'America/Eirunepe',
  'America/Glace_Bay' = 'America/Glace_Bay',
  'America/Goose_Bay' = 'America/Goose_Bay',
  'America/Grenada' = 'America/Grenada',
  'America/Guadeloupe' = 'America/Guadeloupe',
  'America/Guyana' = 'America/Guyana',
  'America/Halifax' = 'America/Halifax',
  'America/Kralendijk' = 'America/Kralendijk',
  'America/La_Paz' = 'America/La_Paz',
  'America/Lower_Princes' = 'America/Lower_Princes',
  'America/Manaus' = 'America/Manaus',
  'America/Marigot' = 'America/Marigot',
  'America/Martinique' = 'America/Martinique',
  'America/Moncton' = 'America/Moncton',
  'America/Montserrat' = 'America/Montserrat',
  'America/Port_of_Spain' = 'America/Port_of_Spain',
  'America/Porto_Acre' = 'America/Porto_Acre',
  'America/Porto_Velho' = 'America/Porto_Velho',
  'America/Puerto_Rico' = 'America/Puerto_Rico',
  'America/Rio_Branco' = 'America/Rio_Branco',
  'America/Santiago' = 'America/Santiago',
  'America/Santo_Domingo' = 'America/Santo_Domingo',
  'America/St_Barthelemy' = 'America/St_Barthelemy',
  'America/St_Kitts' = 'America/St_Kitts',
  'America/St_Lucia' = 'America/St_Lucia',
  'America/St_Thomas' = 'America/St_Thomas',
  'America/St_Vincent' = 'America/St_Vincent',
  'America/Thule' = 'America/Thule',
  'America/Tortola' = 'America/Tortola',
  'America/Virgin' = 'America/Virgin',
  'America/St_Johns' = 'America/St_Johns',
  'America/Araguaina' = 'America/Araguaina',
  'America/Argentina/Buenos_Aires' = 'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca' = 'America/Argentina/Catamarca',
  'America/Argentina/ComodRivadavia' = 'America/Argentina/ComodRivadavia',
  'America/Argentina/Cordoba' = 'America/Argentina/Cordoba',
  'America/Argentina/Jujuy' = 'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja' = 'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza' = 'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos' = 'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta' = 'America/Argentina/Salta',
  'America/Argentina/San_Juan' = 'America/Argentina/San_Juan',
  'America/Argentina/Tucuman' = 'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia' = 'America/Argentina/Ushuaia',
  'America/Bahia' = 'America/Bahia',
  'America/Belem' = 'America/Belem',
  'America/Buenos_Aires' = 'America/Buenos_Aires',
  'America/Catamarca' = 'America/Catamarca',
  'America/Cayenne' = 'America/Cayenne',
  'America/Cordoba' = 'America/Cordoba',
  'America/Fortaleza' = 'America/Fortaleza',
  'America/Godthab' = 'America/Godthab',
  'America/Jujuy' = 'America/Jujuy',
  'America/Maceio' = 'America/Maceio',
  'America/Mendoza' = 'America/Mendoza',
  'America/Miquelon' = 'America/Miquelon',
  'America/Montevideo' = 'America/Montevideo',
  'America/Paramaribo' = 'America/Paramaribo',
  'America/Recife' = 'America/Recife',
  'America/Rosario' = 'America/Rosario',
  'America/Santarem' = 'America/Santarem',
  'America/Sao_Paulo' = 'America/Sao_Paulo',
  'America/Noronha' = 'America/Noronha',
  'America/Scoresbysund' = 'America/Scoresbysund',
  'America/Danmarkshavn' = 'America/Danmarkshavn',
  'Canada/Pacific' = 'Canada/Pacific',
  'Canada/Yukon' = 'Canada/Yukon',
  'Canada/Mountain' = 'Canada/Mountain',
  'Canada/Central' = 'Canada/Central',
  'Canada/East-Saskatchewan' = 'Canada/East-Saskatchewan',
  'Canada/Saskatchewan' = 'Canada/Saskatchewan',
  'Canada/Eastern' = 'Canada/Eastern',
  'Canada/Atlantic' = 'Canada/Atlantic',
  'Canada/Newfoundland' = 'Canada/Newfoundland',
  'Europe/Belfast' = 'Europe/Belfast',
  'Europe/Dublin' = 'Europe/Dublin',
  'Europe/Guernsey' = 'Europe/Guernsey',
  'Europe/Isle_of_Man' = 'Europe/Isle_of_Man',
  'Europe/Jersey' = 'Europe/Jersey',
  'Europe/Lisbon' = 'Europe/Lisbon',
  'Europe/London' = 'Europe/London',
  'Europe/Amsterdam' = 'Europe/Amsterdam',
  'Europe/Andorra' = 'Europe/Andorra',
  'Europe/Belgrade' = 'Europe/Belgrade',
  'Europe/Berlin' = 'Europe/Berlin',
  'Europe/Bratislava' = 'Europe/Bratislava',
  'Europe/Brussels' = 'Europe/Brussels',
  'Europe/Budapest' = 'Europe/Budapest',
  'Europe/Copenhagen' = 'Europe/Copenhagen',
  'Europe/Gibraltar' = 'Europe/Gibraltar',
  'Europe/Ljubljana' = 'Europe/Ljubljana',
  'Europe/Luxembourg' = 'Europe/Luxembourg',
  'Europe/Madrid' = 'Europe/Madrid',
  'Europe/Malta' = 'Europe/Malta',
  'Europe/Monaco' = 'Europe/Monaco',
  'Europe/Oslo' = 'Europe/Oslo',
  'Europe/Paris' = 'Europe/Paris',
  'Europe/Podgorica' = 'Europe/Podgorica',
  'Europe/Prague' = 'Europe/Prague',
  'Europe/Rome' = 'Europe/Rome',
  'Europe/San_Marino' = 'Europe/San_Marino',
  'Europe/Sarajevo' = 'Europe/Sarajevo',
  'Europe/Skopje' = 'Europe/Skopje',
  'Europe/Stockholm' = 'Europe/Stockholm',
  'Europe/Tirane' = 'Europe/Tirane',
  'Europe/Vaduz' = 'Europe/Vaduz',
  'Europe/Vatican' = 'Europe/Vatican',
  'Europe/Warsaw' = 'Europe/Warsaw',
  'Europe/Zagreb' = 'Europe/Zagreb',
  'Europe/Zurich' = 'Europe/Zurich',
  'Europe/Athens' = 'Europe/Athens',
  'Europe/Bucharest' = 'Europe/Bucharest',
  'Europe/Chisinau' = 'Europe/Chisinau',
  'Europe/Helsinki' = 'Europe/Helsinki',
  'Europe/Istanbul' = 'Europe/Istanbul',
  'Europe/Kiev' = 'Europe/Kiev',
  'Europe/Mariehamn' = 'Europe/Mariehamn',
  'Europe/Nicosia' = 'Europe/Nicosia',
  'Europe/Riga' = 'Europe/Riga',
  'Europe/Simferopol' = 'Europe/Simferopol',
  'Europe/Sofia' = 'Europe/Sofia',
  'Europe/Tallinn' = 'Europe/Tallinn',
  'Europe/Tiraspol' = 'Europe/Tiraspol',
  'Europe/Uzhgorod' = 'Europe/Uzhgorod',
  'Europe/Vilnius' = 'Europe/Vilnius',
  'Europe/Zaporozhye' = 'Europe/Zaporozhye',
  'Europe/Kaliningrad' = 'Europe/Kaliningrad',
  'Europe/Minsk' = 'Europe/Minsk',
  'Europe/Moscow' = 'Europe/Moscow',
  'Europe/Samara' = 'Europe/Samara',
  'Europe/Vienna' = 'Europe/Vienna',
  'Europe/Volgograd' = 'Europe/Volgograd',
  'Asia/Nicosia' = 'Asia/Nicosia',
  'Asia/Hong_Kong' = 'Asia/Hong_Kong',
  'Asia/Kolkata' = 'Asia/Kolkata',
  'Asia/Tokyo' = 'Asia/Tokyo',
  'Asia/Kuala_Lumpur' = 'Asia/Kuala_Lumpur',
  'Pacific/Auckland' = 'Pacific/Auckland',
  'Asia/Singapore' = 'Asia/Singapore',
  'Asia/Bangkok' = 'Asia/Bangkok',
  'Asia/Dubai' = 'Asia/Dubai',
  'Asia/Tbilisi' = 'Asia/Tbilisi',
  'Asia/Tehran' = 'Asia/Tehran',
  'Asia/Jerusalem' = 'Asia/Jerusalem',
  'Asia/Amman' = 'Asia/Amman',
  'Asia/Kuwait' = 'Asia/Kuwait',
  'Asia/Muscat' = 'Asia/Muscat',
  'Asia/Karachi' = 'Asia/Karachi',
  'Asia/Manila' = 'Asia/Manila',
  'Asia/Qatar' = 'Asia/Qatar',
  'Africa/Kigali' = 'Africa/Kigali',
  'Africa/Johannesburg' = 'Africa/Johannesburg',
  'Africa/Cairo' = 'Africa/Cairo',
  'Atlantic/Reykjavik' = 'Atlantic/Reykjavik',
  'Pacific/Apia' = 'Pacific/Apia',
  'Asia/Riyadh' = 'Asia/Riyadh',
  'Asia/Seoul' = 'Asia/Seoul',
  'Pacific/Funafuti' = 'Pacific/Funafuti',
  'Asia/Ho_Chi_Minh' = 'Asia/Ho_Chi_Minh',
  'Asia/Aden' = 'Asia/Aden',
}

export interface TimezoneInfo {
  key: Timezone;
  name: string;
  time: number;
}

export const timezoneInfoMap = new Map<Timezone | string, TimezoneInfo>([
  [Timezone['America/Adak'], { key: Timezone['America/Adak'], name: 'Hawaii-Aleutian Standard Time', time: -10 }],
  [Timezone['America/Atka'], { key: Timezone['America/Atka'], name: 'Hawaii-Aleutian Standard Time', time: -10 }],
  [Timezone['America/Anchorage'], { key: Timezone['America/Anchorage'], name: 'Alaska Standard Time', time: -9 }],
  [Timezone['America/Juneau'], { key: Timezone['America/Juneau'], name: 'Alaska Standard Time', time: -9 }],
  [Timezone['America/Nome'], { key: Timezone['America/Nome'], name: 'Alaska Standard Time', time: -9 }],
  [Timezone['America/Sitka'], { key: Timezone['America/Sitka'], name: 'Alaska Standard Time', time: -9 }],
  [Timezone['America/Yakutat'], { key: Timezone['America/Yakutat'], name: 'Alaska Standard Time', time: -9 }],
  [Timezone['America/Dawson'], { key: Timezone['America/Dawson'], name: 'Pacific Standard Time', time: -8 }],
  [Timezone['America/Ensenada'], { key: Timezone['America/Ensenada'], name: 'Pacific Standard Time', time: -8 }],
  [Timezone['America/Los_Angeles'], { key: Timezone['America/Los_Angeles'], name: 'Pacific Standard Time', time: -8 }],
  [Timezone['America/Metlakatla'], { key: Timezone['America/Metlakatla'], name: 'Metlakatla Standard Time', time: -8 }],
  [
    Timezone['America/Santa_Isabel'],
    { key: Timezone['America/Santa_Isabel'], name: 'Pacific Standard Time', time: -8 },
  ],
  [Timezone['America/Tijuana'], { key: Timezone['America/Tijuana'], name: 'Pacific Standard Time', time: -8 }],
  [Timezone['America/Vancouver'], { key: Timezone['America/Vancouver'], name: 'Pacific Standard Time', time: -8 }],
  [Timezone['America/Whitehorse'], { key: Timezone['America/Whitehorse'], name: 'Pacific Standard Time', time: -8 }],
  [Timezone['America/Boise'], { key: Timezone['America/Boise'], name: 'Mountain Standard Time', time: -7 }],
  [
    Timezone['America/Cambridge_Bay'],
    { key: Timezone['America/Cambridge_Bay'], name: 'Mountain Standard Time', time: -7 },
  ],
  [Timezone['America/Chihuahua'], { key: Timezone['America/Chihuahua'], name: 'Mountain Standard Time', time: -7 }],
  [
    Timezone['America/Dawson_Creek'],
    { key: Timezone['America/Dawson_Creek'], name: 'Mountain Standard Time', time: -7 },
  ],
  [Timezone['America/Denver'], { key: Timezone['America/Denver'], name: 'Mountain Standard Time', time: -7 }],
  [Timezone['America/Edmonton'], { key: Timezone['America/Edmonton'], name: 'Mountain Standard Time', time: -7 }],
  [Timezone['America/Hermosillo'], { key: Timezone['America/Hermosillo'], name: 'Mountain Standard Time', time: -7 }],
  [Timezone['America/Inuvik'], { key: Timezone['America/Inuvik'], name: 'Mountain Standard Time', time: -7 }],
  [Timezone['America/Mazatlan'], { key: Timezone['America/Mazatlan'], name: 'Mountain Standard Time', time: -7 }],
  [Timezone['America/Ojinaga'], { key: Timezone['America/Ojinaga'], name: 'Mountain Standard Time', time: -7 }],
  [Timezone['America/Phoenix'], { key: Timezone['America/Phoenix'], name: 'Mountain Standard Time', time: -7 }],
  [Timezone['America/Shiprock'], { key: Timezone['America/Shiprock'], name: 'Mountain Standard Time', time: -7 }],
  [Timezone['America/Yellowknife'], { key: Timezone['America/Yellowknife'], name: 'Mountain Standard Time', time: -7 }],
  [
    Timezone['America/Bahia_Banderas'],
    { key: Timezone['America/Bahia_Banderas'], name: 'Central Standard Time', time: -6 },
  ],
  [Timezone['America/Belize'], { key: Timezone['America/Belize'], name: 'Central Standard Time', time: -6 }],
  [Timezone['America/Cancun'], { key: Timezone['America/Cancun'], name: 'Central Standard Time', time: -6 }],
  [Timezone['America/Chicago'], { key: Timezone['America/Chicago'], name: 'Central Standard Time', time: -6 }],
  [Timezone['America/Costa_Rica'], { key: Timezone['America/Costa_Rica'], name: 'Central Standard Time', time: -6 }],
  [Timezone['America/El_Salvador'], { key: Timezone['America/El_Salvador'], name: 'Central Standard Time', time: -6 }],
  [Timezone['America/Guatemala'], { key: Timezone['America/Guatemala'], name: 'Central Standard Time', time: -6 }],
  [
    Timezone['America/Indiana/Knox'],
    { key: Timezone['America/Indiana/Knox'], name: 'Central Standard Time', time: -6 },
  ],
  [
    Timezone['America/Indiana/Tell_City'],
    { key: Timezone['America/Indiana/Tell_City'], name: 'Central Standard Time', time: -6 },
  ],
  [Timezone['America/Knox_IN'], { key: Timezone['America/Knox_IN'], name: 'Central Standard Time', time: -6 }],
  [Timezone['America/Managua'], { key: Timezone['America/Managua'], name: 'Central Standard Time', time: -6 }],
  [Timezone['America/Matamoros'], { key: Timezone['America/Matamoros'], name: 'Central Standard Time', time: -6 }],
  [Timezone['America/Menominee'], { key: Timezone['America/Menominee'], name: 'Central Standard Time', time: -6 }],
  [Timezone['America/Merida'], { key: Timezone['America/Merida'], name: 'Central Standard Time', time: -6 }],
  [Timezone['America/Mexico_City'], { key: Timezone['America/Mexico_City'], name: 'Central Standard Time', time: -6 }],
  [Timezone['America/Monterrey'], { key: Timezone['America/Monterrey'], name: 'Central Standard Time', time: -6 }],
  [
    Timezone['America/North_Dakota/Beulah'],
    { key: Timezone['America/North_Dakota/Beulah'], name: 'Central Standard Time', time: -6 },
  ],
  [
    Timezone['America/North_Dakota/Center'],
    { key: Timezone['America/North_Dakota/Center'], name: 'Central Standard Time', time: -6 },
  ],
  [
    Timezone['America/North_Dakota/New_Salem'],
    { key: Timezone['America/North_Dakota/New_Salem'], name: 'Central Standard Time', time: -6 },
  ],
  [Timezone['America/Rainy_River'], { key: Timezone['America/Rainy_River'], name: 'Central Standard Time', time: -6 }],
  [
    Timezone['America/Rankin_Inlet'],
    { key: Timezone['America/Rankin_Inlet'], name: 'Central Standard Time', time: -6 },
  ],
  [Timezone['America/Regina'], { key: Timezone['America/Regina'], name: 'Central Standard Time', time: -6 }],
  [Timezone['America/Resolute'], { key: Timezone['America/Resolute'], name: 'Eastern Standard Time', time: -6 }],
  [
    Timezone['America/Swift_Current'],
    { key: Timezone['America/Swift_Current'], name: 'Central Standard Time', time: -6 },
  ],
  [Timezone['America/Tegucigalpa'], { key: Timezone['America/Tegucigalpa'], name: 'Central Standard Time', time: -6 }],
  [Timezone['America/Winnipeg'], { key: Timezone['America/Winnipeg'], name: 'Central Standard Time', time: -6 }],
  [Timezone['Canada/Saskatchewan'], { key: Timezone['Canada/Saskatchewan'], name: 'Central Standard Time', time: -6 }],
  [Timezone['America/Atikokan'], { key: Timezone['America/Atikokan'], name: 'Eastern Standard Time', time: -5 }],
  [Timezone['America/Bogota'], { key: Timezone['America/Bogota'], name: 'Colombia Time', time: -5 }],
  [Timezone['America/Cayman'], { key: Timezone['America/Cayman'], name: 'Eastern Standard Time', time: -5 }],
  [
    Timezone['America/Coral_Harbour'],
    { key: Timezone['America/Coral_Harbour'], name: 'Eastern Standard Time', time: -5 },
  ],
  [Timezone['America/Detroit'], { key: Timezone['America/Detroit'], name: 'Eastern Standard Time', time: -5 }],
  [Timezone['America/Fort_Wayne'], { key: Timezone['America/Fort_Wayne'], name: 'Eastern Standard Time', time: -5 }],
  [Timezone['America/Grand_Turk'], { key: Timezone['America/Grand_Turk'], name: 'Eastern Standard Time', time: -5 }],
  [Timezone['America/Guayaquil'], { key: Timezone['America/Guayaquil'], name: 'Ecuador Time', time: -5 }],
  [Timezone['America/Havana'], { key: Timezone['America/Havana'], name: 'Cuba Standard Time', time: -5 }],
  [
    Timezone['America/Indiana/Indianapolis'],
    { key: Timezone['America/Indiana/Indianapolis'], name: 'Eastern Standard Time', time: -5 },
  ],
  [
    Timezone['America/Indiana/Marengo'],
    { key: Timezone['America/Indiana/Marengo'], name: 'Eastern Standard Time', time: -5 },
  ],
  [
    Timezone['America/Indiana/Petersburg'],
    { key: Timezone['America/Indiana/Petersburg'], name: 'Eastern Standard Time', time: -5 },
  ],
  [
    Timezone['America/Indiana/Vevay'],
    { key: Timezone['America/Indiana/Vevay'], name: 'Eastern Standard Time', time: -5 },
  ],
  [
    Timezone['America/Indiana/Vincennes'],
    { key: Timezone['America/Indiana/Vincennes'], name: 'Eastern Standard Time', time: -5 },
  ],
  [
    Timezone['America/Indiana/Winamac'],
    { key: Timezone['America/Indiana/Winamac'], name: 'Eastern Standard Time', time: -5 },
  ],
  [
    Timezone['America/Indianapolis'],
    { key: Timezone['America/Indianapolis'], name: 'Eastern Standard Time', time: -5 },
  ],
  [Timezone['America/Iqaluit'], { key: Timezone['America/Iqaluit'], name: 'Eastern Standard Time', time: -5 }],
  [Timezone['America/Jamaica'], { key: Timezone['America/Jamaica'], name: 'Eastern Standard Time', time: -5 }],
  [
    Timezone['America/Kentucky/Louisville'],
    { key: Timezone['America/Kentucky/Louisville'], name: 'Eastern Standard Time', time: -5 },
  ],
  [
    Timezone['America/Kentucky/Monticello'],
    { key: Timezone['America/Kentucky/Monticello'], name: 'Eastern Standard Time', time: -5 },
  ],
  [Timezone['America/Lima'], { key: Timezone['America/Lima'], name: 'Peru Time', time: -5 }],
  [Timezone['America/Louisville'], { key: Timezone['America/Louisville'], name: 'Eastern Standard Time', time: -5 }],
  [Timezone['America/Montreal'], { key: Timezone['America/Montreal'], name: 'Eastern Standard Time', time: -5 }],
  [Timezone['America/Nassau'], { key: Timezone['America/Nassau'], name: 'Eastern Standard Time', time: -5 }],
  [Timezone['America/New_York'], { key: Timezone['America/New_York'], name: 'Eastern Standard Time', time: -5 }],
  [Timezone['America/Nipigon'], { key: Timezone['America/Nipigon'], name: 'Eastern Standard Time', time: -5 }],
  [Timezone['America/Panama'], { key: Timezone['America/Panama'], name: 'Eastern Standard Time', time: -5 }],
  [Timezone['America/Pangnirtung'], { key: Timezone['America/Pangnirtung'], name: 'Eastern Standard Time', time: -5 }],
  [
    Timezone['America/Port-au-Prince'],
    { key: Timezone['America/Port-au-Prince'], name: 'Eastern Standard Time', time: -5 },
  ],
  [Timezone['America/Thunder_Bay'], { key: Timezone['America/Thunder_Bay'], name: 'Eastern Standard Time', time: -5 }],
  [Timezone['America/Toronto'], { key: Timezone['America/Toronto'], name: 'Eastern Standard Time', time: -5 }],
  [Timezone['Canada/Eastern'], { key: Timezone['Canada/Eastern'], name: 'Eastern Standard Time', time: -5 }],
  [Timezone['America/Caracas'], { key: Timezone['America/Caracas'], name: 'Venezuela Time', time: -4.5 }],
  [Timezone['America/Anguilla'], { key: Timezone['America/Anguilla'], name: 'Atlantic Standard Time', time: -4 }],
  [Timezone['America/Antigua'], { key: Timezone['America/Antigua'], name: 'Atlantic Standard Time', time: -4 }],
  [
    Timezone['America/Argentina/San_Luis'],
    { key: Timezone['America/Argentina/San_Luis'], name: 'Western Argentine Time', time: -4 },
  ],
  [Timezone['America/Aruba'], { key: Timezone['America/Aruba'], name: 'Atlantic Standard Time', time: -4 }],
  [Timezone['America/Asuncion'], { key: Timezone['America/Asuncion'], name: 'Paraguay Time', time: -4 }],
  [Timezone['America/Barbados'], { key: Timezone['America/Barbados'], name: 'Atlantic Standard Time', time: -4 }],
  [
    Timezone['America/Blanc-Sablon'],
    { key: Timezone['America/Blanc-Sablon'], name: 'Atlantic Standard Time', time: -4 },
  ],
  [Timezone['America/Boa_Vista'], { key: Timezone['America/Boa_Vista'], name: 'Amazon Time', time: -4 }],
  [Timezone['America/Campo_Grande'], { key: Timezone['America/Campo_Grande'], name: 'Amazon Time', time: -4 }],
  [Timezone['America/Cuiaba'], { key: Timezone['America/Cuiaba'], name: 'Amazon Time', time: -4 }],
  [Timezone['America/Curacao'], { key: Timezone['America/Curacao'], name: 'Atlantic Standard Time', time: -4 }],
  [Timezone['America/Dominica'], { key: Timezone['America/Dominica'], name: 'Atlantic Standard Time', time: -4 }],
  [Timezone['America/Danmarkshavn'], { key: Timezone['America/Danmarkshavn'], name: 'Greenwich Mean Time', time: 0 }],
  [Timezone['America/Eirunepe'], { key: Timezone['America/Eirunepe'], name: 'Amazon Time', time: -4 }],
  [Timezone['America/Glace_Bay'], { key: Timezone['America/Glace_Bay'], name: 'Atlantic Standard Time', time: -4 }],
  [Timezone['America/Goose_Bay'], { key: Timezone['America/Goose_Bay'], name: 'Atlantic Standard Time', time: -4 }],
  [Timezone['America/Grenada'], { key: Timezone['America/Grenada'], name: 'Atlantic Standard Time', time: -4 }],
  [Timezone['America/Guadeloupe'], { key: Timezone['America/Guadeloupe'], name: 'Atlantic Standard Time', time: -4 }],
  [Timezone['America/Guyana'], { key: Timezone['America/Guyana'], name: 'Guyana Time', time: -4 }],
  [Timezone['America/Halifax'], { key: Timezone['America/Halifax'], name: 'Atlantic Standard Time', time: -4 }],
  [Timezone['America/Kralendijk'], { key: Timezone['America/Kralendijk'], name: 'GMT-04:00', time: -4 }],
  [Timezone['America/La_Paz'], { key: Timezone['America/La_Paz'], name: 'Bolivia Time', time: -4 }],
  [Timezone['America/Lower_Princes'], { key: Timezone['America/Lower_Princes'], name: 'GMT-04:00', time: -4 }],
  [Timezone['America/Manaus'], { key: Timezone['America/Manaus'], name: 'Amazon Time', time: -4 }],
  [Timezone['America/Marigot'], { key: Timezone['America/Marigot'], name: 'Atlantic Standard Time', time: -4 }],
  [Timezone['America/Martinique'], { key: Timezone['America/Martinique'], name: 'Atlantic Standard Time', time: -4 }],
  [Timezone['America/Moncton'], { key: Timezone['America/Moncton'], name: 'Atlantic Standard Time', time: -4 }],
  [Timezone['America/Montserrat'], { key: Timezone['America/Montserrat'], name: 'Atlantic Standard Time', time: -4 }],
  [
    Timezone['America/Port_of_Spain'],
    { key: Timezone['America/Port_of_Spain'], name: 'Atlantic Standard Time', time: -4 },
  ],
  [Timezone['America/Porto_Acre'], { key: Timezone['America/Porto_Acre'], name: 'Amazon Time', time: -4 }],
  [Timezone['America/Porto_Velho'], { key: Timezone['America/Porto_Velho'], name: 'Amazon Time', time: -4 }],
  [Timezone['America/Puerto_Rico'], { key: Timezone['America/Puerto_Rico'], name: 'Atlantic Standard Time', time: -4 }],
  [Timezone['America/Rio_Branco'], { key: Timezone['America/Rio_Branco'], name: 'Amazon Time', time: -4 }],
  [Timezone['America/Santiago'], { key: Timezone['America/Santiago'], name: 'Chile Time', time: -4 }],
  [
    Timezone['America/Santo_Domingo'],
    { key: Timezone['America/Santo_Domingo'], name: 'Atlantic Standard Time', time: -4 },
  ],
  [
    Timezone['America/St_Barthelemy'],
    { key: Timezone['America/St_Barthelemy'], name: 'Atlantic Standard Time', time: -4 },
  ],
  [Timezone['America/St_Kitts'], { key: Timezone['America/St_Kitts'], name: 'Atlantic Standard Time', time: -4 }],
  [Timezone['America/St_Lucia'], { key: Timezone['America/St_Lucia'], name: 'Atlantic Standard Time', time: -4 }],
  [Timezone['America/St_Thomas'], { key: Timezone['America/St_Thomas'], name: 'Atlantic Standard Time', time: -4 }],
  [Timezone['America/St_Johns'], { key: Timezone['America/St_Johns'], name: 'Atlantic Standard Time', time: -3.5 }],
  [Timezone['America/St_Vincent'], { key: Timezone['America/St_Vincent'], name: 'Atlantic Standard Time', time: -4 }],
  [Timezone['America/Thule'], { key: Timezone['America/Thule'], name: 'Atlantic Standard Time', time: -4 }],
  [Timezone['America/Tortola'], { key: Timezone['America/Tortola'], name: 'Atlantic Standard Time', time: -4 }],
  [Timezone['America/Virgin'], { key: Timezone['America/Virgin'], name: 'Atlantic Standard Time', time: -4 }],
  [Timezone['Canada/Atlantic'], { key: Timezone['Canada/Atlantic'], name: 'Atlantic Standard Time', time: -4 }],
  [Timezone['Canada/Pacific'], { key: Timezone['Canada/Pacific'], name: 'Pacific Standard Time', time: -8 }],
  [Timezone['Canada/Yukon'], { key: Timezone['Canada/Yukon'], name: 'Pacific Standard Time', time: -8 }],
  [Timezone['Canada/Mountain'], { key: Timezone['Canada/Mountain'], name: 'Mountain Standard Time', time: -7 }],
  [Timezone['Canada/Central'], { key: Timezone['Canada/Central'], name: 'Central Standard Time', time: -6 }],
  [
    Timezone['Canada/East-Saskatchewan'],
    { key: Timezone['Canada/East-Saskatchewan'], name: 'Central Standard Time', time: -6 },
  ],
  [
    Timezone['Canada/Newfoundland'],
    { key: Timezone['Canada/Newfoundland'], name: 'Newfoundland Standard Time', time: -3.5 },
  ],
  [Timezone['America/Araguaina'], { key: Timezone['America/Araguaina'], name: 'Brasilia Time', time: -3 }],
  [
    Timezone['America/Argentina/Buenos_Aires'],
    { key: Timezone['America/Argentina/Buenos_Aires'], name: 'Argentine Time', time: -3 },
  ],
  [
    Timezone['America/Argentina/Catamarca'],
    { key: Timezone['America/Argentina/Catamarca'], name: 'Argentine Time', time: -3 },
  ],
  [
    Timezone['America/Argentina/ComodRivadavia'],
    { key: Timezone['America/Argentina/ComodRivadavia'], name: 'Argentine Time', time: -3 },
  ],
  [
    Timezone['America/Argentina/Cordoba'],
    { key: Timezone['America/Argentina/Cordoba'], name: 'Argentine Time', time: -3 },
  ],
  [Timezone['America/Argentina/Jujuy'], { key: Timezone['America/Argentina/Jujuy'], name: 'Argentine Time', time: -3 }],
  [
    Timezone['America/Argentina/La_Rioja'],
    { key: Timezone['America/Argentina/La_Rioja'], name: 'Argentine Time', time: -3 },
  ],
  [
    Timezone['America/Argentina/Mendoza'],
    { key: Timezone['America/Argentina/Mendoza'], name: 'Argentine Time', time: -3 },
  ],
  [
    Timezone['America/Argentina/Rio_Gallegos'],
    { key: Timezone['America/Argentina/Rio_Gallegos'], name: 'Argentine Time', time: -3 },
  ],
  [Timezone['America/Argentina/Salta'], { key: Timezone['America/Argentina/Salta'], name: 'Argentine Time', time: -3 }],
  [
    Timezone['America/Argentina/San_Juan'],
    { key: Timezone['America/Argentina/San_Juan'], name: 'Argentine Time', time: -3 },
  ],
  [
    Timezone['America/Argentina/Tucuman'],
    { key: Timezone['America/Argentina/Tucuman'], name: 'Argentine Time', time: -3 },
  ],
  [
    Timezone['America/Argentina/Ushuaia'],
    { key: Timezone['America/Argentina/Ushuaia'], name: 'Argentine Time', time: -3 },
  ],
  [Timezone['America/Bahia'], { key: Timezone['America/Bahia'], name: 'Brasilia Time', time: -3 }],
  [Timezone['America/Belem'], { key: Timezone['America/Belem'], name: 'Brasilia Time', time: -3 }],
  [Timezone['America/Buenos_Aires'], { key: Timezone['America/Buenos_Aires'], name: 'Argentine Time', time: -3 }],
  [Timezone['America/Catamarca'], { key: Timezone['America/Catamarca'], name: 'Argentine Time', time: -3 }],
  [Timezone['America/Cayenne'], { key: Timezone['America/Cayenne'], name: 'French Guiana Time', time: -3 }],
  [Timezone['America/Cordoba'], { key: Timezone['America/Cordoba'], name: 'Argentine Time', time: -3 }],
  [Timezone['America/Fortaleza'], { key: Timezone['America/Fortaleza'], name: 'Brasilia Time', time: -3 }],
  [Timezone['America/Godthab'], { key: Timezone['America/Godthab'], name: 'Western Greenland Time', time: -3 }],
  [Timezone['America/Jujuy'], { key: Timezone['America/Jujuy'], name: 'Argentine Time', time: -3 }],
  [Timezone['America/Maceio'], { key: Timezone['America/Maceio'], name: 'Brasilia Time', time: -3 }],
  [Timezone['America/Mendoza'], { key: Timezone['America/Mendoza'], name: 'Argentine Time', time: -3 }],
  [
    Timezone['America/Miquelon'],
    { key: Timezone['America/Miquelon'], name: 'Pierre & Miquelon Standard Time', time: -3 },
  ],
  [Timezone['America/Montevideo'], { key: Timezone['America/Montevideo'], name: 'Uruguay Time', time: -3 }],
  [Timezone['America/Paramaribo'], { key: Timezone['America/Paramaribo'], name: 'Suriname Time', time: -3 }],
  [Timezone['America/Recife'], { key: Timezone['America/Recife'], name: 'Brasilia Time', time: -3 }],
  [Timezone['America/Rosario'], { key: Timezone['America/Rosario'], name: 'Argentine Time', time: -3 }],
  [Timezone['America/Santarem'], { key: Timezone['America/Santarem'], name: 'Brasilia Time', time: -3 }],
  [Timezone['America/Sao_Paulo'], { key: Timezone['America/Sao_Paulo'], name: 'Brasilia Time', time: -3 }],
  [Timezone['America/Noronha'], { key: Timezone['America/Noronha'], name: 'Fernando de Noronha Time', time: -2 }],
  [
    Timezone['America/Scoresbysund'],
    { key: Timezone['America/Scoresbysund'], name: 'Eastern Greenland Time', time: -1 },
  ],
  [Timezone['Atlantic/Reykjavik'], { key: Timezone['Atlantic/Reykjavik'], name: 'Greenwich Mean Time', time: 0 }],
  [Timezone['Europe/Belfast'], { key: Timezone['Europe/Belfast'], name: 'Greenwich Mean Time', time: 0 }],
  [Timezone['Europe/Dublin'], { key: Timezone['Europe/Dublin'], name: 'Greenwich Mean Time', time: 0 }],
  [Timezone['Europe/Guernsey'], { key: Timezone['Europe/Guernsey'], name: 'Greenwich Mean Time', time: 0 }],
  [Timezone['Europe/Isle_of_Man'], { key: Timezone['Europe/Isle_of_Man'], name: 'Greenwich Mean Time', time: 0 }],
  [Timezone['Europe/Jersey'], { key: Timezone['Europe/Jersey'], name: 'Greenwich Mean Time', time: 0 }],
  [Timezone['Europe/Lisbon'], { key: Timezone['Europe/Lisbon'], name: 'Western European Time', time: 0 }],
  [Timezone['Europe/London'], { key: Timezone['Europe/London'], name: 'Greenwich Mean Time', time: 0 }],
  [Timezone['Europe/Amsterdam'], { key: Timezone['Europe/Amsterdam'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Andorra'], { key: Timezone['Europe/Andorra'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Belgrade'], { key: Timezone['Europe/Belgrade'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Berlin'], { key: Timezone['Europe/Berlin'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Bratislava'], { key: Timezone['Europe/Bratislava'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Brussels'], { key: Timezone['Europe/Brussels'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Budapest'], { key: Timezone['Europe/Budapest'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Copenhagen'], { key: Timezone['Europe/Copenhagen'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Gibraltar'], { key: Timezone['Europe/Gibraltar'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Ljubljana'], { key: Timezone['Europe/Ljubljana'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Luxembourg'], { key: Timezone['Europe/Luxembourg'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Madrid'], { key: Timezone['Europe/Madrid'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Malta'], { key: Timezone['Europe/Malta'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Monaco'], { key: Timezone['Europe/Monaco'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Oslo'], { key: Timezone['Europe/Oslo'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Paris'], { key: Timezone['Europe/Paris'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Podgorica'], { key: Timezone['Europe/Podgorica'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Prague'], { key: Timezone['Europe/Prague'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Rome'], { key: Timezone['Europe/Rome'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/San_Marino'], { key: Timezone['Europe/San_Marino'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Sarajevo'], { key: Timezone['Europe/Sarajevo'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Skopje'], { key: Timezone['Europe/Skopje'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Stockholm'], { key: Timezone['Europe/Stockholm'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Tirane'], { key: Timezone['Europe/Tirane'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Vaduz'], { key: Timezone['Europe/Vaduz'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Vatican'], { key: Timezone['Europe/Vatican'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Vienna'], { key: Timezone['Europe/Vienna'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Warsaw'], { key: Timezone['Europe/Warsaw'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Zagreb'], { key: Timezone['Europe/Zagreb'], name: 'Central European Time', time: 1 }],
  [Timezone['Europe/Zurich'], { key: Timezone['Europe/Zurich'], name: 'Central European Time', time: 1 }],
  [Timezone['Asia/Nicosia'], { key: Timezone['Asia/Nicosia'], name: 'Eastern European Time', time: 2 }],
  [Timezone['Europe/Athens'], { key: Timezone['Europe/Athens'], name: 'Eastern European Time', time: 2 }],
  [Timezone['Europe/Bucharest'], { key: Timezone['Europe/Bucharest'], name: 'Eastern European Time', time: 2 }],
  [Timezone['Europe/Chisinau'], { key: Timezone['Europe/Chisinau'], name: 'Eastern European Time', time: 2 }],
  [Timezone['Europe/Helsinki'], { key: Timezone['Europe/Helsinki'], name: 'Eastern European Time', time: 2 }],
  [Timezone['Europe/Istanbul'], { key: Timezone['Europe/Istanbul'], name: 'Eastern European Time', time: 2 }],
  [Timezone['Europe/Kiev'], { key: Timezone['Europe/Kiev'], name: 'Eastern European Time', time: 2 }],
  [Timezone['Europe/Mariehamn'], { key: Timezone['Europe/Mariehamn'], name: 'Eastern European Time', time: 2 }],
  [Timezone['Europe/Nicosia'], { key: Timezone['Europe/Nicosia'], name: 'Eastern European Time', time: 2 }],
  [Timezone['Europe/Riga'], { key: Timezone['Europe/Riga'], name: 'Eastern European Time', time: 2 }],
  [Timezone['Europe/Simferopol'], { key: Timezone['Europe/Simferopol'], name: 'Eastern European Time', time: 2 }],
  [Timezone['Europe/Sofia'], { key: Timezone['Europe/Sofia'], name: 'Eastern European Time', time: 2 }],
  [Timezone['Europe/Tallinn'], { key: Timezone['Europe/Tallinn'], name: 'Eastern European Time', time: 2 }],
  [Timezone['Europe/Tiraspol'], { key: Timezone['Europe/Tiraspol'], name: 'Eastern European Time', time: 2 }],
  [Timezone['Europe/Uzhgorod'], { key: Timezone['Europe/Uzhgorod'], name: 'Eastern European Time', time: 2 }],
  [Timezone['Europe/Vilnius'], { key: Timezone['Europe/Vilnius'], name: 'Eastern European Time', time: 2 }],
  [Timezone['Europe/Zaporozhye'], { key: Timezone['Europe/Zaporozhye'], name: 'Eastern European Time', time: 2 }],
  [
    Timezone['Africa/Johannesburg'],
    { key: Timezone['Africa/Johannesburg'], name: 'South Africa Standard Time', time: 2 },
  ],
  [Timezone['Africa/Kigali'], { key: Timezone['Africa/Kigali'], name: 'Central Africa Time', time: 2 }],
  [Timezone['Africa/Cairo'], { key: Timezone['Africa/Cairo'], name: 'Eastern European Time', time: 2 }],
  [Timezone['Europe/Kaliningrad'], { key: Timezone['Europe/Kaliningrad'], name: 'Eastern European Time', time: 3 }],
  [Timezone['Europe/Minsk'], { key: Timezone['Europe/Minsk'], name: 'Eastern European Time', time: 3 }],
  [Timezone['Asia/Jerusalem'], { key: Timezone['Asia/Jerusalem'], name: 'Israel Daylight Time', time: 3 }],
  [Timezone['Asia/Riyadh'], { key: Timezone['Asia/Riyadh'], name: 'Arabian Standard Time', time: 3 }],
  [Timezone['Asia/Aden'], { key: Timezone['Asia/Aden'], name: 'Arabian Standard Time', time: 3 }],
  [Timezone['Asia/Amman'], { key: Timezone['Asia/Amman'], name: 'Eastern European Time', time: 3 }],
  [Timezone['Asia/Qatar'], { key: Timezone['Asia/Qatar'], name: 'Arabian Standard Time', time: 3 }],
  [Timezone['Asia/Kuwait'], { key: Timezone['Asia/Kuwait'], name: 'Arabian Standard Time', time: 3 }],
  [Timezone['Asia/Muscat'], { key: Timezone['Asia/Muscat'], name: 'Gulf Standard Time', time: 4 }],
  [Timezone['Asia/Karachi'], { key: Timezone['Asia/Karachi'], name: 'Pakistan Time', time: 5 }],
  [Timezone['Asia/Tehran'], { key: Timezone['Asia/Tehran'], name: 'Iran Time', time: 3.5 }],
  [Timezone['Asia/Dubai'], { key: Timezone['Asia/Dubai'], name: 'Gulf Standard Time', time: 4 }],
  [Timezone['Asia/Tbilisi'], { key: Timezone['Asia/Tbilisi'], name: 'Georgia Time', time: 4 }],
  [Timezone['Europe/Moscow'], { key: Timezone['Europe/Moscow'], name: 'Moscow Standard Time', time: 4 }],
  [Timezone['Europe/Samara'], { key: Timezone['Europe/Samara'], name: 'Samara Time', time: 4 }],
  [Timezone['Europe/Volgograd'], { key: Timezone['Europe/Volgograd'], name: 'Volgograd Time', time: 4 }],
  [Timezone['Asia/Kolkata'], { key: Timezone['Asia/Kolkata'], name: 'India Standard Time', time: 5.5 }],
  [Timezone['Asia/Bangkok'], { key: Timezone['Asia/Bangkok'], name: 'Indochina Time', time: 7 }],
  [Timezone['Asia/Ho_Chi_Minh'], { key: Timezone['Asia/Ho_Chi_Minh'], name: 'Indochina Time', time: 7 }],
  [Timezone['Asia/Hong_Kong'], { key: Timezone['Asia/Hong_Kong'], name: 'Hong Kong Time', time: 8 }],
  [Timezone['Asia/Kuala_Lumpur'], { key: Timezone['Asia/Kuala_Lumpur'], name: 'Malaysia Time', time: 8 }],
  [Timezone['Asia/Singapore'], { key: Timezone['Asia/Singapore'], name: 'Singapore Time', time: 8 }],
  [Timezone['Asia/Manila'], { key: Timezone['Asia/Manila'], name: 'Philippine Time', time: 8 }],
  [
    Timezone['Australia/Perth'],
    { key: Timezone['Australia/Perth'], name: 'Western Standard Time (Australia)', time: 8 },
  ],
  [Timezone['Australia/West'], { key: Timezone['Australia/West'], name: 'Western Standard Time (Australia)', time: 8 }],
  [
    Timezone['Australia/Eucla'],
    { key: Timezone['Australia/Eucla'], name: 'Central Western Standard Time (Australia)', time: 8.75 },
  ],
  [Timezone['Asia/Tokyo'], { key: Timezone['Asia/Tokyo'], name: 'Japan Standard Time', time: 9 }],
  [Timezone['Asia/Seoul'], { key: Timezone['Asia/Seoul'], name: 'Korea Standard Time', time: 9 }],
  [
    Timezone['Australia/Adelaide'],
    { key: Timezone['Australia/Adelaide'], name: 'Central Standard Time (South Australia)', time: 9.5 },
  ],
  [
    Timezone['Australia/Broken_Hill'],
    {
      key: Timezone['Australia/Broken_Hill'],
      name: 'Central Standard Time (South Australia/New South Wales)',
      time: 9.5,
    },
  ],
  [
    Timezone['Australia/Darwin'],
    { key: Timezone['Australia/Darwin'], name: 'Central Standard Time (Northern Territory)', time: 9.5 },
  ],
  [
    Timezone['Australia/North'],
    { key: Timezone['Australia/North'], name: 'Central Standard Time (Northern Territory)', time: 9.5 },
  ],
  [
    Timezone['Australia/South'],
    { key: Timezone['Australia/South'], name: 'Central Standard Time (South Australia)', time: 9.5 },
  ],
  [
    Timezone['Australia/Yancowinna'],
    {
      key: Timezone['Australia/Yancowinna'],
      name: 'Central Standard Time (South Australia/New South Wales)',
      time: 9.5,
    },
  ],
  [
    Timezone['Australia/ACT'],
    { key: Timezone['Australia/ACT'], name: 'Eastern Standard Time (New South Wales)', time: 10 },
  ],
  [
    Timezone['Australia/Brisbane'],
    { key: Timezone['Australia/Brisbane'], name: 'Eastern Standard Time (Queensland)', time: 10 },
  ],
  [
    Timezone['Australia/Canberra'],
    { key: Timezone['Australia/Canberra'], name: 'Eastern Standard Time (New South Wales)', time: 10 },
  ],
  [
    Timezone['Australia/Currie'],
    { key: Timezone['Australia/Currie'], name: 'Eastern Standard Time (New South Wales)', time: 10 },
  ],
  [
    Timezone['Australia/Hobart'],
    { key: Timezone['Australia/Hobart'], name: 'Eastern Standard Time (Tasmania)', time: 10 },
  ],
  [
    Timezone['Australia/Lindeman'],
    { key: Timezone['Australia/Lindeman'], name: 'Eastern Standard Time (Queensland)', time: 10 },
  ],
  [
    Timezone['Australia/Melbourne'],
    { key: Timezone['Australia/Melbourne'], name: 'Eastern Standard Time (Victoria)', time: 10 },
  ],
  [
    Timezone['Australia/NSW'],
    { key: Timezone['Australia/NSW'], name: 'Eastern Standard Time (New South Wales)', time: 10 },
  ],
  [
    Timezone['Australia/Queensland'],
    { key: Timezone['Australia/Queensland'], name: 'Eastern Standard Time (Queensland)', time: 10 },
  ],
  [
    Timezone['Australia/Sydney'],
    { key: Timezone['Australia/Sydney'], name: 'Eastern Standard Time (New South Wales)', time: 10 },
  ],
  [
    Timezone['Australia/Tasmania'],
    { key: Timezone['Australia/Tasmania'], name: 'Eastern Standard Time (Tasmania)', time: 10 },
  ],
  [
    Timezone['Australia/Victoria'],
    { key: Timezone['Australia/Victoria'], name: 'Eastern Standard Time (Victoria)', time: 10 },
  ],
  [Timezone['Australia/LHI'], { key: Timezone['Australia/LHI'], name: 'Lord Howe Standard Time', time: 10.5 }],
  [
    Timezone['Australia/Lord_Howe'],
    { key: Timezone['Australia/Lord_Howe'], name: 'Lord Howe Standard Time', time: 10.5 },
  ],
  [Timezone['Pacific/Auckland'], { key: Timezone['Pacific/Auckland'], name: 'New Zealand Standard Time', time: 12 }],
  [Timezone['Pacific/Funafuti'], { key: Timezone['Pacific/Funafuti'], name: 'Tuvalu Time', time: 12 }],
  [Timezone['Pacific/Apia'], { key: Timezone['Pacific/Apia'], name: 'Samoa Standard Time', time: 13 }],
]);
