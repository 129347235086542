import { memo } from 'react';
import { Radio, RadioProps } from './Radio';
import { RadioGroupProps } from './RadioGroup';

export interface RadioGroupOptionsProps<T = string>
  extends Pick<RadioGroupProps<T>, 'options' | 'optionClassName' | 'disabled' | 'optionsClassName'> {
  value: T;
  onCheck: RadioProps['onCheck'];
  className?: string;
}

function RadioGroupOptionsComponent<T = string>({
  options,
  className,
  value,
  optionClassName,
  onCheck,
  disabled,
}: RadioGroupOptionsProps<T>) {
  const content = options.map((i, index) => {
    const isString = typeof i !== 'object';
    const v = typeof i === 'string' || typeof i === 'number' ? i : i.value;
    const checked = v === value;

    return isString ? (
      <Radio
        className={optionClassName}
        key={i}
        label={i}
        checked={checked}
        onCheck={onCheck}
        disabled={disabled}
        data-radio-index={index}
      />
    ) : (
      <Radio
        className={optionClassName}
        key={i.key ? i.key.toString() : index}
        label={i.label}
        labelClassName={i.labelClassName}
        checked={checked}
        onCheck={onCheck}
        disabled={disabled}
        data-radio-index={index}
      />
    );
  });
  return className ? <div className={className}>{content}</div> : <>{content}</>;
}

export const RadioGroupOptions = memo(RadioGroupOptionsComponent) as typeof RadioGroupOptionsComponent;
