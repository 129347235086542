export enum Permission {
  // Client
  'client:list' = 101,
  'client:contact_info' = 102,
  'client:create+update' = 103,
  'client:delete' = 104,
  'client:import' = 105,
  'client:export' = 106,

  // Team
  'team:staff:create' = 201,
  'team:staff:update' = 202,
  'team:staff:delete' = 203,
  'team:staff:shift' = 204,
  'team:self:shift' = 205,
  'team:shift' = 211,
  'team:role+permission' = 212,

  // Library
  'library:exercise:create+update' = 301,
  'library:exercise:delete' = 302,
  'library:workout:create+update' = 311,
  'library:workout:delete' = 312,
  'library:program:create+update' = 321,
  'library:program:delete' = 322,

  // Services
  'service:membership:list' = 401,
  'service:membership:create+update' = 402,
  'service:membership:delete' = 403,
  'service:class:list' = 411,
  'service:class:create+update' = 412,
  'service:class:delete' = 413,
  // 'service:product:list' = 421,
  // 'service:product:create+update' = 422,
  // 'service:product:delete' = 423,
  // 'service:voucher:list' = 431,
  // 'service:voucher:create+update' = 432,
  // 'service:voucher:delete' = 433,

  // Forms
  'form:questionnaire:list' = 501,
  'form:questionnaire:create+update' = 502,
  'form:questionnaire:delete' = 503,
  'form:questionnaire:record:list' = 511,
  'form:questionnaire:record:delete' = 512,
  'form:agreement:list' = 521,
  'form:agreement:create+update' = 522,
  'form:agreement:delete' = 523,
  'form:agreement:record:list' = 531,
  'form:agreement:record:delete' = 532,
  'form:intake:list' = 551,
  'form:intake:create+update' = 552,
  'form:intake:delete' = 553,
  'form:intake:record:list' = 561,
  'form:intake:record:delete' = 562,
  'form:checkin:list' = 571,
  'form:checkin:create+update' = 572,
  'form:checkin:delete' = 573,
  'form:checkin:record:list' = 581,
  'form:checkin:record:delete' = 582,

  // Branding
  'branding:page:list' = 601,
  'branding:page:create+update' = 602,
  'branding:page:delete' = 603,

  // Message
  'message:access' = 701,
  'message:automation:manage' = 702,

  // Schedule
  'schedule:create+update' = 802,
  'schedule:reschedule' = 803,
  'schedule:cancel+delete' = 804,
  'schedule:reminder' = 805,

  // Business
  'business:info:update' = 901,
  'business:config:update' = 902,
  'business:tag:create+update' = 903,
  'business:tag:delete' = 904,
  'business:metric:create+update' = 905,
  'business:metric:delete' = 906,
  'business:subscription' = 907,
}

export type PermissionType = keyof typeof Permission;

export const AllPermissionTypes: PermissionType[] = Object.values(Permission).filter(
  (i) => typeof i === 'string',
) as PermissionType[];

export const AllPermissions: Permission[] = Object.values(Permission).filter(
  (i) => typeof i === 'number',
) as Permission[];
