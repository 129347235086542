import { useUpdatedRef } from '@cocast/hooks/useUpdatedRef';
import { useCallback, useState } from 'react';

export function useArray<T>(initValue: T[] = []) {
  const [array, setArray] = useState(initValue);

  const push = useCallback((...values: T[]) => {
    setArray((current) => [...current, ...values]);
  }, []);

  const remove = useCallback((item: T) => {
    setArray((current) => current.filter((i) => i !== item));
  }, []);

  const removeByIndex = useCallback((index: number) => {
    setArray((current) => {
      current.splice(index, 1);
      return [...current];
    });
  }, []);

  const removeBy = useCallback((v: (i: T) => boolean) => {
    setArray((current) => current.filter((i) => !v(i)));
  }, []);

  const pop = useCallback(() => {
    setArray((current) => {
      current.pop();
      return [...current];
    });
  }, []);

  const setBy = useCallback((v: (i: T) => boolean, setter: T | ((v: T) => T)) => {
    setArray((current) => {
      const index = current.findIndex(v);
      if (index === -1) {
        return current;
      }
      const value = typeof setter === 'function' ? (setter as Function)(current[index]) : setter;
      current[index] = value;
      return [...current];
    });
  }, []);

  const set = useCallback((index: number, setter: T | ((v: T) => T)) => {
    setArray((current) => {
      const value = typeof setter === 'function' ? (setter as Function)(current[index]) : setter;
      current[index] = value;
      return [...current];
    });
  }, []);

  const clear = useCallback(() => {
    setArray([]);
  }, []);

  const takeLast = useCallback(() => ref.current[ref.current.length - 1], []);

  const ref = useUpdatedRef<T[]>(array);

  return {
    value: array,
    setValue: setArray,
    clear,
    push,
    pop,
    remove,
    removeByIndex,
    setBy,
    set,
    removeBy,
    takeLast,
    ref,
  };
}

export type UseArrayHook<T> = ReturnType<typeof useArray<T>>;
