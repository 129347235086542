export function getImageDeliverUrl(id: string, variant: string | { w?: number; h?: number } = 'public') {
  const variantName =
    typeof variant === 'string'
      ? variant
      : Object.entries(variant)
          .map(([k, v]) => `${k}=${v}`)
          .join('&');
  return `https://imagedelivery.net/Zw2NKlw6vpAEx4T5z1A_JQ/${id}/${variantName}`;
}

export function getStreamDeliverCover(id: string) {
  return `https://customer-ky03j7dlb6oakc6h.cloudflarestream.com/${id}/thumbnails/thumbnail.jpg`;
}

export function getStreamDeliverPlayerLink(id: string) {
  return `https://customer-ky03j7dlb6oakc6h.cloudflarestream.com/${id}/watch`;
}
