import { BusinessWorkoutVo } from '@cocast/api-client';
import { useLoadable } from '@cocast/mobx';
import { SkeletonGroup } from 'legacy/Skeleton';
import { observer } from 'mobx-react-lite';
import { businessStore } from 'state';
import { Sheet, SheetProps, useSheet } from 'widgets';
import { ExerciseDetailSheet, useExerciseDetail } from '../../Exercise/ExerciseDetail';
import { WorkoutDetailPreview } from './WorkoutDetailPreview';

interface Props extends Pick<SheetProps, 'open' | 'setOpen'> {
  workout: BusinessWorkoutVo;
  exerciseDetail?: ExerciseDetailSheet;
}

function WorkoutDetailComponent({ open, setOpen, workout, exerciseDetail: exerciseDetailSheet }: Props) {
  const { exercises } = businessStore;
  useLoadable(businessStore.businessExercises);

  const exerciseDetail = exerciseDetailSheet || useExerciseDetail();

  return (
    <Sheet open={open} setOpen={setOpen} onConfirm>
      {exercises?.length ? (
        <WorkoutDetailPreview workout={workout} exerciseDetail={exerciseDetail} />
      ) : (
        <>
          <SkeletonGroup
            className="pt-md"
            itemClassName="!bg-dark-200"
            rows={[
              ['50%', '42px'],
              ['40%', '24px'],
              ['70%', '18px'],
            ]}
            space="12px"
          />
          <SkeletonGroup
            className="mt-xl flex-start-center space-x-md"
            itemClassName="!bg-dark-200"
            rows={4}
            width="80px"
            height="44px"
            space="0"
          />
          <SkeletonGroup
            className="mt-xl space-x-xl"
            itemClassName="!bg-dark-200"
            rows={[
              ['30%', '28px'],
              ['55%', '32px'],
              ['72%', '32px'],
            ]}
          />
          <SkeletonGroup
            className="mt-md space-x-xl"
            itemClassName="!bg-dark-200"
            rows={[
              ['30%', '28px'],
              ['55%', '32px'],
              ['72%', '32px'],
            ]}
          />
        </>
      )}
    </Sheet>
  );
}

const WorkoutDetail = observer(WorkoutDetailComponent);

export function useWorkoutDetail() {
  return useSheet(WorkoutDetail);
}

export type WorkoutDetailSheet = ReturnType<typeof useWorkoutDetail>;
