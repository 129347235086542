import { AddressStruct, DurationStruct } from '@cocast/api-client';
import { DurationType, UnitType, unitSymbolMap } from '@cocast/types';
import { omitMap } from '@cocast/utils';

const metricValueFormatterMap = new Map<UnitType, (value: number) => string>([
  [
    UnitType.Time,
    (v: number) => {
      const seconds = Math.abs(v);
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
      const t = hours
        ? `${hours}h ${minutes}m ${remainingSeconds}s`
        : minutes
          ? `${minutes}m ${remainingSeconds}s`
          : `${remainingSeconds}s`;
      return v < 0 ? `- ${t}` : t;
    },
  ],
]);

export function formatMetricValue(value: number, valueType?: UnitType | string): string {
  if (!valueType) {
    return value.toString();
  }
  const formatter = metricValueFormatterMap.get(valueType as UnitType);
  if (formatter) {
    return formatter(value);
  }
  const unit = unitSymbolMap.get(valueType as UnitType);
  return `${value} ${unit || valueType}`;
}

export function formatAddress(address: Partial<AddressStruct>, omit?: (keyof AddressStruct)[]): string {
  const { city, country, countryCode, unit, home, state, street, streetNumber, zipcode } = omit?.length
    ? (omitMap(address, omit) as Partial<AddressStruct>)
    : address;
  return [unit, home, streetNumber ? streetNumber + ' ' + street : street, city, state, countryCode || country, zipcode]
    .filter((i) => !!i)
    .join(', ');
}

export function formatDuration(duration: DurationStruct): string;
export function formatDuration(duration: number, durationType: DurationType): string;
export function formatDuration(duration: number | DurationStruct, durationType?: DurationType): string {
  const { year, month, week, day, hour, minute, second } =
    typeof duration === 'number' ? ({ [durationType]: duration } as DurationStruct) : duration;
  const y = year ? `${year} year${year > 1 ? 's' : ''}` : '';
  const m = month ? `${month} month${month > 1 ? 's' : ''}` : '';
  const w = week ? `${week} week${week > 1 ? 's' : ''}` : '';
  const d = day ? `${day} day${day > 1 ? 's' : ''}` : '';
  const h = hour ? `${hour} hour${hour > 1 ? 's' : ''}` : '';
  const min = minute ? `${minute} min${minute > 1 ? 's' : ''}` : '';
  const s = second ? `${second} second${second > 1 ? 's' : ''}` : '';
  return [y, m, w, d, h, min, s].filter((i) => !!i).join(', ');
}

export function formatEventDuration(duration: number, short = true) {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;

  return `${hours ? `${hours}${short ? 'h' : ` hour${hours > 1 ? 's' : ''}`}` : ''}${
    minutes ? ` ${minutes}${short ? 'min' : ' minute'}${minutes > 1 ? 's' : ''}` : ''
  }`;
}

const weekAlias = ['First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth'];

export function formatProgramWeek(currentDay: number) {
  return typeof currentDay === 'number' ? `${weekAlias[Math.floor(currentDay / 7)]} week` : null;
}

export function formatVariantContent(html: string): string {
  return html.replaceAll(/<variant kind="(.*?)"><\/variant>/g, '<span class="node-variant">$1</span>');
}
