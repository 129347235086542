import { Browser } from '@capacitor/browser';
import { api } from '@cocast/api-client';
import { useStateRef } from '@cocast/hooks/useStateRef';
import { Icons } from '@cocast/icons';
import { TransitionRouterComponentConnectProps } from '@cocast/router';
import { toast } from '@cocast/ui/toast';
import { validateEmail } from '@cocast/utils';
import { default as LongArrowRight } from 'assets/vectors/long-arrow-right.svg';
import { Button } from 'legacy/Button';
import { Image } from 'legacy/Image';
import { Input } from 'legacy/Input';
import { navigation } from 'libs';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { accountStartRoute } from 'routes';
import { uiAction } from 'state';
import { storage } from 'utils';
import { Login } from './Login';
import { Signup } from './Signup';
import { accountState } from './state';
import { afterLogin } from './utils';

function AccountStartComponent({
  route: {
    query: { preview },
  },
}: TransitionRouterComponentConnectProps<typeof accountStartRoute>) {
  const [email, setEmail, emailRef] = useStateRef(accountState.email || '');

  const onContinue = useCallback(async () => {
    const [error, result] = await api.ClientAccount.check({ email: emailRef.current });
    if (error) {
      return;
    }
    await storage.remove('isLiveDemo');
    uiAction.setLiveDemo(false);
    accountState.setState({ email: emailRef.current });
    if (result.canSignUp) {
      navigation().push(() => <Signup />);
    } else if (result.canLogin) {
      navigation().push(() => <Login />);
    } else {
      toast.error('You are not invited to signup');
    }
  }, []);

  const onLiveDemoContinue = useCallback(async () => {
    const [error, info] = await api.ClientAccount.login({
      email: 'michelle@cocast.fit',
      password: '1234',
    });
    if (error) {
      return;
    }
    await storage.set('isLiveDemo', true);
    uiAction.setLiveDemo(true);
    await afterLogin(info);
  }, []);

  const disabled = !!validateEmail(email);
  return (
    <div className="h-screen flex-center flex-col mx-auto max-w-[var(--max-content-width)]">
      <div className="flex-start-center space-x-xs absolute right-md top-[calc(var(--ion-safe-area-top,0)+12px)]">
        <span className="text-caption">Powered by</span>
        <Image id="6eafc94e-ce38-4a4e-5a02-ca3564a6b200" className="w-[74px]" variant={{ w: 200 }} />
      </div>

      <div className="w-full flex justify-end flex-col p-lg h-[50%]">
        <div className="text-hero-4 font-black">
          <p className="relative text-black">
            <span className="block absolute top-[-2px] left-[-12px] bg-lemon w-[150px] h-full" />
            <span className="relative">Forge</span>
          </p>
          <p>Your</p>
          <p>Fitness Future</p>
        </div>
        <div className="flex-end-center mt-md">
          <div className="px-md flex-center bg-base h-[52px] w-[92px] rounded-full">
            <img className="h-[30px] w-[120px]" src={LongArrowRight} alt="LongArrowRight" />
          </div>
        </div>
      </div>

      <div className="w-full h-[50%] px-lg pt-xl pb-[max(var(--ion-safe-area-bottom),24px)] bg-dark flex-between-center flex-col child:w-full">
        <div>
          {preview ? (
            <>
              <h4>Continue with demo account ?</h4>
              <p className="mt-sm text-b2 flex-start-center text-content-100 space-x-xxs">
                <Icons.Alert size={3.5} />
                <span>Your data will not be saved</span>
              </p>
            </>
          ) : (
            <>
              <p className="text-b1">Your Personal Training Hub</p>
              <Input
                className="dark mt-sm"
                prefix={<Icons.Mail size={4} />}
                placeholder="Your email address"
                inputClassName="!text-b1 font-normal"
                type="email"
                onValueChange={setEmail}
                defaultValue={email}
                autoComplete="email"
              />
            </>
          )}
        </div>
        <div>
          <Button
            className="btn w-full lg lemon solid !font-black"
            disabled={!preview && disabled}
            onClick={preview ? onLiveDemoContinue : onContinue}
          >
            Continue
          </Button>
          <p className="text-caption-light mt-lg text-center text-content-100">
            By continuing you agree to our{' '}
            <span
              className="underline"
              onClick={() =>
                Browser.open({ url: 'https://www.cocast.fit/terms_of_use.html', presentationStyle: 'popover' })
              }
            >
              terms
            </span>{' '}
            and{' '}
            <span
              className="underline"
              onClick={() =>
                Browser.open({ url: 'https://www.cocast.fit/privacy_policy.html', presentationStyle: 'popover' })
              }
            >
              policies
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export const Start = observer(AccountStartComponent);
