import { createEntryNode, createRenderRoot } from '@cocast/utils-web/node';
import { useCallback, useEffect, useMemo, useRef } from 'react';

export function useEntryNode(target?: HTMLElement, initial?: (entry: HTMLDivElement) => void) {
  const isServer = typeof window === 'undefined';
  const { entry, remove } = useMemo(
    () => (isServer ? { entry: null, remove: null } : createEntryNode(target, initial)),
    [isServer],
  );

  useEffect(
    () => () => {
      remove?.();
    },
    [remove],
  );

  return entry;
}

export function useLazyEntryNode(
  target?: HTMLElement | (() => HTMLElement),
  initial?: (entry: HTMLDivElement) => void,
): () => HTMLDivElement {
  const ref = useRef<ReturnType<typeof createEntryNode>>();

  const createEntry = useCallback(() => {
    ref.current = createEntryNode(typeof target === 'function' ? target() : target, initial);
    return ref.current.entry;
  }, []);

  useEffect(
    () => () => {
      ref.current?.remove?.();
    },
    [],
  );

  return useCallback(() => ref.current?.entry || createEntry(), []);
}

export function useRenderRoot(target?: HTMLElement, initial?: (entry: HTMLDivElement) => void) {
  const { root, remove } = useMemo(() => createRenderRoot(target, initial), []);

  useEffect(
    () => () => {
      remove();
    },
    [],
  );

  return root;
}
