import { BusinessClientOverviewTrainingVo } from '@cocast/api-client';
import { memo } from 'react';
import { ProgressCircle } from 'widgets';

interface Props {
  training: BusinessClientOverviewTrainingVo;
}

function TrainingProgramDetailProgressComponent({
  training: { programTotalCompliance, programThisWeekCompliance, programLastWeekCompliance },
}: Props) {
  return (
    <div className="my-xl flex-between-center space-x-md">
      <ProgressCircle
        className="h-full w-[94px]"
        progress={programTotalCompliance}
        color="lemon"
        trailColor="black"
        blur="none"
        delay={600}
        animate
      >
        <div className="absolute top-[calc(50%-22px)] left-[calc(50%-20px)] text-center w-[40px] text-center">
          <p className="text-b1 text-lemon">{programTotalCompliance || 0}%</p>
          <p className="text-caption-light">Total</p>
        </div>
      </ProgressCircle>
      <ProgressCircle
        className="h-full w-[94px]"
        progress={programThisWeekCompliance}
        color="lemon"
        trailColor="black"
        blur="none"
        delay={750}
        animate
      >
        <div className="absolute top-[calc(50%-22px)] left-[calc(50%-30px)] text-center w-[60px] text-center">
          <p className="text-b1 text-lemon">{programThisWeekCompliance || 0}%</p>
          <p className="text-caption-light">This week</p>
        </div>
      </ProgressCircle>
      <ProgressCircle
        className="h-full w-[94px]"
        progress={programLastWeekCompliance}
        color="lemon"
        trailColor="black"
        blur="none"
        delay={900}
        animate
      >
        <div className="absolute top-[calc(50%-22px)] left-[calc(50%-30px)] text-center w-[60px] text-center">
          <p className="text-b1 text-lemon">{programLastWeekCompliance || 0}%</p>
          <p className="text-caption-light">Last week</p>
        </div>
      </ProgressCircle>
    </div>
  );
}

export const TrainingProgramDetailProgress = memo(TrainingProgramDetailProgressComponent);
