import { api } from '@cocast/api-client';
import { useStateRef } from '@cocast/hooks/useStateRef';
import { Icons } from '@cocast/icons';
import { toast } from '@cocast/ui/toast';
import { validateEmail } from '@cocast/utils';
import { Input } from 'legacy/Input/Input';
import { Password } from 'legacy/Input/Password';
import { navigation } from 'libs';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { userRoute } from 'routes';
import { accountStore, uiStore } from 'state';
import { Page, ToolbarButton } from 'widgets';

function UserEmailComponent() {
  const [email, setEmail, emailRef] = useStateRef(accountStore.info.email);
  const [password, setPassword, passwordRef] = useStateRef('');

  const onSave = useCallback(async () => {
    if (!uiStore.liveDemo) {
      const [error] = await api.ClientAccount.updateEmail({ email: emailRef.current, password: passwordRef.current });
      if (error) {
        return;
      }
    }
    toast.success('Email updated');
    navigation().pop();
  }, []);

  const disabled = !!validateEmail(email) || password.length < 4 || email === accountStore.info.email;

  return (
    <Page
      title="Change Email"
      onBack="back"
      backDefaultHref={userRoute.path}
      footer={<ToolbarButton text="Save" disabled={disabled} handler={onSave} />}
    >
      <Input
        className="dark mt-xxl"
        prefix={<Icons.Mail size={4} />}
        label="New Email"
        inputClassName="!text-b1 font-normal"
        type="email"
        onValueChange={setEmail}
        defaultValue={email}
        autoComplete="email"
      />
      <Password label="Password" className="dark mt-xl" onValueChange={setPassword} />
    </Page>
  );
}

export const UserEmail = observer(UserEmailComponent);
