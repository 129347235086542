import { ForwardedRef, MutableRefObject, useEffect, useRef } from 'react';

export function useForwardRef<T>(ref: ForwardedRef<T>, initialValue?: T): MutableRefObject<T> {
  if (ref && typeof ref === 'object') {
    return ref;
  }

  const targetRef = useRef<T>(initialValue);
  if (!ref) {
    return targetRef;
  }

  const proxyRef = useRef<T>(initialValue);
  // ref is function
  useEffect(() => {
    Object.defineProperty(proxyRef, 'current', {
      get() {
        return targetRef.current;
      },
      set(v: T) {
        targetRef.current = v;
        (ref as Function)(v);
      },
    });
  }, [ref]);

  return proxyRef;
}
