import { DefaultTransitions, Transition } from '@cocast/components/Transition';
import { useDidMounted } from '@cocast/hooks/useDidMounted';
import { cn } from '@cocast/utils-web/misc';
import { createElement, memo, useMemo, useRef } from 'react';
import { RouteErrorBoundary, useRouteContext } from '../context';
import { Route } from '../route';
import {
  RouterConnectConnectProps,
  TransitionRouteComponentProps,
  TransitionRouteProps,
  TransitionRouterComponentConnectProps,
} from '../types';
import {
  AfterTransitionLeaveCallbacks,
  BeforeTransitionLeaveCallbacks,
  useTransitionRouteCallbacks,
} from './callbacks';

function TransitionRouterComponent<R extends Route<any, any>>({
  route,
  component,
  asFragment,
  transition: transitionProp,
  children,
  keepOnExit,
  disableAppear,
  className,
}: TransitionRouteProps<R, unknown>) {
  const { current: mounted } = useDidMounted();
  const { routes } = useRouteContext();
  if (!mounted) {
    routes.add(route);
  }

  const show = route.useMatch();
  const lastShow = useRef(show);
  const isExit = useMemo(() => {
    const isExit = lastShow.current && !show;
    lastShow.current = show;
    return isExit;
  }, [show]);
  const props: TransitionRouteComponentProps = { show };

  const beforeLeave = useTransitionRouteCallbacks(route, BeforeTransitionLeaveCallbacks);
  const afterLeave = useTransitionRouteCallbacks(route, AfterTransitionLeaveCallbacks);

  const transition = disableAppear && !mounted ? DefaultTransitions.transitionNone : transitionProp;

  return (
    <Transition
      asFragment={asFragment}
      name={route.path}
      show={(keepOnExit && isExit) || show}
      beforeLeave={beforeLeave}
      afterLeave={afterLeave}
      transition={transition}
      className={cn('h-screen overflow-auto', className)}
    >
      {<RouteErrorBoundary>{createElement(component, props as any, children)}</RouteErrorBoundary>}
    </Transition>
  );
}

function TransitionRouterConnectComponent<R extends Route<any, any>>({
  route,
  component,
  asFragment,
  transition: transitionProp,
  children,
  keepOnExit,
  disableAppear,
  className,
}: RouterConnectConnectProps<R>) {
  const { current: mounted } = useDidMounted();
  const { routes } = useRouteContext();
  if (!mounted) {
    routes.add(route);
  }

  const r = route.useRoute();
  const matched = useRef<typeof r>();
  if (r.match) {
    matched.current = r;
  } else if (matched.current) {
    matched.current.match = false;
  }

  const show = r.match;
  const lastShow = useRef(show);
  const isExit = useMemo(() => {
    const isExit = lastShow.current && !show;
    lastShow.current = show;
    return isExit;
  }, [show]);
  const props = { show: r.match, route: matched.current } as TransitionRouterComponentConnectProps<R>;

  const beforeLeave = useTransitionRouteCallbacks(route, BeforeTransitionLeaveCallbacks);
  const afterLeave = useTransitionRouteCallbacks(route, AfterTransitionLeaveCallbacks);

  const transition = disableAppear && !mounted ? DefaultTransitions.transitionNone : transitionProp;

  return (
    <Transition
      asFragment={asFragment}
      name={route.path}
      show={(keepOnExit && isExit) || show}
      beforeLeave={beforeLeave}
      afterLeave={afterLeave}
      transition={transition}
      className={cn('h-screen overflow-auto', className)}
    >
      <RouteErrorBoundary>{createElement(component, props, children)}</RouteErrorBoundary>
    </Transition>
  );
}

const TransitionRouterConnect = memo(TransitionRouterConnectComponent);

const TransitionRouterWithoutConnect = memo(TransitionRouterComponent);

export const TransitionRouter = TransitionRouterWithoutConnect as typeof TransitionRouterWithoutConnect & {
  Connect: typeof TransitionRouterConnect;
};

TransitionRouter.Connect = TransitionRouterConnect;

export * from './callbacks';
