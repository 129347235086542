import { Country } from '@cocast/types';
import { PhoneInput, PhoneInputRef } from 'components';
import { navigation } from 'libs';
import { observer } from 'mobx-react-lite';
import { useCallback, useRef } from 'react';
import { userRoute } from 'routes';
import { accountAction, accountStore, businessStore } from 'state';
import { Page, ToolbarButton } from 'widgets';

function UserPhoneComponent() {
  const { phoneNumber, phoneCountry } = accountStore.info;

  const input = useRef<PhoneInputRef>();
  const onSave = useCallback(async () => {
    const { phoneNumber, phoneCountry } = input.current.value;
    if (phoneCountry === accountStore.info.phoneCountry && phoneNumber === accountStore.info.phoneNumber) {
      navigation().pop();
      return;
    }
    return accountAction.update({ phoneNumber, phoneCountry }, () => navigation().pop());
  }, []);

  return (
    <Page
      title="Phone"
      onBack="back"
      backDefaultHref={userRoute.path}
      footer={<ToolbarButton text="Save" handler={onSave} />}
    >
      <PhoneInput
        className="mt-xxl"
        defaultValue={{
          phoneNumber,
          phoneCountry: (phoneCountry || businessStore.info.country) as Country,
        }}
        input={input}
      />
    </Page>
  );
}

export const UserPhone = observer(UserPhoneComponent);
