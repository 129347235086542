import {
  BusinessExerciseVo,
  WorkoutFields,
  WorkoutRecord,
  WorkoutRecordValue,
  WorkoutSupersetRecord,
} from '@cocast/api-client';
import { Exercise, unitSymbolMap, UnitType, WorkoutRecordType } from '@cocast/types';
import { default as classNames } from 'classnames';
import { memo } from 'react';

interface Props {
  record: WorkoutRecord | WorkoutSupersetRecord;
  index: number;
  superset: boolean;
  exercises: (Exercise | BusinessExerciseVo)[];
  fields: WorkoutFields[];
  valueClassName?: string;
  supersetValueClassName?: string;
  onClickExercise?: (exercise: Exercise | BusinessExerciseVo) => unknown;
}

function WorkoutPreviewRecordComponent({
  record,
  superset,
  index,
  exercises,
  fields,
  valueClassName,
  supersetValueClassName,
  onClickExercise,
}: Props) {
  let content;
  if (superset) {
    const { values, type } = record as WorkoutSupersetRecord;
    content = (
      <div className="flex space-x-md mt-xs">
        <RecordIndex index={index} type={type as WorkoutRecordType} />
        <div className="space-y-xxs">
          {exercises.map((exercise, index) => (
            <div key={index} className="md:space-x-xl md:flex-between-center">
              <p
                className="text-b2 font-semibold capitalize cursor-pointer"
                onClick={onClickExercise ? () => onClickExercise(exercise) : undefined}
              >
                {exercise.name}
              </p>
              <RecordValue
                value={values[index]}
                fields={fields[index]}
                className={classNames(valueClassName, supersetValueClassName)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    const { value, type, dropSetValues } = record as WorkoutRecord;
    content = (
      <div className="flex space-x-md mt-xs">
        <RecordIndex index={index} type={type as WorkoutRecordType} />
        {dropSetValues?.length ? (
          <div className="space-y-xxs">
            <RecordValue value={value} fields={fields[0]} className={valueClassName} />
            {dropSetValues.map((value, index) => (
              <div key={index} className="flex space-x-md relative left-[-48px]">
                <RecordIndex index={index + 1} type={WorkoutRecordType.Drop} />
                <RecordValue key={index} value={value} fields={fields[0]} className={valueClassName} />
              </div>
            ))}
          </div>
        ) : (
          <RecordValue value={value} fields={fields[0]} className={valueClassName} />
        )}
      </div>
    );
  }
  return <div key={record.id}>{content}</div>;
}

export const WorkoutPreviewRecord = memo(WorkoutPreviewRecordComponent);

interface ValueProps {
  value: WorkoutRecordValue;
  fields: WorkoutFields;
  className?: string;
}

function RecordValueComponent({ value, fields, className }: ValueProps) {
  const { weight, timePerRound, time, reps } = fields;
  const values = [];
  if (time) {
    values.push(`${value.time || '_'}s`);
  } else {
    if (weight) {
      values.push(`${value.weight || '_'} ${unitSymbolMap.get(weight as UnitType) || weight}`);
    }
    if (timePerRound) {
      values.push(`${value.timePerRound || '_'}s/round`);
    }
    if (reps) {
      values.push(`${value.reps || '_'} reps`);
    }
  }

  return <div className={className}>{values.join(' x ')}</div>;
}

const RecordValue = memo(RecordValueComponent);

interface IndexProps {
  index: number;
  type: WorkoutRecordType;
  dropset?: boolean;
}

const indexClassNames = 'w-[32px] h-[22px] text-caption rounded-lg border-thin flex-center flex-center';

function RecordIndexComponent({ index, type, dropset }: IndexProps) {
  const isWarmup = type === WorkoutRecordType.Warmup;
  const isDrop = type === WorkoutRecordType.Drop;

  return isWarmup ? (
    <div className={classNames(indexClassNames, 'bg-orange-100 border-orange text-orange')}>W</div>
  ) : isDrop ? (
    <div
      className={classNames(
        indexClassNames,
        'bg-cobalt-100 border-cobalt text-cobalt',
        index > 0
          ? "before:content-[''] before:w-[1px] before:h-[10px] before:bg-cobalt before:opacity-30 before:absolute before:left-[16px] before:top-[-10px]"
          : null,
      )}
    >
      D{index + 1}
    </div>
  ) : (
    <div className={classNames(indexClassNames, 'bg-base-300 border-light text-content')}>
      <span>{index + 1}</span>
    </div>
  );
}

export const RecordIndex = memo(RecordIndexComponent);
