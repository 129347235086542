import { useEffect } from 'react';
import { useUpdatedRef } from './useUpdatedRef';

export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useUpdatedRef(callback);

  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }
    const timer = setInterval(() => savedCallback.current(), delay);
    return () => clearInterval(timer);
  }, [delay]);
}

export function useTimeout(callback: () => void, delay: number, deps: any[] = []) {
  const savedCallback = useUpdatedRef(callback);

  useEffect(() => {
    const timer = setTimeout(() => savedCallback.current(), delay);
    return () => {
      clearTimeout(timer);
    };
  }, [delay, ...deps]);
}
