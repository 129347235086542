export function richtextStyle(scope?: string, style?: string) {
  const prefix = scope ? `${scope} ` : '';

  const globalStyle = scope
    ? ''
    : `body, :host {
  padding: 12px;
  font-family: 'Inter', 'Roboto', sans-serif;
  background-color: #f9fafb;
  font-size: 13px;
}`;

  return `${globalStyle}
  
.editor > div {
  width: 100%;
  min-height: 100%;
  outline: none;
}

${prefix}blockquote {
  border-left: 2px solid #ddd;
  color: #aaa;
  font-style: italic;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
}

${prefix}:not(pre) > code {
  font-family: monospace;
  padding: 3px;
}

${prefix}:not(pre) > code,
pre {
  background-color: #eee;
}

${prefix}code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

${prefix}p {
  line-height: 1.5em;
}

${prefix}p:empty {
  height: 1.5em;
}

${prefix}code,
${prefix}kbd,
${prefix}pre,
${prefix}samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

${prefix}hr {
  border-top: 1px solid #e8e8e8;
}

${prefix}img {
  max-width: 100%;
}

${prefix}em {
  text-decoration: inherit;
}

${style || ''}
`;
}
