import { SplashScreen } from '@capacitor/splash-screen';
import { setSuffix } from '@cocast/hooks/useDocumentTitle';
import { useEntryNode } from '@cocast/hooks/useEntryNode';
import { Router, RouterProvider, TransitionRouter } from '@cocast/router';
import { Toaster } from '@cocast/ui/toast';
import { IonApp } from '@ionic/react';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { autoUpdate, setStatusBarColor } from './libs';
import { Account, Dashboard, Redirect, RootPage, User } from './pages';
import { accountRoute, homeRoute, notFoundRoute, rootRoute, userRoute } from './routes';
import { accountStore, businessStore } from './state';
import { NotFound, SheetRegister, Tabs } from './widgets';

function MainComponent() {
  const { info: business } = businessStore;
  useEffect(() => {
    if (business) {
      setSuffix(business.name, true);
    }
  }, [business]);

  return (
    <>
      <RouterProvider fallback={Redirect}>
        <Router route={rootRoute} component={RootPage} />
        <TransitionRouter route={homeRoute} component={Dashboard} />
        <TransitionRouter route={userRoute} component={User} />
        <TransitionRouter route={notFoundRoute} component={NotFound} />
      </RouterProvider>
      <Tabs />
    </>
  );
}

const Main = observer(MainComponent);

function AppComponent() {
  const { didLogin } = accountStore;

  useEffect(() => {
    setTimeout(async () => {
      await SplashScreen.hide();
      await setStatusBarColor();
      await autoUpdate();
    }, 300);
  }, []);

  const entry = useEntryNode();
  return (
    <>
      <IonApp>{accountRoute.useMatch() || !didLogin ? <Account /> : <Main />}</IonApp>
      {createPortal(
        <>
          <Toaster position="bottom-center" theme="dark" offset={52} />
          <SheetRegister />
        </>,
        entry,
      )}
    </>
  );
}

export const App = observer(AppComponent);
