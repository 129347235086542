import { SetStateAction, useCallback, useRef, useState } from 'react';

export type SetWithDelay<T> = (value: SetStateAction<T>, setDelay?: number, callback?: () => unknown) => void;

export function useDelayState<T>(initialValue: T, delay: number = 200): [T, SetWithDelay<T>] {
  const [value, setValue] = useState(initialValue);

  const timer = useRef<number>();
  const callbackRef = useRef<() => unknown>();
  const setValueWithDelay = useCallback(
    (value: SetStateAction<T>, setDelay?: number, callback?: () => unknown) => {
      if (timer.current) {
        window.clearTimeout(timer.current);
        timer.current = null;
        callbackRef.current = null;
      }
      const d = typeof setDelay === 'number' ? setDelay : delay;
      if (d === 0) {
        setValue(value);
        return;
      }
      timer.current = window.setTimeout(() => {
        timer.current = null;
        callbackRef.current?.();
        callbackRef.current = null;
        setValue(value);
      }, d);
      callbackRef.current = callback;
    },
    [delay],
  );

  return [value, setValueWithDelay];
}
