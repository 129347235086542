import { BusinessExerciseVo } from '@cocast/api-client';
import { IconComponent, Icons } from '@cocast/icons';
import { Exercise } from '@cocast/types';
import { getImageDeliverUrl, getStreamDeliverCover } from '@cocast/utils';
import { cn } from '@cocast/utils-web/misc';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

export interface ExerciseThumbnailProps {
  exercise?: Exercise | BusinessExerciseVo;
  size?: number;
  iconSize?: number;
  fallback?: IconComponent;
  className?: string;
  iconClassName?: string;
  imgSize?: keyof typeof variantsMap;
  onPreview?: () => unknown;
}

const variantsMap = {
  sm: 'w=180',
  md: 'w=360',
  lg: 'w=720',
  xl: 'w=1080',
} as const;

export const ExerciseThumbnail = observer(
  ({
    exercise,
    className: classNameProp,
    size,
    fallback = Icons.DumbBell,
    iconSize,
    iconClassName,
    imgSize = 'sm',
    onPreview,
  }: ExerciseThumbnailProps) => {
    const { src, icon: Icon } = useMemo(() => {
      if (!exercise) {
        return { icon: fallback };
      }
      const { images, imageId, video, videoType } = exercise;
      if (video && !videoType) {
        return { src: getStreamDeliverCover(video) };
      }
      const src = images?.[0] || imageId;
      if (src) {
        return { src: getImageDeliverUrl(src, variantsMap[imgSize]) };
      }
      if (video && videoType === 'youtube') {
        return { icon: Icons.Youtube };
      }
      return { icon: fallback };
    }, [exercise, imgSize]);

    const style = {
      width: size ? `${size / 4}rem` : undefined,
      height: size ? `${size / 4}rem` : undefined,
      background: 'linear-gradient(180deg, rgba(248, 248, 248, 0.8) -10.29%, rgba(240, 240, 240, 0.8) 100%)',
    };
    const className = cn(
      'flex-center rounded-md object-cover bg-grey-50 overflow-hidden aspect-square',
      onPreview ? 'cursor-pointer' : null,
      classNameProp,
    );
    return src ? (
      <img src={src} alt={exercise.name} className={className} style={style} onClick={onPreview} />
    ) : (
      <div
        className={cn(className, 'border rounded-full text-muted-foreground', iconClassName)}
        style={style}
        onClick={onPreview}
      >
        <Icon size={iconSize || (size ? size * 0.6 : 5)} />
      </div>
    );
  },
);
