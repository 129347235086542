import { DayValue, TimeValue, Timezone } from '@cocast/types';
import { Dayjs, default as dayjs } from 'dayjs';
import { default as timezone } from 'dayjs/plugin/timezone';
import { fillUp } from './format';

dayjs.extend(timezone);

export function getDayValueOfToday(): DayValue {
  const today = dayjs();
  return { year: today.year(), month: today.month() + 1, day: today.date() };
}

export function toDayValue(date: string | Dayjs | Date): DayValue {
  if (!date) {
    return;
  }
  const day = dayjs(date);
  return { year: day.year(), month: day.month() + 1, day: day.date() };
}

export function toTimeValue(date: string | Dayjs | Date, second?: boolean): TimeValue {
  if (!date) {
    return;
  }
  const day = dayjs(date);
  return { hour: day.hour(), minute: day.minute(), second: second ? day.second() : undefined };
}

export function timeToMinutes(time: Partial<TimeValue> | Dayjs) {
  if (typeof time.hour === 'function') {
    return time.hour() * 60 + (time as Dayjs).minute();
  }
  return ((time as TimeValue).hour || 0) * 60 + ((time as TimeValue).minute || 0);
}

export function plusTimeValue(t1: Partial<TimeValue>, t2: Partial<TimeValue>) {
  const minutes = timeToMinutes(t1) + timeToMinutes(t2);
  return { hour: Math.floor(minutes / 60), minute: minutes % 60 };
}

export function dayValueToDayjs({ year, month, day }: DayValue, time?: TimeValue | true, timezone?: Timezone): Dayjs {
  const d = (time ? dayjs().tz(timezone) : dayjs())
    .year(year)
    .month(month - 1)
    .date(day);
  if (!time) {
    return d;
  }
  const { hour, minute, second = 0 } = typeof time === 'boolean' ? { hour: 0, minute: 0, second: 0 } : time;
  return d.hour(hour).minute(minute).second(second).millisecond(0);
}

export function getTimeValueOfNow(second?: boolean): TimeValue {
  const now = dayjs();
  return { hour: now.hour(), minute: now.minute(), second: second ? now.second() : undefined };
}

export function dayValueToTimestampZ({ year, day, month }: DayValue, time?: TimeValue | string) {
  let timeValue: string;
  if (typeof time === 'string') {
    timeValue = time;
  } else {
    const { hour, minute, second = 0 } = time || getTimeValueOfNow(false);
    timeValue = typeof time === 'string' ? time : [hour, minute, second].map((v) => fillUp(v)).join(':');
  }
  return `${year}-${fillUp(month)}-${fillUp(day)}T${timeValue}.000`;
}
