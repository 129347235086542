import { useDelayState } from '@cocast/hooks/useDelayState';
import { default as classNames } from 'classnames';
import { ComponentProps, memo, ReactNode, useEffect, useMemo } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';

interface Props {
  progress: number;
  animate?: boolean;
  className?: string;
  strokeWidth?: number;
  blur?: 'md' | 'sm' | 'none';
  color?: 'lemon' | 'yellow' | 'green';
  trailColor?: 'grey' | 'black';
  children?: ReactNode | ReactNode[];
  delay?: number;
}

const styles: ComponentProps<typeof CircularProgressbar>['styles'] = {
  path: {
    strokeLinecap: 'round',
    transition: 'stroke-dashoffset 0.5s ease-in-out',
  },
  text: {
    dominantBaseline: 'middle',
    textAnchor: 'middle',
  },
};

function ProgressCircleComponent({
  progress: value,
  animate,
  className,
  blur = 'md',
  color = 'lemon',
  trailColor = 'black',
  strokeWidth = 14,
  children,
  delay = 300,
}: Props) {
  const [progress, setProgress] = animate ? useDelayState(0, delay) : [value];

  if (animate) {
    useEffect(() => {
      setProgress(value);
    }, [value]);
  }

  const { blurCircleClassNames, circleClassNames } = useMemo(
    () => ({
      blurCircleClassNames: {
        text: '',
        path: classNames({
          'stroke-lemon': color === 'lemon',
          'stroke-mint': color === 'green',
          'stroke-yellow': color === 'yellow',
        }),
        trail: '',
        background: '',
        root: '',
      },
      circleClassNames: {
        text: classNames('text-t3', {
          'fill-lemon': color === 'lemon',
          'fill-mint': color === 'green',
          'fill-yellow-400': color === 'yellow',
        }),
        path: classNames({
          'stroke-lemon': color === 'lemon',
          'stroke-mint': color === 'green',
          'stroke-yellow': color === 'yellow',
        }),
        trail: classNames({
          'stroke-black-300': trailColor === 'black',
          'stroke-grey': trailColor === 'grey',
        }),
        background: '',
        root: '',
      },
    }),
    [color, trailColor],
  );

  return (
    <div className={classNames(className, 'relative')}>
      {blur === 'none' ? null : (
        <div
          className={classNames(
            'w-full h-full transition duration-300',
            {
              'blur-sm': blur === 'sm',
              blur: blur === 'md',
            },
            progress ? 'opacity-100' : 'opacity-0',
          )}
        >
          <CircularProgressbar
            className="absolute"
            value={progress}
            strokeWidth={strokeWidth}
            classes={blurCircleClassNames}
            styles={styles}
          />
        </div>
      )}
      <CircularProgressbar
        value={progress}
        strokeWidth={strokeWidth}
        text={children ? undefined : `${progress}%`}
        classes={circleClassNames}
        styles={styles}
      />
      {children}
    </div>
  );
}

export const ProgressCircle = memo(ProgressCircleComponent);
