import { default as classNames } from 'classnames';
import { ComponentType, ReactNode } from 'react';
import { set, SheetInstanceProps } from './Register';
import { SheetProps } from './Sheet';

export function show(props: Omit<SheetInstanceProps, 'children'>, children?: ReactNode | ReactNode[]) {
  return set({ ...props, children });
}

export function showCustom<P extends Pick<SheetProps, 'open' | 'setOpen'>>(
  as: ComponentType<P>,
  props: Omit<SheetInstanceProps, 'children'>,
  children?: ReactNode | ReactNode[],
) {
  return set({ ...props, children }, as);
}

export function confirm(
  {
    title,
    titleClassName,
    description,
    descriptionClassName,
    ...props
  }: Omit<SheetInstanceProps, 'children' | 'title'> & {
    title: string;
    description?: string;
    descriptionClassName?: string;
  },
  children?: ReactNode | ReactNode[],
) {
  return set({
    ...props,
    children: (
      <>
        <h2 className={classNames('pt-md', titleClassName)}>{title}</h2>
        {description ? <p className="text-b1 font-normal mt-xs">{description}</p> : null}
        {children}
      </>
    ),
  });
}
