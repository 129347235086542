import { useUpdatedState } from '@cocast/hooks/useUpdatedState';
import { Icons } from '@cocast/icons';
import { preventSyntheticEvent } from '@cocast/utils-web/eventHelper';
import { default as classNames } from 'classnames';
import { ForwardedRef, forwardRef, HTMLAttributes, memo, MouseEvent, ReactNode, useCallback, useEffect } from 'react';

export interface RadioProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  onCheck?: (checked: boolean, e: MouseEvent<HTMLDivElement>) => unknown;
  label?: ReactNode;
  labelClassName?: string;
  description?: string;
  descriptionClassName?: string;
  error?: string;
  errorClassName?: string;
  disabled?: boolean;
  preventSyntheticEvent?: boolean;
}

function RadioComponent(
  {
    className,
    checked: checkedProp,
    defaultChecked,
    onCheck,
    label,
    labelClassName,
    description,
    descriptionClassName,
    error,
    errorClassName,
    disabled,
    preventSyntheticEvent: preventDefault,
    ...props
  }: RadioProps,
  ref?: ForwardedRef<HTMLDivElement>,
) {
  const controlled = typeof checkedProp === 'boolean';
  const [checked, setChecked] = useUpdatedState<boolean>(controlled ? checkedProp : defaultChecked || false);

  const onClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (preventDefault) {
        preventSyntheticEvent(e);
      }
      setChecked((i) => {
        const newValue = !i;
        setTimeout(() => {
          onCheck?.(newValue, e);
          props?.onClick?.(e);
        }, 0);
        return newValue;
      });
    },
    [preventDefault, onCheck],
  );

  useEffect(() => {
    if (controlled) {
      setChecked(checkedProp);
    }
  }, [checkedProp]);

  return (
    <div
      ref={ref}
      className={classNames('radio input-root', { checked: controlled ? checkedProp : checked, disabled }, className)}
      title={typeof label === 'string' ? label : undefined}
      onClick={disabled ? undefined : onClick}
      {...props}
    >
      <div className="check">
        <div />
      </div>

      {label && <div className="radio-label">{label}</div>}

      {!error && description ? (
        <label className={classNames('label desc', descriptionClassName)}>{description}</label>
      ) : null}

      {error ? (
        <label className={classNames('label error', errorClassName)}>
          <Icons.Alert size={4} />
          <span>{error}</span>
        </label>
      ) : null}
    </div>
  );
}

export const Radio = memo(forwardRef(RadioComponent));
