import { default as classNames } from 'classnames';
import { CSSProperties, memo, MouseEvent, ReactNode } from 'react';
import { Clickable } from './Clickable';

interface Props {
  className?: string;
  style?: CSSProperties;
  children: ReactNode | ReactNode[];
  clickable?: boolean;
  onClick?: (e: MouseEvent) => unknown;
}

function CardComponent({ children, style, className, clickable, onClick }: Props) {
  return (
    <Clickable
      disabled={!clickable}
      className={classNames('bg-dark rounded-lg p-lg', className)}
      style={style}
      onClick={onClick}
    >
      {children}
    </Clickable>
  );
}

export const Card = memo(CardComponent);
