import { App } from '@capacitor/app';
import { Loadable, StoreUtils } from '@cocast/mobx';
import { promiseWrapper } from '@cocast/utils';
import { makeObservable, observable } from 'mobx';

export class UIStore extends StoreUtils {
  constructor() {
    super();
    makeObservable(this);
  }

  @observable
  public info = Loadable.fromPromiseWrapper(promiseWrapper(App.getInfo()));

  @observable
  public liveDemo = false;
}

export const uiStore = new UIStore();
