'use client';

import { useLoading } from '@cocast/hooks/useLoading';
import { cn } from '@cocast/utils-web/misc';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { Loader } from 'lucide-react';
import * as React from 'react';
import { createElement } from 'react';
import { isValidElementType } from 'react-is';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-white',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        'destructive-outline': 'border-red-500 text-red-500 bg-red-50 hover:bg-red-500 hover:text-white',
        outline: 'border border-input text-black bg-background hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-opacity-70',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        error: 'bg-red-50 text-red-500 hover:bg-red-100 hover:text-red-600',
        success: 'bg-green-500 text-primary-foreground hover:bg-green-500/90',
        'success-outline': 'border-green-100 text-green-500 bg-green-50 hover:bg-green-500 hover:text-white',
        warning: 'bg-yellow-400 text-black hover:bg-yellow-500/90',
        'warning-outline': 'border-yellow-400 text-yellow-500 bg-yellow-50 hover:bg-yellow-500 hover:text-white',
        white: 'bg-white text-primary hover:bg-muted',
        blue: 'bg-blue-500 text-white hover:bg-blue-600',
        'blue-outline': 'border-blue-500 text-blue-500 bg-blue-50 hover:bg-blue-500 hover:text-white',
        brand: 'bg-brand-500 text-brand-foreground hover:bg-brand-600',
        'brand-outline': 'bg-brand-100 text-brand-500 border-brand-200 hover:bg-brand hover:text-white',
      },
      size: {
        default: 'h-10 px-4 py-2 gap-2 text-sm font-medium',
        xs: 'h-7 px-2 gap-1.5 text-2xs',
        sm: 'h-8 px-3 gap-1.5 text-xs',
        lg: 'h-12 px-8 gap-3 text-md font-medium',
        icon: 'h-10 w-10',
      },
      rounded: {
        default: 'rounded-md',
        none: 'rounded-0',
        sm: 'rounded-sm',
        lg: 'rounded-lg',
        xl: 'rounded-xl',
        full: 'rounded-full',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      rounded: 'default',
    },
  },
);

const buttonIconVariants = cva('grow-0 shrink-0', {
  variants: {
    size: {
      default: 'h-4 w-4',
      xs: 'h-3 w-3',
      sm: 'h-4 w-4',
      lg: 'h-5 w-5',
      icon: 'h-5 w-5',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

export type ButtonIcon = React.ComponentType<any> | React.ForwardRefExoticComponent<any> | React.ReactNode;

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  spinner?: boolean;
  spinning?: boolean;
  icon?: ButtonIcon;
  iconPosition?: 'start' | 'end';
  iconClassName?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      rounded,
      size,
      spinner,
      spinning,
      asChild = false,
      icon,
      iconPosition,
      iconClassName,
      children,
      onClick,
      type = 'button',
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    const { handler, loading: isLoading } = spinner
      ? useLoading((e: React.MouseEvent<HTMLButtonElement>) => (onClick as Function)?.(e), [onClick])
      : { handler: onClick, loading: false };

    const loading = spinning || isLoading;
    const iconContent = loading ? (
      <Loader className={buttonIconVariants({ size, className: ['animate-spin', iconClassName] })} />
    ) : icon ? (
      isValidElementType(icon) ? (
        createElement(icon, { className: buttonIconVariants({ size, className: iconClassName }) })
      ) : (
        icon
      )
    ) : null;

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, rounded }), className)}
        onClick={loading ? undefined : handler}
        type={type}
        ref={ref}
        {...props}
      >
        {iconPosition === 'end' ? (
          <>
            {children}
            {iconContent}
          </>
        ) : (
          <>
            {iconContent}
            {children}
          </>
        )}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
