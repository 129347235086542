import { Icons } from '@cocast/icons';

export function LivePreviewTag() {
  return (
    <section className="flex-center mt-xxl">
      <div className="border-thin bg-black border-lemon text-lemon text-caption font-bold px-md py-xxs rounded-md opacity-60 flex-start-center space-x-xxs">
        <Icons.Alert size={3.5} />
        <span>Live Demo Account</span>
      </div>
    </section>
  );
}
