import { ComponentType, createElement, memo } from 'react';
import { useMatchedRoute } from './useMatchedRoute';

export interface FallbackProps {
  component: ComponentType<{}>;
}

function FallbackComponent({ component }: FallbackProps) {
  const matched = useMatchedRoute();
  return matched ? null : createElement(component, {});
}

export const Fallback = memo(FallbackComponent);
