import { IonNavLink } from '@ionic/react';
import { default as classNames } from 'classnames';
import { ComponentType, createElement, CSSProperties, memo, MouseEvent, ReactNode } from 'react';
import { Card } from 'widgets';

interface Props {
  title: string;
  className?: string;
  style?: CSSProperties;
  children: ReactNode | ReactNode[];
  handlerText?: string;
  handler?: (e: MouseEvent) => unknown;
  clickable?: boolean;
  linkComponent?: ComponentType<{}>;
  onClick?: (e: MouseEvent) => unknown;
}

const linkClassName = 'text-b1 text-lemon clickable pt-3xs';

function ItemComponent({
  title,
  children,
  style,
  className,
  handlerText,
  handler,
  linkComponent,
  clickable,
  onClick,
}: Props) {
  return (
    <>
      <div className="flex-between-center">
        <h1>{title}</h1>
        {handlerText ? (
          linkComponent ? (
            <IonNavLink
              routerDirection="forward"
              component={() => createElement(linkComponent)}
              className={linkClassName}
            >
              {handlerText}
            </IonNavLink>
          ) : (
            <div onClick={handler} className={linkClassName}>
              {handlerText}
            </div>
          )
        ) : null}
      </div>
      <Card
        clickable={clickable}
        style={style}
        className={classNames(className, 'mt-md mb-xl', clickable ? 'cursor-pointer' : null)}
        onClick={onClick}
      >
        {children}
      </Card>
    </>
  );
}

export const Item = memo(ItemComponent);
