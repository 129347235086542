import { MediaPlayer, MediaPlayerProps, MediaProvider } from '@vidstack/react';
import { memo } from 'react';

export interface StreamPlayerProps extends Omit<MediaPlayerProps, 'aspectRatio' | 'children'> {
  stream: string;
  poster?: string;
  description?: string;
  aspectRatio?: number;
  autoplay?: boolean;
  className?: string;
  controls?: boolean;
}

function StreamPlayerComponent({
  stream,
  poster,
  description,
  autoplay,
  className,
  aspectRatio = 16 / 9,
  controls = true,
  ...props
}: StreamPlayerProps) {
  // const player = useRef<MediaPlayerElement>(null);

  return (
    <MediaPlayer
      src={`https://customer-ky03j7dlb6oakc6h.cloudflarestream.com/${stream}/manifest/video.m3u8`}
      poster={poster || `https://customer-ky03j7dlb6oakc6h.cloudflarestream.com/${stream}/thumbnails/thumbnail.jpg`}
      aspectRatio={aspectRatio.toString()}
      autoplay={autoplay}
      className={className}
      controls={controls}
      {...props}
    >
      <MediaProvider />
      {/*<MediaOutlet>*/}
      {/*  {description ? <MediaPoster alt={description} /> : null}*/}
      {/*  <PlayerUI player={player} />*/}
      {/*</MediaOutlet>*/}
    </MediaPlayer>
  );
}

export const StreamPlayer = memo(StreamPlayerComponent);

// interface PlayerUIProps {
//   player: MutableRefObject<MediaPlayerElement>;
// }
//
// function PlayerUIComponent({ player }: PlayerUIProps) {
//   const { paused } = useMediaStore(player);
//   const remote = useMediaRemote();
//
//   return (
//     <div className="absolute top-[calc(50%-52px/2)] left-[calc(50%-52px/2)]">
//       <Icon
//         name={IconType.play}
//         size={52}
//         className={classNames(
//           'bg-primary rounded-full text-base p-sm transition cursor-pointer',
//           paused ? 'opacity-90 hover:opacity-100 hover:scale-110' : 'opacity-0',
//         )}
//         onClick={() => (paused ? remote.play() : remote.pause())}
//       />
//     </div>
//   );
// }
//
// const PlayerUI = memo(PlayerUIComponent);
