/** This file is generated automatically. Do not edit it manually. **/
import type { PromiseResult } from '@cocast/utils';
import { ApiExecOptions, exec } from './libs/exec';
import * as ISchemas from './schema';

class BusinessAccountResolver {
  static changeEmail(
    data: ISchemas.MutationBusinessAccount_ChangeEmailArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessAccount_changeEmail']> {
    return exec('mutation', 'BusinessAccount_changeEmail', data, options) as any;
  }
  static changePassword(
    data: ISchemas.MutationBusinessAccount_ChangePasswordArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessAccount_changePassword']> {
    return exec('mutation', 'BusinessAccount_changePassword', data, options) as any;
  }
  static login(
    data: ISchemas.MutationBusinessAccount_LoginArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessAccount_login']> {
    return exec('mutation', 'BusinessAccount_login', data, options) as any;
  }
  static logout(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Mutation['BusinessAccount_logout']> {
    return exec('mutation', 'BusinessAccount_logout', undefined, options) as any;
  }
  static signup(
    data: ISchemas.MutationBusinessAccount_SignupArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessAccount_signup']> {
    return exec('mutation', 'BusinessAccount_signup', data, options) as any;
  }
  static switch(
    data: ISchemas.MutationBusinessAccount_SwitchArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessAccount_switch']> {
    return exec('mutation', 'BusinessAccount_switch', data, options) as any;
  }
  static unsubscribeEmail(
    data: ISchemas.MutationBusinessAccount_UnsubscribeEmailArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessAccount_unsubscribeEmail']> {
    return exec('mutation', 'BusinessAccount_unsubscribeEmail', data, options) as any;
  }
  static info(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Query['BusinessAccount_info']> {
    return exec('query', 'BusinessAccount_info', undefined, options) as any;
  }
}

class BusinessAgreementResolver {
  static create(
    data: ISchemas.MutationBusinessAgreement_CreateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessAgreement_create']> {
    return exec('mutation', 'BusinessAgreement_create', data, options) as any;
  }
  static createRecord(
    data: ISchemas.MutationBusinessAgreement_CreateRecordArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessAgreement_createRecord']> {
    return exec('mutation', 'BusinessAgreement_createRecord', data, options) as any;
  }
  static delete(
    data: ISchemas.MutationBusinessAgreement_DeleteArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessAgreement_delete']> {
    return exec('mutation', 'BusinessAgreement_delete', data, options) as any;
  }
  static deleteRecord(
    data: ISchemas.MutationBusinessAgreement_DeleteRecordArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessAgreement_deleteRecord']> {
    return exec('mutation', 'BusinessAgreement_deleteRecord', data, options) as any;
  }
  static sendLink(
    data: ISchemas.MutationBusinessAgreement_SendLinkArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessAgreement_sendLink']> {
    return exec('mutation', 'BusinessAgreement_sendLink', data, options) as any;
  }
  static update(
    data: ISchemas.MutationBusinessAgreement_UpdateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessAgreement_update']> {
    return exec('mutation', 'BusinessAgreement_update', data, options) as any;
  }
  static info(
    data: ISchemas.QueryBusinessAgreement_InfoArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessAgreement_info']> {
    return exec('query', 'BusinessAgreement_info', data, options) as any;
  }
  static list(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Query['BusinessAgreement_list']> {
    return exec('query', 'BusinessAgreement_list', undefined, options) as any;
  }
  static records(
    data: ISchemas.QueryBusinessAgreement_RecordsArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessAgreement_records']> {
    return exec('query', 'BusinessAgreement_records', data, options) as any;
  }
  static viewInfo(
    data: ISchemas.QueryBusinessAgreement_ViewInfoArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessAgreement_viewInfo']> {
    return exec('query', 'BusinessAgreement_viewInfo', data, options) as any;
  }
}

class BusinessCheckinResolver {
  static addToClient(
    data: ISchemas.MutationBusinessCheckin_AddToClientArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessCheckin_addToClient']> {
    return exec('mutation', 'BusinessCheckin_addToClient', data, options) as any;
  }
  static create(
    data: ISchemas.MutationBusinessCheckin_CreateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessCheckin_create']> {
    return exec('mutation', 'BusinessCheckin_create', data, options) as any;
  }
  static createRecord(
    data: ISchemas.MutationBusinessCheckin_CreateRecordArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessCheckin_createRecord']> {
    return exec('mutation', 'BusinessCheckin_createRecord', data, options) as any;
  }
  static delete(
    data: ISchemas.MutationBusinessCheckin_DeleteArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessCheckin_delete']> {
    return exec('mutation', 'BusinessCheckin_delete', data, options) as any;
  }
  static deleteRecord(
    data: ISchemas.MutationBusinessCheckin_DeleteRecordArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessCheckin_deleteRecord']> {
    return exec('mutation', 'BusinessCheckin_deleteRecord', data, options) as any;
  }
  static sendLink(
    data: ISchemas.MutationBusinessCheckin_SendLinkArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessCheckin_sendLink']> {
    return exec('mutation', 'BusinessCheckin_sendLink', data, options) as any;
  }
  static update(
    data: ISchemas.MutationBusinessCheckin_UpdateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessCheckin_update']> {
    return exec('mutation', 'BusinessCheckin_update', data, options) as any;
  }
  static view(
    data: ISchemas.MutationBusinessCheckin_ViewArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessCheckin_view']> {
    return exec('mutation', 'BusinessCheckin_view', data, options) as any;
  }
  static clientInfo(
    data: ISchemas.QueryBusinessCheckin_ClientInfoArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessCheckin_clientInfo']> {
    return exec('query', 'BusinessCheckin_clientInfo', data, options) as any;
  }
  static info(
    data: ISchemas.QueryBusinessCheckin_InfoArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessCheckin_info']> {
    return exec('query', 'BusinessCheckin_info', data, options) as any;
  }
  static list(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Query['BusinessCheckin_list']> {
    return exec('query', 'BusinessCheckin_list', undefined, options) as any;
  }
  static records(
    data: ISchemas.QueryBusinessCheckin_RecordsArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessCheckin_records']> {
    return exec('query', 'BusinessCheckin_records', data, options) as any;
  }
}

class BusinessClassResolver {
  static clear(
    data: ISchemas.MutationBusinessClass_ClearArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClass_clear']> {
    return exec('mutation', 'BusinessClass_clear', data, options) as any;
  }
  static create(
    data: ISchemas.MutationBusinessClass_CreateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClass_create']> {
    return exec('mutation', 'BusinessClass_create', data, options) as any;
  }
  static delete(
    data: ISchemas.MutationBusinessClass_DeleteArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClass_delete']> {
    return exec('mutation', 'BusinessClass_delete', data, options) as any;
  }
  static update(
    data: ISchemas.MutationBusinessClass_UpdateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClass_update']> {
    return exec('mutation', 'BusinessClass_update', data, options) as any;
  }
  static list(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Query['BusinessClass_list']> {
    return exec('query', 'BusinessClass_list', undefined, options) as any;
  }
}

class BusinessClientBodyResolver {
  static createCircumference(
    data: ISchemas.MutationBusinessClientBody_CreateCircumferenceArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientBody_createCircumference']> {
    return exec('mutation', 'BusinessClientBody_createCircumference', data, options) as any;
  }
  static createMetricRecord(
    data: ISchemas.MutationBusinessClientBody_CreateMetricRecordArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientBody_createMetricRecord']> {
    return exec('mutation', 'BusinessClientBody_createMetricRecord', data, options) as any;
  }
  static createVital(
    data: ISchemas.MutationBusinessClientBody_CreateVitalArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientBody_createVital']> {
    return exec('mutation', 'BusinessClientBody_createVital', data, options) as any;
  }
  static deleteCircumference(
    data: ISchemas.MutationBusinessClientBody_DeleteCircumferenceArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientBody_deleteCircumference']> {
    return exec('mutation', 'BusinessClientBody_deleteCircumference', data, options) as any;
  }
  static deleteMetricRecord(
    data: ISchemas.MutationBusinessClientBody_DeleteMetricRecordArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientBody_deleteMetricRecord']> {
    return exec('mutation', 'BusinessClientBody_deleteMetricRecord', data, options) as any;
  }
  static deleteVital(
    data: ISchemas.MutationBusinessClientBody_DeleteVitalArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientBody_deleteVital']> {
    return exec('mutation', 'BusinessClientBody_deleteVital', data, options) as any;
  }
  static update(
    data: ISchemas.MutationBusinessClientBody_UpdateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientBody_update']> {
    return exec('mutation', 'BusinessClientBody_update', data, options) as any;
  }
  static updateCircumference(
    data: ISchemas.MutationBusinessClientBody_UpdateCircumferenceArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientBody_updateCircumference']> {
    return exec('mutation', 'BusinessClientBody_updateCircumference', data, options) as any;
  }
  static updateCircumferenceTarget(
    data: ISchemas.MutationBusinessClientBody_UpdateCircumferenceTargetArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientBody_updateCircumferenceTarget']> {
    return exec('mutation', 'BusinessClientBody_updateCircumferenceTarget', data, options) as any;
  }
  static updateMetricRecord(
    data: ISchemas.MutationBusinessClientBody_UpdateMetricRecordArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientBody_updateMetricRecord']> {
    return exec('mutation', 'BusinessClientBody_updateMetricRecord', data, options) as any;
  }
  static updateMetricTarget(
    data: ISchemas.MutationBusinessClientBody_UpdateMetricTargetArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientBody_updateMetricTarget']> {
    return exec('mutation', 'BusinessClientBody_updateMetricTarget', data, options) as any;
  }
  static updateVital(
    data: ISchemas.MutationBusinessClientBody_UpdateVitalArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientBody_updateVital']> {
    return exec('mutation', 'BusinessClientBody_updateVital', data, options) as any;
  }
  static updateVitalTarget(
    data: ISchemas.MutationBusinessClientBody_UpdateVitalTargetArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientBody_updateVitalTarget']> {
    return exec('mutation', 'BusinessClientBody_updateVitalTarget', data, options) as any;
  }
  static detail(
    data: ISchemas.QueryBusinessClientBody_DetailArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessClientBody_detail']> {
    return exec('query', 'BusinessClientBody_detail', data, options) as any;
  }
  static listCircumferences(
    data: ISchemas.QueryBusinessClientBody_ListCircumferencesArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessClientBody_listCircumferences']> {
    return exec('query', 'BusinessClientBody_listCircumferences', data, options) as any;
  }
  static listMetricRecords(
    data: ISchemas.QueryBusinessClientBody_ListMetricRecordsArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessClientBody_listMetricRecords']> {
    return exec('query', 'BusinessClientBody_listMetricRecords', data, options) as any;
  }
  static listVitals(
    data: ISchemas.QueryBusinessClientBody_ListVitalsArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessClientBody_listVitals']> {
    return exec('query', 'BusinessClientBody_listVitals', data, options) as any;
  }
}

class BusinessClientFileResolver {
  static create(
    data: ISchemas.MutationBusinessClientFile_CreateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientFile_create']> {
    return exec('mutation', 'BusinessClientFile_create', data, options) as any;
  }
  static list(
    data: ISchemas.QueryBusinessClientFile_ListArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessClientFile_list']> {
    return exec('query', 'BusinessClientFile_list', data, options) as any;
  }
}

class BusinessClientJournalResolver {
  static create(
    data: ISchemas.MutationBusinessClientJournal_CreateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientJournal_create']> {
    return exec('mutation', 'BusinessClientJournal_create', data, options) as any;
  }
  static delete(
    data: ISchemas.MutationBusinessClientJournal_DeleteArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientJournal_delete']> {
    return exec('mutation', 'BusinessClientJournal_delete', data, options) as any;
  }
  static update(
    data: ISchemas.MutationBusinessClientJournal_UpdateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientJournal_update']> {
    return exec('mutation', 'BusinessClientJournal_update', data, options) as any;
  }
  static list(
    data: ISchemas.QueryBusinessClientJournal_ListArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessClientJournal_list']> {
    return exec('query', 'BusinessClientJournal_list', data, options) as any;
  }
}

class BusinessClientMembershipResolver {
  static create(
    data: ISchemas.MutationBusinessClientMembership_CreateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientMembership_create']> {
    return exec('mutation', 'BusinessClientMembership_create', data, options) as any;
  }
  static delete(
    data: ISchemas.MutationBusinessClientMembership_DeleteArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientMembership_delete']> {
    return exec('mutation', 'BusinessClientMembership_delete', data, options) as any;
  }
  static pause(
    data: ISchemas.MutationBusinessClientMembership_PauseArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientMembership_pause']> {
    return exec('mutation', 'BusinessClientMembership_pause', data, options) as any;
  }
  static resume(
    data: ISchemas.MutationBusinessClientMembership_ResumeArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientMembership_resume']> {
    return exec('mutation', 'BusinessClientMembership_resume', data, options) as any;
  }
}

class BusinessClientMemoResolver {
  static createGoal(
    data: ISchemas.MutationBusinessClientMemo_CreateGoalArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientMemo_createGoal']> {
    return exec('mutation', 'BusinessClientMemo_createGoal', data, options) as any;
  }
  static createNote(
    data: ISchemas.MutationBusinessClientMemo_CreateNoteArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientMemo_createNote']> {
    return exec('mutation', 'BusinessClientMemo_createNote', data, options) as any;
  }
  static deleteGoal(
    data: ISchemas.MutationBusinessClientMemo_DeleteGoalArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientMemo_deleteGoal']> {
    return exec('mutation', 'BusinessClientMemo_deleteGoal', data, options) as any;
  }
  static deleteNote(
    data: ISchemas.MutationBusinessClientMemo_DeleteNoteArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientMemo_deleteNote']> {
    return exec('mutation', 'BusinessClientMemo_deleteNote', data, options) as any;
  }
  static finishGoal(
    data: ISchemas.MutationBusinessClientMemo_FinishGoalArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientMemo_finishGoal']> {
    return exec('mutation', 'BusinessClientMemo_finishGoal', data, options) as any;
  }
  static updateGoal(
    data: ISchemas.MutationBusinessClientMemo_UpdateGoalArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientMemo_updateGoal']> {
    return exec('mutation', 'BusinessClientMemo_updateGoal', data, options) as any;
  }
  static updateNote(
    data: ISchemas.MutationBusinessClientMemo_UpdateNoteArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientMemo_updateNote']> {
    return exec('mutation', 'BusinessClientMemo_updateNote', data, options) as any;
  }
  static info(
    data: ISchemas.QueryBusinessClientMemo_InfoArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessClientMemo_info']> {
    return exec('query', 'BusinessClientMemo_info', data, options) as any;
  }
  static listGoal(
    data: ISchemas.QueryBusinessClientMemo_ListGoalArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessClientMemo_listGoal']> {
    return exec('query', 'BusinessClientMemo_listGoal', data, options) as any;
  }
  static listNote(
    data: ISchemas.QueryBusinessClientMemo_ListNoteArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessClientMemo_listNote']> {
    return exec('query', 'BusinessClientMemo_listNote', data, options) as any;
  }
}

class BusinessClientTrainingResolver {
  static assignProgram(
    data: ISchemas.MutationBusinessClientTraining_AssignProgramArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClientTraining_assignProgram']> {
    return exec('mutation', 'BusinessClientTraining_assignProgram', data, options) as any;
  }
  static info(
    data: ISchemas.QueryBusinessClientTraining_InfoArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessClientTraining_info']> {
    return exec('query', 'BusinessClientTraining_info', data, options) as any;
  }
}

class BusinessClientResolver {
  static activate(
    data: ISchemas.MutationBusinessClient_ActivateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClient_activate']> {
    return exec('mutation', 'BusinessClient_activate', data, options) as any;
  }
  static clear(
    data: ISchemas.MutationBusinessClient_ClearArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClient_clear']> {
    return exec('mutation', 'BusinessClient_clear', data, options) as any;
  }
  static create(
    data: ISchemas.MutationBusinessClient_CreateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClient_create']> {
    return exec('mutation', 'BusinessClient_create', data, options) as any;
  }
  static delete(
    data: ISchemas.MutationBusinessClient_DeleteArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClient_delete']> {
    return exec('mutation', 'BusinessClient_delete', data, options) as any;
  }
  static inactivate(
    data: ISchemas.MutationBusinessClient_InactivateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClient_inactivate']> {
    return exec('mutation', 'BusinessClient_inactivate', data, options) as any;
  }
  static token(
    data: ISchemas.MutationBusinessClient_TokenArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClient_token']> {
    return exec('mutation', 'BusinessClient_token', data, options) as any;
  }
  static update(
    data: ISchemas.MutationBusinessClient_UpdateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessClient_update']> {
    return exec('mutation', 'BusinessClient_update', data, options) as any;
  }
  static list(
    data: ISchemas.QueryBusinessClient_ListArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessClient_list']> {
    return exec('query', 'BusinessClient_list', data, options) as any;
  }
  static overview(
    data: ISchemas.QueryBusinessClient_OverviewArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessClient_overview']> {
    return exec('query', 'BusinessClient_overview', data, options) as any;
  }
  static publicInfo(
    data: ISchemas.QueryBusinessClient_PublicInfoArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessClient_publicInfo']> {
    return exec('query', 'BusinessClient_publicInfo', data, options) as any;
  }
  static trainingOverview(
    data: ISchemas.QueryBusinessClient_TrainingOverviewArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessClient_trainingOverview']> {
    return exec('query', 'BusinessClient_trainingOverview', data, options) as any;
  }
}

class BusinessExerciseResolver {
  static create(
    data: ISchemas.MutationBusinessExercise_CreateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessExercise_create']> {
    return exec('mutation', 'BusinessExercise_create', data, options) as any;
  }
  static delete(
    data: ISchemas.MutationBusinessExercise_DeleteArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessExercise_delete']> {
    return exec('mutation', 'BusinessExercise_delete', data, options) as any;
  }
  static update(
    data: ISchemas.MutationBusinessExercise_UpdateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessExercise_update']> {
    return exec('mutation', 'BusinessExercise_update', data, options) as any;
  }
  static list(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Query['BusinessExercise_list']> {
    return exec('query', 'BusinessExercise_list', undefined, options) as any;
  }
}

class BusinessIntakeResolver {
  static addToClient(
    data: ISchemas.MutationBusinessIntake_AddToClientArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessIntake_addToClient']> {
    return exec('mutation', 'BusinessIntake_addToClient', data, options) as any;
  }
  static create(
    data: ISchemas.MutationBusinessIntake_CreateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessIntake_create']> {
    return exec('mutation', 'BusinessIntake_create', data, options) as any;
  }
  static createRecord(
    data: ISchemas.MutationBusinessIntake_CreateRecordArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessIntake_createRecord']> {
    return exec('mutation', 'BusinessIntake_createRecord', data, options) as any;
  }
  static delete(
    data: ISchemas.MutationBusinessIntake_DeleteArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessIntake_delete']> {
    return exec('mutation', 'BusinessIntake_delete', data, options) as any;
  }
  static deleteRecord(
    data: ISchemas.MutationBusinessIntake_DeleteRecordArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessIntake_deleteRecord']> {
    return exec('mutation', 'BusinessIntake_deleteRecord', data, options) as any;
  }
  static update(
    data: ISchemas.MutationBusinessIntake_UpdateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessIntake_update']> {
    return exec('mutation', 'BusinessIntake_update', data, options) as any;
  }
  static view(
    data: ISchemas.MutationBusinessIntake_ViewArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessIntake_view']> {
    return exec('mutation', 'BusinessIntake_view', data, options) as any;
  }
  static info(
    data: ISchemas.QueryBusinessIntake_InfoArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessIntake_info']> {
    return exec('query', 'BusinessIntake_info', data, options) as any;
  }
  static list(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Query['BusinessIntake_list']> {
    return exec('query', 'BusinessIntake_list', undefined, options) as any;
  }
  static records(
    data: ISchemas.QueryBusinessIntake_RecordsArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessIntake_records']> {
    return exec('query', 'BusinessIntake_records', data, options) as any;
  }
}

class BusinessMembershipResolver {
  static clear(
    data: ISchemas.MutationBusinessMembership_ClearArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessMembership_clear']> {
    return exec('mutation', 'BusinessMembership_clear', data, options) as any;
  }
  static create(
    data: ISchemas.MutationBusinessMembership_CreateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessMembership_create']> {
    return exec('mutation', 'BusinessMembership_create', data, options) as any;
  }
  static delete(
    data: ISchemas.MutationBusinessMembership_DeleteArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessMembership_delete']> {
    return exec('mutation', 'BusinessMembership_delete', data, options) as any;
  }
  static update(
    data: ISchemas.MutationBusinessMembership_UpdateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessMembership_update']> {
    return exec('mutation', 'BusinessMembership_update', data, options) as any;
  }
  static count(
    data: ISchemas.QueryBusinessMembership_CountArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessMembership_count']> {
    return exec('query', 'BusinessMembership_count', data, options) as any;
  }
  static list(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Query['BusinessMembership_list']> {
    return exec('query', 'BusinessMembership_list', undefined, options) as any;
  }
}

class BusinessMessageAutomationResolver {
  static toggle(
    data: ISchemas.MutationBusinessMessageAutomation_ToggleArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessMessageAutomation_toggle']> {
    return exec('mutation', 'BusinessMessageAutomation_toggle', data, options) as any;
  }
  static update(
    data: ISchemas.MutationBusinessMessageAutomation_UpdateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessMessageAutomation_update']> {
    return exec('mutation', 'BusinessMessageAutomation_update', data, options) as any;
  }
  static query(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Query['BusinessMessageAutomation_query']> {
    return exec('query', 'BusinessMessageAutomation_query', undefined, options) as any;
  }
}

class BusinessMetricResolver {
  static create(
    data: ISchemas.MutationBusinessMetric_CreateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessMetric_create']> {
    return exec('mutation', 'BusinessMetric_create', data, options) as any;
  }
  static delete(
    data: ISchemas.MutationBusinessMetric_DeleteArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessMetric_delete']> {
    return exec('mutation', 'BusinessMetric_delete', data, options) as any;
  }
  static update(
    data: ISchemas.MutationBusinessMetric_UpdateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessMetric_update']> {
    return exec('mutation', 'BusinessMetric_update', data, options) as any;
  }
  static count(
    data: ISchemas.QueryBusinessMetric_CountArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessMetric_count']> {
    return exec('query', 'BusinessMetric_count', data, options) as any;
  }
  static list(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Query['BusinessMetric_list']> {
    return exec('query', 'BusinessMetric_list', undefined, options) as any;
  }
}

class BusinessNotificationResolver {
  static read(
    data: ISchemas.MutationBusinessNotification_ReadArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessNotification_read']> {
    return exec('mutation', 'BusinessNotification_read', data, options) as any;
  }
  static readAll(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Mutation['BusinessNotification_readAll']> {
    return exec('mutation', 'BusinessNotification_readAll', undefined, options) as any;
  }
  static countUnread(
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessNotification_countUnread']> {
    return exec('query', 'BusinessNotification_countUnread', undefined, options) as any;
  }
  static list(
    data: ISchemas.QueryBusinessNotification_ListArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessNotification_list']> {
    return exec('query', 'BusinessNotification_list', data, options) as any;
  }
}

class BusinessPageResolver {
  static create(
    data: ISchemas.MutationBusinessPage_CreateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessPage_create']> {
    return exec('mutation', 'BusinessPage_create', data, options) as any;
  }
  static createDomain(
    data: ISchemas.MutationBusinessPage_CreateDomainArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessPage_createDomain']> {
    return exec('mutation', 'BusinessPage_createDomain', data, options) as any;
  }
  static delete(
    data: ISchemas.MutationBusinessPage_DeleteArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessPage_delete']> {
    return exec('mutation', 'BusinessPage_delete', data, options) as any;
  }
  static offline(
    data: ISchemas.MutationBusinessPage_OfflineArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessPage_offline']> {
    return exec('mutation', 'BusinessPage_offline', data, options) as any;
  }
  static publish(
    data: ISchemas.MutationBusinessPage_PublishArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessPage_publish']> {
    return exec('mutation', 'BusinessPage_publish', data, options) as any;
  }
  static setGoogleAnalytics(
    data: ISchemas.MutationBusinessPage_SetGoogleAnalyticsArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessPage_setGoogleAnalytics']> {
    return exec('mutation', 'BusinessPage_setGoogleAnalytics', data, options) as any;
  }
  static template(
    data: ISchemas.MutationBusinessPage_TemplateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessPage_template']> {
    return exec('mutation', 'BusinessPage_template', data, options) as any;
  }
  static update(
    data: ISchemas.MutationBusinessPage_UpdateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessPage_update']> {
    return exec('mutation', 'BusinessPage_update', data, options) as any;
  }
  static updateDomain(
    data: ISchemas.MutationBusinessPage_UpdateDomainArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessPage_updateDomain']> {
    return exec('mutation', 'BusinessPage_updateDomain', data, options) as any;
  }
  static info(
    data: ISchemas.QueryBusinessPage_InfoArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessPage_info']> {
    return exec('query', 'BusinessPage_info', data, options) as any;
  }
  static list(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Query['BusinessPage_list']> {
    return exec('query', 'BusinessPage_list', undefined, options) as any;
  }
}

class BusinessPermissionResolver {
  static createRole(
    data: ISchemas.MutationBusinessPermission_CreateRoleArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessPermission_createRole']> {
    return exec('mutation', 'BusinessPermission_createRole', data, options) as any;
  }
  static deleteRole(
    data: ISchemas.MutationBusinessPermission_DeleteRoleArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessPermission_deleteRole']> {
    return exec('mutation', 'BusinessPermission_deleteRole', data, options) as any;
  }
  static updateRoles(
    data: ISchemas.MutationBusinessPermission_UpdateRolesArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessPermission_updateRoles']> {
    return exec('mutation', 'BusinessPermission_updateRoles', data, options) as any;
  }
}

class BusinessProgramResolver {
  static create(
    data: ISchemas.MutationBusinessProgram_CreateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessProgram_create']> {
    return exec('mutation', 'BusinessProgram_create', data, options) as any;
  }
  static delete(
    data: ISchemas.MutationBusinessProgram_DeleteArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessProgram_delete']> {
    return exec('mutation', 'BusinessProgram_delete', data, options) as any;
  }
  static duplicate(
    data: ISchemas.MutationBusinessProgram_DuplicateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessProgram_duplicate']> {
    return exec('mutation', 'BusinessProgram_duplicate', data, options) as any;
  }
  static update(
    data: ISchemas.MutationBusinessProgram_UpdateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessProgram_update']> {
    return exec('mutation', 'BusinessProgram_update', data, options) as any;
  }
  static info(
    data: ISchemas.QueryBusinessProgram_InfoArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessProgram_info']> {
    return exec('query', 'BusinessProgram_info', data, options) as any;
  }
  static list(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Query['BusinessProgram_list']> {
    return exec('query', 'BusinessProgram_list', undefined, options) as any;
  }
}

class BusinessQuestionnaireResolver {
  static create(
    data: ISchemas.MutationBusinessQuestionnaire_CreateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessQuestionnaire_create']> {
    return exec('mutation', 'BusinessQuestionnaire_create', data, options) as any;
  }
  static createRecord(
    data: ISchemas.MutationBusinessQuestionnaire_CreateRecordArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessQuestionnaire_createRecord']> {
    return exec('mutation', 'BusinessQuestionnaire_createRecord', data, options) as any;
  }
  static delete(
    data: ISchemas.MutationBusinessQuestionnaire_DeleteArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessQuestionnaire_delete']> {
    return exec('mutation', 'BusinessQuestionnaire_delete', data, options) as any;
  }
  static deleteRecord(
    data: ISchemas.MutationBusinessQuestionnaire_DeleteRecordArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessQuestionnaire_deleteRecord']> {
    return exec('mutation', 'BusinessQuestionnaire_deleteRecord', data, options) as any;
  }
  static update(
    data: ISchemas.MutationBusinessQuestionnaire_UpdateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessQuestionnaire_update']> {
    return exec('mutation', 'BusinessQuestionnaire_update', data, options) as any;
  }
  static info(
    data: ISchemas.QueryBusinessQuestionnaire_InfoArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessQuestionnaire_info']> {
    return exec('query', 'BusinessQuestionnaire_info', data, options) as any;
  }
  static list(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Query['BusinessQuestionnaire_list']> {
    return exec('query', 'BusinessQuestionnaire_list', undefined, options) as any;
  }
  static records(
    data: ISchemas.QueryBusinessQuestionnaire_RecordsArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessQuestionnaire_records']> {
    return exec('query', 'BusinessQuestionnaire_records', data, options) as any;
  }
}

class BusinessScheduleResolver {
  static create(
    data: ISchemas.MutationBusinessSchedule_CreateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessSchedule_create']> {
    return exec('mutation', 'BusinessSchedule_create', data, options) as any;
  }
  static delete(
    data: ISchemas.MutationBusinessSchedule_DeleteArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessSchedule_delete']> {
    return exec('mutation', 'BusinessSchedule_delete', data, options) as any;
  }
  static reschedule(
    data: ISchemas.MutationBusinessSchedule_RescheduleArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessSchedule_reschedule']> {
    return exec('mutation', 'BusinessSchedule_reschedule', data, options) as any;
  }
  static sendReminder(
    data: ISchemas.MutationBusinessSchedule_SendReminderArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessSchedule_sendReminder']> {
    return exec('mutation', 'BusinessSchedule_sendReminder', data, options) as any;
  }
  static update(
    data: ISchemas.MutationBusinessSchedule_UpdateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessSchedule_update']> {
    return exec('mutation', 'BusinessSchedule_update', data, options) as any;
  }
  static info(
    data: ISchemas.QueryBusinessSchedule_InfoArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessSchedule_info']> {
    return exec('query', 'BusinessSchedule_info', data, options) as any;
  }
  static list(
    data: ISchemas.QueryBusinessSchedule_ListArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessSchedule_list']> {
    return exec('query', 'BusinessSchedule_list', data, options) as any;
  }
  static overlap(
    data: ISchemas.QueryBusinessSchedule_OverlapArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessSchedule_overlap']> {
    return exec('query', 'BusinessSchedule_overlap', data, options) as any;
  }
  static upcoming(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Query['BusinessSchedule_upcoming']> {
    return exec('query', 'BusinessSchedule_upcoming', undefined, options) as any;
  }
  static viewInfo(
    data: ISchemas.QueryBusinessSchedule_ViewInfoArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessSchedule_viewInfo']> {
    return exec('query', 'BusinessSchedule_viewInfo', data, options) as any;
  }
}

class BusinessStaffResolver {
  static create(
    data: ISchemas.MutationBusinessStaff_CreateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessStaff_create']> {
    return exec('mutation', 'BusinessStaff_create', data, options) as any;
  }
  static delete(
    data: ISchemas.MutationBusinessStaff_DeleteArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessStaff_delete']> {
    return exec('mutation', 'BusinessStaff_delete', data, options) as any;
  }
  static sendInviteLink(
    data: ISchemas.MutationBusinessStaff_SendInviteLinkArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessStaff_sendInviteLink']> {
    return exec('mutation', 'BusinessStaff_sendInviteLink', data, options) as any;
  }
  static update(
    data: ISchemas.MutationBusinessStaff_UpdateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessStaff_update']> {
    return exec('mutation', 'BusinessStaff_update', data, options) as any;
  }
  static updateShift(
    data: ISchemas.MutationBusinessStaff_UpdateShiftArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessStaff_updateShift']> {
    return exec('mutation', 'BusinessStaff_updateShift', data, options) as any;
  }
  static checkLimitation(
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessStaff_checkLimitation']> {
    return exec('query', 'BusinessStaff_checkLimitation', undefined, options) as any;
  }
  static inviteInfo(
    data: ISchemas.QueryBusinessStaff_InviteInfoArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessStaff_inviteInfo']> {
    return exec('query', 'BusinessStaff_inviteInfo', data, options) as any;
  }
  static list(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Query['BusinessStaff_list']> {
    return exec('query', 'BusinessStaff_list', undefined, options) as any;
  }
}

class BusinessSubscriptionResolver {
  static applyCoupon(
    data: ISchemas.MutationBusinessSubscription_ApplyCouponArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessSubscription_applyCoupon']> {
    return exec('mutation', 'BusinessSubscription_applyCoupon', data, options) as any;
  }
  static billingPortal(
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessSubscription_billingPortal']> {
    return exec('mutation', 'BusinessSubscription_billingPortal', undefined, options) as any;
  }
  static cancel(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Mutation['BusinessSubscription_cancel']> {
    return exec('mutation', 'BusinessSubscription_cancel', undefined, options) as any;
  }
  static setup(
    data: ISchemas.MutationBusinessSubscription_SetupArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessSubscription_setup']> {
    return exec('mutation', 'BusinessSubscription_setup', data, options) as any;
  }
  static update(
    data: ISchemas.MutationBusinessSubscription_UpdateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessSubscription_update']> {
    return exec('mutation', 'BusinessSubscription_update', data, options) as any;
  }
  static checkSetup(
    data: ISchemas.QueryBusinessSubscription_CheckSetupArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessSubscription_checkSetup']> {
    return exec('query', 'BusinessSubscription_checkSetup', data, options) as any;
  }
  static failedReason(
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessSubscription_failedReason']> {
    return exec('query', 'BusinessSubscription_failedReason', undefined, options) as any;
  }
  static invoices(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Query['BusinessSubscription_invoices']> {
    return exec('query', 'BusinessSubscription_invoices', undefined, options) as any;
  }
  static query(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Query['BusinessSubscription_query']> {
    return exec('query', 'BusinessSubscription_query', undefined, options) as any;
  }
}

class BusinessWorkoutResolver {
  static create(
    data: ISchemas.MutationBusinessWorkout_CreateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessWorkout_create']> {
    return exec('mutation', 'BusinessWorkout_create', data, options) as any;
  }
  static delete(
    data: ISchemas.MutationBusinessWorkout_DeleteArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessWorkout_delete']> {
    return exec('mutation', 'BusinessWorkout_delete', data, options) as any;
  }
  static update(
    data: ISchemas.MutationBusinessWorkout_UpdateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['BusinessWorkout_update']> {
    return exec('mutation', 'BusinessWorkout_update', data, options) as any;
  }
  static list(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Query['BusinessWorkout_list']> {
    return exec('query', 'BusinessWorkout_list', undefined, options) as any;
  }
  static viewInfo(
    data: ISchemas.QueryBusinessWorkout_ViewInfoArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessWorkout_viewInfo']> {
    return exec('query', 'BusinessWorkout_viewInfo', data, options) as any;
  }
}

class BusinessResolver {
  static create(
    data: ISchemas.MutationBusiness_CreateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['Business_create']> {
    return exec('mutation', 'Business_create', data, options) as any;
  }
  static createTag(
    data: ISchemas.MutationBusiness_CreateTagArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['Business_createTag']> {
    return exec('mutation', 'Business_createTag', data, options) as any;
  }
  static deleteTag(
    data: ISchemas.MutationBusiness_DeleteTagArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['Business_deleteTag']> {
    return exec('mutation', 'Business_deleteTag', data, options) as any;
  }
  static toggleBrandMark(
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['Business_toggleBrandMark']> {
    return exec('mutation', 'Business_toggleBrandMark', undefined, options) as any;
  }
  static updateBrandConfig(
    data: ISchemas.MutationBusiness_UpdateBrandConfigArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['Business_updateBrandConfig']> {
    return exec('mutation', 'Business_updateBrandConfig', data, options) as any;
  }
  static updateConfig(
    data: ISchemas.MutationBusiness_UpdateConfigArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['Business_updateConfig']> {
    return exec('mutation', 'Business_updateConfig', data, options) as any;
  }
  static updateInfo(
    data: ISchemas.MutationBusiness_UpdateInfoArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['Business_updateInfo']> {
    return exec('mutation', 'Business_updateInfo', data, options) as any;
  }
  static updateMyProfile(
    data: ISchemas.MutationBusiness_UpdateMyProfileArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['Business_updateMyProfile']> {
    return exec('mutation', 'Business_updateMyProfile', data, options) as any;
  }
  static updateTag(
    data: ISchemas.MutationBusiness_UpdateTagArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['Business_updateTag']> {
    return exec('mutation', 'Business_updateTag', data, options) as any;
  }
  static checkNameExists(
    data: ISchemas.QueryBusiness_CheckNameExistsArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['Business_checkNameExists']> {
    return exec('query', 'Business_checkNameExists', data, options) as any;
  }
  static info(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Query['Business_info']> {
    return exec('query', 'Business_info', undefined, options) as any;
  }
  static usage(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Query['Business_usage']> {
    return exec('query', 'Business_usage', undefined, options) as any;
  }
}

class ClientAccountResolver {
  static login(
    data: ISchemas.MutationClientAccount_LoginArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['ClientAccount_login']> {
    return exec('mutation', 'ClientAccount_login', data, options) as any;
  }
  static signup(
    data: ISchemas.MutationClientAccount_SignupArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['ClientAccount_signup']> {
    return exec('mutation', 'ClientAccount_signup', data, options) as any;
  }
  static switch(
    data: ISchemas.MutationClientAccount_SwitchArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['ClientAccount_switch']> {
    return exec('mutation', 'ClientAccount_switch', data, options) as any;
  }
  static update(
    data: ISchemas.MutationClientAccount_UpdateArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['ClientAccount_update']> {
    return exec('mutation', 'ClientAccount_update', data, options) as any;
  }
  static updateEmail(
    data: ISchemas.MutationClientAccount_UpdateEmailArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['ClientAccount_updateEmail']> {
    return exec('mutation', 'ClientAccount_updateEmail', data, options) as any;
  }
  static updatePassword(
    data: ISchemas.MutationClientAccount_UpdatePasswordArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['ClientAccount_updatePassword']> {
    return exec('mutation', 'ClientAccount_updatePassword', data, options) as any;
  }
  static check(
    data: ISchemas.QueryClientAccount_CheckArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['ClientAccount_check']> {
    return exec('query', 'ClientAccount_check', data, options) as any;
  }
}

class ClientResolver {
  static createGoal(
    data: ISchemas.MutationClient_CreateGoalArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['Client_createGoal']> {
    return exec('mutation', 'Client_createGoal', data, options) as any;
  }
  static finishGoal(
    data: ISchemas.MutationClient_FinishGoalArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['Client_finishGoal']> {
    return exec('mutation', 'Client_finishGoal', data, options) as any;
  }
  static goals(
    data: ISchemas.QueryClient_GoalsArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['Client_goals']> {
    return exec('query', 'Client_goals', data, options) as any;
  }
  static info(options?: Partial<ApiExecOptions>): PromiseResult<ISchemas.Query['Client_info']> {
    return exec('query', 'Client_info', undefined, options) as any;
  }
  static schedules(
    data: ISchemas.QueryClient_SchedulesArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['Client_schedules']> {
    return exec('query', 'Client_schedules', data, options) as any;
  }
}

class ResourceResolver {
  static deleteStream(
    data: ISchemas.MutationResource_DeleteStreamArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Mutation['Resource_deleteStream']> {
    return exec('mutation', 'Resource_deleteStream', data, options) as any;
  }
}

class BusinessSearchResolver {
  static search(
    data: ISchemas.QueryBusinessSearch_SearchArgs['data'],
    options?: Partial<ApiExecOptions>,
  ): PromiseResult<ISchemas.Query['BusinessSearch_search']> {
    return exec('query', 'BusinessSearch_search', data, options) as any;
  }
}

export const api = {
  BusinessAccount: BusinessAccountResolver,
  BusinessAgreement: BusinessAgreementResolver,
  BusinessCheckin: BusinessCheckinResolver,
  BusinessClass: BusinessClassResolver,
  BusinessClientBody: BusinessClientBodyResolver,
  BusinessClientFile: BusinessClientFileResolver,
  BusinessClientJournal: BusinessClientJournalResolver,
  BusinessClientMembership: BusinessClientMembershipResolver,
  BusinessClientMemo: BusinessClientMemoResolver,
  BusinessClientTraining: BusinessClientTrainingResolver,
  BusinessClient: BusinessClientResolver,
  BusinessExercise: BusinessExerciseResolver,
  BusinessIntake: BusinessIntakeResolver,
  BusinessMembership: BusinessMembershipResolver,
  BusinessMessageAutomation: BusinessMessageAutomationResolver,
  BusinessMetric: BusinessMetricResolver,
  BusinessNotification: BusinessNotificationResolver,
  BusinessPage: BusinessPageResolver,
  BusinessPermission: BusinessPermissionResolver,
  BusinessProgram: BusinessProgramResolver,
  BusinessQuestionnaire: BusinessQuestionnaireResolver,
  BusinessSchedule: BusinessScheduleResolver,
  BusinessStaff: BusinessStaffResolver,
  BusinessSubscription: BusinessSubscriptionResolver,
  BusinessWorkout: BusinessWorkoutResolver,
  Business: BusinessResolver,
  ClientAccount: ClientAccountResolver,
  Client: ClientResolver,
  Resource: ResourceResolver,
  BusinessSearch: BusinessSearchResolver,
};
