import { api, ClientInfoVo } from '@cocast/api-client';
import { Loadable, StoreUtils } from '@cocast/mobx';
import { DateFormat } from '@cocast/types';
import { Dayjs, default as dayjs } from 'dayjs';
import { computed, makeObservable, observable } from 'mobx';
import { uiAction } from '../ui/action';

export class ClientStore extends StoreUtils {
  constructor() {
    super();
    makeObservable(this);
    window.__client = this;
  }

  @observable
  public info = Loadable.from<ClientInfoVo>(() => api.Client.info({ onError: uiAction.onGlobalError }));

  @observable
  public dateFormat = DateFormat['YYYY-MM-DD'];

  @observable
  public timeFormat = 'HH:mm';

  @computed({ keepAlive: true })
  public get goals() {
    return this.info.data?.goals;
  }

  public readonly formatDate = (date: string | Date | Dayjs, time?: boolean) => {
    return dayjs(date).format(this.dateFormat + (time ? ` ${this.timeFormat}` : ''));
  };

  public readonly fromNow = (date: string | Date | Dayjs) => {
    return dayjs(date).from(dayjs());
  };

  public readonly formatTime = (time: string | Date | Dayjs) => {
    return dayjs(time).format(this.timeFormat);
  };
}

export const clientStore = new ClientStore();
