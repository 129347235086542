export enum Currency {
  AUD = 'AUD',
  EUR = 'EUR',
  BRL = 'BRL',
  CAD = 'CAD',
  HRK = 'HRK',
  CZK = 'CZK',
  DKK = 'DKK',
  HUF = 'HUF',
  INR = 'INR',
  JPY = 'JPY',
  MYR = 'MYR',
  MXN = 'MXN',
  NZD = 'NZD',
  NOK = 'NOK',
  PLN = 'PLN',
  RON = 'RON',
  SGD = 'SGD',
  SEK = 'SEK',
  CHF = 'CHF',
  THB = 'THB',
  USD = 'USD',
  GBP = 'GBP',
  AED = 'AED',
  HKD = 'HKD',
  RWF = 'RWF',
  ZAR = 'ZAR',
  // Added at 2024-03-30
  ARS = 'ARS',
  BSD = 'BSD',
  BBD = 'BBD',
  BOB = 'BOB',
  BGN = 'BGN',
  CLP = 'CLP',
  COP = 'COP',
  CRC = 'CRC',
  EGP = 'EGP',
  SVC = 'SVC',
  GEL = 'GEL',
  ISK = 'ISK',
  IRR = 'IRR',
  ILS = 'ILS',
  JOD = 'JOD',
  KWD = 'KWD',
  OMR = 'OMR',
  PKR = 'PKR',
  PAB = 'PAB',
  PHP = 'PHP',
  QAR = 'QAR',
  WST = 'WST',
  SAR = 'SAR',
  RSD = 'RSD',
  KRW = 'KRW',
  TRY = 'TRY',
  TVD = 'TVD',
  UAH = 'UAH',
  VND = 'VND',
  YER = 'YER',
}

export const currencySymbolMap = new Map<Currency, string>([
  [Currency.AUD, '$'],
  [Currency.EUR, '€'],
  [Currency.BRL, 'R$'],
  [Currency.CAD, '$'],
  [Currency.HRK, 'kn'],
  [Currency.CZK, 'Kč'],
  [Currency.DKK, 'kr'],
  [Currency.HUF, 'Ft'],
  [Currency.INR, '₹'],
  [Currency.JPY, '¥'],
  [Currency.MYR, 'RM'],
  [Currency.MXN, '$'],
  [Currency.NZD, '$'],
  [Currency.NOK, 'kr'],
  [Currency.PLN, 'zł'],
  [Currency.RON, 'lei'],
  [Currency.SGD, '$'],
  [Currency.SEK, 'kr'],
  [Currency.CHF, 'CHF'],
  [Currency.THB, '฿'],
  [Currency.USD, '$'],
  [Currency.GBP, '£'],
  [Currency.AED, 'د.إ'],
  [Currency.HKD, '$'],
  [Currency.RWF, 'R₣'],
  [Currency.ZAR, 'R'],
  // Added at 2024-03-30
  [Currency.ARS, '$'],
  [Currency.BSD, 'B$'],
  [Currency.BBD, 'Bds$'],
  [Currency.BOB, 'Bs'],
  [Currency.BGN, 'лв'],
  [Currency.CLP, '$'],
  [Currency.COP, '$'],
  [Currency.CRC, '₡'],
  [Currency.EGP, 'E£'],
  [Currency.SVC, '$'],
  [Currency.GEL, '₾'],
  [Currency.ISK, 'kr'],
  [Currency.IRR, '﷼'],
  [Currency.ILS, '₪'],
  [Currency.KWD, 'د.ك'],
  [Currency.OMR, 'ر.ع.'],
  [Currency.PKR, 'Rs'],
  [Currency.PAB, 'B/.'],
  [Currency.PHP, '₱'],
  [Currency.QAR, 'ر.ق'],
  [Currency.WST, 'WS$'],
  [Currency.SAR, 'ر.س'],
  [Currency.RSD, 'din'],
  [Currency.KRW, '₩'],
  [Currency.TRY, '₺'],
  [Currency.TVD, '$'],
  [Currency.UAH, '₴'],
  [Currency.VND, '₫'],
  [Currency.YER, '﷼'],
]);
