import { useUpdatedRef } from '@cocast/hooks/useUpdatedRef';
import { ComponentType, useCallback, useEffect, useMemo, useRef } from 'react';
import { showCustom } from './api';
import { SheetAPI } from './Register';
import { SheetProps } from './Sheet';

type OpenSheetProps<P extends Pick<SheetProps, 'open' | 'setOpen' | 'stack' | 'onConfirm'>> = Omit<
  P,
  'open' | 'setOpen' | 'onConfirm'
> & {
  onConfirm?: (
    sheet: SheetAPI,
    ...params: Parameters<P['onConfirm'] extends (...args: any) => any ? P['onConfirm'] : (...args: any) => any>
  ) => ReturnType<P['onConfirm'] extends (...args: any) => any ? P['onConfirm'] : (...args: any) => any>;
};

export function useSheet<P extends Pick<SheetProps, 'open' | 'setOpen' | 'stack' | 'onConfirm'>>(
  Component: ComponentType<P>,
  defaultProps?: OpenSheetProps<P>,
) {
  const sheet = useRef<SheetAPI>();

  const close = useCallback(() => {
    if (sheet.current) {
      sheet.current.setOpen?.disable();
      sheet.current = null;
    }
  }, []);

  useEffect(() => close, []);

  const openTimer = useRef<number>();
  const component = useUpdatedRef(Component);
  return useMemo(
    () => ({
      open: (props?: OpenSheetProps<P>) => {
        if (openTimer.current) {
          window.clearTimeout(openTimer.current);
          openTimer.current = null;
        }
        const show = () => {
          sheet.current = showCustom(component.current, props ?? defaultProps);
        };
        if (sheet.current) {
          sheet.current.setOpen.destroy();
          openTimer.current = window.setTimeout(show, 100);
        } else {
          show();
        }
      },
      close,
    }),
    [],
  );
}

export type UseSheetHooks<P extends Pick<SheetProps, 'open' | 'setOpen'>> = ReturnType<typeof useSheet<P>>;
