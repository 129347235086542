import { useArray } from '@cocast/hooks/useArray';
import { nanoid } from 'nanoid';
import { ComponentType, Dispatch, forwardRef, memo, SetStateAction, useCallback, useRef } from 'react';
import { Sheet, SheetProps } from './Sheet';

export interface SheetAPI {
  setOpen: SheetProps['setOpen'];
  update: Dispatch<SetStateAction<SheetInstanceProps>>;
}

export type SheetInstanceProps = Omit<SheetProps, 'open' | 'setOpen' | 'onConfirm'> & {
  onConfirm?: (Sheet: SheetAPI, ...params: unknown[]) => unknown;
};

let setter: <P extends Pick<SheetProps, 'open' | 'setOpen'>>(
  props: SetStateAction<SheetInstanceProps>,
  as?: ComponentType<P>,
) => SheetAPI;

interface SheetItem {
  id: string;
  props: SheetInstanceProps;
  open: boolean;
  stack?: boolean;
  setOpen: SheetProps['setOpen'];
  component: ComponentType<any>;
}

function SheetRegisterComponent() {
  const { value: Sheets, push, setValue, set, setBy, removeBy, ref } = useArray<SheetItem>();

  const disableTimer = useRef<number>();
  setter = useCallback(
    <P extends Pick<SheetProps, 'open' | 'setOpen'>>(
      { stack, ...props }: SheetInstanceProps,
      as: ComponentType<P> = Sheet,
    ) => {
      const id = nanoid();
      const byId = (i: SheetItem) => i.id === id;

      const setOpen: SheetProps['setOpen'] = {
        enable: () => setBy(byId, (i) => ({ ...i, open: true })),
        disable: () => {
          if (disableTimer.current) {
            window.clearTimeout(disableTimer.current);
            disableTimer.current = null;
          }
          const index = ref.current.findIndex(byId);
          set(index, (i) => {
            disableTimer.current = window.setTimeout(() => removeBy(byId), 400);
            return { ...i, open: false };
          });
          if (index > 0 && ref.current[index - 1]?.stack) {
            disableTimer.current = window.setTimeout(() => set(index - 1, (i) => ({ ...i, stack: false })), 100);
          }
        },
        destroy: () => {
          if (disableTimer.current) {
            window.clearTimeout(disableTimer.current);
            disableTimer.current = null;
          }
          removeBy(byId);
        },
      };

      const Sheet: SheetAPI = {
        setOpen,
        update: (props: SheetInstanceProps) => setBy(byId, (i) => ({ ...i, props })),
      };

      const update = () => {
        push({
          id,
          props: props.onConfirm ? { ...props, onConfirm: (...params) => props.onConfirm(Sheet, ...params) } : props,
          open: true,
          component: as,
          setOpen,
        });
      };
      if (stack && ref.current.length) {
        setValue((v) => v.map((i) => ({ ...i, stack: true })));
        setTimeout(update, 100);
      } else {
        update();
      }

      return Sheet;
    },
    [push, setValue],
  );

  return (
    <>
      {Sheets.map((Sheet, index) => (
        <SheetRegisterItem key={Sheet.id} Sheet={Sheet} index={index} />
      ))}
    </>
  );
}

export const SheetRegister = memo(forwardRef(SheetRegisterComponent));

interface ItemProp {
  Sheet: SheetItem;
  index: number;
}

function SheetRegisterItemComponent({
  Sheet: {
    id,
    props: { onConfirm, ...props },
    open,
    setOpen,
    stack,
    component: Component,
  },
  index,
}: ItemProp) {
  return (
    <Component
      key={id}
      {...props}
      onConfirm={onConfirm}
      open={open}
      setOpen={setOpen}
      mask={index === 0}
      stack={stack}
    />
  );
}

export const SheetRegisterItem = memo(SheetRegisterItemComponent);

export function set<P extends Pick<SheetProps, 'open' | 'setOpen' | 'mask' | 'stack'>>(
  props: SetStateAction<SheetInstanceProps>,
  as?: ComponentType<P>,
) {
  if (!setter) {
    throw new Error('SheetRegister is not initialized');
  }
  return setter(props, as);
}
