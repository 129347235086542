import { waitUntil } from '@cocast/utils';
import { createRoot } from 'react-dom/client';
import { getStyleEnvVariable } from './style';

export function createEntryNode(target?: HTMLElement, initial?: (entry: HTMLDivElement) => void) {
  if (!target) {
    target = document.body;
  }

  const entry = document.createElement('div');
  if (initial) {
    initial(entry);
  }
  target.append(entry);

  return {
    entry,
    remove: () => {
      try {
        target?.removeChild?.(entry);
      } catch {}
    },
  };
}

export function createRenderRoot(target?: HTMLElement, initial?: (entry: HTMLDivElement) => void) {
  const { entry, remove } = createEntryNode(target, initial);
  const root = createRoot(entry);
  return {
    root,
    remove: () => {
      root.unmount();
      remove();
    },
  };
}

function scrollNodeIntoView(node: Element, callback?: () => unknown) {
  const { top } = node.getBoundingClientRect();
  console.log(top, top < 90 || top > window.innerHeight - 160);
  if (top < 90 || top > window.innerHeight - 160) {
    node.scrollIntoView({ behavior: 'auto', block: 'center' });
    setTimeout(callback, 100);
  } else {
    callback();
  }
}

const shakeTimers = new WeakMap<Element, number>();
export function shakeNode(node: Element, duration: number = 500) {
  if (!node) {
    return;
  }

  const shake = () => {
    const timer = shakeTimers.get(node);
    if (timer) {
      window.clearTimeout(timer);
    }
    shakeTimers.set(
      node,
      window.setTimeout(() => {
        node.classList.remove('anim-shake');
        shakeTimers.delete(node);
      }, duration),
    );
    node.classList.add('anim-shake');
    navigator.vibrate?.(100);
  };

  scrollNodeIntoView(node, shake);
}

const flashTimers = new WeakMap<Element, number>();
export function flashNode(node: Element, duration: number = 500) {
  if (!node) {
    return;
  }

  const flash = () => {
    const timer = flashTimers.get(node);
    if (timer) {
      window.clearTimeout(timer);
    }
    flashTimers.set(
      node,
      window.setTimeout(() => {
        node.classList.remove('anim-flash');
        flashTimers.delete(node);
      }, duration),
    );
    node.classList.add('anim-flash');
  };

  scrollNodeIntoView(node, flash);
}

export function findAncestorNode(
  node: HTMLElement,
  condition: (v: HTMLElement) => boolean,
  maxDepth = 10,
): HTMLElement {
  let current: HTMLElement | null = node;
  let depth = 0;
  while (current && !condition(current) && depth < maxDepth) {
    current = current.parentElement;
    depth++;
  }
  return current && condition(current) ? current : null;
}

export function scrollToSafePosition(node: HTMLElement): (() => void) | undefined {
  const target =
    node.getAttribute('data-component-name') === 'DropdownSelector'
      ? node
      : node.querySelector('[data-component-name="DropdownSelector"]');
  if (!target) {
    return;
  }
  const container = findFormRoot(target as HTMLElement);
  if (!container) {
    return;
  }
  const safeBottom = 56 + 24 + 30 + parseInt(getStyleEnvVariable('--safe-inset-bottom'));
  const bottom = () => {
    const { height, top } = target.getBoundingClientRect();
    return window.visualViewport!.height - height - top;
  };
  if (bottom() > safeBottom) {
    return;
  }

  const dy = () => safeBottom - bottom();
  const requiredScrollHeight = () => container.scrollTop + dy() + window.visualViewport!.height;
  if (requiredScrollHeight() <= container.scrollHeight) {
    const targetTop = container.scrollTop + dy();
    setTimeout(() => container.scrollTo({ top: targetTop + 10, behavior: 'smooth' }), 200);
    return;
  }

  const originalPb = container.style.paddingBottom;
  const pb = parseInt(getComputedStyle(container).paddingBottom.split('px')[0] || '0');
  container.style.paddingBottom = `${pb + requiredScrollHeight() - container.scrollHeight + 100}px`;
  waitUntil(
    () => Promise.resolve(container.scrollHeight),
    (scrollHeight) => {
      return scrollHeight >= requiredScrollHeight();
    },
    50,
    20,
  ).finally(() => {
    const top = container.scrollTop + dy();
    setTimeout(() => container.scrollTo({ top, behavior: 'smooth' }), 200);
  });
  return () => {
    container.style.paddingBottom = originalPb;
  };
}

function findFormRoot(node: HTMLElement): HTMLElement {
  const condition = (v: Element) => v.getAttribute('data-input-role') === 'root-container';
  const target = findAncestorNode(node, condition, 10);
  return target && condition(target) ? target.parentElement : null;
}
