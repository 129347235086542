import { ScheduleItem, useScheduleDetail } from 'components';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { clientStore } from 'state';
import { ScheduleList } from '../ScheduleList';
import { Item } from './Item';

function ScheduleComponent() {
  const schedule = clientStore.info.data?.schedule;

  const scheduleDetail = useScheduleDetail();
  const onClick = useCallback(() => {
    scheduleDetail.open({ schedule });
  }, [schedule]);

  return (
    <Item
      title="Schedule"
      handlerText="View All"
      linkComponent={ScheduleList}
      onClick={schedule ? onClick : undefined}
      clickable
    >
      {schedule ? <ScheduleItem schedule={schedule} /> : <p>No schedule</p>}
    </Item>
  );
}

export const Schedule = observer(ScheduleComponent);
