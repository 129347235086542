/** This file is generated automatically. Do not edit it manually. **/
import { createIcon } from './factory';

export const Agreement = createIcon('ui', 'agreement', 'Agreement');
export const Alert = createIcon('ui', 'alert', 'Alert');
export const AlignCenter = createIcon('ui', 'align-center', 'AlignCenter');
export const AlignLeft = createIcon('ui', 'align-left', 'AlignLeft');
export const AlignRight = createIcon('ui', 'align-right', 'AlignRight');
export const Alternate = createIcon('ui', 'alternate', 'Alternate');
export const Android = createIcon('ui', 'android', 'Android');
export const AppleSolid = createIcon('ui', 'apple-solid', 'AppleSolid');
export const Apple = createIcon('ui', 'apple', 'Apple');
export const Apps = createIcon('ui', 'apps', 'Apps');
export const ArrowLeft = createIcon('ui', 'arrow-left', 'ArrowLeft');
export const ArrowRight = createIcon('ui', 'arrow-right', 'ArrowRight');
export const ArrowUpRight = createIcon('ui', 'arrow-up-right', 'ArrowUpRight');
export const Assign = createIcon('ui', 'assign', 'Assign');
export const Avatar = createIcon('ui', 'avatar', 'Avatar');
export const Band = createIcon('ui', 'band', 'Band');
export const BarChart = createIcon('ui', 'bar-chart', 'BarChart');
export const Barbell = createIcon('ui', 'barbell', 'Barbell');
export const Bell = createIcon('ui', 'bell', 'Bell');
export const Bin = createIcon('ui', 'bin', 'Bin');
export const Birthday = createIcon('ui', 'birthday', 'Birthday');
export const BodyFat = createIcon('ui', 'body-fat', 'BodyFat');
export const Body = createIcon('ui', 'body', 'Body');
export const Bodyweight = createIcon('ui', 'bodyweight', 'Bodyweight');
export const Bold = createIcon('ui', 'bold', 'Bold');
export const BookOpen = createIcon('ui', 'book-open', 'BookOpen');
export const BookText = createIcon('ui', 'book-text', 'BookText');
export const Boxing = createIcon('ui', 'boxing', 'Boxing');
export const Briefcase = createIcon('ui', 'briefcase', 'Briefcase');
export const Building = createIcon('ui', 'building', 'Building');
export const Cable = createIcon('ui', 'cable', 'Cable');
export const CalendarAlt = createIcon('ui', 'calendar-alt', 'CalendarAlt');
export const Calendar = createIcon('ui', 'calendar', 'Calendar');
export const Camera = createIcon('ui', 'camera', 'Camera');
export const Car = createIcon('ui', 'car', 'Car');
export const Card = createIcon('ui', 'card', 'Card');
export const Change = createIcon('ui', 'change', 'Change');
export const Charge = createIcon('ui', 'charge', 'Charge');
export const ChartPie = createIcon('ui', 'chart-pie', 'ChartPie');
export const Chart = createIcon('ui', 'chart', 'Chart');
export const CheckIn = createIcon('ui', 'check-in', 'CheckIn');
export const Check = createIcon('ui', 'check', 'Check');
export const Circle = createIcon('ui', 'circle', 'Circle');
export const Clock = createIcon('ui', 'clock', 'Clock');
export const Close = createIcon('ui', 'close', 'Close');
export const Code = createIcon('ui', 'code', 'Code');
export const Coins = createIcon('ui', 'coins', 'Coins');
export const Contacts = createIcon('ui', 'contacts', 'Contacts');
export const Copy = createIcon('ui', 'copy', 'Copy');
export const Crown = createIcon('ui', 'crown', 'Crown');
export const Cube = createIcon('ui', 'cube', 'Cube');
export const DollorCircle = createIcon('ui', 'dollor-circle', 'DollorCircle');
export const Download = createIcon('ui', 'download', 'Download');
export const DragHorizontal = createIcon('ui', 'drag-horizontal', 'DragHorizontal');
export const DumbBell = createIcon('ui', 'dumb-bell', 'DumbBell');
export const Edit = createIcon('ui', 'edit', 'Edit');
export const Emergency = createIcon('ui', 'emergency', 'Emergency');
export const Empty = createIcon('ui', 'empty', 'Empty');
export const Equipment = createIcon('ui', 'equipment', 'Equipment');
export const ExternalLink = createIcon('ui', 'external-link', 'ExternalLink');
export const EyeSlash = createIcon('ui', 'eye-slash', 'EyeSlash');
export const Eye = createIcon('ui', 'eye', 'Eye');
export const Facebook = createIcon('ui', 'facebook', 'Facebook');
export const FileText = createIcon('ui', 'file-text', 'FileText');
export const Filter = createIcon('ui', 'filter', 'Filter');
export const Flag = createIcon('ui', 'flag', 'Flag');
export const Flash = createIcon('ui', 'flash', 'Flash');
export const Focus = createIcon('ui', 'focus', 'Focus');
export const Folder = createIcon('ui', 'folder', 'Folder');
export const Food = createIcon('ui', 'food', 'Food');
export const Form = createIcon('ui', 'form', 'Form');
export const FormatClear = createIcon('ui', 'format-clear', 'FormatClear');
export const FullscreenExit = createIcon('ui', 'fullscreen-exit', 'FullscreenExit');
export const Fullscreen = createIcon('ui', 'fullscreen', 'Fullscreen');
export const GenderFemale = createIcon('ui', 'gender-female', 'GenderFemale');
export const GenderMale = createIcon('ui', 'gender-male', 'GenderMale');
export const Gender = createIcon('ui', 'gender', 'Gender');
export const Gift = createIcon('ui', 'gift', 'Gift');
export const Globe = createIcon('ui', 'globe', 'Globe');
export const Goal = createIcon('ui', 'goal', 'Goal');
export const Google = createIcon('ui', 'google', 'Google');
export const GroupClass = createIcon('ui', 'group-class', 'GroupClass');
export const HammerMachine = createIcon('ui', 'hammer-machine', 'HammerMachine');
export const Header1 = createIcon('ui', 'header1', 'Header1');
export const Header2 = createIcon('ui', 'header2', 'Header2');
export const Header3 = createIcon('ui', 'header3', 'Header3');
export const Header4 = createIcon('ui', 'header4', 'Header4');
export const Header5 = createIcon('ui', 'header5', 'Header5');
export const Header6 = createIcon('ui', 'header6', 'Header6');
export const HeadphonesMicro = createIcon('ui', 'headphones-micro', 'HeadphonesMicro');
export const Heart = createIcon('ui', 'heart', 'Heart');
export const Height = createIcon('ui', 'height', 'Height');
export const HouseAlt = createIcon('ui', 'house-alt', 'HouseAlt');
export const Hover = createIcon('ui', 'hover', 'Hover');
export const Hr = createIcon('ui', 'hr', 'Hr');
export const HybridTrain = createIcon('ui', 'hybrid-train', 'HybridTrain');
export const Image = createIcon('ui', 'image', 'Image');
export const IndentDecrease = createIcon('ui', 'indent-decrease', 'IndentDecrease');
export const IndentIncrease = createIcon('ui', 'indent-increase', 'IndentIncrease');
export const Injury = createIcon('ui', 'injury', 'Injury');
export const InpersonTrain = createIcon('ui', 'inperson-train', 'InpersonTrain');
export const Instagram = createIcon('ui', 'instagram', 'Instagram');
export const IntakeForm = createIcon('ui', 'intake-form', 'IntakeForm');
export const Invoice = createIcon('ui', 'invoice', 'Invoice');
export const Italic = createIcon('ui', 'italic', 'Italic');
export const Journal = createIcon('ui', 'journal', 'Journal');
export const Kettlebell = createIcon('ui', 'kettlebell', 'Kettlebell');
export const Key = createIcon('ui', 'key', 'Key');
export const Laptop = createIcon('ui', 'laptop', 'Laptop');
export const Layer = createIcon('ui', 'layer', 'Layer');
export const LeverageMachine = createIcon('ui', 'leverage-machine', 'LeverageMachine');
export const LineBreak = createIcon('ui', 'line-break', 'LineBreak');
export const LineChart = createIcon('ui', 'line-chart', 'LineChart');
export const LinkChain = createIcon('ui', 'link-chain', 'LinkChain');
export const Link = createIcon('ui', 'link', 'Link');
export const ListBulleted = createIcon('ui', 'list-bulleted', 'ListBulleted');
export const ListNumbers = createIcon('ui', 'list-numbers', 'ListNumbers');
export const List = createIcon('ui', 'list', 'List');
export const Lock = createIcon('ui', 'lock', 'Lock');
export const Logo = createIcon('ui', 'logo', 'Logo');
export const Mail = createIcon('ui', 'mail', 'Mail');
export const MapPinAlt = createIcon('ui', 'map-pin-alt', 'MapPinAlt');
export const MapPin = createIcon('ui', 'map-pin', 'MapPin');
export const Mark = createIcon('ui', 'mark', 'Mark');
export const Maximize = createIcon('ui', 'maximize', 'Maximize');
export const Medical = createIcon('ui', 'medical', 'Medical');
export const Memo = createIcon('ui', 'memo', 'Memo');
export const Menu = createIcon('ui', 'menu', 'Menu');
export const MessageQuestion = createIcon('ui', 'message-question', 'MessageQuestion');
export const Message = createIcon('ui', 'message', 'Message');
export const Microsoft = createIcon('ui', 'microsoft', 'Microsoft');
export const Minimize = createIcon('ui', 'minimize', 'Minimize');
export const Minus = createIcon('ui', 'minus', 'Minus');
export const MoreHorizontal = createIcon('ui', 'more-horizontal', 'MoreHorizontal');
export const Obstacles = createIcon('ui', 'obstacles', 'Obstacles');
export const OnlineTrain = createIcon('ui', 'online-train', 'OnlineTrain');
export const Overview = createIcon('ui', 'overview', 'Overview');
export const Pause = createIcon('ui', 'pause', 'Pause');
export const PersonalClass = createIcon('ui', 'personal-class', 'PersonalClass');
export const Phone = createIcon('ui', 'phone', 'Phone');
export const PlaySolid = createIcon('ui', 'play-solid', 'PlaySolid');
export const Play = createIcon('ui', 'play', 'Play');
export const Plus = createIcon('ui', 'plus', 'Plus');
export const Program = createIcon('ui', 'program', 'Program');
export const QuestionCircle = createIcon('ui', 'question-circle', 'QuestionCircle');
export const Question = createIcon('ui', 'question', 'Question');
export const Quote = createIcon('ui', 'quote', 'Quote');
export const Redo = createIcon('ui', 'redo', 'Redo');
export const Refresh = createIcon('ui', 'refresh', 'Refresh');
export const Reset = createIcon('ui', 'reset', 'Reset');
export const Rowing = createIcon('ui', 'rowing', 'Rowing');
export const Rows = createIcon('ui', 'rows', 'Rows');
export const Ruler = createIcon('ui', 'ruler', 'Ruler');
export const Run = createIcon('ui', 'run', 'Run');
export const Save = createIcon('ui', 'save', 'Save');
export const Scale = createIcon('ui', 'scale', 'Scale');
export const Search = createIcon('ui', 'search', 'Search');
export const Section = createIcon('ui', 'section', 'Section');
export const Services = createIcon('ui', 'services', 'Services');
export const SettingsAlt = createIcon('ui', 'settings-alt', 'SettingsAlt');
export const Settings = createIcon('ui', 'settings', 'Settings');
export const SignOut = createIcon('ui', 'sign-out', 'SignOut');
export const SmallDown = createIcon('ui', 'small-down', 'SmallDown');
export const SmallLeft = createIcon('ui', 'small-left', 'SmallLeft');
export const SmallRight = createIcon('ui', 'small-right', 'SmallRight');
export const SmallUpDown = createIcon('ui', 'small-up-down', 'SmallUpDown');
export const SmallUp = createIcon('ui', 'small-up', 'SmallUp');
export const Smartphone = createIcon('ui', 'smartphone', 'Smartphone');
export const SmithMachine = createIcon('ui', 'smith-machine', 'SmithMachine');
export const Sort = createIcon('ui', 'sort', 'Sort');
export const Spinner = createIcon('ui', 'spinner', 'Spinner');
export const Star = createIcon('ui', 'star', 'Star');
export const Strength = createIcon('ui', 'strength', 'Strength');
export const Strikethrough = createIcon('ui', 'strikethrough', 'Strikethrough');
export const Superset = createIcon('ui', 'superset', 'Superset');
export const Swimming = createIcon('ui', 'swimming', 'Swimming');
export const Tablet = createIcon('ui', 'tablet', 'Tablet');
export const Tag = createIcon('ui', 'tag', 'Tag');
export const Template = createIcon('ui', 'template', 'Template');
export const ThinArrowDown = createIcon('ui', 'thin-arrow-down', 'ThinArrowDown');
export const ThinArrowUp = createIcon('ui', 'thin-arrow-up', 'ThinArrowUp');
export const Tiktok = createIcon('ui', 'tiktok', 'Tiktok');
export const Timer = createIcon('ui', 'timer', 'Timer');
export const Trainer = createIcon('ui', 'trainer', 'Trainer');
export const TrendingUp = createIcon('ui', 'trending-up', 'TrendingUp');
export const Twitter = createIcon('ui', 'twitter', 'Twitter');
export const Underline = createIcon('ui', 'underline', 'Underline');
export const Undo = createIcon('ui', 'undo', 'Undo');
export const Ungroup = createIcon('ui', 'ungroup', 'Ungroup');
export const Update = createIcon('ui', 'update', 'Update');
export const UploadAlt = createIcon('ui', 'upload-alt', 'UploadAlt');
export const User = createIcon('ui', 'user', 'User');
export const Users = createIcon('ui', 'users', 'Users');
export const Variant = createIcon('ui', 'variant', 'Variant');
export const Video = createIcon('ui', 'video', 'Video');
export const View = createIcon('ui', 'view', 'View');
export const Vital = createIcon('ui', 'vital', 'Vital');
export const Warn = createIcon('ui', 'warn', 'Warn');
export const Water = createIcon('ui', 'water', 'Water');
export const X = createIcon('ui', 'x', 'X');
export const Yoga = createIcon('ui', 'yoga', 'Yoga');
export const Youtube = createIcon('ui', 'youtube', 'Youtube');

export const Icons = {
  Agreement,
  Alert,
  AlignCenter,
  AlignLeft,
  AlignRight,
  Alternate,
  Android,
  AppleSolid,
  Apple,
  Apps,
  ArrowLeft,
  ArrowRight,
  ArrowUpRight,
  Assign,
  Avatar,
  Band,
  BarChart,
  Barbell,
  Bell,
  Bin,
  Birthday,
  BodyFat,
  Body,
  Bodyweight,
  Bold,
  BookOpen,
  BookText,
  Boxing,
  Briefcase,
  Building,
  Cable,
  CalendarAlt,
  Calendar,
  Camera,
  Car,
  Card,
  Change,
  Charge,
  ChartPie,
  Chart,
  CheckIn,
  Check,
  Circle,
  Clock,
  Close,
  Code,
  Coins,
  Contacts,
  Copy,
  Crown,
  Cube,
  DollorCircle,
  Download,
  DragHorizontal,
  DumbBell,
  Edit,
  Emergency,
  Empty,
  Equipment,
  ExternalLink,
  EyeSlash,
  Eye,
  Facebook,
  FileText,
  Filter,
  Flag,
  Flash,
  Focus,
  Folder,
  Food,
  Form,
  FormatClear,
  FullscreenExit,
  Fullscreen,
  GenderFemale,
  GenderMale,
  Gender,
  Gift,
  Globe,
  Goal,
  Google,
  GroupClass,
  HammerMachine,
  Header1,
  Header2,
  Header3,
  Header4,
  Header5,
  Header6,
  HeadphonesMicro,
  Heart,
  Height,
  HouseAlt,
  Hover,
  Hr,
  HybridTrain,
  Image,
  IndentDecrease,
  IndentIncrease,
  Injury,
  InpersonTrain,
  Instagram,
  IntakeForm,
  Invoice,
  Italic,
  Journal,
  Kettlebell,
  Key,
  Laptop,
  Layer,
  LeverageMachine,
  LineBreak,
  LineChart,
  LinkChain,
  Link,
  ListBulleted,
  ListNumbers,
  List,
  Lock,
  Logo,
  Mail,
  MapPinAlt,
  MapPin,
  Mark,
  Maximize,
  Medical,
  Memo,
  Menu,
  MessageQuestion,
  Message,
  Microsoft,
  Minimize,
  Minus,
  MoreHorizontal,
  Obstacles,
  OnlineTrain,
  Overview,
  Pause,
  PersonalClass,
  Phone,
  PlaySolid,
  Play,
  Plus,
  Program,
  QuestionCircle,
  Question,
  Quote,
  Redo,
  Refresh,
  Reset,
  Rowing,
  Rows,
  Ruler,
  Run,
  Save,
  Scale,
  Search,
  Section,
  Services,
  SettingsAlt,
  Settings,
  SignOut,
  SmallDown,
  SmallLeft,
  SmallRight,
  SmallUpDown,
  SmallUp,
  Smartphone,
  SmithMachine,
  Sort,
  Spinner,
  Star,
  Strength,
  Strikethrough,
  Superset,
  Swimming,
  Tablet,
  Tag,
  Template,
  ThinArrowDown,
  ThinArrowUp,
  Tiktok,
  Timer,
  Trainer,
  TrendingUp,
  Twitter,
  Underline,
  Undo,
  Ungroup,
  Update,
  UploadAlt,
  User,
  Users,
  Variant,
  Video,
  View,
  Vital,
  Warn,
  Water,
  X,
  Yoga,
  Youtube,
};
