import { BusinessScheduleVo } from '@cocast/api-client';
import { MultilineText } from 'legacy/MultilineText';
import { observer } from 'mobx-react-lite';

interface Props {
  schedule: BusinessScheduleVo;
}

function ScheduleDetailNoteComponent({ schedule: { description } }: Props) {
  return description ? (
    <MultilineText className="text-b2 text-base-400" space={8}>
      {description}
    </MultilineText>
  ) : (
    <div className="flex-center text-b2 py-xl">
      <p>No notes provided</p>
    </div>
  );
}

export const ScheduleDetailNote = observer(ScheduleDetailNoteComponent);
