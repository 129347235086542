import { Icons } from '@cocast/icons';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { default as classNames } from 'classnames';
import { ForwardedRef, forwardRef, memo, ReactNode, useEffect } from 'react';
import { navigation, onAndroidBack } from '../libs';

interface Props {
  title: string;
  headerTitle?: string;
  children?: ReactNode | ReactNode[];
  content?: ReactNode | ReactNode[];
  onBack?: boolean | 'back' | (() => void);
  noHeader?: boolean;
  noContentTitle?: boolean;
  contentClassName?: string;
  contentTitle?: ReactNode | ReactNode[];
  segment?: ReactNode;
  operation?: ReactNode;
  backDefaultHref?: string;
  headerToolbarClassName?: string;
  contentToolbarClassName?: string;
  className?: string;
  footer?: ReactNode | ReactNode[];
}

const back = () => history.back();

function PageComponent(
  {
    title,
    headerTitle,
    segment,
    operation,
    noHeader,
    children,
    content,
    onBack,
    noContentTitle,
    contentClassName,
    contentTitle,
    backDefaultHref,
    contentToolbarClassName,
    headerToolbarClassName,
    className,
    footer,
  }: Props,
  ref?: ForwardedRef<HTMLElement>,
) {
  useEffect(() => {
    if (onBack === 'back') {
      return onAndroidBack(() => navigation().pop());
    }
  }, [onBack]);

  return (
    <IonPage
      className={classNames(className, noHeader ? 'pt-[calc(var(--ion-safe-area-top,0)+24px)]' : null)}
      ref={ref}
    >
      {noHeader ? null : (
        <IonHeader className="mx-auto max-w-[var(--max-content-width)]">
          <IonToolbar className={headerToolbarClassName}>
            {onBack ? (
              <IonButtons
                slot="start"
                onClick={onBack === 'back' ? undefined : typeof onBack === 'function' ? onBack : back}
                className="pl-xs"
              >
                {onBack === 'back' ? (
                  <IonBackButton defaultHref={backDefaultHref} text="" />
                ) : (
                  <Icons.ArrowLeft size={6} aria-hidden="true" />
                )}
              </IonButtons>
            ) : null}
            {segment ? (
              <div className="w-full h-full flex-center absolute left-0 top-0">{segment}</div>
            ) : (
              <IonTitle>{headerTitle || title}</IonTitle>
            )}
            {operation ? (
              <IonButtons className="pr-xs" slot="end">
                {operation}
              </IonButtons>
            ) : null}
          </IonToolbar>
        </IonHeader>
      )}

      <IonContent fullscreen className="">
        {noContentTitle ? null : (
          <IonHeader collapse="condense">
            <IonToolbar
              className={classNames('mx-auto max-w-[var(--max-content-width)] mt-lg', contentToolbarClassName)}
            >
              {!contentTitle || typeof contentTitle === 'string' ? (
                <IonTitle size="large">{contentTitle || title}</IonTitle>
              ) : (
                contentTitle
              )}
            </IonToolbar>
          </IonHeader>
        )}
        <section className={classNames(contentClassName, 'px-md pb-[128px] mx-auto max-w-[var(--max-content-width)]')}>
          {children}
        </section>
        {content}
      </IonContent>

      {footer ? <IonFooter className="page-footer">{footer}</IonFooter> : null}
    </IonPage>
  );
}

export const Page = memo(forwardRef(PageComponent));
