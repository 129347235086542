import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { setConfig } from '@cocast/api-client';
import { setSuffix } from '@cocast/hooks/useDocumentTitle';
import { toast } from '@cocast/ui/toast';
import { setupIonicReact } from '@ionic/react';
import { default as dayjs } from 'dayjs';
import { default as duration } from 'dayjs/plugin/duration';
import { default as isBetween } from 'dayjs/plugin/isBetween';
import { default as isSameOrAfter } from 'dayjs/plugin/isSameOrAfter';
import { default as isSameOrBefore } from 'dayjs/plugin/isSameOrBefore';
import { default as localeData } from 'dayjs/plugin/localeData';
import { default as localizedFormat } from 'dayjs/plugin/localizedFormat';
import { default as minMax } from 'dayjs/plugin/minMax';
import { default as relativeTime } from 'dayjs/plugin/relativeTime';
import { default as timezone } from 'dayjs/plugin/timezone';
import { default as toObject } from 'dayjs/plugin/toObject';
import { default as utc } from 'dayjs/plugin/utc';
import { default as weekday } from 'dayjs/plugin/weekday';
import { H } from 'highlight.run';
import 'virtual:svg-icons-register';
import { getAuthenticationToken, navigation } from './libs';
import { accountAction, uiAction } from './state';
import './styles/index.css';
import { getEnv, initStorage, isOnline, storage } from './utils';
import { Sheet } from './widgets';

dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(minMax);
dayjs.extend(relativeTime);
// dayjs.extend(objectSupport);
dayjs.extend(timezone);
dayjs.extend(toObject);
dayjs.extend(utc);
dayjs.extend(weekday);

setupIonicReact({ mode: 'ios', rippleEffect: true, animated: true });

const env = getEnv();
console.info(`[client-portal] Current env is "${env}"`);
setConfig({
  env,
  toast,
  onAuthenticationFailed: async () => {
    await Promise.all([Sheet.closeAll(), navigation().popToRoot()]);
    return accountAction.logout();
  },
});

setSuffix('Cocast.fit for Client');

if (isOnline()) {
  H.init('memzjppg', {
    serviceName: 'client-portal',
    version: BUNDLE_VERSION,
    tracingOrigins: true,
    disableSessionRecording: true,
    disableBackgroundRecording: true,
  });
}

export async function setup() {
  const info = await CapacitorUpdater.notifyAppReady();
  console.log('[client-portal] App bundle ready', info);
  await initStorage();
  const token = await getAuthenticationToken();
  if (token) {
    setConfig({ auth: token });
    accountAction.setAuthenticationToken(token);
  }

  const liveDemo = await storage.get('isLiveDemo');
  if (liveDemo) {
    uiAction.setLiveDemo(true);
  }
}
