import type { BaseSyntheticEvent, SyntheticEvent } from 'react';

export function preventSyntheticEvent<T extends Element = any, E extends Event = any>(
  e: BaseSyntheticEvent<T, E> | SyntheticEvent<T, E> | Event,
): unknown {
  e.preventDefault();
  e.stopPropagation();
  return false;
}

export function withPreventEvent(action: (e: BaseSyntheticEvent | Event) => void) {
  return (e: BaseSyntheticEvent | Event) => {
    action(e);
    return preventSyntheticEvent(e);
  };
}

type ReactEventHandler<T> = (e: T) => void;

export function withPersistReactEvent<T extends BaseSyntheticEvent = SyntheticEvent>(
  handler: ReactEventHandler<T>,
): ReactEventHandler<T> {
  return (e: T) => {
    e.persist();
    return handler(e);
  };
}
