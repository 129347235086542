import { api } from '@cocast/api-client';
import { useStateRef } from '@cocast/hooks/useStateRef';
import { Icons } from '@cocast/icons';
import { Button } from '@cocast/ui/button';
import { Input } from 'legacy/Input';
import { Password } from 'legacy/Input/Password';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { accountStartRoute } from 'routes';
import { Page } from 'widgets';
import { accountState } from './state';
import { afterLogin } from './utils';

function LoginComponent() {
  const { email } = accountState;
  const [password, setPassword, passwordRef] = useStateRef('');

  const onContinue = useCallback(async () => {
    const [error, info] = await api.ClientAccount.login({
      email: accountState.email,
      password: passwordRef.current,
    });
    if (error) {
      return;
    }
    await afterLogin(info);
  }, []);

  if (!email) {
    accountStartRoute.replace();
  }

  const valid = password.length >= 4;
  return (
    <Page
      title="Log in"
      contentTitle="Welcome back 👋"
      contentClassName="space-y-lg pt-lg"
      onBack="back"
      backDefaultHref={accountStartRoute.path}
    >
      <Input
        className="dark"
        inputClassName="!text-b1 font-normal"
        prefix={<Icons.Mail size={4} />}
        placeholder="Your email address"
        type="email"
        value={email}
        readOnly
      />
      <Password
        className="dark"
        inputClassName="!text-b1 font-normal"
        placeholder="Password"
        onValueChange={setPassword}
      />
      <Button className="btn w-full lg lemon solid !font-black !mt-xxl" onClick={onContinue} disabled={!valid}>
        Log in
      </Button>
    </Page>
  );
}

export const Login = observer(LoginComponent);
