import { action } from 'mobx';

export class StoreUtils {
  @action
  public readonly setState = (v: Partial<this> | ((store: this) => Partial<this> | void)): void => {
    if (typeof v === 'object') {
      Object.assign(this, v);
      return;
    }

    const s = v(this);
    if (s) {
      Object.assign(this, s);
    }
  };
}
