import { IconComponent } from '@cocast/icons';
import { IonToolbar } from '@ionic/react';
import { default as classNames } from 'classnames';
import { Button } from 'legacy/Button';

interface Props {
  text: string;
  icon?: IconComponent;
  handler?: () => unknown;
  disabled?: boolean;
}

export function ToolbarButtonComponent({ text, icon: Icon, disabled, handler }: Props) {
  return (
    <IonToolbar
      color={disabled ? 'light' : 'primary'}
      className={classNames('!pb-0', disabled ? null : 'touch-opacity')}
    >
      <Button
        className={classNames(
          'btn transparent w-full font-black pb-[calc(var(--ion-safe-area-bottom)/2)] !h-[calc(26px+var(--ion-safe-area-bottom))]',
          disabled ? 'text-base opacity-80' : 'text-black',
        )}
        onClick={handler}
        disabled={disabled}
      >
        {Icon ? <Icon /> : null}
        <span>{text}</span>
      </Button>
    </IonToolbar>
  );
}

export const ToolbarButton = ToolbarButtonComponent;
