import { getImageDeliverUrl } from '@cocast/utils';
import { formatProgramWeek } from '@cocast/utils-business/format';
import { default as classNames } from 'classnames';
import { useTrainingProgramDetail } from 'components';
import { Avatar } from 'legacy/Avatar';
import { memo, useCallback } from 'react';
import { businessStore, clientStore } from 'state';
import { ProgressCircle } from 'widgets';
import { Item } from './Item';

function ProgramComponent() {
  const training = clientStore.info.data?.training;
  const program = training?.program;

  const trainingProgramDetail = useTrainingProgramDetail();
  const onClick = useCallback(() => trainingProgramDetail.open(), []);

  if (!program) {
    return null;
  }

  const { programTotalCompliance, programCurrentDay } = training;
  const { weeks, name, createdBy, cover } = program;
  const staff = businessStore.getStaff(createdBy);

  return (
    <Item
      title="Program"
      className={classNames(
        'space-x-lg bg-cover bg-center !p-0 flex overflow-hidden',
        cover ? 'h-[180px] xxs:h-[210px] xs:h-[240px] sm:h-[280px] items-end' : 'h-[148px] flex-center',
      )}
      style={cover ? { backgroundImage: `url("${getImageDeliverUrl(cover, { w: 600 })}")` } : undefined}
      onClick={onClick}
      clickable
    >
      <div
        className={classNames('w-full h-[110px] flex', cover ? 'items-end' : 'items-center')}
        style={
          cover
            ? { background: 'linear-gradient(181deg, rgba(0, 0, 0, 0.00) 0.54%, rgba(0, 0, 0, 0.80) 99.45%)' }
            : undefined
        }
      >
        <div className="flex-between-center w-full px-lg py-sm space-x-lg">
          <div>
            <p className="text-caption lowercase">
              {weeks?.length} weeks plan • {formatProgramWeek(programCurrentDay)}
            </p>
            <h4 className="py-[2px] capitalize line-clamp-2">{name}</h4>
            {staff ? (
              <div className="flex-start-center space-x-xs">
                <Avatar size={20} name={staff.name} src={staff.avatar} id={createdBy} crossOrigin />
                <p className="text-caption-light text-base-200">Planed by {staff.name}</p>
              </div>
            ) : null}
          </div>

          <ProgressCircle
            className="h-full w-[64px]"
            strokeWidth={14}
            progress={programTotalCompliance}
            color="yellow"
            trailColor="grey"
          />
        </div>
      </div>
    </Item>
  );
}

export const Program = memo(ProgramComponent);
