import { APIErrorCode, APIHosts, EnvType } from '@cocast/types';
import { PromiseResult, upperFirst } from '@cocast/utils';
import { getConfig, getEnv } from './config';
import { APIResponse, getApiJson, postApiJson } from './http';

export interface ApiExecOptions {
  disableErrorToast?: boolean;
  toastLoading?: boolean | string;
  onError?: (error: any) => void;
  host?: string;
  env?: EnvType;
  auth?: string;
  init?: RequestInit;
}

export async function exec(
  type: 'query' | 'mutation',
  name: string,
  variables?: {},
  options?: ApiExecOptions,
): PromiseResult<unknown> {
  if (options?.toastLoading) {
    getConfig().toast?.loading(typeof options?.toastLoading === 'boolean' ? 'Loading...' : options?.toastLoading);
  }
  const url = `${options?.host || getAPIHost(options?.env || getEnv())}/api-proxy/${name}`;
  const params: RequestInit = {
    credentials: 'include',
    headers: {},
    ...(options?.init || {}),
  };

  const auth = options?.auth || getConfig().auth;
  if (auth) {
    (params.headers as Record<string, string>).authentication = auth;
  }

  const [success, result, response] =
    type === 'query'
      ? await getApiJson(`${url}${variables ? `?v=${encodeURIComponent(JSON.stringify(variables))}` : ''}`, params)
      : await postApiJson(url, variables, params);

  if (options?.toastLoading) {
    getConfig().toast?.dismiss();
  }
  if (success) {
    return [null, result];
  }

  if (response.statusCode === -2) {
    const errors = response.stacks;
    handleError(name, response, options);
    const error = errors[0] as unknown as Error;
    error.name = name;
    return [error, result || null];
  }

  const error = response as unknown as Error;
  error.name = name;
  handleError(name, response, options);
  return [error, null];
}

function handleError(name: string, response: APIResponse, options?: ApiExecOptions) {
  setTimeout(() => {
    const [resolver, method] = name.split('_');
    const api = `${resolver}Resolver.${method}`;

    let message: string;
    if (response.stacks?.length) {
      response.stacks?.forEach((error: any) => {
        if (!message && error.message) {
          message = formatErrorMessage(error.message);
        }
        handleGraphqlError(api, error, options?.onError);
      });
    } else {
      console.error(`🌐 [${api}]`, response.message, response);
    }

    if (!message) {
      message = response.message;
    }
    if (!options?.disableErrorToast) {
      getConfig().toast?.error(message);
    }
  }, 0);
}

function handleGraphqlError(api: string, error: any, onError?: (e: Error) => unknown) {
  console.error(`🌐 [${api}]`, error.message, error);
  const { onAuthenticationFailed } = getConfig();
  if (onAuthenticationFailed && isAuthenticationTokenError(error)) {
    onAuthenticationFailed(error);
  } else {
    onError?.(error);
  }
}

const AuthenticationErrorCodes = new Set([
  APIErrorCode.AUTHENTICATION_TOKEN_INVALID,
  APIErrorCode.AUTHENTICATION_TOKEN_NOT_EXISTS,
]);

function isAuthenticationTokenError(error: any) {
  const code = error?.extensions?.errorCode ?? error?.extensions?.code;
  if (!code) {
    return false;
  }
  return AuthenticationErrorCodes.has(code);
}

function formatErrorMessage(v: string) {
  return upperFirst(v.replace(/_/g, ' ').toLowerCase().trim());
}

function getAPIHost(env: EnvType) {
  return env === EnvType.DEV && typeof window === 'undefined' ? 'http://127.0.0.1:4001' : APIHosts.get(env);
}
