import { api, BusinessClientMemoGoalVo } from '@cocast/api-client';
import { useDocumentTitle } from '@cocast/hooks/useDocumentTitle';
import { useLoading } from '@cocast/hooks/useLoading';
import { useStateRef } from '@cocast/hooks/useStateRef';
import { Icons } from '@cocast/icons';
import { IonButton } from '@ionic/react';
import { GoalItem, useGoalForm } from 'components';
import { SkeletonGroup } from 'legacy/Skeleton';
import { memo, useCallback } from 'react';
import { homeRoute } from 'routes';
import { Page, Segment, SegmentOption } from 'widgets';

type Filter = 'inProgress' | 'finished';

const options: SegmentOption<Filter>[] = [
  { label: 'In Progress', value: 'inProgress' },
  { label: 'Finished', value: 'finished' },
];

interface Props {
  filter?: Filter;
}

function GoalListComponent({ filter: initFilter = 'inProgress' }: Props) {
  const [filter, setFilter, filterRef] = useStateRef<Filter>(initFilter);

  const {
    result: goals,
    loading,
    setResult,
  } = useLoading(
    async () => {
      const [, result] = await api.Client.goals({ filter, take: 999 });
      return result;
    },
    [filter],
    { immediate: true },
  );

  const onFinished = useCallback((id: string) => {
    setResult((goals) => ({
      ...goals,
      total: goals.total - 1,
      results: goals.results.filter((i) => i.id !== id),
    }));
  }, []);

  const onCreated = useCallback((goal: BusinessClientMemoGoalVo) => {
    if (filterRef.current === 'finished') {
      return;
    }
    setResult((goals) => ({
      ...goals,
      total: goals.total + 1,
      results: [goal, ...goals.results],
    }));
  }, []);

  const goalForm = useGoalForm();
  const onCreate = useCallback(() => {
    goalForm.open({ onCreated });
  }, []);

  useDocumentTitle('Goals');

  return (
    <Page
      title="Goals"
      segment={
        <Segment<Filter>
          options={options}
          defaultValue={filter}
          onChange={setFilter}
          labelClassName="capitalize font-bold"
        />
      }
      operation={
        <IonButton onClick={onCreate}>
          <Icons.Plus size={7} />
        </IonButton>
      }
      onBack="back"
      backDefaultHref={homeRoute.path}
      headerToolbarClassName="pb-xs !pt-[max(var(--ion-safe-area-top,0),18px)]"
    >
      {loading ? (
        <SkeletonGroup rows={3} itemClassName="!bg-dark" height="86px" rounded="20px" space="16px" />
      ) : goals?.total ? (
        goals.results?.map((goal) => (
          <GoalItem
            key={goal.id}
            goal={goal}
            className="mb-md rounded-lg bg-black px-lg py-sm"
            onFinished={onFinished}
          />
        ))
      ) : (
        <div>No {filter === 'inProgress' ? 'in progress' : 'finished'} goals</div>
      )}
    </Page>
  );
}

export const GoalList = memo(GoalListComponent);
