import { useDelayState } from '@cocast/hooks/useDelayState';
import { Icons } from '@cocast/icons';
import { DurationType } from '@cocast/types';
import { formatDuration } from '@cocast/utils-business/format';
import { default as classNames } from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { clientStore } from 'state';
import { ProgressCircle } from 'widgets';
import { Item } from './Item';

const iconBlockClassName = 'bg-opacity-10 p-sm rounded-md relative';
const iconClassName = 'relative 3xs:w-[28px] 3xs:h-[28px] w-[24px] h-[24px]';
const blurClassName = 'w-[20px] h-[20px] absolute bottom-md bottom-xs left-[15px] opacity-40 blur';
const titleClassName = '3xs:text-b1 text-b2 font-bold';
const valueClassName = '3xs:text-t2 text-t3';

function ActivityComponent() {
  const training = clientStore.info.data?.training;

  const [progress, setProgress] = useDelayState(0, 300);
  useEffect(() => {
    setProgress(training?.programTotalCompliance || 0);
  }, [training?.programTotalCompliance]);

  return (
    <Item title="Activity" className="flex-between-center space-x-lg">
      <div className="3xs:space-y-sm space-y-xs">
        <div className="flex-start-center space-x-sm">
          <div className={classNames(iconBlockClassName, 'bg-primary text-primary')}>
            <div className={classNames(blurClassName, 'bg-primary')} />
            <Icons.Clock className={iconClassName} />
          </div>
          <div>
            <p className={titleClassName}>Workout time</p>
            <h2 className={valueClassName}>
              {training?.duration ? formatDuration(training.duration, DurationType.Minute) : '0 mins'}
            </h2>
          </div>
        </div>
        <div className="flex-start-center space-x-sm">
          <div className={classNames(iconBlockClassName, 'bg-orange text-orange')}>
            <div className={classNames(blurClassName, 'bg-orange')} />
            <Icons.Kettlebell className={iconClassName} />
          </div>
          <div>
            <p className={titleClassName}>Workouts done</p>
            <h2 className={valueClassName}>{training?.workouts || 0}</h2>
          </div>
        </div>
        <div className="flex-start-center space-x-sm">
          <div className={classNames(iconBlockClassName, 'bg-maroon text-maroon')}>
            <div className={classNames(blurClassName, 'bg-maroon')} />
            <Icons.DumbBell className={iconClassName} />
          </div>
          <div>
            <p className={titleClassName}>Exercise done</p>
            <h2 className={valueClassName}>{training?.exercises || 0}</h2>
          </div>
        </div>
      </div>
      <div className="retractable flex-center flex-col max-w-[180px]">
        <ProgressCircle className="w-full h-full" progress={progress} animate>
          <div className="absolute top-[calc(50%-20px)] left-[calc(50%-24px)] text-center">
            <p className="text-b1 text-lemon">{training?.programTotalCompliance || 0}%</p>
            <p className="text-caption-light">Program</p>
          </div>
        </ProgressCircle>
      </div>
    </Item>
  );
}

export const Activity = observer(ActivityComponent);
