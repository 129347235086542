import { api, BusinessExerciseVo } from '@cocast/api-client';
import { buildExerciseMap, mergeBusinessExercise } from '@cocast/exercises';
import { Loadable, StoreUtils } from '@cocast/mobx';
import { Exercise } from '@cocast/types';
import { ExerciseImageSize, getExercisePreview } from '@cocast/utils-business/exercise';
import { computed, makeObservable, observable } from 'mobx';
import { clientStore } from '../client/store';

export class BusinessStore extends StoreUtils {
  constructor() {
    super();
    makeObservable(this);
    window.__business = this;
  }

  @computed({ keepAlive: true })
  get info() {
    return clientStore.info.data?.business;
  }

  @computed({ keepAlive: true })
  get workouts() {
    return clientStore.info.data?.workouts;
  }

  @computed({ keepAlive: true })
  get metrics() {
    return clientStore.info.data?.metrics;
  }

  @computed({ keepAlive: true })
  get staffs() {
    return clientStore.info.data?.staffs;
  }

  @observable
  public businessExercises = Loadable.from<BusinessExerciseVo[]>(api.BusinessExercise.list);

  @computed({ keepAlive: true })
  public get exercises() {
    const exercises = this.businessExercises.data;
    return mergeBusinessExercise(exercises);
  }

  @computed({ keepAlive: true })
  public get exerciseMap() {
    return buildExerciseMap(this.exercises, this.businessExercises.data);
  }

  @computed({ keepAlive: true })
  public get businessId() {
    return this.info?.id;
  }

  public readonly getWorkout = (id: string) => {
    return this.workouts?.find((workout) => workout.id === id);
  };

  public readonly getStaff = (id: string) => {
    return this.staffs?.find((staff) => staff.id === id);
  };

  public getExerciseImage = (exercise: BusinessExerciseVo | Exercise | string, size?: ExerciseImageSize) => {
    const item = typeof exercise === 'string' ? this.exerciseMap.get(exercise) : exercise;
    return item ? getExercisePreview(item, size) : null;
  };

  public getExercise = (id: string, allowUnknown?: boolean) => {
    const exercise = this.exerciseMap.get(id);
    if (!exercise && allowUnknown) {
      console.warn('Exercise not found for:', id);
      return { id, name: 'Unknown exercise' } as Exercise;
    }
    return exercise;
  };
}

export const businessStore = new BusinessStore();
