import { useDocumentTitle } from '@cocast/hooks/useDocumentTitle';
import { useLoadable } from '@cocast/mobx';
import { Avatar } from 'legacy/Avatar';
import { observer } from 'mobx-react-lite';
import { userRoute } from 'routes';
import { accountStore, businessStore, clientStore, uiStore } from 'state';
import { LivePreviewTag, Page } from 'widgets';
import { Activity } from './Activity';
import { Goal } from './Goals';
import { Program } from './Program';
import { Schedule } from './Schedule';
import { DashboardSkeleton } from './Skeleton';

function DashboardComponent() {
  const { loading } = useLoadable(clientStore.info);
  const { info } = accountStore;
  const { info: business } = businessStore;
  const { liveDemo } = uiStore;

  useDocumentTitle('Dashboard');

  if (loading) {
    return <DashboardSkeleton />;
  }

  return (
    <Page
      title="Dashboard"
      contentTitle={
        <div className="px-md flex-between-center cursor-pointer" onClick={userRoute.navigate.withPreventEvent}>
          <div className="flex-start-center space-x-sm">
            <Avatar size={42} id={info.id} name={info.name} src={info.avatar} crossOrigin />
            <div>
              <h3 className="text-t3">Hi, {info.firstname} 👋</h3>
              <p className="text-b2">Welcome to {business.name} !</p>
            </div>
          </div>
        </div>
      }
      contentClassName="pt-xl"
    >
      <Activity />
      <Schedule />
      <Program />
      <Goal />
      {liveDemo ? <LivePreviewTag /> : null}
    </Page>
  );
}

export const Dashboard = observer(DashboardComponent);
