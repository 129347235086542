import { IconComponent } from '@cocast/icons';
import { WithToString } from '@cocast/types';
import { default as classNames } from 'classnames';
import { Button } from 'legacy/Button';
import { CSSProperties, memo, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { defaultSnapProps, SnapPointProps } from 'react-spring-bottom-sheet/dist/types';
import { onAndroidBack } from '../../libs';

export interface SheetProps {
  open: boolean;
  setOpen: {
    enable: () => unknown;
    disable: () => unknown;
    destroy?: () => unknown;
  };
  mask?: boolean;
  stack?: boolean;
  title?: ReactNode;
  titleClassName?: string;
  onConfirm?: ((...params: unknown[]) => unknown) | boolean;
  confirmText?: string;
  confirmIcon?: IconComponent;
  confirmClassName?: string;
  confirmDisabled?: boolean;
  confirmDanger?: boolean;
  onCancel?: (() => unknown) | boolean;
  cancelText?: string;
  cancelClassName?: string;
  cancelIcon?: IconComponent;
  snap?: number | 'min';
  noResize?: boolean;
  noScrollClose?: boolean;
  noScrollLocking?: boolean;
  noExpandOnDrag?: boolean;
  minSnap?: number;
  children?: ReactNode | ReactNode[];
  style?: CSSProperties & { [key: `--${string}`]: WithToString };
}

const openedSheets = new Map<Symbol, SheetProps['setOpen']>();

function SheetComponent({
  open,
  setOpen,
  title,
  titleClassName,
  children,
  cancelClassName,
  confirmClassName,
  cancelIcon: CancelIcon,
  cancelText,
  confirmText,
  confirmIcon: ConfirmIcon,
  confirmDisabled,
  confirmDanger,
  onCancel,
  noScrollClose,
  noScrollLocking,
  noExpandOnDrag,
  onConfirm,
  snap = 'min',
  noResize,
  minSnap,
  mask = true,
  style,
  stack,
}: SheetProps) {
  const instanceKey = useMemo(() => Symbol(), []);
  useEffect(() => {
    if (open) {
      openedSheets.set(instanceKey, setOpen);
      return onAndroidBack(setOpen.disable);
    } else {
      openedSheets.delete(instanceKey);
    }
  }, [open]);

  const header = title ? <h1 className={classNames('text-t4', titleClassName)}>{title}</h1> : null;
  const footer =
    onConfirm || onCancel ? (
      <div className="flex-center child:retractable">
        {onCancel ? (
          <div className="bg-black">
            <Button
              className={classNames('transparent text-base', cancelClassName)}
              onClick={typeof onCancel === 'boolean' ? setOpen.disable : onCancel}
            >
              {CancelIcon ? <CancelIcon /> : null}
              <span>{cancelText || 'Cancel'}</span>
            </Button>
          </div>
        ) : null}
        {onConfirm ? (
          <div className={classNames(confirmDanger ? 'bg-red' : 'bg-lemon', confirmDisabled ? 'bg-opacity-60' : null)}>
            <Button
              className={classNames('transparent text-black', confirmClassName)}
              onClick={typeof onConfirm === 'boolean' ? setOpen.disable : onConfirm}
              disabled={confirmDisabled}
            >
              {ConfirmIcon ? <ConfirmIcon /> : null}
              <span>{confirmText || (typeof onConfirm === 'boolean' ? 'Close' : 'Confirm')}</span>
            </Button>
          </div>
        ) : null}
      </div>
    ) : null;

  const snapPoints = useCallback(
    ({ maxHeight, minHeight }: SnapPointProps) => {
      const min = minSnap ? minSnap : minHeight;
      return snap === 'min' ? (noResize ? min : [min, maxHeight * 0.8]) : [maxHeight - maxHeight / 5, maxHeight * snap];
    },
    [snap, minSnap, noResize],
  );

  return (
    <BottomSheet
      className="sheet"
      open={open}
      onDismiss={noScrollClose ? undefined : setOpen.disable}
      defaultSnap={defaultSnap}
      snapPoints={snapPoints}
      header={header}
      footer={footer}
      blocking={mask}
      initialFocusRef={false}
      expandOnContentDrag={!noExpandOnDrag}
      scrollLocking={noScrollLocking ? false : undefined}
      style={style}
    >
      {children}
    </BottomSheet>
  );
}

const SheetWithoutAPI = memo(SheetComponent);

function defaultSnap({ snapPoints, lastSnap }: defaultSnapProps) {
  return lastSnap ?? Math.min(...snapPoints);
}

function closeAll() {
  const modals = Array.from(openedSheets.values());
  if (!modals.length) {
    return Promise.resolve();
  }
  return new Promise<void>((resolve) => {
    modals.forEach((modal) => modal.disable());
    setTimeout(resolve, 500);
  });
}

export const Sheet = SheetWithoutAPI as typeof SheetWithoutAPI & {
  closeAll: () => Promise<void>;
};

Sheet.closeAll = closeAll;
