import { BusinessExerciseVo } from '@cocast/api-client';
import { StreamPlayer } from '@cocast/components/StreamPlayer';
import { useImagePreload } from '@cocast/hooks/useImagePreload';
import { useTimeout } from '@cocast/hooks/useTimer';
import { ColoredIcons, Icons } from '@cocast/icons';
import { Exercise } from '@cocast/types';
import { getImageDeliverUrl, getStreamDeliverCover } from '@cocast/utils';
import { default as classNames } from 'classnames';
import { Image } from 'legacy/Image';
import { memo, useMemo, useState } from 'react';
import { default as YouTube } from 'react-youtube';

interface Props {
  exercise: Exercise | BusinessExerciseVo;
  delay?: number;
}

interface GalleryItem {
  type: 'image' | 'video' | 'youtube' | string;
  src: string;
}

function ExerciseGalleryComponent({ exercise, delay }: Props) {
  const { items, images } = useMemo(() => {
    const { video, videoType } = exercise;
    const images = exercise.images?.length ? exercise.images : exercise?.imageId ? [exercise?.imageId] : [];
    const items: GalleryItem[] = video ? [{ type: videoType || 'video', src: video }] : [];
    return {
      images,
      items: images?.length ? items.concat(images?.map((src) => ({ type: 'image', src }))) : items,
    };
  }, [exercise]);

  const [index, setIndex] = useState(delay ? -1 : 0);

  useImagePreload(images);

  if (delay) {
    useTimeout(() => setIndex(0), delay);
  }

  if (!items.length) {
    return (
      <div className="w-full rounded-lg py-lg bg-dark-200 flex-center flex-col my-lg">
        <Icons.Image size={9} className="opacity-30" />
        <p className="text-b2 text-base-400 mt-xs">No exercise medias</p>
      </div>
    );
  }

  return (
    <section className="my-lg">
      <div
        className={classNames('aspect-[16/8] rounded-lg overflow-hidden', index > -1 ? 'bg-base' : 'loading-skeleton')}
      >
        {index > -1 ? <Item item={items[index]} /> : null}
      </div>
      {items.length > 1 ? (
        <div className="mt-xs w-full flex-start-center space-x-xs">
          {items.map(({ type, src }, i) => {
            return (
              <div
                key={src}
                className={classNames(
                  'rounded-md overflow-hidden w-[52px] aspect-square bg-cover bg-center border cursor-pointer transition box-content flex-center bg-base-400',
                  index === i ? 'border-lemon' : 'border-dark opacity-60',
                )}
                onClick={() => setIndex(i)}
                style={
                  type === 'youtube'
                    ? undefined
                    : {
                        backgroundImage: `url(${
                          type === 'video' ? getStreamDeliverCover(src) : getImageDeliverUrl(src, { w: 200 })
                        })`,
                      }
                }
              >
                {type === 'youtube' ? (
                  <ColoredIcons.Youtube size={8} />
                ) : type === 'video' ? (
                  <Icons.PlaySolid size={5.5} className="text-lemon" />
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}
    </section>
  );
}

export const ExerciseGallery = memo(ExerciseGalleryComponent);

interface ItemProps {
  item: GalleryItem;
}

function ItemComponent({ item: { type, src } }: ItemProps) {
  switch (type) {
    case 'image': {
      return (
        <Image
          alt="gallery"
          src={getImageDeliverUrl(src, { w: 720 })}
          className="w-full h-full"
          imgClassName="max-w-full max-h-full object-contain mx-auto"
          draggable="false"
          spinner
        />
      );
    }
    case 'youtube': {
      return <YouTube videoId={src} className="w-full h-full rounded-lg" iframeClassName="w-full h-full" />;
    }
    case 'video': {
      return <StreamPlayer stream={src} className="w-full h-full rounded-lg" aspectRatio={16 / 8} />;
    }
  }
  return null;
}

const Item = memo(ItemComponent);
