import { createElement, memo, ReactHTML, useEffect, useRef, useState } from 'react';
import { DefaultTransitions } from './defaults';
import { Transition, TransitionProps } from './Transition';

export interface TransitionTextProps {
  as?: keyof ReactHTML;
  children: string;
  className?: string;
  disableAppear?: boolean;
  transition?: Partial<
    Omit<TransitionProps, 'transition' | 'name' | 'show' | 'disableAppear' | 'transitionDelay' | 'asFragment'>
  >;
}

function TransitionTextComponent({
  as = 'p',
  className,
  disableAppear,
  transition = DefaultTransitions.transitionX,
  children,
}: TransitionTextProps) {
  const [show, setShow] = useState(true);
  const [content, setContent] = useState(children);

  const firstChange = useRef(true);
  useEffect(() => {
    if (disableAppear && firstChange.current) {
      firstChange.current = false;
      return;
    }

    setShow(false);
    setTimeout(() => {
      setContent(children);
      setShow(true);
    }, 300);
    firstChange.current = false;
  }, [children]);

  return (
    <Transition
      name="text"
      transition={transition}
      show={show}
      transitionDelay={0}
      disableAppear={disableAppear}
      asFragment
    >
      {createElement(as, { className }, content)}
    </Transition>
  );
}

export const TransitionText = memo(TransitionTextComponent);
