import { ComponentType, createElement, Fragment, HTMLAttributes, memo, ReactHTML } from 'react';

export interface MultilineTextProps extends HTMLAttributes<HTMLParagraphElement> {
  as?: keyof ReactHTML | ComponentType<any>;
  children?: string;
  space?: string | number;
  br?: 1 | 2;
}

function MultilineTextComponent({ children, as = 'p', space, br = 1, ...props }: MultilineTextProps) {
  return createElement(
    as,
    props,
    children?.split('\n').map((line, i) => (
      <Fragment key={i}>
        <span style={i > 0 && space ? { marginTop: space, display: 'block' } : undefined}>{line}</span>
        {space ? null : br === 1 ? (
          <br />
        ) : (
          <>
            <br />
            <br />
          </>
        )}
      </Fragment>
    )),
  );
}

export const MultilineText = memo(MultilineTextComponent);
