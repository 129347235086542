import { BusinessScheduleVo } from '@cocast/api-client';
import { ExerciseDetailSheet, ScheduleDetailSheet, ScheduleItem, WorkoutDetailSheet } from 'components';
import { memo, useCallback } from 'react';
import { Card } from 'widgets';

interface Props {
  schedule: BusinessScheduleVo;
  scheduleDetail: ScheduleDetailSheet;
  workoutDetail: WorkoutDetailSheet;
  exerciseDetail: ExerciseDetailSheet;
}

function ScheduleItemComponent({ schedule, scheduleDetail, workoutDetail, exerciseDetail }: Props) {
  const onClick = useCallback(() => {
    scheduleDetail.open({ schedule, workoutDetail, exerciseDetail });
  }, [schedule]);

  return (
    <Card className="mb-md" onClick={onClick} clickable>
      <ScheduleItem schedule={schedule} />
    </Card>
  );
}

export const ScheduleListItem = memo(ScheduleItemComponent);
