import { useBoolean } from '@cocast/hooks/useBoolean';
import { ForwardedRef, forwardRef, memo } from 'react';
import { Icon, IconType } from '../Icon';
import { Input, InputProps } from './Input';

export interface PasswordInputProps extends InputProps<'input'> {
  defaultShowPassword?: boolean;
  placeholder?: string;
}

function PasswordComponent(
  { defaultShowPassword, suffixClassName, ...inputProps }: PasswordInputProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const [show, setShow] = useBoolean(!!defaultShowPassword);

  return (
    <Input
      ref={ref}
      type={show ? 'text' : 'password'}
      prefix={<Icon name={IconType.key} />}
      suffix={
        <Icon
          name={show ? IconType.eyeSlash : IconType.eye}
          className="cursor-pointer hover:!text-primary"
          onClick={setShow}
        />
      }
      autoComplete="on"
      maxLength={32}
      {...inputProps}
    />
  );
}

export const Password = memo(forwardRef(PasswordComponent));
