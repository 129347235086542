import { BusinessExerciseVo } from '@cocast/api-client';
import { Icons } from '@cocast/icons';
import { Exercise } from '@cocast/types';
import { getExercisePrimaryFocus } from '@cocast/utils-business/exercise';
import { default as classNames } from 'classnames';
import { Tag } from 'components';
import { MultilineText } from 'legacy/MultilineText';
import { memo } from 'react';
import { Sheet, SheetProps, useSheet } from 'widgets';
import { ExerciseGallery } from './ExerciseGallery';

interface Props extends Pick<SheetProps, 'open' | 'setOpen'> {
  exercise: Exercise | BusinessExerciseVo;
}

const featureClassName =
  'rounded-md bg-opacity-10 py-xs pl-xs pr-sm text-caption flex-center space-x-3xs truncate capitalize';

function ExerciseDetailComponent({
  open,
  setOpen,
  exercise,
  exercise: { name, description, tags, equipment, category },
}: Props) {
  return (
    <Sheet open={open} setOpen={setOpen} onConfirm>
      <h2 className="pt-md capitalize">{name}</h2>
      {tags?.length ? (
        <div className="space-x-xs mt-3xs">
          {tags.map((tag) => (
            <Tag key={tag.id} tag={tag} className="sm" />
          ))}
        </div>
      ) : null}

      <section className="flex gap-3xs flex-wrap mt-md">
        <div className={classNames(featureClassName, 'bg-cobalt')}>
          <Icons.Layer size={4.5} className="text-cobalt" />
          <div>
            <p className="text-cobalt opacity-70">Type</p>
            <p className="text-b2 font-bold">{category}</p>
          </div>
        </div>
        <div className={classNames(featureClassName, 'bg-primary')}>
          <Icons.Focus size={4.5} className="text-primary" />
          <div>
            <p className="text-primary opacity-70">Focus</p>
            <p className="text-b2 font-bold">{getExercisePrimaryFocus(exercise)}</p>
          </div>
        </div>
        {equipment ? (
          <div className={classNames(featureClassName, 'bg-mint')}>
            <Icons.Equipment size={4.5} className="text-mint" />
            <div>
              <p className="text-mint opacity-70">Equipment</p>
              <p className="text-b2 font-bold">{equipment}</p>
            </div>
          </div>
        ) : null}
      </section>

      <ExerciseGallery exercise={exercise} delay={500} />

      <h4 className="font-bold mb-3xs mt-lg">Instruction</h4>
      <MultilineText className={classNames('text-b2 text-base-200', description ? null : 'opacity-60')} space={8}>
        {description || 'No instruction provided'}
      </MultilineText>
    </Sheet>
  );
}

const ExerciseDetail = memo(ExerciseDetailComponent);

export function useExerciseDetail() {
  return useSheet(ExerciseDetail);
}

export type ExerciseDetailSheet = ReturnType<typeof useExerciseDetail>;
