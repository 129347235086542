import { storage } from 'utils';

const AUTHENTICATION_TOKEN_KEY = 'authentication';

export async function getAuthenticationToken() {
  return (await storage.get(AUTHENTICATION_TOKEN_KEY)) as string;
}

export async function persistAuthenticationToken(token: string) {
  await storage.set(AUTHENTICATION_TOKEN_KEY, token);
}

export async function removeAuthenticationToken() {
  await storage.remove(AUTHENTICATION_TOKEN_KEY);
}
