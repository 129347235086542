export function preloadImages(imgs: string[], onLoaded?: () => void, onError?: (e: Error) => void) {
  if (onLoaded) {
    return Promise.all(
      imgs.map((img) => {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.onload = () => resolve(null);
          image.onerror = () => reject();
          image.src = img;
        });
      }),
    )
      .then(onLoaded)
      .catch(onError);
  }

  imgs.forEach((img) => {
    const image = new Image();
    image.src = img;
  });
}
