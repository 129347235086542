import { StretchingExercise } from '@cocast/types';

export const StretchingExercises = [
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: '4fbd2de8bb3d09120e6f79a408e26eb4',
    imageId: 'e9e9603a-3783-4616-7a1b-1ee449883100',
    name: 'across chest stretch',
  },
  {
    category: 'stretching',
    recordType: 'assistedBodyweight',
    type: 'static',
    id: '2dcf9869da8fd4dbc1a804c9dc164004',
    imageId: '02edcadd-6542-4932-19f1-d4e31b8fb500',
    name: 'assisted chest stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: 'a63e7bb3f6d6ea8a0b2c0607ca2a94ea',
    imageId: '58d5e1aa-defb-4086-833c-4573f4998900',
    name: 'bent arm chest stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: '03020d3d0b5d5fba583fba24f8f9c6de',
    imageId: 'f9c2de1a-c3ee-4a81-3cc5-ca0da0eaa900',
    name: 'bent arm shoulder stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'dynamic',
    id: '782ec8cc61057cd40a44f3e3a3874469',
    imageId: '29b31752-a64d-4b4a-c7c9-247a49d8ab00',
    name: 'bent knee lying twist',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: '88c9fa7eadeb44760c92df08f4dd7db2',
    imageId: 'b776fb34-b3d3-47b7-a327-b726285ef100',
    name: 'cat stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'dynamic',
    id: 'c9538ebf16a47c3dedd75d46881f85b8',
    imageId: '636ceae9-7070-4eab-ffe8-e48f24447c00',
    name: 'cobra push up',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'dynamic',
    id: '6a330ad67d16b9506f73d47eea5a86d9',
    imageId: '212d93ad-a65d-429c-77de-7858e9059c00',
    name: 'dynamic chest stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: '074d03dc1aeb163e4025916058d57daa',
    imageId: 'e8915397-6795-4f31-f283-6a4cade8bf00',
    name: 'fixed bar back stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: '8b88b9784eef783eda99f3f9f751188e',
    imageId: 'c0981e35-7fcc-417f-7768-71e76699cc00',
    name: 'floor leg up stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'dynamic',
    id: '9dd3c83ab914be6676df465fd40636bd',
    imageId: '7c0c2f0c-dd2e-4b82-9fe0-68b19ae28200',
    name: 'frog hip thrust',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: 'e16e14fea7daa3f1b2315679ad6d45ca',
    imageId: '0b7d8219-dd8e-4f65-e964-0a4209632100',
    name: 'hands behind the back stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'dynamic',
    id: 'e28ee2955e8bacc141c3b97947eb489b',
    imageId: 'aa8a20b6-580c-4cfb-3363-ce128746c600',
    name: 'inclined stool stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: 'd554e06967131b6f62cec10e92fc6548',
    imageId: 'bbdc8e04-28e3-4a5b-1246-91c0da9d0800',
    name: 'keeling hip flexor stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'dynamic',
    id: 'd0a1c5283326ccfa208dd8a0f6a4c6ea',
    imageId: '85f95621-74e7-44aa-7556-f0fc31a7f100',
    name: 'kneeling lateral stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: '69eb849d78d6336782048a51904f85dc',
    imageId: '3b991f76-cd28-47b0-585c-767c71d8cc00',
    name: 'kneeling toe up stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: 'ee951d9eeb822b606dfd7aced7d4c201',
    imageId: 'd3ec97ae-5190-47fd-5a98-b87ceec42a00',
    name: 'kneeling waist stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: 'd4956eee5f81c0a64282d995aedd2067',
    imageId: '0797a091-5724-400d-83c5-076124849e00',
    name: 'lying leg tuck hip twist stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'dynamic',
    id: 'b1439d78ad6363f1bdafcb07cbb66cb2',
    imageId: '9acfa053-cf0e-4f1a-acef-53cef0011100',
    name: 'one arm medial rotation',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: 'dcee8dfbfea385fe7a8781a3bcae5123',
    imageId: '582eb6cc-55dc-4aa7-d849-98346564b700',
    name: 'overhead triceps stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: 'ad03e08d681acad54127fe03d7e270f6',
    imageId: 'debb8982-832c-42ad-5891-e6068e383f00',
    name: 'reverse chest stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'dynamic',
    id: '1a9941c03a16661f062beab73471ef8c',
    imageId: '82ba63fd-bdd8-4a9b-e265-4107caf6ad00',
    name: 'rocking frog stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: '99fb4214bea008811f25a93bcd40e765',
    imageId: '093fc9cd-98c3-415e-277d-ad5e2c904300',
    name: 'rod chest stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: 'b7fbb1b63556cf79f839c76c8f7a101f',
    imageId: 'd5326bb8-3554-40a3-793c-51f2c6a3da00',
    name: 'roll hip thrust',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: '1b1e6ca280f7d66490c8b5b8254976fd',
    imageId: '9c489673-178f-458c-f571-1b458ce00200',
    name: 'roll recumbent rotating hip stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: '68e3776d83d9d24110a1bd6b0712c58b',
    imageId: 'd72d467d-e8a2-491c-4405-4a6633459500',
    name: 'seated leg stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: '06d958861c289a1acc244edded204e02',
    imageId: 'fbb82d44-8b4c-484a-3bfa-386eaca33400',
    name: 'skiing',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: '57b8c2bca3bb867b081fe1c306f29f7d',
    imageId: '69bf00df-77c5-4306-b290-1dfb9eeae500',
    name: 'standing back stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: 'ba12ba405d14dffe9a7cdc7d8c0d0969',
    imageId: '13c9c4ff-db2d-4ae0-504d-7e4db8255900',
    name: 'standing hamstring stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: 'e4739587fa2802188011de1a08396132',
    imageId: 'dd91b1dc-6ce2-4ead-3e3b-d65267d1b100',
    name: 'standing lateral stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: 'da292ba4fe279470d52b9836761a80b6',
    imageId: '28755a8a-fb63-40f9-2568-e34fce36ca00',
    name: 'standing one arm wall stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: '403b8a192fb8bc97caee3c7169dd2e4e',
    imageId: 'f9b0c4ac-fccd-419e-cf2b-b56a7eeff400',
    name: 'standing quadriceps stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: '0400f04a37871629933118a44a1e26dc',
    imageId: '9cc031f8-6a78-4d6e-8265-eddc33fb5a00',
    name: 'standing raised leg stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'dynamic',
    id: '0d8f55fb594201899b75534618d1734d',
    imageId: 'de4b2220-bd87-430c-25ed-811f487ca900',
    name: 'standing reach up rotation stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'dynamic',
    id: 'f17474c696e63c002a3303402760cc9d',
    imageId: 'c66931dc-9620-43df-6ae3-ef7aca68b900',
    name: 'standing w raise',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'static',
    id: 'a56fc2bc2be9ebf6f8db924906205fa2',
    imageId: '8943acb6-15e5-458e-7a59-ae75798d6d00',
    name: 'triceps stretch',
  },
  {
    category: 'stretching',
    recordType: 'stretching',
    type: 'dynamic',
    id: 'c3cb86889922e2cdb6d91114882f4bbc',
    imageId: '95d21d68-907a-4a80-4a84-f7d42b3da600',
    name: "world's greatest stretch",
  },
] as StretchingExercise[];

export const StretchingTypes = ['dynamic', 'static'] as string[];
