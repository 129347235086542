export const DefaultTransitions = {
  transitionY: {
    enter: 'transition-all duration-300 ease-in-out',
    enterFrom: 'opacity-0 translate-y-[min(50vh,400px)]',
    enterTo: 'opacity-100 translate-y-0',
    leave: 'transition-all duration-300 ease-in-out',
    leaveFrom: 'opacity-100 translate-y-0',
    leaveTo: 'opacity-0 translate-y-[min(50vh,400px)]',
    transitionDelay: 250,
  },
  transitionX: {
    enter: 'transition-all duration-250 ease-in-out',
    enterFrom: 'opacity-0 translate-x-[80px]',
    enterTo: 'opacity-100 translate-x-0',
    leave: 'transition-all duration-250 ease-in-out',
    leaveFrom: 'opacity-100 translate-x-0',
    leaveTo: 'opacity-0 translate-x-[-80px]',
    transitionDelay: 200,
  },
  transitionXReverse: {
    enter: 'transition-all duration-250 ease-in-out',
    enterFrom: 'opacity-0 translate-x-[-80px]',
    enterTo: 'opacity-100 translate-x-0',
    leave: 'transition-all duration-250 ease-in-out',
    leaveFrom: 'opacity-100 translate-x-0',
    leaveTo: 'opacity-0 translate-x-[80px]',
    transitionDelay: 200,
  },
  transitionXLight: {
    enter: 'transition-all duration-250 ease-in-out',
    enterFrom: 'opacity-0 translate-x-[40px]',
    enterTo: 'opacity-100 translate-x-0',
    leave: 'transition-all duration-250 ease-in-out',
    leaveFrom: 'opacity-100 translate-x-0',
    leaveTo: 'opacity-0 translate-x-[-40px]',
    transitionDelay: 200,
  },
  transitionXReverseLight: {
    enter: 'transition-all duration-250 ease-in-out',
    enterFrom: 'opacity-0 translate-x-[-40px]',
    enterTo: 'opacity-100 translate-x-0',
    leave: 'transition-all duration-250 ease-in-out',
    leaveFrom: 'opacity-100 translate-x-0',
    leaveTo: 'opacity-0 translate-x-[40px]',
    transitionDelay: 200,
  },
  transitionScale: {
    enter: 'transition-all duration-250 ease-out',
    enterFrom: 'opacity-0 scale-80',
    enterTo: 'opacity-100 scale-100',
    leave: 'transition-all duration-250 ease-in',
    leaveFrom: 'opacity-100 scale-100',
    leaveTo: 'opacity-0 scale-90',
    transitionDelay: 200,
  },
  transitionNone: {
    enter: 'transition-none',
    enterFrom: 'transition-none',
    enterTo: 'transition-none',
    leave: 'transition-none',
    leaveFrom: 'transition-none',
    leaveTo: 'transition-none',
    transitionDelay: 0,
  },
};
