export function isEmptyObject(i: object): boolean {
  return Array.isArray(i) ? i.length === 0 : Object.keys(i).length === 0;
}

export function isNormal(v: unknown): boolean {
  if (typeof v === 'number') {
    return v > 0;
  }
  const str = v?.toString();
  if (typeof str !== 'string') {
    return false;
  }
  return str !== '0' && str !== 'false' && str !== 'undefined' && str !== 'null' && str.length > 0;
}

export function isNil(v: unknown): boolean {
  return typeof v === 'undefined' || v === null;
}

export function isDeepEquals(a: unknown, b: unknown): boolean {
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) {
      return false;
    }
    return a.every((v, i) => isDeepEquals(v, b[i]));
  }

  if (typeof a === 'object' && typeof b === 'object') {
    if (a === null || b === null) {
      return a === b;
    }
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);
    if (aKeys.length !== bKeys.length) {
      return false;
    }
    return aKeys.every((k) => isDeepEquals((a as any)[k], (b as any)[k]));
  }

  return a === b;
}

export function isNumber(v: unknown): boolean {
  return typeof v === 'number';
}

export function isUrl(v: unknown): boolean {
  if (typeof v !== 'string') {
    return false;
  }
  return /^https?:\/\/.+/.test(v) || /^\/\//.test(v);
}

export function notNil(v: unknown): boolean {
  return !isNil(v);
}
