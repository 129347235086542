import { BusinessLoginInfoVo } from '@cocast/api-client';
import { StoreUtils } from '@cocast/mobx';
import { makeObservable, observable } from 'mobx';

class AccountState extends StoreUtils {
  constructor() {
    super();
    makeObservable(this);
  }

  @observable
  public email?: string;

  @observable
  public businesses: BusinessLoginInfoVo[] = [];
}

export const accountState = new AccountState();
