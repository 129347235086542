import { ErrorBoundary } from '@highlight-run/react';
import { IonNav } from '@ionic/react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { navRef } from './libs';
import { setup } from './setup';

setup().then(() => {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(
    <ErrorBoundary>
      <IonNav root={() => <App />} ref={navRef} />
    </ErrorBoundary>,
  );
});
