import type { MutableRefObject } from 'react';
import * as React from 'react';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  type DependencyList,
  type EffectCallback,
} from 'react';
import { disposables, env, transition } from './utils';

export let useIsoMorphicEffect = (effect: EffectCallback, deps?: DependencyList | undefined) => {
  if (env.isServer) {
    useEffect(effect, deps);
  } else {
    useLayoutEffect(effect, deps);
  }
};

let Optional = Symbol();

export function optionalRef<T>(cb: (ref: T) => void, isOptional = true) {
  return Object.assign(cb, { [Optional]: isOptional });
}

export function useSyncRefs<TType>(
  ...refs: (React.MutableRefObject<TType | null> | ((instance: TType) => void) | null)[]
) {
  let cache = useRef(refs);

  useEffect(() => {
    cache.current = refs;
  }, [refs]);

  let syncRefs = useEvent((value: TType) => {
    for (let ref of cache.current) {
      if (ref == null) continue;
      if (typeof ref === 'function') ref(value);
      else ref.current = value;
    }
  });

  return refs.every((ref) => ref == null || (ref as any)?.[Optional]) ? undefined : syncRefs;
}

/**
 * This is used to determine if we're hydrating in React 18.
 *
 * The `useServerHandoffComplete` hook doesn't work with `<Suspense>`
 * because it assumes all hydration happens at one time during page load.
 *
 * Given that the problem only exists in React 18 we can rely
 * on newer APIs to determine if hydration is happening.
 */
function useIsHydratingInReact18(): boolean {
  let isServer = typeof document === 'undefined';

  // React < 18 doesn't have any way to figure this out afaik
  if (!('useSyncExternalStore' in React)) {
    return false;
  }

  // This weird pattern makes sure bundlers don't throw at build time
  // because `useSyncExternalStore` isn't defined in React < 18
  const useSyncExternalStore = ((r) => r.useSyncExternalStore)(React);

  // @ts-ignore
  let result = useSyncExternalStore(
    () => () => {},
    () => false,
    () => (isServer ? false : true),
  );

  return result;
}

// TODO: We want to get rid of this hook eventually
export function useServerHandoffComplete() {
  let isHydrating = useIsHydratingInReact18();
  let [complete, setComplete] = React.useState(env.isHandoffComplete);

  if (complete && env.isHandoffComplete === false) {
    // This means we are in a test environment and we need to reset the handoff state
    // This kinda breaks the rules of React but this is only used for testing purposes
    // And should theoretically be fine
    setComplete(false);
  }

  React.useEffect(() => {
    if (complete === true) return;
    setComplete(true);
  }, [complete]);

  // Transition from pending to complete (forcing a re-render when server rendering)
  React.useEffect(() => env.handoff(), []);

  if (isHydrating) {
    return false;
  }

  return complete;
}

export function useIsMounted() {
  let mounted = useRef(false);

  useIsoMorphicEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return mounted;
}

export function useFlags(initialFlags = 0) {
  let [flags, setFlags] = useState(initialFlags);
  let mounted = useIsMounted();

  let addFlag = useCallback(
    (flag: number) => {
      if (!mounted.current) return;
      setFlags((flags) => flags | flag);
    },
    [flags, mounted],
  );
  let hasFlag = useCallback((flag: number) => Boolean(flags & flag), [flags]);
  let removeFlag = useCallback(
    (flag: number) => {
      if (!mounted.current) return;
      setFlags((flags) => flags & ~flag);
    },
    [setFlags, mounted],
  );
  let toggleFlag = useCallback(
    (flag: number) => {
      if (!mounted.current) return;
      setFlags((flags) => flags ^ flag);
    },
    [setFlags],
  );

  return { flags, addFlag, hasFlag, removeFlag, toggleFlag };
}

export function useLatestValue<T>(value: T) {
  let cache = useRef(value);

  useIsoMorphicEffect(() => {
    cache.current = value;
  }, [value]);

  return cache;
}

export function useDisposables() {
  // Using useState instead of useRef so that we can use the initializer function.
  let [d] = useState(disposables);
  useEffect(() => () => d.dispose(), [d]);
  return d;
}

export let useEvent =
  // TODO: Add React.useEvent ?? once the useEvent hook is available
  function useEvent<F extends (...args: any[]) => any, P extends any[] = Parameters<F>, R = ReturnType<F>>(
    cb: (...args: P) => R,
  ) {
    let cache = useLatestValue(cb);
    return React.useCallback((...args: P) => cache.current(...args), [cache]);
  };

interface TransitionArgs {
  immediate: boolean;
  container: MutableRefObject<HTMLElement | null>;
  classes: MutableRefObject<{
    base: string[];

    enter: string[];
    enterFrom: string[];
    enterTo: string[];

    leave: string[];
    leaveFrom: string[];
    leaveTo: string[];

    entered: string[];
  }>;
  direction: 'enter' | 'leave' | 'idle';
  onStart: MutableRefObject<(direction: TransitionArgs['direction']) => void>;
  onStop: MutableRefObject<(direction: TransitionArgs['direction']) => void>;
}

export function useTransition({ immediate, container, direction, classes, onStart, onStop }: TransitionArgs) {
  let mounted = useIsMounted();
  let d = useDisposables();

  let latestDirection = useLatestValue(direction);

  useIsoMorphicEffect(() => {
    if (!immediate) return;

    latestDirection.current = 'enter';
  }, [immediate]);

  useIsoMorphicEffect(() => {
    let dd = disposables();
    d.add(dd.dispose);

    let node = container.current;
    if (!node) return; // We don't have a DOM node (yet)
    if (latestDirection.current === 'idle') return; // We don't need to transition
    if (!mounted.current) return;

    dd.dispose();

    onStart.current(latestDirection.current);

    dd.add(
      transition(node, classes.current, latestDirection.current === 'enter', () => {
        dd.dispose();
        onStop.current(latestDirection.current);
      }),
    );

    return dd.dispose;
  }, [direction]);
}
