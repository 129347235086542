import { App } from '@capacitor/app';
import { StatusBar, Style } from '@capacitor/status-bar';
import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar';
import { isAndroid } from '../utils';

const backHandlers: (() => unknown)[] = [];

export function onAndroidBack(handler: () => unknown) {
  if (!isAndroid()) {
    return;
  }
  backHandlers.push(handler);
  return () => {
    const index = backHandlers.indexOf(handler);
    if (index > -1) {
      backHandlers.splice(index, 1);
    }
  };
}

App.addListener('backButton', () => {
  const handler = backHandlers.pop();
  if (handler) {
    handler();
  } else {
    App.minimizeApp();
  }
});

export async function setStatusBarColor() {
  if (!isAndroid()) {
    return;
  }
  return setTimeout(async () => {
    await Promise.all([
      await StatusBar.setStyle({ style: Style.Dark }),
      await StatusBar.setBackgroundColor({ color: '#000000' }),
      await NavigationBar.setColor({ color: '#0D0D0D' }),
    ]);
  }, 500);
}
