import { BusinessScheduleVo } from '@cocast/api-client';
import { Icons } from '@cocast/icons';
import { ScheduleStatus as Status } from '@cocast/types';
import { default as classNames } from 'classnames';
import { Avatar } from 'legacy/Avatar';
import { createElement, memo, useState } from 'react';
import { clientStore } from 'state';
import { Segment, Sheet, SheetProps, useSheet } from 'widgets';
import { ExerciseDetailSheet, useExerciseDetail } from '../../Exercise/ExerciseDetail';
import { useWorkoutDetail, WorkoutDetailSheet } from '../../Workout/WorkoutDetail';
import { ScheduleStatus } from '../ScheduleStatus';
import { ScheduleDetailNote } from './ScheduleDetailNote';
import { ScheduleDetailTerms } from './ScheduleDetailTerms';
import { ScheduleDetailWorkout } from './ScheduleDetailWorkout';

interface Props extends Pick<SheetProps, 'open' | 'setOpen'> {
  schedule: BusinessScheduleVo;
  workoutDetail?: WorkoutDetailSheet;
  exerciseDetail?: ExerciseDetailSheet;
}

type Tab = 'workout' | 'note' | 'terms';

const options: Tab[] = ['workout', 'note', 'terms'];

const infoClassName = 'text-b1 font-normal text-base opacity-80 child:flex-start-center child:space-x-xs';

function ScheduleDetailComponent({
  open,
  setOpen,
  schedule,
  schedule: { name, status, assignedStaff: staff, assignedClassId, group, startAt, endAt, duration },
  workoutDetail: workoutDetailSheet,
  exerciseDetail: exerciseDetailSheet,
}: Props) {
  const [tab, setTab] = useState<Tab>('workout');

  const workoutDetail = workoutDetailSheet || useWorkoutDetail();
  const exerciseDetail = exerciseDetailSheet || useExerciseDetail();

  return (
    <Sheet open={open} setOpen={setOpen} onConfirm>
      <h2 className="pt-md" data-schedule-id={schedule.id}>
        {name} {assignedClassId ? ` (Class)` : ''}
      </h2>
      <ScheduleStatus status={status as Status} className="mt-xxs" />

      <section className={classNames(infoClassName, 'space-x-md flex-start-center mt-xl')}>
        <div>
          <Avatar size={22} name={staff.name} src={staff.avatar} id={staff.id} crossOrigin />
          <span>{staff.name}</span>
        </div>
        <div>
          {createElement(group ? Icons.GroupClass : Icons.PersonalClass, { size: 5.5 })}
          <span>{group ? 'Group' : 'Personal'}</span>
        </div>
      </section>

      <section className={classNames(infoClassName, 'space-y-md mt-md')}>
        <div>
          <Icons.Calendar size={5.5} />
          <p>{clientStore.formatDate(startAt)}</p>
        </div>
        <div>
          <Icons.Clock size={5.5} />
          <p>
            {clientStore.formatTime(startAt)} - {clientStore.formatTime(endAt)}
          </p>
        </div>
        <div>
          <Icons.Timer size={5.5} />
          <p>{duration}mins</p>
        </div>
      </section>

      <Segment<Tab>
        options={options}
        defaultValue={tab}
        onChange={setTab}
        className="mt-xl mb-lg"
        labelClassName="capitalize font-bold"
        size="lg"
      />

      {tab === 'workout' ? (
        <ScheduleDetailWorkout schedule={schedule} workoutDetail={workoutDetail} exerciseDetail={exerciseDetail} />
      ) : tab === 'note' ? (
        <ScheduleDetailNote schedule={schedule} />
      ) : (
        <ScheduleDetailTerms schedule={schedule} />
      )}
    </Sheet>
  );
}

const ScheduleDetail = memo(ScheduleDetailComponent);

export function useScheduleDetail() {
  return useSheet(ScheduleDetail);
}

export type ScheduleDetailSheet = ReturnType<typeof useScheduleDetail>;
