import { BusinessWorkoutVo } from '@cocast/api-client';
import { countWorkoutSets } from '@cocast/utils-business/workout';

export function countWorkoutsSets(workouts: BusinessWorkoutVo[]) {
  return workouts.reduce(
    (accu, { sections }) => {
      const { setCount, supersetCount, circuitsCount } = countWorkoutSets(sections);
      return {
        sectionCount: accu.sectionCount + sections.length,
        setCount: accu.setCount + setCount,
        supersetCount: accu.supersetCount + supersetCount,
        circuitsCount: accu.circuitsCount + circuitsCount,
      };
    },
    {
      sectionCount: 0,
      setCount: 0,
      supersetCount: 0,
      circuitsCount: 0,
    },
  );
}
