import { BusinessScheduleVo } from '@cocast/api-client';
import { RichTextRender } from '@cocast/components/Richtext/RichtextRender';
import { memo } from 'react';

interface Props {
  schedule: BusinessScheduleVo;
}

function ScheduleDetailTermsComponent({ schedule: { assignedClass } }: Props) {
  return assignedClass?.terms ? (
    <RichTextRender className="w-full text-base-300">{assignedClass.terms}</RichTextRender>
  ) : (
    <div className="flex-center text-b2 py-xl">
      <p>No terms provided</p>
    </div>
  );
}

export const ScheduleDetailTerms = memo(ScheduleDetailTermsComponent);
