import {
  forwardRef,
  ForwardRefRenderFunction,
  memo,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Transition, TransitionProps } from './Transition';

export interface TransitionSectionProps {
  children: ReactNode | ReactNode[];
  transition?: Partial<
    Omit<TransitionProps, 'transition' | 'name' | 'show' | 'disableAppear' | 'transitionDelay' | 'asFragment'>
  >;
  transKey?: string;
  asFragment?: boolean;
}

export interface TransitionControllerRef {
  trans: () => void;
}

function TransitionSectionComponent(
  {
    children,
    asFragment,
    transKey,
    transition,
  }: TransitionSectionProps & { ref?: MutableRefObject<TransitionControllerRef> },
  ref: MutableRefObject<TransitionControllerRef>,
) {
  if (!ref && !transKey) {
    throw new Error('TransitionSection requires a ref or a transKey');
  }

  const [show, setShow] = useState(true);

  const trans = useCallback(() => {
    setShow(false);
    setTimeout(() => {
      setShow(true);
    }, 300);
  }, []);

  useEffect(() => {
    if (ref) {
      ref.current = { trans };
    }
  }, []);

  useEffect(() => {
    trans();
  }, [transKey]);

  return (
    <Transition name="text" transition={transition} show={show} transitionDelay={0} asFragment={asFragment}>
      {children}
    </Transition>
  );
}

export const TransitionSection = memo(
  forwardRef(TransitionSectionComponent as ForwardRefRenderFunction<{}>),
) as typeof TransitionSectionComponent;
