import { useUpdatedState } from '@cocast/hooks/useUpdatedState';
import { Country } from '@cocast/types';
import { Input } from 'legacy/Input/Input';
import { default as classNames } from 'classnames';
import { memo, MutableRefObject, useCallback, useEffect } from 'react';
import { CountrySelector } from './CountrySelector';

export interface PhoneValue {
  phoneCountry?: Country;
  phoneNumber?: string;
}

export interface PhoneInputRef {
  value: PhoneValue;
  setValue: (v: Partial<PhoneValue>) => unknown;
}

interface Props {
  defaultValue?: PhoneValue;
  value?: PhoneValue;
  onChange?: (v: PhoneValue) => unknown;
  className?: string;
  input?: MutableRefObject<PhoneInputRef>;
}

function PhoneInputComponent({ defaultValue, value, className, input, onChange }: Props) {
  const [country, setCountry] = useUpdatedState<Country>((value || defaultValue)?.phoneCountry);
  const [number, setNumber] = useUpdatedState<string>((value || defaultValue)?.phoneNumber);

  if (onChange) {
    useEffect(() => {
      onChange({
        phoneCountry: country,
        phoneNumber: number,
      });
    }, [country, number]);
  }

  if (input) {
    input.current = {
      value: {
        phoneCountry: country,
        phoneNumber: number,
      },
      setValue: useCallback(({ phoneCountry, phoneNumber }) => {
        if (phoneCountry) {
          setCountry(phoneCountry);
        }
        if (phoneNumber) {
          setNumber(phoneNumber);
        }
      }, []),
    };
  }

  return (
    <section className={classNames('flex-center w-full space-x-md', className)}>
      <CountrySelector
        label="Country"
        value={country}
        className="dark w-[max(20%,80px)] no-retractable"
        renderValue={(v) => (v ? `+${v.countryCode}` : '')}
        onChange={setCountry}
      />
      <Input label="Number" className="dark retractable" value={number} onValueChange={setNumber} type="number" />
    </section>
  );
}

export const PhoneInput = memo(PhoneInputComponent);
