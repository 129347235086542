import { CountryIcons, Icons } from '@cocast/icons';
import { Country, CountryInfo, countryInfoMap } from '@cocast/types';
import { default as classNames } from 'classnames';
import { Input, InputProps } from 'legacy/Input/Input';
import { createElement, memo, useCallback } from 'react';
import { SelectorItemProps, useSelector } from 'widgets';

const options: Country[] = Object.values(Country);

interface Props extends InputProps {
  value?: Country;
  defaultValue?: Country;
  onChange?: (v: Country) => unknown;
  className?: string;
  selectorClassName?: string;
  renderValue?: (v: CountryInfo) => string;
}

function CountrySelectorComponent({
  value,
  defaultValue,
  onChange,
  className,
  selectorClassName,
  renderValue,
  ...props
}: Props) {
  const selector = useSelector();
  const onSelect = useCallback(() => {
    selector.open({
      options,
      value,
      defaultValue,
      item: Item,
      closeAfterSelect: true,
      onChange: (v: Country) => {
        onChange?.(v);
      },
    });
  }, [value, defaultValue]);

  const v = value || defaultValue;
  const country = v ? countryInfoMap.get(v) : null;

  return (
    <Input
      {...props}
      className={className}
      value={renderValue ? renderValue(country) : country?.name || ''}
      onClick={onSelect}
      readOnly
    />
  );
}

export const CountrySelector = memo(CountrySelectorComponent);

function ItemComponent({ selected, onSelect, index, value }: SelectorItemProps<Country>) {
  const { name, key, countryCode } = countryInfoMap.get(value);

  const onClick = useCallback(() => onSelect(value, index), [value, index]);

  return (
    <div
      className={classNames('cursor-pointer flex-start-center px-md py-xs relative', selected ? 'text-lemon' : null)}
      onClick={onClick}
    >
      {createElement(CountryIcons[key], {
        size: 6,
        className: 'mr-sm',
      })}
      <span>{name}</span>
      <span className="ml-sm text-b2 opacity-80">+{countryCode}</span>
      {selected ? (
        <Icons.Check className="bg-lemon text-black p-3xs rounded-full absolute right-md top-3xs" size={5} />
      ) : null}
    </div>
  );
}

export const Item = memo(ItemComponent);
