import { EnvType } from '@cocast/types';
import { isPlatform } from '@ionic/react';

export function getEnv(): EnvType {
  if (import.meta.env.DEV) {
    return EnvType.DEV;
  }
  const v = localStorage.getItem('__env') as EnvType;
  return v || EnvType.ONLINE;
}

export function isOnline() {
  return getEnv() === EnvType.ONLINE;
}

export function isIOS() {
  return isPlatform('ios');
}

export function isAndroid() {
  return isPlatform('android');
}

export function isApp() {
  return isPlatform('hybrid');
}

export function isWeb() {
  return isPlatform('desktop') || isPlatform('mobileweb');
}

export function isPWA() {
  return isPlatform('pwa');
}

export function isWebIOS() {
  return isIOS() && isWeb();
}

export function isAndroidWeb() {
  return isAndroid() && isWeb();
}
