import { APIHosts, EnvType } from '@cocast/types';
import { getConfig, getEnv } from './libs/config';
import { ParsedAPIResponse, postApiForm, prefix } from './libs/http';

export interface ResourceUploadWithFormOptions {
  businessId?: string;
  destination?: string;
  bucket?: string;
  form: FormData;
  toastOnError?: boolean;
}

export interface ResourceUploadOptions {
  businessId?: string;
  destination?: string;
  bucket?: string;
  files: File[];
  toastOnError?: boolean;
}

export interface ImageUploadOptions {
  businessId?: string;
  files: File[];
  toastOnError?: boolean;
}

interface VideoUploadOptions {
  businessId: string;
  setProgress?: (progress: number) => void;
  meta?: Record<string, string>;
  apiHost?: string;
  env?: EnvType;
}

async function uploadWithForm({ businessId, form, destination, bucket, toastOnError }: ResourceUploadWithFormOptions) {
  const result = await postApiForm<string[]>(prefix('resource', { businessId, destination, bucket }), form);
  const [success, , response] = result;
  if (!success && toastOnError !== false) {
    console.error(response);
    getConfig().toast?.error('Upload failed');
    return null;
  }
  return withTake(result);
}

function uploadVideo(file: File, { businessId, setProgress, meta, apiHost, env }: VideoUploadOptions): Promise<string> {
  return new Promise(async (resolve, reject) => {
    try {
      const { UploadClient } = await import('./tus');
      const upload = new UploadClient(file, {
        endpoint: `${apiHost || APIHosts.get(env || getEnv())}/api/resource/stream/endpoint`,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: file.name,
          filetype: file.type,
          businessId,
          ...meta,
        },
        onShouldRetry: () => false,
        onError: function (error) {
          getConfig().toast?.error('Video upload error');
          console.error('Upload stream failed: ', error);
          reject(error);
        },
        onProgress: setProgress
          ? (bytesUploaded, bytesTotal) => {
              setProgress(Math.round((bytesUploaded / bytesTotal) * 100));
            }
          : undefined,
        onSuccess: function () {
          const stream = upload.url.split('/').pop().split('?')[0];
          resolve(stream);
        },
      });
      upload.start();
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
}

function withTake(
  result: ParsedAPIResponse<string[]> & any,
): ParsedAPIResponse<string[]> & { take: () => string[]; takeOne: () => string } {
  const [success, results, response] = result;
  result.take = () => {
    if (!success) {
      throw new Error('Upload failed');
    }
    return results;
  };
  result.takeOne = () => {
    return result.take()[0];
  };
  return result as any;
}

export const Resource = {
  upload: ({ businessId, files, destination, bucket, toastOnError }: ResourceUploadOptions) => {
    const form = new FormData();
    files.map((file, index) => form.append(index.toString(), file, file.name));
    return uploadWithForm({ businessId, form, destination, bucket, toastOnError });
  },
  uploadImage: async ({ businessId, files, toastOnError }: ImageUploadOptions) => {
    const form = new FormData();
    files.map((file, index) => form.append(index.toString(), file, file.name));
    const result = await postApiForm<string[]>(prefix('resource/image', { businessId, toastOnError }), form);
    return withTake(result);
  },
  uploadVideo,
  uploadWithForm,
};
