import { StrengthExercise } from '@cocast/types';

export const StrengthExercises = [
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: '7542565c8028858ea4851ff5ed3c5ba0',
    imageId: 'ad06dea1-68be-45bd-7615-80f3391e2c00',
    name: '3:4 sit up',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'back',
    subType: 'lower back',
    equipment: 'bodyweight',
    id: '40ad2ea059a3bb9eb6296a5e26d1e033',
    imageId: 'fb51f9de-a246-426b-dd1f-060793b30500',
    name: '45 degree hyperextension',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'leverage machine',
    id: '723853dada380997e19f2390de056632',
    imageId: 'd22f36f6-a8bd-4991-c78b-52ab9c0c3a00',
    name: '45 degree leg leverage press',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: 'f147f8f4dc70908d8a9d58db472cd7d6',
    imageId: '36463c09-3d5b-469d-f31a-5edcd0dd2900',
    name: '45 degree side bend',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: '240577697fbfaa5b339de6c2e052f503',
    imageId: 'c255dc46-3b24-403e-ae2b-c40e86262e00',
    name: 'air bike',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: 'ca9e258c2c4c6a3d9a80261ef2cc87c3',
    imageId: '9d5109c3-b3b6-43d4-9939-64cc20577500',
    name: 'air bike (difficult)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'bodyweight',
    id: '0c417fef1b9dbe6f11a09ab1d59d8db0',
    imageId: '4ef53966-c52b-45df-fa2e-7c7ac5abfa00',
    name: 'air squat',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: 'c898a6ff3066dcfba54828021308607c',
    imageId: 'e93efadf-4bbd-4a5c-5688-9a9963ca7300',
    name: 'alternate heel touchers',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: '81947a27bb9af29aecfe983e330acb78',
    imageId: '2acc3cca-918a-4c30-f181-d7d469b4f700',
    name: 'alternate leg raise (lying floor)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: '454aec3777cff07f18c4e1f011337453',
    imageId: '21ef7b84-8610-45ff-039d-32d47e39d000',
    name: 'assisted wheel rollout',
  },
  {
    category: 'strength',
    recordType: 'assistedBodyweight',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'trx & band',
    id: '2325f90fc995e35659ac57b40e4a527b',
    imageId: 'cfd244a3-5a26-421e-6931-6f0977bc0400',
    name: 'band - assisted nordic hamstring curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'trx & band',
    id: 'c1867c1049f5d220f1353beb735014d1',
    imageId: 'acfb1b01-a19f-4492-0a58-368e38319b00',
    name: 'band - bench press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'hips',
    equipment: 'trx & band',
    id: '9e341f387aa72e28632052e04fb7a29f',
    imageId: 'dbcdb390-5864-478c-743b-daee78e23100',
    name: 'band - bent over hip extension',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'trx & band',
    id: 'ac34aad3f99759c331c18b8dd1b5f46b',
    imageId: '71b2a8be-d321-4f8e-7207-e35389500700',
    name: 'band - bent over row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'trx & band',
    id: 'fd9a9c63ffd1c68b1128929e3b548147',
    imageId: '2297e04e-cf79-417a-92bf-102eb0605000',
    name: 'band - concentration curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'rear',
    equipment: 'trx & band',
    id: '8e3df6c57991b5afbe9ccd378434852c',
    imageId: 'a057d075-4f29-490f-9c51-ca5b1627b600',
    name: 'band - face pull',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'outer',
    equipment: 'trx & band',
    id: '148f0b1605a1d091cb74babc7eac4666',
    imageId: '648bc30a-8055-446f-d1c1-57deb691f400',
    name: 'band - hammer curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'trx & band',
    id: '376180f6c15384c3890efb763a0f4b2c',
    imageId: 'cdc746f3-5524-46c7-ba61-cf9deb2e1500',
    name: 'band - high fly',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'waist',
    equipment: 'trx & band',
    id: '216d137e9cdd44e7c7e6d3d7eb78b267',
    imageId: '3b1be580-64b2-4955-4b9a-186d64d32200',
    name: 'band - kneeling crunch',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'hips',
    equipment: 'trx & band',
    id: '8dbd97141b4b1dd1b0fdd96b0ef4bb60',
    imageId: 'cc8fae26-c75c-40dd-a118-e02ac8f88a00',
    name: 'band - kneeling hip extension',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'trx & band',
    id: '231682c50cc91aeeb605f117c9c7e717',
    imageId: '50f747ba-1ba0-40d4-d9bb-23b203fbda00',
    name: 'band - kneeling one arm pulldown',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'trx & band',
    id: '77f0469de16157fbb3f0f905a06ff402',
    imageId: '4f92c4ec-985a-4233-710f-f885476cf700',
    name: 'band - kneeling pulldown',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'medial',
    equipment: 'trx & band',
    id: '560b60dddbd951082c837a52ff29cf27',
    imageId: '1f735045-23ac-47a6-63ef-320dc5634f00',
    name: 'band - lateral raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'trx & band',
    id: 'f7a03c16a4774cad9e19c71ad7706b85',
    imageId: '0af71c2a-5459-4b9b-b914-8b05e2535600',
    name: 'band - leg extension',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'trx & band',
    id: 'afb6d4e62f365eb590d227d27fa176ba',
    imageId: '2091e376-8eeb-4ae5-4276-2366f1d01900',
    name: 'band - leg lift',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'upper chest',
    equipment: 'trx & band',
    id: '087fd011ea20e30783069c92aac7f168',
    imageId: '69d71259-d651-4cd2-b445-fd2e1e8c0500',
    name: 'band - low fly',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'trx & band',
    id: 'ce767087f5fdeab27a04fe61eac23ed5',
    imageId: 'e391f78d-f9e5-4754-ea54-b34ea6602100',
    name: 'band - lunge',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'trx & band',
    id: 'b105dc11ab93628cf302c272d7a42dc1',
    imageId: 'f6d05ca8-6a7d-465b-5bbe-42a18b27d900',
    name: 'band - lunge squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'trx & band',
    id: 'ea67987bd7d0d6e2f024930886af27a7',
    imageId: '74954b9e-db06-4222-e553-8f60c1a9e200',
    name: 'band - lunge walk',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'trx & band',
    id: 'ee5d058644d2e5abc202e0c2d69834d3',
    imageId: 'cd01210c-2f2f-4aae-c972-83b352399700',
    name: 'band - lying leg curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'waist',
    equipment: 'trx & band',
    id: '1d1bc850e52d77adea28f9792176bfd5',
    imageId: '7f1f89e9-8b5c-432e-a903-aa1c8a7bb000',
    name: 'band - lying leg raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'trx & band',
    id: '8ad50e7c33aa7020d0dbe127c263f070',
    imageId: '59847491-b2be-46d5-7536-1dee3a912000',
    name: 'band - middle fly',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'trx & band',
    id: '3ac0cb6a61584ed20492fa9e6d4c6b5c',
    imageId: 'de3fdaa1-487c-47fc-10b7-a599b3998e00',
    name: 'band - one arm seated row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'trx & band',
    id: 'eba9e44288613227e15cbf38a07cd09c',
    imageId: '01a3c472-3fcc-485b-650a-265b8d16f200',
    name: 'band - one arm tricpes extension',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'trx & band',
    id: 'b54130f7a7c9cf79b37f1401e63dc755',
    imageId: '8e40be49-063d-45bb-3d01-439ad2b9c700',
    name: 'band - overhead triceps extension',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'trx & band',
    id: 'd87c0d75bc1ef6f8c987ec640ca7b10d',
    imageId: '829e76b2-00a9-4518-2f3f-d24354ec7a00',
    name: 'band - pulldown',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'trx & band',
    id: 'f87c5477d9fc0b9be14e6c20009d5639',
    imageId: '514d56ef-a2fe-4c93-56aa-641c47c65700',
    name: 'band - push up',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'trx & band',
    id: 'f6314eb10d4d0667f0995bd5b9b6455a',
    imageId: '1fdef1cb-0174-48b3-681a-e23486a7fa00',
    name: 'band - pushdown',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'rear',
    equipment: 'trx & band',
    id: '45a0b918099ea6bec2e04b5c728c0bc4',
    imageId: '057c136e-2b3a-44a3-9a4d-596ebf442e00',
    name: 'band - rear delt row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'trx & band',
    id: '1570d31495ce78986be0a637b531ed7c',
    imageId: '68642253-c98b-485b-e083-ddfffdc71e00',
    name: 'band - reverse pulldown',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'trx & band',
    id: 'f32597b081ffca4f334fda84bc105c50',
    imageId: '59e00e34-3ff0-4fb7-1c47-4cff88afe200',
    name: 'band - seated curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'trx & band',
    id: 'c694dc36a9770b1d988880ef6cf0b556',
    imageId: 'cbee83a6-f345-4c40-f328-ca777bfdca00',
    name: 'band - seated row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'trx & band',
    id: '2103f21d03a19147ecf74b9658908d31',
    imageId: '9482fb10-0af9-4a02-e144-896c8127d800',
    name: 'band - seated shoulder press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'trapezius',
    equipment: 'trx & band',
    id: '123912f9013ac0e52e2202850de785e4',
    imageId: '7572d239-b5e6-485f-7c99-54d1d686e300',
    name: 'band - shrug',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'trx & band',
    id: '311ca5e180f8f489d7ed47c8126ee539',
    imageId: 'd11fde2c-1e03-4c03-9600-2636e9aafe00',
    name: 'band - single leg squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'trx & band',
    id: '45671f53fee108b481ae984bdadb1dfc',
    imageId: 'b47fffa1-1dd9-46cd-6e91-66a6ea977a00',
    name: 'band - skull crusher',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'trx & band',
    id: '4d451c01d2011c1628c08a70e2816132',
    imageId: '495b752c-8558-4b8f-fa0a-7e416a000f00',
    name: 'band - split squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'trx & band',
    id: '0fd49713ec5e2ccc5af6d4c8f5e3762b',
    imageId: 'e360725b-ff6e-4362-8d87-c01128619b00',
    name: 'band - squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'trx & band',
    id: '688022de7440a8ce312a3cf408d5f879',
    imageId: '8cc0f403-a2fa-4f5f-f651-83339d92fb00',
    name: 'band - standing alternate curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'trx & band',
    id: 'e3cc4ce2245ac146320081c7e7cddb36',
    imageId: '986b2b34-1cd1-4036-2618-146c81258800',
    name: 'band - standing bicep curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'medial',
    equipment: 'trx & band',
    id: '59e3418d5861fc69b2c606accaee54f6',
    imageId: 'e699b49f-e3c6-418d-5cfb-c4a7055f9100',
    name: 'band - standing external rotation',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'upper chest',
    equipment: 'trx & band',
    id: '0d25205663a01e0485f402624623c6e5',
    imageId: '9acaad52-3ebe-4f5c-9769-ed5b73ce0f00',
    name: 'band - standing incline press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'trx & band',
    id: '2dd59627614ace4b525daeb59686f898',
    imageId: '71f64e5c-45b2-42a1-8366-e87cdf3cde00',
    name: 'band - standing lateral raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'trx & band',
    id: '6075af247048c62a6e28f92bc598b838',
    imageId: 'b724b2a4-bfa8-4b12-80a8-41e309b40e00',
    name: 'band - standing leg curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'trx & band',
    id: 'aa6914595b47fce31ca0b09f05f1575f',
    imageId: 'd1e9ba2a-8988-4a75-64e9-4954aed3c000',
    name: 'band - standing leg extension',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'trx & band',
    id: '7a6bf0b3ba7ce29836862312d51e4f09',
    imageId: '6d6df9b0-832b-47f0-8d8e-8745f7addd00',
    name: 'band - standing press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'trx & band',
    id: '78b59f8e29efdd06c0c922cdcdb1fa0e',
    imageId: '0ebfb038-8548-4441-3861-59d3ae4ecb00',
    name: 'band - standing shoulder press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'rear',
    equipment: 'trx & band',
    id: '745594086c9ad108bb6d10cc23feaf6a',
    imageId: '2a1b4809-7a0c-4635-8e12-e7bdf1a82300',
    name: 'band - standing single arm lateral extension',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'trx & band',
    id: '35fe369ac3a9a9d2dbe2be3cba8370f0',
    imageId: 'f0eeac2c-61bb-4fa3-db2a-5718982d7300',
    name: 'band - standing single arm shoulder flexion',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'trx & band',
    id: 'b1d578c7bd4c47afd7eeacdb883a8f02',
    imageId: '3091ad6d-2cb5-4276-f69b-cdfc3b037f00',
    name: 'band - standing straight back row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'trx & band',
    id: '5bd778508a0c8f0c2b68034a05812db6',
    imageId: '28704459-b8d7-47ff-5d41-abccd5b4d400',
    name: 'band - straight arm pulldown',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'trx & band',
    id: '76b69c182246848005ad947a91f3f9a2',
    imageId: 'c4611aec-45b8-4927-c7ce-51468e583d00',
    name: 'band - straight back seated row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'hips',
    equipment: 'trx & band',
    id: '0f1b007e731d68361fa5f358fcd6e4a0',
    imageId: '7d57b96e-78c9-4961-2d3a-f0a26934f400',
    name: 'band - straight leg deadlift',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'trx & band',
    id: '77ede57449be42cc9dc54e39801f6296',
    imageId: 'b3dd7452-d35d-4b34-f842-f76928f51f00',
    name: 'band - tricpes kickback',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'waist',
    equipment: 'trx & band',
    id: '3a6a79047a1c5aa172308ff7301d1027',
    imageId: 'ab9e804a-2772-475e-21c5-f3495428ee00',
    name: 'band - twist (crossover)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'waist',
    equipment: 'trx & band',
    id: '70aed411783a48427614eb70fda8e869',
    imageId: '5c9f4f33-c9fc-4ad3-1e34-14f68feb8f00',
    name: 'band - twist (down to up)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'waist',
    equipment: 'trx & band',
    id: '7e179f0545a8d5b353a87bde74a39c23',
    imageId: 'd0875625-9f41-4d1a-675c-dd0cdab8d700',
    name: 'band - twist (up to down)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'trx & band',
    id: 'd78df1bd101edf65c099332aecd43b01',
    imageId: '0f6c5ec1-17a3-4d87-b2fa-dba58d9b1700',
    name: 'band - upright row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'cable',
    id: '4496ce9455b7ad4009f19989758b7c92',
    imageId: '8ca61100-e907-42eb-ad15-6c007b4fa800',
    name: 'cable pushdown (bar)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'cable',
    id: '08177fef6ca985cc52b64919a1206c74',
    imageId: '78eb5879-352b-4487-289f-b9d8d2078a00',
    name: 'cable high pulley overhead tricep extension (bar)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'cable',
    id: 'e1aab5e40f24ce6319092650d1d95e9d',
    imageId: '143062e6-d538-46ba-de55-093cf8e7a800',
    name: 'cable lateral pulldown (bar)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'forearm',
    equipment: 'barbell',
    id: '6d2ead57593fdc7eb73d4373fb5f7806',
    imageId: 'd265b120-2e8e-43d8-7e61-636798adff00',
    name: 'barbell wrist curl (bent)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'barbell',
    id: 'dc5597d2fa9d0345097a846d96754a03',
    imageId: '4a276e40-eed2-4827-76d0-8c3c0eb55000',
    name: 'barbell bench press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'lower back',
    equipment: 'barbell',
    id: 'd6edb39573ee9896815ab9886cd541d2',
    imageId: '180769d3-cfce-4c4e-b5e2-64b0e826c200',
    name: 'barbell bent over row (lower back)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'barbell',
    id: 'd6edb39573ee9896815ab9886cd541d1',
    imageId: '180769d3-cfce-4c4e-b5e2-64b0e826c200',
    name: 'barbell bent over row (upper back)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'rear',
    equipment: 'barbell',
    id: '51c9e27cdbe2f044dee5e65661c7b836',
    imageId: '6767e3cc-6cae-4bcb-7a8d-0a7f445e3100',
    name: 'barbell bent over row (shoulder)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'barbell',
    id: '714140293d60034b8329be59f764f78a',
    imageId: '35ef5fca-9883-4b55-f394-3fd58a730e00',
    name: 'barbell burgalian squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'barbell',
    id: '1181c4eaf97ca6aa343587020e9ac162',
    imageId: 'e045dd64-f74d-4708-f09e-080646261c00',
    name: 'barbell clean deadlift',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'barbell',
    id: '26cfac6b593596c84a3fe9b19f5dc5dc',
    imageId: '16ee0485-4b5e-4a9d-560d-a99b57b74700',
    name: 'barbell close grip bench press (close)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'barbell',
    id: '61f59c71412bcdb8fcf64aff2be14bc1',
    imageId: '19d70871-4924-41ef-9e8e-82ab19d3b100',
    name: 'barbell close grip bench press (open)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'barbell',
    id: '8d4969adb7d82f3b6946ee621f9a005e',
    imageId: '8d78cdec-7d18-4ee8-0491-0f6939a71e00',
    name: 'barbell concentration curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'barbell',
    id: '597758f5e50ef219bbf478ff59945f54',
    imageId: 'a368fc0f-9152-4a7d-9d28-f27027083a00',
    name: 'barbell curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'barbell',
    id: '7ca2dcbe59890e1f45ccad36f08cfa9c',
    imageId: '6ab2f4a3-b403-4ffc-cf4f-e7c9d7562200',
    name: 'barbell front-back walking lunge',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'barbell',
    id: 'c2d11f11c1a742df997235c0f4e9f7c5',
    imageId: '12e33a7f-29c1-4687-7829-6e345f918400',
    name: 'barbell front raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'barbell',
    id: 'd11a95ec74b79acdf31d3bbf8be8b957',
    imageId: 'be7a8920-6afd-4199-9dc8-3aed3ebd0b00',
    name: 'barbell front squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'barbell',
    id: 'f4395491512bc8b524c518baf60deecb',
    imageId: '15172f18-1b02-4cea-0f29-0d132d612600',
    name: 'barbell full squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'barbell',
    id: 'd65d0941630256a5a6d36cf424a68928',
    imageId: '8f493e49-16d3-43d7-54b6-a333c1bd8b00',
    name: 'barbell full zercher squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'barbell',
    id: '2a9d640ca638ce2bba12173c64313d2b',
    imageId: 'c6887ea1-0661-4fd4-bf01-51f586efbb00',
    name: 'barbell hack squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'hips',
    equipment: 'barbell',
    id: '99fed7dc55f87cbf7ac1c6902d2739dd',
    imageId: 'e9b4e6da-330a-45b4-cf95-d8c2440dc100',
    name: 'barbell hip bridge',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'hips',
    equipment: 'barbell',
    id: 'ae44ce09fd8de6f3c428084afd92f30e',
    imageId: '10acde23-828c-4f2f-6d77-21189e483700',
    name: 'barbell hip thrust',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'barbell',
    id: 'f72eb533400651e7a548828dc492cb68',
    imageId: 'df222804-8eab-4fef-94d6-d98c11347100',
    name: 'barbell jefferson squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'barbell',
    id: '503c59ac50f8e6e8b9b103ba793a5847',
    imageId: '9a6f9046-7d0d-4068-e135-1e8a63f54100',
    name: 'barbell tricep extension (lying)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'trapezius',
    equipment: 'barbell',
    id: 'bf29611851e21aee7686cf302c98232c',
    imageId: '63898821-dae4-4930-1a86-f7bd21e46600',
    name: 'barbell overhead shrug',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'lower back',
    equipment: 'barbell',
    id: 'f00bea3a7f07d22496f28964b8adc625',
    imageId: '463f841d-7b81-49e8-d884-47e480244c00',
    name: 'barbell pendlay row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'forearm',
    equipment: 'barbell',
    id: '25181cbf2120826b68550e07e556a1c1',
    imageId: 'b7895ece-0b19-416f-490e-3a9e62254c00',
    name: 'barbell plate forearms curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'barbell',
    id: '3da3bffdb5f5fcb8fef4cb78fac53cde',
    imageId: 'be82b915-39a5-45f1-7077-4591b6957300',
    name: 'barbell plate front raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'barbell',
    id: '304d3d454c2c1a897a7c16b24a440c0d',
    imageId: 'd47a6047-25bd-438c-b490-51c16484c400',
    name: 'barbell plate press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'barbell',
    id: '796301361a564082921ca0804a6ccdf9',
    imageId: '940598d9-21d9-4e6d-48a8-91900c301300',
    name: 'barbell plate twist',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'barbell',
    id: 'fd934303d55fe62d3d11114788700eba',
    imageId: '44c8aee1-4170-4683-5bae-1aefad668500',
    name: 'barbell preacher curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'barbell',
    id: '0479e9878788dc393df108a63ca6013a',
    imageId: 'f6ba0c92-9f8e-4717-fe86-1e3733866700',
    name: 'barbell rear lunge',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'barbell',
    id: '73f0a60da8a2518310d17d363f8bc6e7',
    imageId: 'bee9f5d2-a413-4e8b-bfcc-c937d6962f00',
    name: 'barbell reverse bent over row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'outer',
    equipment: 'barbell',
    id: 'f4ecc2bd88ed3f98c2b63edb91f8462d',
    imageId: 'fc221d85-8a7b-441f-07a6-067cdc169a00',
    name: 'barbell reverse curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'barbell',
    id: '2f666fdac62ba83f3b62812a9268931a',
    imageId: '133c807d-86e7-4fb9-365f-8c4890004b00',
    name: 'barbell seated bradford rocky press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'calf',
    equipment: 'barbell',
    id: 'ca009dcc52a48ddcea7869456caed633',
    imageId: 'f6396c1b-6029-4a13-ff66-e6d1966b5c00',
    name: 'barbell seated calf raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'barbell',
    id: '5a78504d9d56e6315d07b0f9d4b43b88',
    imageId: 'b6bde1dd-dc64-4b25-e61a-5d0b7f252a00',
    name: 'barbell seated overhead tricep extension',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'trapezius',
    equipment: 'barbell',
    id: '0c750374e05f6b7f72db496f75a5cf82',
    imageId: '382bb8e5-41b1-4f5c-47a1-d0c0c8585c00',
    name: 'barbell shrug',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'barbell',
    id: 'f7430c202b4ca519f6bcce354b971814',
    imageId: 'c1604337-4250-4a98-c8cb-b26c341b4100',
    name: 'barbell snatch deadlift',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'barbell',
    id: 'c3feb2580fa40e4c83b2b0994c9f70a8',
    imageId: 'fa3dac43-d658-4eb3-ba26-ba68e75db000',
    name: 'barbell split squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'forearm',
    equipment: 'barbell',
    id: '39f2ec49b63381938a21e30bcb352522',
    imageId: 'b5424b3d-84ad-4531-f73f-cb0a64461c00',
    name: 'barbell standing back wrist curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'barbell',
    id: 'ebb2a43528da07c45d7a881e21f9ca3d',
    imageId: 'e250ab8c-ac7f-4d60-e1dc-4077fa1a8a00',
    name: 'barbell standing behind neck press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'barbell',
    id: 'c1d9b2d1ee8d016f106d1725d17dd656',
    imageId: 'af1da12a-4f6b-40dc-00cd-09ea2a928600',
    name: 'barbell standing bradford press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'calf',
    equipment: 'barbell',
    id: '8f87cec7a03e9722d18698a3f14148e8',
    imageId: 'c1d06749-4307-4f8d-542c-e26117fdb300',
    name: 'barbell standing calf raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'barbell',
    id: '06a90821eb3d5579846b8a519cfc4caa',
    imageId: '7e86423c-f92b-4d11-1c4c-9350bd86b600',
    name: 'barbell standing military press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'forearm',
    equipment: 'barbell',
    id: '993aee1a39e9d318265119436c26b143',
    imageId: '3439e945-0460-4d68-6aae-4ee3ec1dce00',
    name: 'barbell standing wrist curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'barbell',
    id: '00ec5d62c7de15bf3934a91853d57343',
    imageId: 'c40d491f-1ae8-4a52-af0a-7677aa5b9f00',
    name: 'barbell sumo deadlift',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'barbell',
    id: '8581f87e410e255d1dcc32bdcfc6c5b8',
    imageId: 'eca20dc9-36d1-44ff-64a8-4dec7d218a00',
    name: 'barbell upright row (front)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'medial',
    equipment: 'barbell',
    id: '8581f87e410e255d1dcc32bdcfc6c5b9',
    imageId: 'eca20dc9-36d1-44ff-64a8-4dec7d218a00',
    name: 'barbell upright row (medial)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'forearm',
    equipment: 'barbell',
    id: '4739e6a6ecdb1f926bcc3f0dff5f9d4c',
    imageId: '304b7859-a9d3-4501-db10-0647961e6800',
    name: 'barbell wrist curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'forearm',
    equipment: 'barbell',
    id: '4d73b1329d9e37c6e9c7e73abcdf6582',
    imageId: '79e71d0f-b115-42a4-b66c-9bffdd78f400',
    name: 'barbell wrist curl (bent)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'hips',
    equipment: 'bodyweight',
    id: '9c5159ca53d01c6b0393719eea7a9ad4',
    imageId: '3e0365e2-b1ed-49c8-170d-d39f0ecc9300',
    name: 'bear plank kickback',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'leverage machine',
    id: '367b4db13a4828b9e5e2e25986f0dc19',
    imageId: '0eba357d-91a5-43c6-3b0c-fd44a97d4000',
    name: 'belt leverage squat',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'tricep',
    equipment: 'bodyweight',
    id: 'e6026c5499132981f3ebb575fbf77b49',
    imageId: '4a67945e-d587-4e74-b462-936513c33000',
    name: 'bench dip (knees bent)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'tricep',
    equipment: 'bodyweight',
    id: '69833f462ad1072a595b16c93f15bf1c',
    imageId: '841f207f-2291-4bed-51f1-171cf7bace00',
    name: 'bench dip (legs on bench)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'tricep',
    equipment: 'bodyweight',
    id: '0273a04e060a4ced239136350b03d297',
    imageId: 'de3d70a9-9418-441c-6485-bf83b2308000',
    name: 'bench dip (floor)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: '9aecd715dced160b38703acbbf99baf4',
    imageId: '076d06a8-5559-49f0-bf69-cb478f8bb300',
    name: 'bent knee lying twist',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'shoulder',
    subType: 'rear',
    equipment: 'bodyweight',
    id: '6bc71953eb52a9f24e946a639fe801b9',
    imageId: '87150d60-5fc6-47bd-4d8b-3af1d7903300',
    name: 'bent over rear delt fly',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'bodyweight',
    id: '6e7185b7eacce3c99e15b79cbb3b0e9d',
    imageId: '30f0eb3b-8a51-4b8e-8fbe-4e99c885d500',
    name: 'bodyweight leg curl',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'bodyweight',
    id: 'c9e6a45f92d12cd3300ec296b171daf9',
    imageId: 'ee8803e6-288b-42af-98ae-407a5b654100',
    name: 'bulgarian squat with chair',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'cable',
    id: '7cad548a10eae18190be36547aad3168',
    imageId: '97697250-24e6-418b-a5d5-8123977aa800',
    name: 'cable curl (palms up)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'outer',
    equipment: 'cable',
    id: '7cad548a10eae18190be36547aad3169',
    imageId: '97697250-24e6-418b-a5d5-8123977aa800',
    name: 'cable curl (palms down)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'waist',
    equipment: 'cable',
    id: '1c73fe8634b3f568e15a6d80722451af',
    imageId: '61b0bf01-85c7-4733-805c-3dc5c6c23300',
    name: 'cable crunch',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'cable',
    id: '76cf8a4f5a4d0a6c941da66fb9e41555',
    imageId: 'c54d6d87-0e06-4baa-e4d8-28168fa64700',
    name: 'cable deadlift',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'cable',
    id: '0f97027faebeabe804342c2526f4a6c6',
    imageId: 'fb545fe9-ad7c-437b-a9af-873c18ea2d00',
    name: 'cable front raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'outer',
    equipment: 'cable',
    id: '1c62b54d2b796f4ba505f9ae7e4f191b',
    imageId: '881b415e-cdda-402f-088d-15acc27a5f00',
    name: 'cable hammer curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'waist',
    equipment: 'cable',
    id: 'c475146c444199a27535023371e05a92',
    imageId: '1ede9f1c-9323-451e-bf62-f843f8c12800',
    name: 'cable kneeling crunch',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'medial',
    equipment: 'cable',
    id: '08fceea1229db8a44a8eb623df61b328',
    imageId: '9f1b8dcf-7f8f-41fe-4cea-726c45ed4100',
    name: 'cable lateral raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'medial',
    equipment: 'cable',
    id: '0ed4451f4aa5a4b944866e576f717996',
    imageId: 'd298416e-e7ff-49bd-635a-aeac7f2b8800',
    name: 'cable lateral raise (one arm)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'waist',
    equipment: 'cable',
    id: '4125815f0c3ac407341a9d303b719313',
    imageId: '3b23db46-8428-44b4-69d4-78ad941deb00',
    name: 'cable on shoulder pulldown',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'cable',
    id: '3f2316f64ee227658580234d7ab43b49',
    imageId: '0208bcd4-f419-4b67-d5bf-9de68bcf3d00',
    name: 'cable one arm curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'cable',
    id: '85d628b19dcfcc27abaf5b8e07a68bb2',
    imageId: 'e43b8e87-1130-4c30-db51-3c901e2b6200',
    name: 'cable one arm extension (kickback)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'cable',
    id: 'bf64a599244ae23927e4fde671a3bda9',
    imageId: '1c68d88e-3cba-4515-a8e7-e4bb65d78900',
    name: 'cable overhead curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'rear',
    equipment: 'cable',
    id: 'ff35f79145d761119b1e65a215c872c8',
    imageId: '45f19780-bd0b-4b94-e072-4a7dba9fab00',
    name: 'cable rear drive',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'trapezius',
    equipment: 'cable',
    id: '753ae94aca970801f8e0d134ae879299',
    imageId: 'fe3a1382-3375-4b6d-6a0e-c974b8ce8300',
    name: 'cable shrug',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'cable',
    id: 'ca57040a15f60b84cda9ed8eea5593fb',
    imageId: '86b6b3f4-a031-48b9-19fc-c82aaf86d900',
    name: 'cable standing bent pushdown (rope)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'rear',
    equipment: 'cable',
    id: 'a34b5413892b37ca3faf8fc8ccc29942',
    imageId: 'fce38e99-54a7-43f1-bb45-3dda22acc900',
    name: 'cable standing cross over high reverse fly',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'rear',
    equipment: 'cable',
    id: '5f19c0681571ef4dd40b21065888beb1',
    imageId: '125a8b7d-1c56-4679-e156-b45706211d00',
    name: 'cable standing rear delt row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'hips',
    equipment: 'cable',
    id: '393f5063534cc0342b269b6b5feae821',
    imageId: '297b1cde-24d2-412c-e54c-97263860b600',
    name: 'cable straight leg deadlift',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'cable',
    id: '55b024617cecce2d9547aad3554ebcc6',
    imageId: '31f3ed16-6b2f-4ee1-025d-2839a0af1900',
    name: 'cable tricep pushdown',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'forearm',
    equipment: 'cable',
    id: '3365387173f3995378d4ebca8e1c6c4b',
    imageId: 'c3b45042-3488-4d1c-9ff1-7f113ec18a00',
    name: 'cable wrist curl',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'bodyweight',
    id: '85b20f0330a6dbc7dc49d2b60fcb5f57',
    imageId: 'a5503e71-5e79-4b54-079a-0b509bc70100',
    name: 'chest dip bodyweight',
  },
  {
    category: 'strength',
    recordType: 'assistedBodyweight',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'leverage machine',
    id: 'e4f27427943159b69a3df793f6c0b45c',
    imageId: 'fc24ffc2-709b-483a-0eed-7799858a6b00',
    name: 'chest dip (assisted)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'back',
    subType: 'upper back',
    equipment: 'bodyweight',
    id: '39b7829f4de7ca479e25d3627f422c98',
    imageId: '451fc27b-0383-4c70-1619-e284e5f54d00',
    name: 'chin up',
  },
  {
    category: 'strength',
    recordType: 'assistedBodyweight',
    type: 'back',
    subType: 'upper back',
    equipment: 'leverage machine',
    id: 'a40cc26e0041fb101b8ca0e5700c8c75',
    imageId: 'd706f04e-2a63-4fe9-a0fb-2996044e8e00',
    name: 'chin up (assisted)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'bodyweight',
    id: '95428cba5a364a0d637bf1faca5a5a38',
    imageId: '5395280a-672a-4560-cf3a-5e02a2eb6900',
    name: 'clap push up',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'upper chest',
    equipment: 'dumbbell',
    id: 'c6f2b006a9f11e20c2e1d4e37e4050d4',
    imageId: 'b289c368-4355-47f2-1c1d-d90c15f37700',
    name: 'dumbbell bench press (close grip)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'cable',
    id: '60d35d4f9e6165ab20cafec4a74d2085',
    imageId: '07c883ef-fed4-417e-5e19-cc55b3e49c00',
    name: 'pulldown (close grip)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'tricep',
    equipment: 'bodyweight',
    id: 'b86673182c62c4c62e10a46ab463e2f3',
    imageId: 'c304cc06-6966-4f75-22de-daebf0ceb300',
    name: 'push up (close grip)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: '799ea00f728e4f2191741d509b3ee9f9',
    imageId: '178042b6-da1c-4b08-2e8f-e4b8d7414000',
    name: 'cocoons',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'bodyweight',
    id: '8457afa0c97dd761e6a450e519814175',
    imageId: 'e2e1c7d9-11fe-4622-8e4c-c065c2c71000',
    name: 'cossack squat',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: 'b928e4f4421d02b1614526fd88f5663b',
    imageId: '839a48dd-9b14-44c7-3077-7fb7cc17fa00',
    name: 'crunch floor',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: 'cc14a5dd529a56f4c15a52fa5e3c82de',
    imageId: 'b0b9b504-00e8-4d07-4505-33d6014b6d00',
    name: 'crunch toe touch',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: '3152e2e329b5ad2744271e3ab80daa3b',
    imageId: '42b160ab-adae-494b-4816-17cc2a255f00',
    name: 'dead bug',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'barbell',
    id: '285af95c5a5e797a8ba84b7e8f8f394e',
    imageId: '6ec4bbc1-b2bf-49c9-87ff-d4c215fc9f00',
    name: 'decline barbell bench press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'upper chest',
    equipment: 'cable',
    id: '4308373f10defc7477cf3377dcdb293e',
    imageId: '4ff19d42-74dc-4934-72e1-40f3c0e63200',
    name: 'decline cable chest fly',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: '8385c2d71ef5a4f6869a33b2c4dbf672',
    imageId: '444553d7-18d6-421c-3438-d7756dfe8600',
    name: 'decline crunch',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'dumbbell',
    id: '787b9d6d8d12fcd8595b3fa61dc549f8',
    imageId: '18dd7609-f4dd-45f6-f06e-8bd7b4a31300',
    name: 'decline dumbbell bench press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'dumbbell',
    id: 'b94c89bf427097dfd7af61b04dc9860c',
    imageId: 'a7232d58-b7e7-46b1-97bd-343ed581d400',
    name: 'dumbbell alternate curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'dumbbell',
    id: '9e30eb3f79a5dbe1f317e2fef696ba13',
    imageId: '7235240d-5afc-4bd1-5bc3-f883dc5d5100',
    name: 'dumbbell arnold press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'dumbbell',
    id: '45f40f5712c6240e3dde26b41db6e957',
    imageId: 'ede279cf-7ac9-4bf4-a24d-2e1859566d00',
    name: 'dumbbell bench press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'dumbbell',
    id: 'fa30a3366fadea4ac8cdf815e1b4c397',
    imageId: 'b021f672-9b28-4be6-82fb-d8db8bb8ed00',
    name: 'dumbbell bench press (hammer)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'upper chest',
    equipment: 'dumbbell',
    id: '99a78bfb232014e863c9af2426a6bf9b',
    imageId: 'a7d79963-a54d-4ce1-9b2a-5d01477e7b00',
    name: 'dumbbell bent arm pullover',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'dumbbell',
    id: 'aa26fb60314ac66083afd694c36ee71d',
    imageId: '99d86a98-e200-446f-57c4-a29fcb59c400',
    name: 'dumbbell bent arm pullover around the world (middle & lower)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'upper chest',
    equipment: 'dumbbell',
    id: 'aa26fb60314ac66083afd694c36ee71e',
    imageId: '99d86a98-e200-446f-57c4-a29fcb59c400',
    name: 'dumbbell bent arm pullover around the world (upper)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'lower back',
    equipment: 'dumbbell',
    id: '3f0c012cf4d4b0e9b015893b90f74e77',
    imageId: 'e685b5e6-ff48-4181-a1cc-8fb41bd34d00',
    name: 'dumbbell bent over row (lower back)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'dumbbell',
    id: '3f0c012cf4d4b0e9b015893b90f74e78',
    imageId: 'e685b5e6-ff48-4181-a1cc-8fb41bd34d00',
    name: 'dumbbell bent over row (upper back)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'dumbbell',
    id: 'cf0220d7bb84c7b9ea8f5bd10368da40',
    imageId: '283ec77b-d9d2-48a3-3ef0-156c2786c100',
    name: 'dumbbell biceps curl squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'dumbbell',
    id: '17d0905a7d70e092cc1571da1ac88e4e',
    imageId: '21b7258d-af32-4fac-3ac0-712a449cb800',
    name: 'dumbbell burgarian squat (hamstring)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'dumbbell',
    id: '17d0905a7d70e092cc1571da1ac88e4f',
    imageId: '21b7258d-af32-4fac-3ac0-712a449cb800',
    name: 'dumbbell burgarian squat (quadricep)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'dumbbell',
    id: '20a056d2a91663dc0016d1646cc91301',
    imageId: '303d156b-dd98-47a8-57cd-04f5e9763000',
    name: 'dumbbell concentration curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'dumbbell',
    id: '10e9bfafcb7e131925eed94bb5c8d79b',
    imageId: '098be993-f856-4ebe-1b6a-d57f3ba07800',
    name: 'dumbbell cuban press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'dumbbell',
    id: 'b8b16e7a92fc99e862c674c9d7a0a039',
    imageId: '50ccbc8f-e5b7-4c2b-a24c-5523cd37d900',
    name: 'dumbbell curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'dumbbell',
    id: '0f2d5ce607720a6f6ae0d81573cd270b',
    imageId: '684593cb-e711-4679-9eb1-b82f3b162900',
    name: 'dumbbell deadlift',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'trapezius',
    equipment: 'dumbbell',
    id: 'a9890452b8018269e0f77bcb4b1c8172',
    imageId: '60cfefd0-0643-4b84-a8ea-830142aff600',
    name: 'dumbbell decline shrug',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'dumbbell',
    id: '7ca6fabd47e38cf831299f3d5b3dee72',
    imageId: '6d9fe7fc-cbcb-4888-3f5e-78b6ca903900',
    name: 'dumbbell farmers walk',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'dumbbell',
    id: 'adf1e8a33f8198863dbd2bd304f88244',
    imageId: 'b12eafd2-0a15-4d53-94da-b5dc84b97100',
    name: 'dumbbell fly (floor)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'dumbbell',
    id: '0887e57c37f1013856239fe834f62039',
    imageId: '15f37ebf-1fa7-4986-f381-c65217e44b00',
    name: 'dumbbell hammer press (floor)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'dumbbell',
    id: 'bad5340fe4bd1308e123aa751eb4dbe8',
    imageId: 'f7f879e2-dc0c-4b58-93a9-61529d822000',
    name: 'dumbbell fly',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'dumbbell',
    id: '13e0af36946147ff918a787a54ab9855',
    imageId: '0e094c23-3a93-45ef-becb-cacca3343900',
    name: 'dumbbell front raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'dumbbell',
    id: 'b9710dc5fa1bb57b4210e4882bef23bc',
    imageId: 'a990ed2a-be9c-48e0-a4b1-c959e4035e00',
    name: 'dumbbell front squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'dumbbell',
    id: '50bc776d84f36f17cf4a34557726bbd2',
    imageId: 'b96d9472-06d0-4f59-3522-de67003ab600',
    name: 'dumbbell goblet squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'outer',
    equipment: 'dumbbell',
    id: '9c037f7bf443c67470f42aed6ad82438',
    imageId: 'f34be378-a06a-4c31-2c30-d5e16188f700',
    name: 'dumbbell hammer curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'outer',
    equipment: 'dumbbell',
    id: '10018471a67f87ed63f68c8134426247',
    imageId: 'b1d0ef2e-8e8a-46b9-1066-e82cec8e8a00',
    name: 'dumbbell hammer curl (alternate)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'outer',
    equipment: 'dumbbell',
    id: '1ad953053adecda5a2ad5a5388de3080',
    imageId: '898b6346-5b8b-404c-f0ba-fdaa99492700',
    name: 'dumbbell hammer preacher curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'dumbbell',
    id: '15a099cd679c182180336593857e11dd',
    imageId: '385e04dd-593c-432e-c8bb-ff08e03b3300',
    name: 'dumbbell hip squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'dumbbell',
    id: '61fdb2242309eb215e6368edef7eef04',
    imageId: '5b55ce2e-9d0a-4f48-c10b-a7da73058400',
    name: 'dumbbell incline curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'upper chest',
    equipment: 'dumbbell',
    id: '834afdb575898b3ec47b9d2231ae0955',
    imageId: '1a4b7160-a281-475b-f1c7-1dc674f44c00',
    name: 'dumbbell incline fly',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'outer',
    equipment: 'dumbbell',
    id: '761ae2fbbf68c352439c48e8170fad87',
    imageId: '4162fcce-bce5-4c66-1920-2a5431134000',
    name: 'dumbbell incline hammer curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'dumbbell',
    id: 'f74f50af189ce25689646d626332bec8',
    imageId: '3fac45f2-4351-48c8-6afc-a4de3c83e800',
    name: 'dumbbell incline inner curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'rear',
    equipment: 'dumbbell',
    id: 'fdd1015e9e008ebeb9ed81e285a8800d',
    imageId: '05f6cbb6-5de7-48a3-7aec-1bc90e123300',
    name: 'dumbbell incline rear lateral raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'trapezius',
    equipment: 'dumbbell',
    id: '41936e6e804cd01e938d2a2a50e127e6',
    imageId: '3020a353-1ed0-4014-8e35-1f1fb0860900',
    name: 'dumbbell incline shrug',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'dumbbell',
    id: 'a31a7f7c031df1dd6792b18a063a8ca4',
    imageId: '66bd4966-15e5-4f5f-5d82-67647dfc9500',
    name: 'dumbbell lateral raise (front)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'medial',
    equipment: 'dumbbell',
    id: 'a31a7f7c031df1dd6792b18a063a8ca5',
    imageId: '66bd4966-15e5-4f5f-5d82-67647dfc9500',
    name: 'dumbbell lateral raise (medial)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'dumbbell',
    id: 'e53510e46dba50b413b4011bbcbdaa1e',
    imageId: '352e7903-9152-474e-72d5-42facfbf0700',
    name: 'dumbbell lunge (hamstring tendon)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'dumbbell',
    id: 'e53510e46dba50b413b4011bbcbdaa1f',
    imageId: '352e7903-9152-474e-72d5-42facfbf0700',
    name: 'dumbbell lunge (quadricep)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'dumbbell',
    id: '528f81037c52214fd50f753743326742',
    imageId: 'adcccd00-d4a3-493a-bf3b-00e0367db800',
    name: 'dumbbell lying triceps extension',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'forearm',
    equipment: 'dumbbell',
    id: '3455d2dcc4d10ae59a7061c4c815c781',
    imageId: '084b2fc8-adfa-45db-5720-24b0666c2e00',
    name: 'dumbbell one arm reverse wrist curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'forearm',
    equipment: 'dumbbell',
    id: '5b2db93abdbb7ad7a9e8d64ec7a4213d',
    imageId: 'eced03f4-5ea5-4bab-590f-ae46aa071400',
    name: 'dumbbell one arm reverse wrist curl (bench)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'dumbbell',
    id: 'd66339b432efb789a9903b2b3dd967d1',
    imageId: 'af47faad-f31d-4d72-df4a-71f7e4a61c00',
    name: 'dumbbell one arm row (rack support)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'dumbbell',
    id: 'ce00cbfcd65eb0f6d3f60b3278d67d92',
    imageId: '88a74c4a-73ca-4e06-6a55-d2797f4df300',
    name: 'dumbbell one arm snatch',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'dumbbell',
    id: '33b4ad58a5469db74d12667100fe85b6',
    imageId: '13a546fe-e7a5-4737-72fa-380ff47c3c00',
    name: 'dumbbell one arm tricep extension (kickback)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'forearm',
    equipment: 'dumbbell',
    id: '0eb16f458698b1292d5d64d5124a0c2f',
    imageId: 'af864131-0d59-4931-5f39-5ba6bf30d700',
    name: 'dumbbell one arm wrist curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'dumbbell',
    id: 'e8221279dba2a0b2295f797dad176d6b',
    imageId: '1add1434-8fb9-4589-fb98-1e69d89c0400',
    name: 'dumbbell overhead pullover',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'dumbbell',
    id: '8d8fc149e4a75ac53e98ee2bf95f82eb',
    imageId: 'be68e009-6452-404a-8a41-14f52bbbd800',
    name: 'dumbbell bent over row (palm rotational)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'dumbbell',
    id: '385094011a356d481d15f50d88b40dc2',
    imageId: 'a4b30e35-db16-4ae9-c5c0-f442db813200',
    name: 'dumbbell incline curl (prone)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'trapezius',
    equipment: 'dumbbell',
    id: '301dfc0e7e05b43d679831d3c43e6398',
    imageId: '5061e38f-a4d0-4729-5950-dee5025d6100',
    name: 'dumbbell incline shrug (prone)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'rear',
    equipment: 'dumbbell',
    id: '8a0b380d7803e958387a93c761e9db77',
    imageId: 'e9fe8851-62d7-41e1-7fd7-4c78fd10bf00',
    name: 'dumbbell rear delt row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'rear',
    equipment: 'dumbbell',
    id: '726ad78774c7c907cc54d88d067ce083',
    imageId: '6bc22508-f28c-468e-007d-b1055a97d800',
    name: 'dumbbell rear fly',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'rear',
    equipment: 'dumbbell',
    id: '903addb4403c0b126891867dc8589a4b',
    imageId: '2b94ecc4-9855-4010-b48e-b88d5afffb00',
    name: 'dumbbell rear lateral raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'dumbbell',
    id: 'c15f2cf44924c87bfaa4858db47566cb',
    imageId: '485b4486-4814-4fd4-e121-2938870d6b00',
    name: 'dumbbell scott press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'dumbbell',
    id: 'b75c2958cfaaa4eecfd77c1167271ea0',
    imageId: 'dc88474a-ef7c-4b83-3bd0-916d2238f500',
    name: 'dumbbell seated curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'forearm',
    equipment: 'dumbbell',
    id: '9d9a0f0315516aebd4ed62bb16da0976',
    imageId: 'cefbbb53-3014-416a-5df7-5753eb3e8300',
    name: 'dumbbell seated hammer wrist curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'dumbbell',
    id: 'd36e2873914deba3f998e69862bd4765',
    imageId: '6f06a405-9c5f-47c6-faeb-a557da87de00',
    name: 'dumbbell seated one arm preacher curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'dumbbell',
    id: '4abd2d097a42de2859438b2e94a66d0e',
    imageId: 'b5907c9f-c7a9-4851-72bc-3a78f174c200',
    name: 'dumbbell seated tricpes extension',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'trapezius',
    equipment: 'dumbbell',
    id: 'eb15d06db395579c0cba7deca69d6170',
    imageId: '14277d1a-4af7-4066-14c1-e998e1cfae00',
    name: 'dumbbell shrug',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'hips',
    equipment: 'dumbbell',
    id: 'f6fc0a207e45a5a83a5c870c1275b22b',
    imageId: 'fe9aac16-4453-4885-2b4b-4ee97e14e600',
    name: 'dumbbell single leg deadlift',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'hips',
    equipment: 'dumbbell',
    id: '315573dee24d21e383a85577ae7972af',
    imageId: 'cc63d34b-6c5b-4f1c-1e76-3dcc35006000',
    name: 'dumbbell single leg hip thrust',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'dumbbell',
    id: '9cad4498a5c937d1b26fd048d49d17bb',
    imageId: '681b2535-99a7-4ce4-dca5-dd577d85b600',
    name: 'dumbbell squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'dumbbell',
    id: '63744db34eefb1b86c737de58987419c',
    imageId: '538a537a-8529-4077-8565-29925a67ce00',
    name: 'dumbbell standing bent over row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'calf',
    equipment: 'dumbbell',
    id: '77b2e8e16ae7b08098fda613390554be',
    imageId: '2ffcd574-73d8-42fe-09ba-8b3b27aeb500',
    name: 'dumbbell standing calf raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'dumbbell',
    id: '3b9ef6a33476d71ade3781938728bd89',
    imageId: '3e4eec40-457e-407e-0f49-b2ea408cbb00',
    name: 'dumbbell standing one arm preacher curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'dumbbell',
    id: '2e5feeffcd08080818a4f5723fa339b3',
    imageId: '53b64b7e-bb4c-4c2c-dbc1-285c4a5ddf00',
    name: 'dumbbell standing one arm tricep extension (kickback)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'dumbbell',
    id: '3f980f8227eabeed71d4a610c002cd5a',
    imageId: '4cdd9e2a-6001-4b2c-e091-b723e130c800',
    name: 'dumbbell standing overhead triceps extension',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'dumbbell',
    id: 'd6696598406a21a2b652ef6c906e405d',
    imageId: 'd357decf-6084-4a2a-fe77-59c280d64600',
    name: 'dumbbell standing overhead triceps extension (one arm)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'dumbbell',
    id: 'c22cdce9518c9c455689d4417eb7a225',
    imageId: '1feb58db-2a64-4da0-a31a-bbc4770f0c00',
    name: 'dumbbell sumo squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'dumbbell',
    id: 'de27ef7b2cb3b635a93ce29f52d15957',
    imageId: '16cd6562-1bad-4ce1-c8fe-58ae0ed2b000',
    name: 'dumbbell upright row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'dumbbell',
    id: 'b52dd45622d48ea3887ffe3aeeb03c9a',
    imageId: '863be62a-9316-41d0-4156-7b3b9da94900',
    name: 'dumbbell zottman preacher curl (inner)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'outer',
    equipment: 'dumbbell',
    id: 'b52dd45622d48ea3887ffe3aeeb03c9b',
    imageId: '863be62a-9316-41d0-4156-7b3b9da94900',
    name: 'dumbbell zottman preacher curl (outer)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'hips',
    equipment: 'dumbbell',
    id: '7e1c48a5a1aa6db1422e32cbd79f2c06',
    imageId: '56f9fe20-ebc3-44e0-7b62-474521c6e100',
    name: 'dumbell hip thrust',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'back',
    subType: 'upper back',
    equipment: 'bodyweight',
    id: '739b89b4086b42d6de2c3afaa491c533',
    imageId: '2eef679c-9455-412a-fc50-5cfdadd21c00',
    name: 'elbow lift reverse push up',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'bodyweight',
    id: '7a1a3655a5b9f305441fdbe43b930c7d',
    imageId: '3c7e8aea-b0fa-409f-5007-e41267642d00',
    name: 'elevanted push up',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'forearm',
    equipment: 'barbell',
    id: '02815d3857b26357f336c673f588e8bd',
    imageId: '5c8da50e-7e4c-4c12-1107-9edbcdcc8900',
    name: 'ez barbell reverse forearms curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'dumbbell',
    id: 'b1c5b619131cd80da068bb45332bd3e4',
    imageId: 'f6e73c81-f649-49d5-fa57-69b9bb98a300',
    name: 'dumbbell press (floor)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'dumbbell',
    id: 'd6f52c4b6a12a1b36505eb1a9fe5dc28',
    imageId: '01d1d488-a528-411e-78b7-d31f7f3c0100',
    name: 'skullcrusher (floor)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: '80248b5946c7bd6aaed4039d07fdf52f',
    imageId: '47c6e9b0-520d-46de-3112-1a57c1b74800',
    name: 'front plank',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'cable',
    id: 'cae989446a48f5d467b47693d324a3f9',
    imageId: 'ec15248e-fff4-48c8-9ffb-ba293fb36700',
    name: 'front seated row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'hips',
    equipment: 'barbell',
    id: '5333945d664a1315f1fe7b657f0f76e4',
    imageId: '48d2aa25-2aac-4d63-93a5-9f7543655100',
    name: 'good morning',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'leverage machine',
    id: '268c0932e8f027b73318f5cff546735c',
    imageId: 'ea811928-6d05-44dc-56f8-0c93065e8b00',
    name: 'hack squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'lower back',
    equipment: 'barbell',
    id: 'ad1e974e071e64f9c47b891e2f4ec9d2',
    imageId: 'c2f71a6b-0243-45cd-850f-e16cbc777900',
    name: 'deadlift (half distance)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'shoulder',
    subType: 'front',
    equipment: 'bodyweight',
    id: 'ca5cfd4e098c609d15a48c7bc250f013',
    imageId: '356d48f5-518c-4028-f607-3a506c5eda00',
    name: 'hold on wall (handstand)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'shoulder',
    subType: 'front',
    equipment: 'bodyweight',
    id: '57499936a64fbbe7e305e952bbb4e147',
    imageId: 'a1733ec6-0216-4979-3d05-51592481f900',
    name: 'push up (handstand)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: '9b8c9d080e1d7f72f6a6b265f2a7ed35',
    imageId: '8681f402-6632-4961-c2bd-955a339acc00',
    name: 'hanging leg raise',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: '33913a8637223fac75cf91cbc90448f7',
    imageId: '3434bd14-0b6a-4a09-cf09-0122d6206600',
    name: 'hanging leg raise (slingers)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: 'bb95cd645a82c690d73b247897d3c693',
    imageId: 'bd3c7f32-3f57-4d46-6e7e-a9597dab3a00',
    name: 'hanging oblique knee raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'cable',
    id: '1b54eb04ae5cc1cebbc2528fa53f0766',
    imageId: '19def3b6-5f31-477e-e692-af06635f7f00',
    name: 'hight rope row (kneeling)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'hips',
    equipment: 'smith machine',
    id: '604238e427f89475a245d24e81df78af',
    imageId: '9bafbfe2-2a59-4d8d-0424-3bb9a6d9fe00',
    name: 'smith hip thrust',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'hips',
    equipment: 'bodyweight',
    id: '4a09ead2ec37d4e459f0739772d0b7cf',
    imageId: 'd9dba08d-4fc1-4be0-f091-0fe9c0e14800',
    name: 'horizontal hyperextension',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'hummer machine',
    id: 'e6a02ae8f42b22a284ba8ebaf6a0b5c0',
    imageId: 'c299bfe6-8981-4fd4-ac72-4b81cfac4100',
    name: 'chest press (hummer)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'hummer machine',
    id: '18ba57af0e55a8806cae8f6b0e61caa3',
    imageId: 'b3eac751-db9e-418d-2d25-7d6f81a2c000',
    name: 'lying chest press (hummer)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'hummer machine',
    id: 'a11f3f801365274491de2e8572b4746a',
    imageId: 'a133193b-dfaf-4dbc-3765-a9d0728a8400',
    name: 'pulldown (hummer)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'hummer machine',
    id: 'd3a3863c2b6e36c77bc138ef9b2ede5b',
    imageId: '4063e80f-3faa-457d-981e-683bef53ed00',
    name: 'reverse pulldown (hummer)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'hummer machine',
    id: 'c32a59dd111b47d9d191a47b83614baf',
    imageId: 'ee4a0aba-0aa0-4771-29f6-35f85816f400',
    name: 'row (hummer)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'upper chest',
    equipment: 'barbell',
    id: '23e9e70a6872ccfa36bf9b0cda9f7a59',
    imageId: 'af78afb2-cc5f-428f-bb36-790566274700',
    name: 'incline barbell bench press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'upper chest',
    equipment: 'cable',
    id: '4e66ac9e90f5db59eb830e4625e26555',
    imageId: '2405c2d1-699b-4ed7-85ad-83d7ac883000',
    name: 'incline cable chest fly',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'upper chest',
    equipment: 'dumbbell',
    id: 'f7c01084af81cf69dbbcde0505e7e913',
    imageId: 'a8a8be99-c9ee-494b-dc08-01083168aa00',
    name: 'incline dumbbell bench press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'upper chest',
    equipment: 'dumbbell',
    id: '4a8620e8bc9259d8127c4e67a1f6df39',
    imageId: '0499b1eb-3da3-4850-22a1-4ed619304500',
    name: 'incline dumbbell twisted fly',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: '6eca4912580304d5f332a131acef9347',
    imageId: 'cb5c7a6c-e1de-4c3a-71b4-846fc071c100',
    name: 'incline leg raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'medial',
    equipment: 'dumbbell',
    id: '85dfb1824a9c12085d5312fa7ee04b37',
    imageId: 'b94be9c6-d48c-4f19-ebd6-2476a4c0da00',
    name: 'incline one arm dumbbell lateral raise',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'chest',
    subType: 'upper chest',
    equipment: 'bodyweight',
    id: '16c86ae4774e78bef9cea1127dfe4f6a',
    imageId: '13277d92-88c3-4ca3-8b10-5852b3189400',
    name: 'incline push up',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'upper chest',
    equipment: 'dumbbell',
    id: '08f77813465ad7472737229bf76aa4e7',
    imageId: '6a2067cd-cf54-401d-044c-2b64fd92b600',
    name: 'incline reverse dumbbell chest press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'upper chest',
    equipment: 'smith machine',
    id: 'f0a896b52ee0f8c9273026ecc693695f',
    imageId: 'a3a0fa5d-5640-4589-2d01-fc7791e8cc00',
    name: 'smith incline bench press',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: 'c53331b213ad2b998832ef2fc9258c63',
    imageId: 'bdb18189-af0e-411f-e3ca-85e813c4ae00',
    name: 'incline twisting sittup',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: 'c8e157713892ca0d785778febf686ed4',
    imageId: '590e1e4c-02cc-4230-036c-98c61e777400',
    name: 'jackknife',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: '3959f451cf9f4e80b55273af62e938c3',
    imageId: 'da6bb3c8-a109-491f-6eed-c3b1133bd600',
    name: 'jackknife sit up',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'bodyweight',
    id: '34f492c6e3a844a3aa61e05e4e03e6b1',
    imageId: '020b91b6-8143-46c1-0787-c5040300c800',
    name: 'jump split',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'back',
    subType: 'upper back',
    equipment: 'bodyweight',
    id: '9203bb5c3e240f71c883a1c09e7177f6',
    imageId: 'a019e9bf-579d-42c9-7bbc-dddea4691100',
    name: 'jumping pull up',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'cable',
    id: '1aef1aba3d4f59446262a4ce0bbf0421',
    imageId: '4f00c04f-434b-4506-72ea-8430572f7f00',
    name: 'kneeling tricep extension (cable)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'hips',
    equipment: 'smith machine',
    id: '8fec71ced09b4bae93b02c3b0f9fcc0a',
    imageId: '6d784f0e-2364-46b4-1218-d1b5d5eece00',
    name: 'smith kneeling hip thrust',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'bodyweight',
    id: '4e17c3c210ff4f802e35fe1c4d1c3108',
    imageId: 'd574f6c4-db9e-486f-b3da-48157988b400',
    name: 'kneeling push up',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'hips',
    equipment: 'smith machine',
    id: '978fc032c7deb2e19a0a2a963df2b812',
    imageId: '1d96632b-9e6e-4f34-d4dc-e7686f2b8000',
    name: 'smith kneeling rear kick',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'lower back',
    equipment: 'barbell',
    id: '75b4c61910de9bd5186bc7abbacc53f2',
    imageId: '9d2a5b4a-76e6-4388-1bf4-12dc4ef37d00',
    name: 'landmine one arm bent over row (lower back)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'barbell',
    id: '75b4c61910de9bd5186bc7abbacc53f3',
    imageId: '9d2a5b4a-76e6-4388-1bf4-12dc4ef37d00',
    name: 'landmine one arm bent over row (upper back)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'barbell',
    id: '6edefb3a11c2caec0263f944d96c98de',
    imageId: '9dd48e01-d1a9-4441-c1cf-0ac986559b00',
    name: 'landmine squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'leverage machine',
    id: 'e029a548f04103a46ac59cb554aed1b1',
    imageId: 'e93e2512-9578-4e17-1523-aaed1dc9b400',
    name: 'lateral leverage pull down',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'leverage machine',
    id: 'cc7b155d6b68b53e6d916ce36ccf7387',
    imageId: '083077e7-b17f-402a-556f-118318d6ff00',
    name: 'lateral leverage pull down (v2)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'calf',
    equipment: 'leverage machine',
    id: '687a7c5de210b2cd0084d71b3fe5aa8c',
    imageId: 'b7ee66bd-b8fa-4dd2-ec8a-50f87c6dbc00',
    name: 'leg lift leverage calf press',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: 'a67ccd002399a8f74133022e6355691c',
    imageId: 'b2973031-3fd1-4190-8f5e-c294b9365700',
    name: 'leg raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'waist',
    equipment: 'leverage machine',
    id: '75be915801dd3c28d3ec4182f53b658d',
    imageId: 'b5a5884a-0a7b-4241-d28c-beb53103a000',
    name: 'leverage ab swing',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'leverage machine',
    id: 'dfd27dfbcc3f25761dc30ba25113da57',
    imageId: '033a4588-fd1c-40f8-6e64-80d585112700',
    name: 'leverage deadlift',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'trapezius',
    equipment: 'leverage machine',
    id: 'b5648b06a45d2d9bf58c09f8bb0dfed0',
    imageId: 'fe57df1a-1c0d-48a2-e12d-980a3d057f00',
    name: 'leverage deadlift shrug',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'waist',
    equipment: 'leverage machine',
    id: '22f5bda03b97b009c6aed15b17637f2f',
    imageId: 'b273fb55-1e78-46ab-32e3-f7f7403dc400',
    name: 'leverage kneeling twist',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'medial',
    equipment: 'leverage machine',
    id: 'fc7b9489cb72985601a1168965d5730c',
    imageId: 'ec22215f-4efb-4899-c8c5-4d2e02ed1a00',
    name: 'leverage lateral raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'leverage machine',
    id: '1ced47847ca6548aa194a1a670aa5cf3',
    imageId: 'f0a2748b-0736-428b-0f3a-160c253ec500',
    name: 'leverage leg extension',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'leverage machine',
    id: 'd112456e0c00d919ce4506d6c50ffd95',
    imageId: '93eb4d7d-4f52-4c54-c485-273876cb9e00',
    name: 'leverage preacher curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'leverage machine',
    id: '2bfd7cebcba41cbe3273a740df2abd08',
    imageId: 'c8a1c3a1-480b-41c9-1289-cc1bfa171a00',
    name: 'leverage press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'waist',
    equipment: 'leverage machine',
    id: '52cfd3a3c31bb9c879f6ebc6dffbd112',
    imageId: '9e723214-e692-4259-e283-0fda410fd000',
    name: 'leverage seated crunch',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'leverage machine',
    id: '5592572c2e9f7f59a1cfa1b7b5bee4be',
    imageId: 'ef963256-94ba-454c-5d74-8597be700d00',
    name: 'leverage seated dip',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'trapezius',
    equipment: 'leverage machine',
    id: 'c3ffaec91fc62312c150f68803c856d9',
    imageId: '16ffccc4-a237-4520-270f-f3fb9d011900',
    name: 'leverage shrug',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'trapezius',
    equipment: 'leverage machine',
    id: 'bfb67ad8f4fdead4c51f45a39d21e79f',
    imageId: '31387684-406b-4b19-ad23-7b722dc6e300',
    name: 'leverage shrug (bench press machine)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'hips',
    equipment: 'leverage machine',
    id: '0196d8f82356cf20b29a586326333c01',
    imageId: '8e43169a-3ae0-473f-0d40-f548d7b28000',
    name: 'leverage standing rear kick',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'hips',
    equipment: 'leverage machine',
    id: 'dd4396e875f241c551bff59adcf7a1e0',
    imageId: '3e1fcbe2-a542-4cb2-f264-ef0557366900',
    name: 'leverage standing rear kick (v2)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'leverage machine',
    id: '6771d1b3f8f4ee9549ae1194c9411e51',
    imageId: 'd0ab3687-77a4-42ca-5023-b3bf0d313100',
    name: 'leverage triceps dip',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'leverage machine',
    id: '5a8afabb943b892bf95a0c27672b0e09',
    imageId: '0acfb490-3af6-4561-85a1-a61fa5eb8500',
    name: 'leverage triceps extension',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'leverage machine',
    id: '142a43904f942c2d34af4dd49e9ebcdf',
    imageId: '55407291-f393-4e34-dc3c-7331aac25900',
    name: 'leverage triceps extension (v2)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'leverage machine',
    id: '7275e4990cec62308675096ec51a3a48',
    imageId: '0c020986-edcd-4f60-1d29-d020af2f7900',
    name: 'levereage curl',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'bodyweight',
    id: '72cc536f16868cb443ac8248f072f362',
    imageId: '1f51afa4-e8f1-46fa-c8a4-7a8299796500',
    name: 'lunge',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'cable',
    id: '5471de939549339e32ac3a923c77e973',
    imageId: 'f26adac6-e5ad-45c1-5f1f-8d5440ccbf00',
    name: 'cable fly (lying)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'dumbbell',
    id: '2a6b757e7c36ad232ea15834d3398434',
    imageId: 'db0b63bc-2fd8-4bbf-35f8-38b87a4b4300',
    name: 'dumbbell leg curl (lying)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'leverage machine',
    id: '17267e1bc25b3dbc5030ff0de5d57168',
    imageId: '13b49073-45d4-458f-2de0-db4d74236100',
    name: 'leg leverage curl (lying)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'dumbbell',
    id: '2e60ac48e284969b8b3acf8acd4e8cfa',
    imageId: '8f6f2b8e-63ee-493f-9e47-e574fe625900',
    name: 'rear dumbbell row (lying)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'bicep',
    subType: 'inner',
    equipment: 'bodyweight',
    id: 'd2efacd40ff71549d685fe2833180f5e',
    imageId: 'ecc7e1e1-32b3-4902-e0e7-99cccdec2d00',
    name: 'single legs reverse curl with towel bodyweight (lying)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'leverage machine',
    id: 'f83d157886a9892d6b3be28b17a8c84b',
    imageId: 'a364d218-71f3-46be-e710-2d730eda1f00',
    name: 't - bar leverage row (lying)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'cable',
    id: 'e4bbe343a6359251c8ac8c6480ae48f4',
    imageId: 'c753207c-9d81-442b-6d4c-0f5e7f735c00',
    name: 'tricep extension (lying)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'cable',
    id: '1769d703667d7f31cb260d50382106ce',
    imageId: '111e4cfe-b29b-4526-f6f9-9540693e7a00',
    name: 'middle cable fly',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: 'ca5a30cc33686fdcdcecf3e18d68f720',
    imageId: '81eb6e7b-321c-4ca3-3fc0-a35def5f3900',
    name: 'mountain climber (cross body)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'cable',
    id: 'ee870cd53d417d547ffd5d4b4e687cb5',
    imageId: '76c12be7-0fd7-420b-781c-09a924c30400',
    name: 'pulldown (one arm)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'cable',
    id: 'ff35f79145d761119b1e65a215c872c9',
    imageId: '45f19780-bd0b-4b94-e072-4a7dba9fab00',
    name: 'rear drive (one arm)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'bodyweight',
    id: '031610fbb43fa11afb9353a3e4561766',
    imageId: 'b38373a7-2cda-47ab-022f-cfa449b9c200',
    name: 'squat (one leg)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'barbell',
    id: '494dfba0b2e87b91c3e5cb9245d397ae',
    imageId: 'd462ce40-040f-4c4a-fc08-0a24f69e8000',
    name: 'barbell bench press (pause)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'leverage machine',
    id: 'bc19f9307fb5329e24db72e1e0c03b15',
    imageId: '73142136-f0a6-4a8c-f529-a640c58f8800',
    name: 'pec deck fly',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'barbell',
    id: 'f00bea3a7f07d22496f28964b8adc626',
    imageId: '463f841d-7b81-49e8-d884-47e480244c00',
    name: 'pendlay barbell row',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'chest',
    subType: 'upper chest',
    equipment: 'bodyweight',
    id: '7e7d0db73490a1bcbe9f76336bb70a1a',
    imageId: '1657b4a6-0d14-4da9-6914-2cdb2a8f3a00',
    name: 'pike push up (chest)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'shoulder',
    subType: 'front',
    equipment: 'bodyweight',
    id: '7e7d0db73490a1bcbe9f76336bb70a1b',
    imageId: '1657b4a6-0d14-4da9-6914-2cdb2a8f3a00',
    name: 'pike push up (shoulder)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'bodyweight',
    id: 'dcf036c38393cc6244bce80222d9f9c8',
    imageId: '8d461e76-5c58-4a9e-d714-d2dd3d9f4200',
    name: 'pistol squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'barbell',
    id: 'dfea706c3ea7f3ca8b1d77c225825db3',
    imageId: '7d3e933a-e8dc-4188-e9f3-7670ffc5c700',
    name: 'barbell bench row (prone)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'barbell',
    id: 'ee6277968bdb59d3b760d85e617d2d31',
    imageId: '01847f46-187b-4b64-881e-8a1efdc44000',
    name: 'incline curl barbell (prone)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'dumbbell',
    id: 'a463471d31bb35f0deb48ec2d7bac817',
    imageId: 'fdc325a4-796d-439d-306e-4701fe113500',
    name: 'incline dumbbell row (prone)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'dumbbell',
    id: '3d544295a4c5eeb64d75ececb6e636f8',
    imageId: '453c4e07-8309-4e54-2907-45000c11b200',
    name: 'incline dumbbell hammer row (prone)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'back',
    subType: 'upper back',
    equipment: 'bodyweight',
    id: '3aa9121cc0a10388e8fc6d45acb9cc91',
    imageId: 'e0076fa4-f6cc-4daf-ef59-a773bb052f00',
    name: 'pull up',
  },
  {
    category: 'strength',
    recordType: 'assistedBodyweight',
    type: 'back',
    subType: 'upper back',
    equipment: 'leverage machine',
    id: '52cdc4d1321971621d8a7dfca8ecd9f8',
    imageId: '4fc44ea0-62fc-408d-f2ab-93ad4bc17400',
    name: 'pull up (assisted)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'bodyweight',
    id: '60b04ea4e44f022bda027860a3b816d4',
    imageId: 'b8c62672-77a6-4456-06ed-5da3ecb7d300',
    name: 'push up',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'bodyweight',
    id: '386d1d814bb4ab6a7a25cd9fbbcc98e6',
    imageId: '5236c869-6e53-44aa-8efb-9d985edb7d00',
    name: 'push up (handles)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'bodyweight',
    id: '32602ceefa6d9bc03c56eb352b8dfa01',
    imageId: 'ec442cdd-3058-4430-11d8-ea1684045000',
    name: 'push up (stability ball)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'rear',
    equipment: 'cable',
    id: '15dac409310f78d2c57925d05524a4ad',
    imageId: 'b97dfa01-f0cb-4451-58c3-047e7d467b00',
    name: 'rear cable lateral raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'dumbbell',
    id: '62e300357c79ef39333be0d4216f7b1b',
    imageId: '33306dde-259c-4649-7c67-b6dd32ed3600',
    name: 'rear dumbbell lunge (quadricep)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'dumbbell',
    id: '62e300357c79ef39333be0d4216f7b1c',
    imageId: '33306dde-259c-4649-7c67-b6dd32ed3600',
    name: 'rear lunge dumbbell (hamstring)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'trx & band',
    id: '7324c8359800b67fe829ae9f4ef7ac8b',
    imageId: '6b00c3f0-5c29-46b5-7665-b80ef6a15b00',
    name: 'band - seated press (middle & lower chest)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'upper chest',
    equipment: 'trx & band',
    id: '7324c8359800b67fe829ae9f4ef7ac8c',
    imageId: '6b00c3f0-5c29-46b5-7665-b80ef6a15b00',
    name: 'band - seated press (upper chest)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'lower back',
    equipment: 'barbell',
    id: '98323ef09a193b803479d1b069f7a803',
    imageId: '6cb56edd-6a8b-47bf-f4b2-fd1cfb097100',
    name: 'reverse barbell bent over row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'cable',
    id: '155719957eb2e2b897db787b9015d767',
    imageId: 'd27876ca-8d73-40a4-8f3a-3a93328c5600',
    name: 'reverse close grip pulldown',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'upper chest',
    equipment: 'dumbbell',
    id: 'a4867659b2f4bb6600f5a27d475c78bb',
    imageId: '6ede41a8-dc23-4059-4af7-3b0252c64200',
    name: 'reverse dumbbell bench press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'hips',
    equipment: 'leverage machine',
    id: 'fba366c43ed45da32ccd23a2744c1112',
    imageId: '7519cedd-d6f9-4233-185f-7e0cdad3f400',
    name: 'reverse hack squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'hips',
    equipment: 'leverage machine',
    id: '863dc6e343b1edf76112c9d8bdd5f5e0',
    imageId: '8b421be1-fd50-4973-3731-992df1597100',
    name: 'reverse hack squat (v2)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'bodyweight',
    id: '5dff1c8bcc0565ee0aae75b166d2686a',
    imageId: '6b8d59b8-5d97-400f-73b3-b1b6e4cce300',
    name: 'reverse lunge (leg kick)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'cable',
    id: '257caa53d47343a7bae254bbd1f4a264',
    imageId: '8962c3e9-5e43-410a-a857-9c15533e4900',
    name: 'reverse tricep pushdown',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'barbell',
    id: '8943fdfc01440dc3404c4fb49cd8d33e',
    imageId: 'aa1c623b-ef3f-4323-5d74-86541a87e600',
    name: 'romanian barbell deadlift',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'dumbbell',
    id: '7a95c6ed123914c5975786d3d6ba205e',
    imageId: 'b69fdff9-1fcf-4f0c-57f3-656bb5ca2f00',
    name: 'romanian dumbbell deadlift',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: '0c82c7ac75a1951da0ea0cbf24a9927a',
    imageId: '6f1b116b-8171-4add-8aed-3360e3cbc200',
    name: 'russian twist',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'cable',
    id: '739cc2c3995f3ac823535c404511d282',
    imageId: '32c755b6-4a38-4836-d038-83a9be5baa00',
    name: 'seated bar row (close grip)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'cable',
    id: '04aa35c91f4177b99a1bbad4b03cf4e3',
    imageId: '7324d86f-549b-4a88-07e6-f20860a03900',
    name: 'seated bar row (wide grip)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'medial',
    equipment: 'dumbbell',
    id: '180fba3011b98b67c3a53be14c06b407',
    imageId: '25fa262a-1368-4fd1-8855-02bb5926a900',
    name: 'seated bent arm dumbbell lateral raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'dumbbell',
    id: 'bfe5f189223230e2467694fe43c49924',
    imageId: '27ea11e6-0a21-403b-344a-28aebd131600',
    name: 'seated dumbbell shoulder press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'leverage machine',
    id: 'fb28330454e45b3a3dd884c1d3acb64b',
    imageId: '9b5fabdf-2251-4de8-00e7-230aeb5d7600',
    name: 'seated hummer row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'hummer machine',
    id: 'bdfba49a0e16aaf096773d146c075887',
    imageId: '65b943e3-462b-4b8a-1b34-c2186a1d3c00',
    name: 'seated hummer shoulder press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'leverage machine',
    id: 'dc0ca22c8be0a2d420a93c6282e283f8',
    imageId: 'b7d49b61-b0e5-4538-b62e-b16ba9478d00',
    name: 'seated leg leverage curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'leverage machine',
    id: 'a072f515ac7136ae818e0aae0705bf58',
    imageId: '658f9b62-e01c-4767-3701-ea8b23e7a600',
    name: 'seated leg leverage press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'leverage machine',
    id: '926835e1f4a6512bf78eed3c48714095',
    imageId: '2a8219ab-a302-46d7-3ceb-362566e69600',
    name: 'seated leg leverage press (v2)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: 'ececd05a5723f571d7511ae01e0127bb',
    imageId: 'df5d87c8-c3ec-420a-db62-ccd5cb711600',
    name: 'seated leg raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'calf',
    equipment: 'leverage machine',
    id: '2039fee18f0288e0e6bde26db7962d1a',
    imageId: 'eb9fe8a5-d19e-4402-0dcc-0a93f83f2300',
    name: 'seated leverage calf press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'calf',
    equipment: 'leverage machine',
    id: '4e3117e132d2825e0d18e8131db783f1',
    imageId: '0665eda9-d68b-4bdc-08f7-54f397a30100',
    name: 'seated leverage calf raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'calf',
    equipment: 'leverage machine',
    id: 'cfc85d52651b9fd985ffa62c3a17c93a',
    imageId: 'bc1b3b07-4855-4f9f-8dfe-693e9cfb5d00',
    name: 'seated leverage calf raise (v2)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'leverage machine',
    id: '41f5b2e4353415aaadd53ae9c37e9c0d',
    imageId: '6e7fd6ca-02c6-42fd-943d-04d6bc2d0e00',
    name: 'seated leverage fly',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'leverage machine',
    id: 'dd54f1008ff8f164a9a61548241bc424',
    imageId: '8453cec0-6d82-4797-3540-706b442e6e00',
    name: 'seated leverage pullover',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'leverage machine',
    id: 'c4b29c418490f4fdc2f5281513a59330',
    imageId: '79124066-19a4-47b8-3a06-adaa39247f00',
    name: 'seated leverage row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'leverage machine',
    id: '633ec4c3e128476f3caafd7df608fc5f',
    imageId: '589bd38c-b7ec-40a9-b68f-6895ea1c4a00',
    name: 'seated leverage row (v2)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'leverage machine',
    id: '108c34b6cec7274d776e0e96a81db26b',
    imageId: '25aa58a5-cac3-452c-86b8-790a6a08dc00',
    name: 'seated leverage shoulder press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'cable',
    id: '955f9e797f92477f98f137c67cf7f820',
    imageId: 'e0146dea-ea4a-42fc-2cce-137f5e5fe100',
    name: 'seated one arm alternate row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'cable',
    id: '36b62142fa6311894d7f50cc565700c7',
    imageId: '4fd15846-8045-4727-6f77-988d5d37cf00',
    name: 'seated one arm row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'barbell',
    id: 'd1c76a15e75f0d4ac55e7cb92554ad38',
    imageId: 'a92d65bd-d16d-4804-0684-6dcf37ef7600',
    name: 'seated overhead barbell press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'cable',
    id: '09c9889a03a68eb39cbbb80c763e3a1f',
    imageId: '5567d6d2-e710-42b0-2352-6e0ad81a3e00',
    name: 'seated overhead cable tricep extension',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'rear',
    equipment: 'leverage machine',
    id: '8bc92deed632ec49bbae52ddd9aac4ef',
    imageId: 'fb5651e4-f293-4cb4-ba45-4c7472ba5900',
    name: 'seated pec reverse fly',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'medial',
    equipment: 'cable',
    id: 'd67799ae94767877610049e5b3a2d6e5',
    imageId: '98c7a43e-ca15-433a-02ea-74793707ab00',
    name: 'seated rear cable lateral raise (medial)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'rear',
    equipment: 'cable',
    id: 'd67799ae94767877610049e5b3a2d6e6',
    imageId: '98c7a43e-ca15-433a-02ea-74793707ab00',
    name: 'seated rear cable lateral raise (rear)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'cable',
    id: 'adc0dec6d8490103ebadc14bcbdc53d2',
    imageId: '4424ff2a-45ed-4018-2465-6c1586b2b900',
    name: 'seated row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'cable',
    id: '4c2fc7c9fdce0d1c1480ef022d8a330f',
    imageId: '653144b9-24ed-4c4f-0b44-bf48f4ecc200',
    name: 'seated row (v bar)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'smith machine',
    id: 'bfeb897f0c2e2a652cfe021ac6940b97',
    imageId: 'f8c05023-f98b-4659-9a32-839ca2173100',
    name: 'smith seated shoulder press',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'bodyweight',
    id: '0bcb5a5990b9ec2372bb7baae4a28c52',
    imageId: 'b4a0c1cd-1bbc-414a-41c9-a3f23ff37d00',
    name: 'single arm push up',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'bodyweight',
    id: '9815ff2efe617fdead7a8f76ed0754aa',
    imageId: 'db3bb390-4cf5-48fa-6eca-c911be7ead00',
    name: 'sit (wall)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'dumbbell',
    id: '7b7901a26c9fefc3760867cb8fd89352',
    imageId: '88dd6230-812e-46e0-1a6a-d5016d736100',
    name: 'skullcrusher',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'smith machine',
    id: '2e7f86528a22d56b4c55899b1462e4b4',
    imageId: '989fc0ff-693c-4647-361c-1249bc17ee00',
    name: 'smith asissted pull up',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'trapezius',
    equipment: 'smith machine',
    id: 'e1728b124904ce99c0c6299374e17ee9',
    imageId: 'd9563c9c-ab81-41f8-a9d8-4126423df700',
    name: 'smith back shrug',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'smith machine',
    id: '757545bb1756a292dbd749b4e1271b7d',
    imageId: 'cd84e133-0058-4c85-f274-bafeff6d1600',
    name: 'smith bench press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'smith machine',
    id: 'de2c63aad8fe9927cb7837da2fdbfdfd',
    imageId: '33b80ddd-ae65-40ca-7807-2e0cddfbf600',
    name: 'smith decline bench press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'smith machine',
    id: 'f6dc5f9d164219344c32134df1e79ddd',
    imageId: 'ff040ed8-a4a1-4b0e-7cfa-b54676b71f00',
    name: 'smith full squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'waist',
    equipment: 'smith machine',
    id: '3ca64bc62e8541b96a4a6f4ea66cdefd',
    imageId: '6ff3af10-0d9c-4022-b0bd-2d6ce3196f00',
    name: 'smith leg raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'smith machine',
    id: '3cfcf947f072b442d1edef3665c534cc',
    imageId: '2aa742b2-b2f8-4fc1-3e89-89dc3e5a3700',
    name: 'smith reverse row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'trapezius',
    equipment: 'smith machine',
    id: 'ed97c4175200b95da975db097af693fb',
    imageId: 'afebb966-98bc-4e90-ae01-93ded535b800',
    name: 'smith shrug',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'smith machine',
    id: '73ba3fe2a61cf78a469accb00e3e8c89',
    imageId: '053e277e-43b0-4702-b1f3-148e3b661000',
    name: 'smith single leg split squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'smith machine',
    id: 'ca4d81c1a1dcf0cd998c6f390532bf66',
    imageId: 'e7c3761a-176e-466d-32a7-5da81fa12700',
    name: 'smith split squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'calf',
    equipment: 'smith machine',
    id: 'b01e058e59713a11bb6a797f702066cd',
    imageId: 'b840bf65-868e-41af-774b-49c0d3509700',
    name: 'smith standing calf raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'smith machine',
    id: '4afa09207f6088364f3ef96225e49eee',
    imageId: '82dd5e09-91e9-4faa-46da-cb2586c40300',
    name: 'smith v - bar press',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'bodyweight',
    id: 'e184f66fa198218ff1a352286f2fa892',
    imageId: '3738d60c-e8b9-42c0-260f-0efc756bd000',
    name: 'squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'cable',
    id: '09a6ac74131ac66cac05b02f2cbf031e',
    imageId: '6f0fdb12-ba56-44ab-86a6-9ac100cabc00',
    name: 'standing cable crossover fly',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'waist',
    equipment: 'cable',
    id: '8574a0e20a7c3c53b2b7f7a64a451f39',
    imageId: 'fcd7022f-580d-4c10-4599-c125e63f6400',
    name: 'standing cable twist',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'waist',
    equipment: 'cable',
    id: '30197d4c39b8ec1f8009ddfb84588178',
    imageId: 'f89c13a0-6510-4184-1ffe-1105d8a4c200',
    name: 'standing cable twist (v2)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'cable',
    id: 'de45d6a711f4583398142cca6c8bda34',
    imageId: 'd3d32ab8-5673-4839-7dc2-00e1642a6900',
    name: 'standing cable crossover overhead tricep extension',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'front',
    equipment: 'dumbbell',
    id: 'c179bb22a3ae8398424c6b1bf4c3d599',
    imageId: 'c0e0588e-d218-4910-4b8e-f1b5f47b2b00',
    name: 'standing dumbbell alternate shoulder press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'calf',
    equipment: 'leverage machine',
    id: 'feed560bf35c56fc8ada61f71a77c82a',
    imageId: 'f16aefa1-c72e-4273-1e83-1ee0d0c1e100',
    name: 'standing leverage calf raise',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'shoulder',
    subType: 'front',
    equipment: 'bodyweight',
    id: 'aec54d90f59b5d758829fe62a42ebab9',
    imageId: '3fc2143a-b959-4b87-f6b0-29c621f28700',
    name: 'standing military press',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'leverage machine',
    id: '28e8f574b69ee77eaf0fd8d348de249d',
    imageId: '29679e08-d86a-4bab-3dbc-65c543428500',
    name: 'standing one leg leverage curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'cable',
    id: '9396ba013d94c6a3e323b04d99e345be',
    imageId: '7e7fa242-6682-4303-2444-ba4570a16800',
    name: 'standing overhead tricep extension',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'cable',
    id: '91f8dd1b499aed3c544733e6feb7aa0b',
    imageId: 'f6fb2e6b-68f8-4809-447b-19b828dfdb00',
    name: 'standing row (palm rotational)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'cable',
    id: 'fc72e54d8639bba8a247c05f3f967155',
    imageId: 'ed4fc2c9-6156-4d55-5801-acc1ccbccb00',
    name: 'straight arm pulldown (bar)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'cable',
    id: 'f8caa3bab4940ea06a278b4053259a3b',
    imageId: 'fa3df481-d915-416a-fc86-87521e2dc900',
    name: 'straight arm pulldown (bar) (v2)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'cable',
    id: '7bec8764cb5132dd7a87ee32169c565d',
    imageId: '9e7d1215-56e5-416b-66e1-de3218885f00',
    name: 'straight arm pulldown (rope)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'barbell',
    id: '6571d9abd7893a9b5403561514b590cc',
    imageId: '4fc6748a-865a-4fcc-eaef-f8365dc61e00',
    name: 'straight leg barbell deadlift',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'dumbbell',
    id: '9b61707d7fe198d6c979d7dbd9c44040',
    imageId: '303e8d63-d87e-47da-59c3-5ddbd7fb8000',
    name: 'straight leg dumbbell deadlift',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'lower back',
    equipment: 'barbell',
    id: 'a7ebce3321e398ca710b05cfd9b1631c',
    imageId: 'c32ad654-df02-4361-1400-216a3ffd1e00',
    name: 't bar row (close grip)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'lower back',
    equipment: 'leverage machine',
    id: '71fc53009ca1d7b496451fdbb36fa3ee',
    imageId: 'd14123fb-a059-4be1-9292-c35b04e0a200',
    name: 't bar row (wide grip)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'barbell',
    id: 'a7ebce3321e398ca710b05cfd9b1631d',
    imageId: 'c32ad654-df02-4361-1400-216a3ffd1e00',
    name: 't rod row',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'bicep',
    subType: 'inner',
    equipment: 'bodyweight',
    id: '58deccb4e2f2f41befdb059cb181a88e',
    imageId: '2284f3ff-b059-4739-71c3-3e2772fc6400',
    name: 'towel biceps curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'hamstring tendon',
    equipment: 'trap bar',
    id: 'ba185d3ac8d5142ec3bc1f481322550d',
    imageId: '1619d1b3-bb02-4a94-632d-a2da65427900',
    name: 'trap bar deadlift',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'cable',
    id: '46bed867298167bc74dd5a9fa051a6fe',
    imageId: '5427414d-0917-45fb-bc6a-683d6dd35a00',
    name: 'tricep overhead extension (kneeling)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'cable',
    id: 'c402083c514da47f11ae59a22d4d1057',
    imageId: 'fe0a537f-14bc-4edf-a179-0154ce279a00',
    name: 'tricep pushdown (bar)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'tricep',
    equipment: 'bodyweight',
    id: '16420db93e48285da0f82b2f691f18b8',
    imageId: 'f50c19ac-57ed-4273-0f9e-03c252b2c000',
    name: 'triceps dip floor',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'tricep',
    equipment: 'bodyweight',
    id: '51cb24d05650b2b6c0da238a8cc7eb4d',
    imageId: 'fb72305d-2a2c-4da5-764a-0e2e163a0200',
    name: 'triceps press (head blow bench)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'trx & band',
    id: 'c4c5b7f34f61ae808819eb4d90410a40',
    imageId: '5659718b-1b8d-4834-89f4-367bf2a59e00',
    name: 'trx - clutch',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'bicep',
    subType: 'inner',
    equipment: 'trx & band',
    id: 'ba6c42e6a6c211b045de4a67d482282d',
    imageId: '0b7307a7-4314-4e8c-2d63-1f5d89b8e200',
    name: 'trx - curl',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'rear',
    equipment: 'trx & band',
    id: '50a262fd9ecda252813e164aeeda2e73',
    imageId: '7d80212f-e0f9-4736-104d-467838bbaf00',
    name: 'trx - face pull',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'shoulder',
    subType: 'rear',
    equipment: 'trx & band',
    id: '3469db574809995ddd58ae7818507d44',
    imageId: '0cf1cae0-4bbf-4ecc-fe7b-0d0067724b00',
    name: 'trx - row',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'trx & band',
    id: '818e91ff5afc1387d805c894e3f8c4fa',
    imageId: '6d7fc70c-d0f5-47c0-908b-96de25c44a00',
    name: 'trx - side squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'trx & band',
    id: '512e0bc3acd98b8ec44dd1cb1807d59d',
    imageId: 'ca4002fb-89be-4773-318c-c4ad27c51300',
    name: 'trx - single leg squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'thigh',
    subType: 'quadricep',
    equipment: 'trx & band',
    id: '996c6bfe80f6106bf12b00a471db93e6',
    imageId: '2f081c52-e9a8-4254-3ce9-1efa5744ec00',
    name: 'trx - split squat',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'trx & band',
    id: 'a5fe924fe0552ee9b1e907ce803970d5',
    imageId: '8d3cdb77-8715-442d-c72c-de61b7215000',
    name: 'trx - triceps extension',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'waist',
    equipment: 'bodyweight',
    id: 'f9c1ac4dfe79fabef67da5a05db52fac',
    imageId: '6084b9ad-15d4-4580-ad88-f3c98169e400',
    name: 'twisted leg raise',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'tricep',
    equipment: 'cable',
    id: '8a5e7e60964fca2d3177c24b7e3184c2',
    imageId: '82328110-f798-4d70-c6da-addee6e2df00',
    name: 'v - bar cable pushdown',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'cable',
    id: '95ea1eec4833bad6a30163ae38fa814b',
    imageId: '93cd98f6-0742-4240-5289-912643fedb00',
    name: 'v - bar pulldown',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'barbell',
    id: 'dd6b17e5c067e92335bcddfd86e9fee1',
    imageId: '7a4f450c-f6f9-4364-b125-5549c398f000',
    name: 'barbell bench press (wide grip)',
  },
  {
    category: 'strength',
    recordType: 'bodyweight',
    type: 'chest',
    subType: 'middle and lower chest',
    equipment: 'bodyweight',
    id: '90070bf1b1b83ad8f38e9303aa6974de',
    imageId: 'd94726ce-2984-48a3-0190-5a1828b28400',
    name: 'push up (wide hand)',
  },
  {
    category: 'strength',
    recordType: 'strength',
    type: 'back',
    subType: 'upper back',
    equipment: 'cable',
    id: '8c3f69e8404b2083eef91937d9734861',
    imageId: '0d6986c1-1565-47c1-c470-250f76c33300',
    name: 'cable pulldown (wide)',
  },
] as StrengthExercise[];

export const StrengthTypes: { type: string; subTypes: string[] }[] = [
  {
    type: 'back',
    subTypes: ['lower back', 'upper back'],
  },
  {
    type: 'bicep',
    subTypes: ['inner', 'outer'],
  },
  {
    type: 'calf',
    subTypes: [],
  },
  {
    type: 'chest',
    subTypes: ['middle and lower chest', 'upper chest'],
  },
  {
    type: 'forearm',
    subTypes: [],
  },
  {
    type: 'hips',
    subTypes: [],
  },
  {
    type: 'shoulder',
    subTypes: ['front', 'medial', 'rear'],
  },
  {
    type: 'thigh',
    subTypes: ['hamstring tendon', 'quadricep'],
  },
  {
    type: 'trapezius',
    subTypes: [],
  },
  {
    type: 'tricep',
    subTypes: [],
  },
  {
    type: 'waist',
    subTypes: [],
  },
];

export const StrengthEquipments: string[] = [
  'barbell',
  'bodyweight',
  'cable',
  'dumbbell',
  'hummer machine',
  'leverage machine',
  'trap bar',
  'others',
  'smith machine',
  'trx & band',
];
