import { BusinessClientMemoGoalVo } from '@cocast/api-client';
import { useStateRef } from '@cocast/hooks/useStateRef';
import { Icons } from '@cocast/icons';
import { Input } from 'legacy/Input/Input';
import { memo, useCallback } from 'react';
import { clientAction } from 'state';
import { Sheet, SheetProps, useSheet } from 'widgets';

interface Props extends Pick<SheetProps, 'open' | 'setOpen'> {
  onCreated?: (goal: BusinessClientMemoGoalVo) => unknown;
}

function GoalFormComponent({ open, setOpen, onCreated }: Props) {
  const [title, setTitle, titleRef] = useStateRef('');
  const [description, setDescription, descriptionRef] = useStateRef('');

  const onAdd = useCallback(
    async () =>
      clientAction.createGoal(
        {
          title: titleRef.current,
          description: descriptionRef.current,
        },
        (goal) => {
          onCreated?.(goal);
          setOpen.disable();
        },
      ),
    [onCreated],
  );

  return (
    <Sheet
      open={open}
      setOpen={setOpen}
      confirmText="Add"
      confirmDisabled={(title.trim()?.length || 0) < 2}
      onConfirm={onAdd}
      onCancel
      noResize
      noScrollClose={!!(title.trim() || description.trim())}
    >
      <h2>Add Goal</h2>
      <Input
        className="dark mt-lg"
        prefix={<Icons.Goal size={4} />}
        title="Title"
        maxLength={64}
        onValueChange={setTitle}
        noErrorMessage
        required
      />
      <Input
        title="Description"
        as="textarea"
        inputClassName="resize-none"
        className="dark !h-[88px] my-lg"
        prefixClassName="items-start pt-[14px]"
        maxLength={512}
        onValueChange={setDescription}
        noErrorMessage
        counter
      />
    </Sheet>
  );
}

const GoalForm = memo(GoalFormComponent);

export function useGoalForm() {
  return useSheet(GoalForm);
}
